import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiTruckS = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M18.13 6.2002H5.875V14.2549H18.13V6.2002Z" />
    <path d="M7.3919 16.224H6.60107V14.2529H8.97356" />
    <path d="M7.05908 14.2529H18.1305V16.224H10.5556" />
    <path d="M18.9207 16.2238H18.1299V8.33984H21.2932L22.8749 12.3546V16.2238H22.084" />
    <path d="M21.2934 16.6181C21.2934 15.965 20.7623 15.4355 20.1071 15.4355C19.452 15.4355 18.9209 15.965 18.9209 16.6181C18.9209 17.2713 19.452 17.8007 20.1071 17.8007C20.7623 17.8007 21.2934 17.2713 21.2934 16.6181Z" />
    <path d="M9.76458 16.6181C9.76458 15.965 9.23347 15.4355 8.57833 15.4355C7.92318 15.4355 7.39209 15.965 7.39209 16.6181C7.39209 17.2713 7.92318 17.8007 8.57833 17.8007C9.23347 17.8007 9.76458 17.2713 9.76458 16.6181Z" />
    <path d="M22.8747 12.2817L19.7114 12.2818V8.33984" />
    <path d="M5.87482 15.2812L4.71151 15.2813L1.125 17.5001" />
  </SvgWrapper>
);
