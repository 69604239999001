import { inventoryChecksActions } from "api/wh-inventory-check/actions";
import {
  InventoryCheckItem,
  NormalizedInventoryCheckSummaryItem,
} from "api/wh-inventory-check/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { ErrorType } from "hooks/createApiQuery";
import { useNavigate } from "hooks/useNavigate";
import { useParams } from "react-router";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { useFilters } from "hooks/useFilters";
import { FiltersBar } from "./components/FiltersBar";
import { Line } from "components/miloDesignSystem/atoms/line";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useMemo } from "react";
import cuid from "cuid";
import { useInventoryCheckSummaryColumns } from "./useInventoryCheckSummaryColumns";
import { Paper } from "components/miloDesignSystem/atoms/paper";

interface HeaderProps {
  error: ErrorType | null;
  inventoryCheck: InventoryCheckItem | null;
  isLoading: boolean;
}

export interface Filters {
  differenceGreaterThan: string;
  differenceLessThan: string;
  page: number;
  pageSize: number;
  search: string;
}

export const InventoryCheckSummary = () => {
  const { inventoryCheckId } = useParams<{ inventoryCheckId: string }>();
  const { data: inventoryCheck, error, isLoading } = inventoryChecksActions.useInventoryCheck(
    Number(inventoryCheckId),
  );
  const { filters, setFilter, searchParams } = useFilters<Filters>({
    differenceGreaterThan: "",
    differenceLessThan: "",
    page: 1,
    pageSize: 25,
    search: "",
  });
  const search = `${inventoryCheckId}$${searchParams}`;
  const {
    data: summary,
    error: summaryError,
    isLoading: isSummaryLoading,
    isPreviousData,
    pagination,
  } = inventoryChecksActions.useInventoryChecksSummary(search);
  const columns = useInventoryCheckSummaryColumns();
  const normalizedSummary: NormalizedInventoryCheckSummaryItem[] = useMemo(() => {
    return summary.map(summaryItem => ({ ...summaryItem, id: cuid() }));
  }, [summary]);
  const tableProps = useTableFeatureConnector({
    rows: normalizedSummary,
  });

  return (
    <PageWrapper>
      <Header error={error} inventoryCheck={inventoryCheck} isLoading={isLoading} />

      <Paper
        backgroundColor="neutralWhite100"
        className="d-flex flex-column m-3 pb-3 flex-1 overflow-hidden"
      >
        <GeneralInfoSection error={error} inventoryCheck={inventoryCheck} isLoading={isLoading} />
        <FiltersBar filters={filters} setFilter={setFilter} />
        <div className="px-3">
          <Line color="neutralBlack12" className="w-100 mt-2" lineStyle="solid" />
        </div>
        <div className="px-3 flex-1 d-flex flex-column overflow-hidden">
          <Table<NormalizedInventoryCheckSummaryItem>
            columns={columns}
            error={summaryError}
            isLoading={isSummaryLoading || isPreviousData}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            pagination={pagination}
            uiSchema={{
              cell: { height: "36" },
              header: { backgroundColor: "neutralWhite100" },
            }}
            {...tableProps}
          />
        </div>
      </Paper>
    </PageWrapper>
  );
};

const Header = ({ error, inventoryCheck, isLoading }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <PageHeader
      searchBar={false}
      title={
        <div className="d-flex align-items-center gap-2">
          <IconButton
            icon={MdiArrowBack}
            onClick={() => navigate(`/finances/inventory-checks/list/all`)}
            variant="gray"
          />
          {error ? (
            <CommonError status={error._httpStatus_} />
          ) : isLoading ? (
            <Spinner size={20} />
          ) : (
            <Typography fontSize="20" fontWeight="700">
              {inventoryCheck?.signature}
            </Typography>
          )}
        </div>
      }
      viewLabel="INVENTORY_CHECK_SUMMARY"
    />
  );
};
