import styles from "./Login.module.css";
import { Field, FormikHelpers } from "formik";
import { getUserInfo, obtainToken } from "api/users/calls";
import { useHistory, useRouteMatch } from "react-router";
import { useRedux } from "hooks";
import cx from "classnames";
import { Button } from "components/common";
import { Link } from "react-router-dom";
import mainImg from "assets/images/route.jpeg";
import manufacturingImg from "assets/images/manufacturing.jpg";
import logoImg from "assets/images/logo.svg";
import kivyImg from "assets/images/kivy.png";
import { ErrorMessage, FormWizard } from "components/utils";
import arrowImg from "assets/images/50.svg";
import { getAnyErrorKey } from "utilities";
import { FLAVOR, permittedUserTypes } from "CONSTANTS";
import { moduleConfig } from "components/common/moduleNavigation/moduleConfig";
import { UserInfo } from "api/users/models";
import { isModuleLink } from "typePredicates";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";

interface Values {
  phone: string;
  password: string;
}

/**
 * Validators for each form page
 */
const validators = {
  0: (values: Values) => {
    const errors: Partial<{ [K in keyof Values]: string }> = {};
    if (!values.phone) {
      errors.phone = "Wpisz numer telefonu";
    }
    if (values.phone.match(/[a-z]/i)) {
      errors.phone = "Numer telefonu nie powinien zawierać liter";
    }
    return errors;
  },
  1: (values: Values) => {
    const errors: Partial<{ [K in keyof Values]: string }> = {};
    if (!values.password) {
      errors.password = "Wpisz hasło";
    }
    return errors;
  },
};

export const Login = () => {
  const history = useHistory();
  const matchLogin = useRouteMatch({
    path: "/login",
    strict: true,
  });

  const matchMain = useRouteMatch({
    path: "/",
    strict: true,
  });

  const [dispatch, { auth }] = useRedux();
  const initialValues = {
    phone: "",
    password: "",
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setErrors }: FormikHelpers<Values>,
  ) => {
    const data = {
      phone: values.phone.replace(/ /g, ""), // remove spaces
      password: values.password.trim(),
    };
    const [payload, error] = await obtainToken(data);
    if (payload) {
      localStorage.setItem("token", payload.access);
      localStorage.setItem("refreshToken", payload.refresh);
      const [user, error] = await getUserInfo();

      if (user) {
        const redirectUrl = getUserRedirectAfterLogin(user);

        if (!permittedUserTypes.includes(user.type)) {
          setErrors({ password: "Nie masz dostępu do aplikacji" });
          return;
        }

        localStorage.setItem("userUUID", user.uuid);
        dispatch(auth.login(user));

        if (matchLogin?.isExact || matchMain?.isExact) {
          history.replace(redirectUrl);
        }
      } else if (error) {
        setErrors({ password: getAnyErrorKey(error) });
        setSubmitting(false);
      }
    } else if (error) {
      setErrors({ password: getAnyErrorKey(error) });
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={cx("d-flex justify-content-between p-4", styles.nav)}>
        <div className="app-logo">
          <img src={logoImg} alt="Milo" />
        </div>
      </div>
      <div className={styles.imageBox}>
        {(FLAVOR === "main" || FLAVOR === "b2b") && <img alt="Logowanie - main" src={mainImg} />}
        {FLAVOR === "manufacturing" && <img alt="Logowanie - produkcja" src={manufacturingImg} />}
        {FLAVOR === "externalManufacturing" && (
          <img alt="Logowanie - zewnętrzna produkcja" src={manufacturingImg} />
        )}
      </div>
      <div className={cx("d-flex align-items-center justify-content-center", styles.formBox)}>
        <div className={styles.container}>
          <h1>Witaj w Milo!</h1>
          <h5>Miło Cię widzieć</h5>
          <br />
          <FormWizard initialValues={initialValues} onSubmit={handleSubmit} pages={2}>
            {({ values, isLastPage, previous, isSubmitting, errors }) => (
              <>
                <FormWizard.Page page={0} validate={validators[0]}>
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>Wpisz swój numer telefonu</h4>
                  <Field
                    id="phone"
                    name="phone"
                    autoFocus
                    placeholder="791260529"
                    type="text"
                    className={styles.input}
                  />
                  <ErrorMessage name="phone" />
                </FormWizard.Page>
                <FormWizard.Page page={1} validate={validators[1]}>
                  <div className="mt-5">
                    <div className="mb-2">{values.phone}</div>
                    <Button kind="secondary-stroke" size="round-s" onClick={previous}>
                      <img src={arrowImg} alt="" />
                    </Button>
                  </div>
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>Podaj hasło aby kontynuować</h4>
                  <Field
                    autoFocus
                    id="password"
                    name="password"
                    placeholder="Hasło"
                    type="password"
                    className={styles.input}
                  />
                  <ErrorMessage name="password" />
                  <ErrorMessage type="plain" name="message" error={errors} />
                </FormWizard.Page>
                {!isLastPage && (
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <DesignSystemButton
                      size="large"
                      type="submit"
                      typographyProps={{
                        fontFamily: "cabin",
                      }}
                      variant="deepPurple"
                    >
                      Kontynuuj
                    </DesignSystemButton>
                  </div>
                )}
                {isLastPage && (
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <DesignSystemButton
                      isLoading={isSubmitting}
                      size="large"
                      type="submit"
                      typographyProps={{
                        fontFamily: "cabin",
                      }}
                      variant="deepPurple"
                    >
                      Zaloguj
                    </DesignSystemButton>
                    <Link to={"/reset-password?phone=" + values.phone}>Nie pamiętam hasła</Link>
                  </div>
                )}
              </>
            )}
          </FormWizard>
        </div>
      </div>
      <div className={cx("p-4", styles.footer)}>
        <span>Crafted by</span>
        <div>
          <a href="https://kivy.pl/pl/">
            <img src={kivyImg} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

function getUserRedirectAfterLogin(user: UserInfo): string {
  const moduleToRedirect = Object.values(moduleConfig)
    .filter(module =>
      module.navigationSections.some(section => isModuleLink(section) && section.isAccessible),
    )
    .find(config => config.name === user.startView);

  if (moduleToRedirect) return moduleToRedirect.url;

  const flavorToRedirect: Record<typeof FLAVOR, string> = {
    b2b: "/client/orders",
    externalManufacturing: "/external-manufacturer-panel/manufacturer-line-items",
    main: "/orders",
    manufacturing: "/",
  };
  return flavorToRedirect[FLAVOR];
}
