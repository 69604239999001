import { CURRENCY_TYPE } from "CONSTANTS";
import { TradingDocument } from "api/trading-documents/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { DownloadPaymentsPDF } from "pages/finances/payments/components/downloadPaymentsPdf/DownloadPaymentsPDF";
import { dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useFinancesPaymentsColumns = () => {
  return useCreateTableColumns<TradingDocument["payments"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row.kind, {
          header: "typ",
          size: 35,
          cell: info => {
            const kind = info.getValue();
            return <FinancesPaymentKind kind={kind} />;
          },
        }),
        columnHelper.accessor(row => row, {
          header: "nr płatności",
          size: 100,
          cell: info => {
            const financesPayment = info.getValue();
            return (
              <div className="d-flex flex-column" style={{ maxWidth: "100%" }}>
                <Typography.OverflowTextWithTooltip fontSize="12" fontWeight="700" noWrap>
                  {financesPayment.paymentNumber || EMPTY_VALUE}
                </Typography.OverflowTextWithTooltip>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="500" noWrap>
                  {financesPayment.paymentDate
                    ? dateUtils.formatDateToDisplayOnlyDate(financesPayment.paymentDate)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "amountAndCommission",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              kwota | prowizja
            </Typography>
          ),
          size: 100,
          cell: info => {
            const financesPayment = info.getValue();
            return (
              <div className="d-flex flex-column w-100 text-right align-items-end">
                <AmountDisplay
                  amount={financesPayment.amount.toString()}
                  currency={financesPayment.currency as CURRENCY_TYPE}
                  decimalTypographyProps={{
                    fontSize: "12",
                    fontWeight: "700",
                  }}
                  integerTypographyProps={{
                    fontSize: "12",
                    fontWeight: "700",
                  }}
                />
                {financesPayment.commission !== null ? (
                  <AmountDisplay
                    amount={financesPayment.commission.toString()}
                    currency={financesPayment.currency as CURRENCY_TYPE}
                    decimalTypographyProps={{
                      fontSize: "12",
                      fontWeight: "400",
                    }}
                    integerTypographyProps={{
                      fontSize: "12",
                      fontWeight: "400",
                    }}
                  />
                ) : (
                  <Typography fontSize="12" fontWeight="400">
                    nie podlega
                  </Typography>
                )}
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.type, {
          header: "sposób",
          size: 60,
          cell: info => {
            const type = info.getValue();
            return <FinancesPaymentType type={type} />;
          },
        }),
        columnHelper.text(row => row.provider, {
          header: "usługa",
          size: 50,
          typographyProps: {
            fontSize: "10",
            fontWeight: "800",
          },
        }),
        columnHelper.accessor(row => row.mode, {
          header: "metoda",
          size: 50,
          cell: info => {
            const mode = info.getValue();
            return <FinancesPaymentMode mode={mode} />;
          },
        }),
        columnHelper.accessor(row => row, {
          header: "nr przelewu",
          size: 100,
          cell: info => {
            const financesPayment = info.getValue();
            if (!Boolean(financesPayment.bankTransactionNumber.length))
              return <EmptyValue fontSize="12" />;
            return (
              <div className="d-flex flex-column" style={{ maxWidth: "100%" }}>
                <Typography.OverflowTextWithTooltip fontSize="12" fontWeight="500" noWrap>
                  {financesPayment.bankTransactionNumber}
                </Typography.OverflowTextWithTooltip>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
                  {financesPayment.bankTransactionDate
                    ? dateUtils.formatDateToDisplayOnlyDate(financesPayment.bankTransactionDate)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.avatar(row => row.createdBy, {
          header: "odp.",
          size: 32,
          avatarProps: {
            size: "sm",
          },
        }),
        columnHelper.date(row => row.createdAt, {
          header: "dodano",
          size: 60,
          typographyProps: {
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        }),
        columnHelper.accessor(row => row, {
          id: "downloadPDF",
          header: " ",
          size: 40,
          cell: info => {
            const financesPayment = info.getValue();
            if (financesPayment.type === "CASH" && financesPayment.kind === "PAYMENT")
              return (
                <DownloadPaymentsPDF financesPayments={[{ ...financesPayment, signature: "" }]} />
              );
            return null;
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};

const FinancesPaymentKind = ({ kind }: { kind: TradingDocument["payments"][number]["kind"] }) => {
  switch (kind) {
    case "ADVANCE":
      return <Tag label="zal." variant="success" />;
    case "OTHER":
      return <Tag label="inn." type="outlined" variant="quaternary" />;
    case "PAYMENT":
      return <Tag label="płt." variant="deepPurple400" />;
    case "REFUND":
      return <Tag label="zwr." variant="danger" />;
    default:
      const exhaustiveCheck: never = kind;
      console.error(`Unhandled payment kind: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
  }
};

const FinancesPaymentType = ({ type }: { type: TradingDocument["payments"][number]["type"] }) => {
  switch (type) {
    case "CASH":
      return <Tag label="gotówka" type="outlined" variant="success" />;
    case "ONLINE":
      return <Tag label="przelew" type="outlined" variant="deepPurple400" />;
    default:
      const exhaustiveCheck: never = type;
      console.error(`Unhandled payment type: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
  }
};

const FinancesPaymentMode = ({ mode }: { mode: TradingDocument["payments"][number]["mode"] }) => {
  switch (mode) {
    case "AUTO":
      return (
        <Typography fontSize="10" fontWeight="600">
          automat.
        </Typography>
      );
    case "MANUAL":
      return (
        <Typography fontSize="10" fontWeight="600">
          manual.
        </Typography>
      );
    default:
      const exhaustiveCheck: never = mode;
      console.error(`Unhandled payment mode: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="10" />;
  }
};
