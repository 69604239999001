import { CarrierOrder, CarrierOrderPaymentMethod } from "api/logistics/carrierOrders/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns, dateUtils, generateDictBasedOnFactoryAndEnum } from "utilities";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { carriersContstants } from "constants/carriers";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { PostDeliveryInfo } from "pages/routes/shared/PostDeliveryInfo";
import { countryCurrenciesFlags } from "CONSTANTS";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

interface Props {
  carrierOrder: CarrierOrder;
}

export const GeneralInfoSection = ({ carrierOrder }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {carrierOrder.route.signature}
        </Typography>
      </div>
      <InfoLabel title="przewoźnik">
        <div>
          <Typography fontSize="14" fontWeight="700">
            {carrierOrder.carrier.companyName}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.street},
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.postCode}
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.city}
            </Typography>
          </div>
        </div>
      </InfoLabel>
      <InfoLabel title="data zlecenia">
        <Typography fontSize="14" fontWeight="400">
          {dateFns.format(new Date(carrierOrder.createdAt), "dd.MM.yyyy, H:mm")}
        </Typography>
      </InfoLabel>
      <InfoLabel title="zlecono przez">
        <>
          <Avatar size="sm" user={carrierOrder.createdBy} theme="light" />
          <Typography className="overflow" fontSize="12" fontWeight="700">
            {carrierOrder.createdBy.firstName} {carrierOrder.createdBy.lastName}
          </Typography>
        </>
      </InfoLabel>
      <InfoLabel title="nr rejestracyjny">
        <Typography fontSize="14" fontWeight="700">
          {carrierOrder.car.registrationNumber}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kierowcy">
        {Boolean(carrierOrder.drivers.length) ? (
          <Typography fontSize="14" fontWeight="600" noWrap>
            {carrierOrder.drivers
              .map(driver => `${driver.firstName} ${driver.lastName}`)
              .join(", ")}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="data załadunku">
        <Typography fontSize="14" fontWeight="600">
          {Boolean(carrierOrder.route.loadingDate && carrierOrder.route.loadingDate !== "None")
            ? dateUtils.formatDateToDisplayOnlyDate(carrierOrder.route.loadingDate)
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="data wyjazdu">
        <Typography fontSize="14" fontWeight="700">
          {Boolean(carrierOrder.route.departureDate && carrierOrder.route.loadingDate !== "None")
            ? dateUtils.formatDateToDisplayOnlyDate(carrierOrder.route.departureDate)
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="metoda płatności">
        <Select.Async
          items={generateDictBasedOnFactoryAndEnum(
            carriersContstants.getPaymentMethods,
            CarrierOrderPaymentMethod,
          ).map(({ value, key }) => ({
            text: value,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={carrierOrdersActions.usePatchCarrierOrder}
          selected={carrierOrder.paymentMethod}
          transformQueryData={paymentMethod => ({
            id: carrierOrder.id,
            paymentMethod: paymentMethod as CarrierOrderPaymentMethod,
          })}
        />
      </InfoLabel>
      <InfoLabel title="termin płatności [dni]">
        <TextField.Async
          mutationHook={carrierOrdersActions.usePatchCarrierOrder}
          size="small"
          transformQueryData={paymentDeadlineDays => ({
            id: carrierOrder.id,
            paymentDeadlineDays: Number(paymentDeadlineDays),
          })}
          type="number"
          value={carrierOrder.paymentDeadlineDays}
        />
      </InfoLabel>
      <CodSummary carrierOrder={carrierOrder} />
      <PostDeliveryInfo
        routeId={Number(carrierOrder.route.id)}
        ordersCount={carrierOrder.orders.length}
      />
    </RightPanelSection>
  );
};

const CodSummary = ({ carrierOrder }: Props) => {
  if (!Boolean(carrierOrder.codSummary.length))
    return (
      <InfoLabel className="py-2" title="suma pobrań">
        <EmptyValue fontSize="14" />
      </InfoLabel>
    );
  return (
    <InfoLabel className="py-2 align-items-start" title="suma pobrań">
      <div className="d-flex flex-column gap-2">
        {carrierOrder.codSummary.map(summary => (
          <div className="d-flex align-items-center gap-2">
            {summary.currency && <img alt="" src={countryCurrenciesFlags[summary.currency]} />}
            <AmountDisplay amount={String(summary.amount)} currency={summary.currency} />
          </div>
        ))}
      </div>
    </InfoLabel>
  );
};
