import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { draftOrdersApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";
import { UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import { DraftOrderLineItem } from "./models";
import { draftOrdersKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";

const useItems = createPaginatedApiQuery(draftOrdersApi.getItems);
const useItem = createApiQuery(draftOrdersApi.getItem);

const useDeleteItem = () => {
  return withDeleteConfirmation(
    useMutation(draftOrdersApi.deleteItem, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Usunięto robocze zlecenie",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć to robocze zlecenie?",
  )();
};

const useDeleteLineItem = () => {
  return withDeleteConfirmation(
    useMutation(draftOrdersApi.deleteLineItem, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Usunięto indeks",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten indeks?",
  )();
};

const useLineItems = createPaginatedApiQuery(draftOrdersApi.getLineItems);

const useCreateDraftOrder = () => {
  return useMutation(draftOrdersApi.postDraft, ({ toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono robocze zlecenie do producenta",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useAddLineItem = () => {
  return useMutation(draftOrdersApi.postLineItem, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano wybrane elementy do roboczego zlecenia",
      });
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useConfirmDraftOrder = () => {
  return useMutation(
    draftOrdersApi.confirmDraft,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );
};

const usePatchLineItem = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<DraftOrderLineItem> }) => {
      return draftOrdersApi.patchLineItem(toUpdate, id);
    },
    ({ queryUtils, queryClient }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          draftOrdersKeys.lineItems.list(),
          id,
          toUpdate,
        );

        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: (_, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(draftOrdersKeys.lineItems.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const draftOrdersActions = {
  useAddLineItem,
  useConfirmDraftOrder,
  useCreateDraftOrder,
  useDeleteItem,
  useDeleteLineItem,
  useItem,
  useItems,
  useLineItems,
  usePatchLineItem,
};
