import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { CommonError, Pagination } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DashedLabel } from "../../../subcomponents/DashedLabel";
import { ColumnWrapper } from "../ColumnWrapper";
import { useQuery } from "hooks";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { queryString } from "utilities";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { useFilters } from "hooks/useFilters";
import { DrawerRenderer, useManufacturingBoardDrawer } from "../../panel/DrawerRenderer";
import { ColumnType } from "../../ColumnView";
import { memo, useMemo } from "react";
import { ManufacturingUnit } from "api/manufacturing/units/models";
import { getDraggableElementStyles } from "../shared";
import { useIsMutating } from "react-query";
import { OrderTypeChoices } from "api/orders/enums";

export const ReadySection = () => {
  const { query, updateQuery } = useQuery();
  const stageId = useStageId();
  const { filters, setFilter } = useFilters({
    masterStages: stageId,
    status: "FINISHED",
    ordering: "finishedAt",
    page: 1,
  });

  const {
    data: units,
    error,
    isFetching,
    isPreviousData,
    isLoading,
    pagination,
  } = manufacturingUnitsActions.useGetUnitItems(
    queryString.stringify({ ...filters, ...query, search: query.readyUnitsSearch }),
    {
      keepPreviousData: true,
    },
  );

  const { data: manufacturingItemCount } = manufacturingUnitsActions.useStatistics(
    manufacturingStagesUtils.getStatisticsSearch(query, stageId),
  );
  const isMutationLoading = useIsMutating();

  const sortedUnits = useMemo(() => {
    return manufacturingStagesUtils.getSortedManufacturingUnitGroups<ManufacturingUnit>(
      units ?? [],
      "finishedAt",
    );
  }, [units]);

  if (isLoading && !isPreviousData)
    return (
      <div className="position-relative d-flex overflow-hidden flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  if (error) {
    return (
      <div className="position-relative d-flex overflow-hidden flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <CommonError status={error._httpStatus_} />
          </div>
        </ColumnWrapper>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column overflow-hidden flex-1 px-0">
      <Droppable droppableId={manufacturingStagesConstants.READY_DROPPABLE}>
        {(provided, snapshot) => (
          <ColumnWrapper
            isDragOver={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="d-flex align-items-center justify-content-between py-2 gap-3">
              <div className="d-flex align-items-center gap-2">
                <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
                  Gotowe
                </Typography>
                <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                  {manufacturingItemCount?.counts.finished || "brak"}
                </Typography>
                {(isFetching || Boolean(isMutationLoading)) && <Spinner size={16} />}
              </div>
              <ColumnSearch
                isLoading={isFetching}
                queryKey="readyUnitsSearch"
                onChange={search => updateQuery({ ...query, readyUnitsSearch: search ?? "" })}
                value={query["readyUnitsSearch"]}
              />
            </div>

            <ListWrapper sortedUnits={sortedUnits} />

            <span
              style={{
                display: "none",
              }}
            >
              {provided.placeholder}
            </span>
          </ColumnWrapper>
        )}
      </Droppable>
      <Pagination
        page={filters.page}
        onChange={page => setFilter("page", page)}
        mode="manual"
        className="position-relative"
        removeMarginLeft
        pagination={pagination}
      />

      <DrawerRenderer columnType={ColumnType.READY} />
    </div>
  );
};

const ListWrapper = memo(({ sortedUnits }: { sortedUnits: (string | ManufacturingUnit)[] }) => {
  const { toggleDrawer } = useManufacturingBoardDrawer();
  return (
    <div className="d-flex flex-column">
      {sortedUnits.map((element, index) => {
        if (typeof element === "string") {
          return <DashedLabel key={element} label={element} />;
        }
        return (
          <Draggable index={index} draggableId="NoDrag" isDragDisabled key={element.id}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getDraggableElementStyles(snapshot, provided.draggableProps.style)}
              >
                <ManufacturingTicket
                  canAssignEmployee
                  isDragging={false}
                  onClick={ticket => {
                    toggleDrawer({
                      columnType: ColumnType.READY,
                      id: String(element.id),
                      type: "unitItem",
                    });
                  }}
                  ticket={{
                    attributesValues: element.attributeValues,
                    itemNote: element.itemNote,
                    productName: element.name,
                    externalOrderNumber: element.manufacturingItem.externalOrderNumber,
                    employee: element.employee,
                    isComplaint: element.orderType === OrderTypeChoices.COMPLAINT,
                    id: element.id,
                    cancelledElements: element.isCancelled ? 1 : undefined,
                    declinedElements: element.isDeclined ? 1 : undefined,
                    implementedBy: element.implementedBy,
                    manufacturer: element.manufacturer?.name,
                    manufacturingItems: [element.manufacturingItem.id],
                    note: element.note,
                    priority: element.priority,
                    recentlyMovedDate: element.finishedAt,
                    signature: element.manufacturingItem.signature,
                  }}
                />
              </div>
            )}
          </Draggable>
        );
      })}
    </div>
  );
});
