import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { sellersKeys } from "./keys";
import { CreateSeller, Seller } from "./models";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";

export const getSellers = (search: string = ""): ApiFetcher<Pagination<Seller>> => ({
  key: sellersKeys.seller.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/business-entities/items" + search,
    }),
});

export const getSeller = ({ id }: { id: UUID }): ApiFetcher<Seller> => ({
  key: sellersKeys.seller.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/business-entities/items/" + id,
    }),
});

export const postSeller = (data: CreateSeller) =>
  queryFetch<Seller>({
    method: "POST",
    url: "/finances/business-entities/items",
    data,
  });

export const patchSeller = (data: PartialOf<Seller>, id: UUID) =>
  queryFetch<Seller>({
    method: "PATCH",
    url: "/finances/business-entities/items/" + id,
    data: parsePatchData(data),
  });

/**
 * @argument data = {file: File}
 */
export const patchSellerLogo = (args: { data: FormData; sellerId: Seller["id"] }) =>
  queryFetch<Seller>({
    method: "PATCH",
    url: "/finances/business-entities/items/" + args.sellerId,
    data: args.data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.png"',
    },
  });

export const deleteSeller = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/business-entities/items/" + id,
  });
