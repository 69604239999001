import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { financesKeys } from "./keys";
import {
  CreateTradingDocumentFooterColumnDTO,
  FinanceReportBalance,
  TradingDocumentFooterColumn,
} from "./models";
import { CreateDraftDocumentPositionPayload, TradingDocument } from "api/trading-documents/models";
import { createPreviewFactory } from "utilities/createPreviewMiddleware";
import { Pagination } from "api/types";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

export const getReportBalance = ({
  search,
}: {
  search: string;
}): ApiFetcher<FinanceReportBalance> => ({
  key: financesKeys.getReportBalance(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/reports/balance" + search,
    }),
});

const postDraftDocumentPosition = (data: CreateDraftDocumentPositionPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents-items/work-in-progress-items",
    data,
  });

const getTradingDocumentFooterColumns = (
  search: string = "",
): ApiFetcher<Pagination<TradingDocumentFooterColumn>> => ({
  key: financesKeys.tradingDocumentFooterColumns.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/business-entities/trading-document-footer-columns/items" + search,
    }),
});

const postTradingDocumentFooterColumn = (data: CreateTradingDocumentFooterColumnDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/business-entities/trading-document-footer-columns/items",
    data,
  });

const patchTradingDocumentFooterColumn = (
  data: Assign<Partial<TradingDocumentFooterColumn>, { id: TradingDocumentFooterColumn["id"] }>,
) =>
  queryFetch<TradingDocumentFooterColumn>({
    method: "PATCH",
    url: "/finances/business-entities/trading-document-footer-columns/items/" + data.id,
    data: parsePatchData(data),
  });

const deleteTradingDocumentFooterColumn = (id: TradingDocumentFooterColumn["id"]) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/business-entities/trading-document-footer-columns/items/" + id,
  });

export const financesPreviewFactory = createPreviewFactory({
  tradingDocumentCmrPDF: (tradingDocument: TradingDocument) => ({
    url: `/finances/trading-documents/cmr-pdf/${tradingDocument.id}`,
  }),
});

export const financesApi = {
  deleteTradingDocumentFooterColumn,
  getReportBalance,
  getTradingDocumentFooterColumns,
  patchTradingDocumentFooterColumn,
  postDraftDocumentPosition,
  postTradingDocumentFooterColumn,
};
