import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiDraft2 } from "components/miloDesignSystem/atoms/icons/MdiDraft2";
import { DraftOrdersRouting } from "routes/externalManufacturing/DraftOrdersRouting";

export const draftOrders: ModuleLink = {
  url: "draft-orders",
  label: "Szkice zleceń",
  icon: MdiDraft2,
  routing: DraftOrdersRouting,
};
