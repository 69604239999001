import { Button, PageHeader } from "components/common";
import messageIcon from "assets/images/mail.svg";
import call_missed from "assets/images/call_missed.svg";
import timelineIcon from "assets/images/timeline.svg";
import { CallCenterRouteDetails } from "api/call-center/routes/models";
import { FilterRoundMultiSelect } from "./FilterRoundMultiSelect";
import { FilterSquareMultiSelect } from "./FilterSquareMultiSelect";
import cx from "classnames";
import styles from "../RouteOrdersList.module.css";
import { FilterSquareSingleSelect } from "./FilterSquareSingleSelect";
import {
  callStatusFiltersToPick,
  smsConfirmedStatusFiltersToPick,
  smsSentStatusFiltersToPick,
} from "CONSTANTS";
import { PingLabel } from "components/common/pingLabel";
import { isFromLastXMinutes } from "../utils/isFromLastXMinutes";
import { Link } from "react-router-dom";
import map from "assets/images/map_alt.svg";
import { useToggle } from "hooks";
import { RouteTrackingModal } from "pages/callCenter/routesList/routeTracking/RouteTrackingModal";
import { noop } from "utilities";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
  route?: CallCenterRouteDetails;
}

export const ListHeader = ({ additionalListParams, route }: Props) => {
  const routeTrackingModal = useToggle();

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button className={cx("btn btnSizeSmall", styles.goToMap)} kind="create">
              <Link to={`/routes/creator/${route?.id}`} className="btnBase btnBaseSmall uppercase">
                <img alt="Mapa" src={map} style={{ height: "16px", width: "16px" }} />
                Mapa
              </Link>
            </Button>
            <span className="line-divider lineDividerMargin" />
          </div>
        }
        additionalListParams={additionalListParams}
        createButton={{
          alt: "Koperta",
          img: messageIcon,
          label: "Wyślij wiadomość do wszystkich",
          onClick: () => {},
        }}
        searchInput={{
          label: "Szukaj wśród zamówień i klientów",
          tags: [],
        }}
        bottomBarButtons={
          <div className="d-flex align-items-center px-0 gap-1 ">
            <FilterRoundMultiSelect
              name="callStatuses"
              options={callStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareMultiSelect
              name="smsStatus"
              options={smsSentStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareMultiSelect
              name="isDeliveryDateConfirmed"
              options={smsConfirmedStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareSingleSelect
              name="isDeliveryDateRejected"
              iconSrc={call_missed}
              label="odrzucone"
            />
          </div>
        }
        title={
          route ? (
            <div className="d-flex align-items-center gap-1">
              <span className="mr-2">{route.signature}</span>
              {route.carBrand &&
              route.carBrand.length > 0 &&
              route.registrationNumber &&
              route.registrationNumber.length > 0 ? (
                <span className="body-14-600">
                  {route.carBrand} <span className="fw-800">{route.registrationNumber}</span>
                </span>
              ) : (
                <span className="body-14 fw-700 text-black-6 mr-2">nie wybrano samochodu</span>
              )}
              {route.editedAt && route.isRecentlyEdited && isFromLastXMinutes(route.editedAt, 2) && (
                <div className="d-flex align-items-center">
                  <PingLabel kind="LAST_EDITED" label="W trakcie edycji" />
                </div>
              )}
              {(route.status === "NEW" || route.status === "ROUTE_READY") && (
                <div className="d-flex align-items-center gap-1">
                  <PingLabel kind="NO_EFFECT" label="Nierozpoczęta" />
                  <Button
                    className="btnDisabled"
                    disabled={true}
                    kind="create-medium-transparent"
                    onClick={e => {
                      e.stopPropagation();
                      noop();
                    }}
                    style={{ height: "26px" }}
                  >
                    <div className="btnBase btnBaseSmall">
                      <img alt="" src={timelineIcon} style={{ height: "16px", width: "16px" }} />
                      <div className={styles.routeTrackingBtn}>
                        {String("Podgląd").toUpperCase()}
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {(route.status === "DEPARTED" ||
                route.status === "FINISHED" ||
                route.status === "SETTLED") && (
                <Button
                  kind="create-medium-transparent"
                  onClick={e => {
                    e.stopPropagation();
                    routeTrackingModal.open();
                  }}
                  style={{ height: "26px" }}
                >
                  <div className="btnBase btnBaseSmall">
                    <img alt="" src={timelineIcon} />
                    <div className={styles.routeTrackingBtn}>{String("Podgląd").toUpperCase()}</div>
                  </div>
                </Button>
              )}
            </div>
          ) : (
            <>
              <span className="mr-2">Trasa</span>
              <span className="body-14">...</span>
            </>
          )
        }
        viewLabel="CALL_CENTER_ROUTE"
      />
      {routeTrackingModal.isOpen && route !== undefined && (
        <RouteTrackingModal close={routeTrackingModal.close} route={route} />
      )}
    </div>
  );
};
