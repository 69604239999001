import { ExternalManufacturerItemsTab, OrderStatus } from "api/external-manufacturing/models";
import { GetSearchProps, TabProps } from "api/types";
import { queryString } from "utilities";

const tabsDict: Record<ExternalManufacturerItemsTab, string> = {
  all: "Wszystkie",
  "not-sent": "Niezlecone",
  sent: "W produkcji",
  "in-stock": "Dostarczone",
  cancelled: "Anulowane",
};

const getTabs = (): TabProps => {
  return Object.entries(tabsDict).map(([name, label]) => ({ label, name }));
};

const orderStatusDict: Record<ExternalManufacturerItemsTab, OrderStatus | "true"> = {
  [ExternalManufacturerItemsTab["not-sent"]]: OrderStatus.NOT_SENT,
  [ExternalManufacturerItemsTab["sent"]]: OrderStatus.SENT,
  [ExternalManufacturerItemsTab["in-stock"]]: OrderStatus.IN_STOCK,
  [ExternalManufacturerItemsTab["cancelled"]]: "true",
  [ExternalManufacturerItemsTab["all"]]: "" as OrderStatus,
};

const getSearch = ({ query, tab }: GetSearchProps<ExternalManufacturerItemsTab>): string => {
  if (tab === ExternalManufacturerItemsTab["cancelled"]) {
    return queryString.stringify({
      ...query,
      isCancelled: orderStatusDict["cancelled"],
      format: "json",
    });
  }

  const tabsQuery = {
    orderStatus: orderStatusDict[tab],
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
    format: "json",
  });
};

export const ordersUtils = {
  getSearch,
  getTabs,
  orderStatusDict,
  tabsDict,
};
