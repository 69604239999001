const calculateItemTotalAmount = ({
  amountWithTaxAfterDiscount,
  quantity,
}: {
  amountWithTaxAfterDiscount: number;
  quantity: number;
}) => {
  return Number((amountWithTaxAfterDiscount * quantity).toFixed(2));
};

export const tradingDocumentPaymentUtils = {
  calculateItemTotalAmount,
};
