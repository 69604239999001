import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { areConfirmedHoursInCollectionRange } from "pages/routes/creator/leftPanel/pointsList/utils/areConfirmedHoursInCollectionRange";
import likeIcon from "assets/images/greenThumbUp.svg";
import styles from "../../shared/CallCenter.module.css";
import cx from "classnames";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns, getAnyErrorKey } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useMutation } from "hooks/useMutation";
import { removeDeliveryConfirmationForOrders } from "api/routes/calls";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { useConfirmModal } from "hooks";

interface Props {
  order: CallCenterRouteOrderListItem;
  hasDeliveryRemoval?: boolean;
}

export const ExpectedDelivery = ({ order, hasDeliveryRemoval = false }: Props) => {
  const confirmModal = useConfirmModal();

  const removeDeliveryConfirmationForOrderMutation = useMutation(
    removeDeliveryConfirmationForOrders,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(callCenterRoutesKeys.routeOrder.details(order.id));
        queryClient.invalidateQueries(callCenterRoutesKeys.routeOrder.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto godziny potwierdzenia "${order.minimumCollectionHourAtConfirmation} - ${order.maximumCollectionHourAtConfirmation}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const hasConfirmationHours =
    Boolean(order.minimumCollectionHourAtConfirmation.length) ||
    Boolean(order.maximumCollectionHourAtConfirmation.length);

  const isConfirmedWithinRange = areConfirmedHoursInCollectionRange(
    order.delivery.maximumCollectionHour,
    order.delivery.minimumCollectionHour,
    order.maximumCollectionHourAtConfirmation,
    order.minimumCollectionHourAtConfirmation,
  );

  if (isConfirmedWithinRange === false) {
    return (
      <div className="position-relative d-flex align-items-center gap-2">
        {order.delivery.plannedDeliveryTime && (
          <Typography fontSize="12" fontWeight="700">
            {dateFns.format(new Date(order.delivery.plannedDeliveryTime), "dd.MM.yyyy")},
          </Typography>
        )}

        <Typography
          className={styles.negativeCollectionHours}
          color="danger400"
          fontSize={hasDeliveryRemoval ? "14" : "12"}
          fontWeight="700"
        >
          {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
          {order.delivery.maximumCollectionHour}&nbsp;
        </Typography>

        <div className="position-relative d-flex align-items-center gap-1">
          {hasConfirmationHours && (
            <Tooltip title="Potwierdzono">
              <Typography fontSize={hasDeliveryRemoval ? "14" : "12"} fontWeight="700">
                {order.minimumCollectionHourAtConfirmation} -{" "}
                {order.maximumCollectionHourAtConfirmation}{" "}
              </Typography>
            </Tooltip>
          )}
          <div>
            <img
              alt="potwierdzono"
              src={likeIcon}
              style={{ paddingBottom: "2px", width: "12px" }}
            />
          </div>
        </div>

        {hasDeliveryRemoval && hasConfirmationHours && (
          <Button
            className="text-uppercase"
            onClick={() =>
              confirmModal.open({
                text: `Teraz usuniesz godziny dostawy potwierdzone przez klienta (${order.minimumCollectionHourAtConfirmation} - ${order.maximumCollectionHourAtConfirmation}). Czy na pewno chcesz to zrobić?`,
                confirmText: "Usuń potwierdzenie",
                callback: () =>
                  removeDeliveryConfirmationForOrderMutation.mutate({
                    orders: [order.id],
                  }),
              })
            }
            size="small"
            variant="gray"
          >
            Usuń potwierdzenie
          </Button>
        )}
      </div>
    );
  }

  if (isConfirmedWithinRange === true) {
    return (
      <div className="position-relative d-flex align-items-center gap-2">
        {order.delivery.plannedDeliveryTime && (
          <Typography fontSize="12" fontWeight="700">
            {dateFns.format(new Date(order.delivery.plannedDeliveryTime), "dd.MM.yyyy")},
          </Typography>
        )}
        <Tooltip title="Potwierdzono">
          <Typography className={styles.negativeCollectionHours} fontSize="12" fontWeight="700">
            {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
            {order.delivery.maximumCollectionHour}&nbsp;
          </Typography>
        </Tooltip>

        <div className="position-relative d-flex align-items-center gap-1">
          <div>
            <img
              alt="potwierdzono"
              src={likeIcon}
              style={{ paddingBottom: "2px", width: "12px" }}
            />
          </div>
        </div>

        {hasDeliveryRemoval && hasConfirmationHours && (
          <Button
            className="text-uppercase"
            onClick={() =>
              confirmModal.open({
                text: ` Teraz usuniesz godziny dostawy potwierdzone przez klienta (${order.minimumCollectionHourAtConfirmation} - ${order.maximumCollectionHourAtConfirmation}). Czy na pewno chcesz to zrobić?`,
                confirmText: "Usuń potwierdzenie",
                callback: () =>
                  removeDeliveryConfirmationForOrderMutation.mutate({
                    orders: [order.id],
                  }),
              })
            }
            size="small"
            variant="gray"
          >
            Usuń potwierdzenie
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="position-relative d-flex align-items-center gap-2">
        {order.delivery.plannedDeliveryTime && (
          <Typography fontSize="12" fontWeight="700">
            {dateFns.format(new Date(order.delivery.plannedDeliveryTime), "dd.MM.yyyy")},
          </Typography>
        )}
        <Tooltip title="Nie potwierdzono">
          <div>
            {order.delivery.minimumCollectionHour && order.delivery.maximumCollectionHour ? (
              <div
                className={cx(styles.negativeCollectionHours, "fs-12 fw-500")}
                data-for={String(order.id)}
              >
                {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
                {order.delivery.maximumCollectionHour}&nbsp;
              </div>
            ) : (
              "--"
            )}
          </div>
        </Tooltip>
      </div>
    </>
  );
};
