import { indexesActions } from "api/indexes/actions";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

interface Props {
  close: () => void;
  indexes: IndexForExternalManufacturingListItem[];
}

export const WithdrawIndexes = ({ close, indexes }: Props) => {
  const isDeletedMutation = indexesActions.useBulkUpdateIndexes();

  return (
    <Tooltip title="Wycofaj ze sprzedaży">
      <IconButton
        icon={MdiDelete}
        isLoading={isDeletedMutation.isLoading}
        onClick={() =>
          isDeletedMutation.mutate(
            {
              objects: indexes.map(index => index.id),
              isDeleted: true,
            },
            {
              onSuccess: () => close(),
            },
          )
        }
        theme="dark"
        variant="transparent"
      />
    </Tooltip>
  );
};
