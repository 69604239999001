import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  AddLineItemDTO,
  ConfirmDraftOrderDTO,
  ConfirmDraftOrderPreview,
  CreateDraftOrderDTO,
  CreateDraftOrderPreview,
  DraftOrderDetails,
  DraftOrderLineItem,
  DraftOrderListItem,
} from "./models";
import { draftOrdersKeys } from "./keys";
import { PartialOf } from "typeUtilities";

const getItems = (search: string): ApiFetcher<Pagination<DraftOrderListItem>> => ({
  key: draftOrdersKeys.items.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/drafts/items" + search,
    }),
});

const getItem = (id: UUID): ApiFetcher<DraftOrderDetails> => ({
  key: draftOrdersKeys.items.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/drafts/items/" + id,
    }),
});

const deleteItem = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/external-manufacturing/orders/drafts/items/" + id,
  });

const getLineItems = (search: string): ApiFetcher<Pagination<DraftOrderLineItem>> => ({
  key: draftOrdersKeys.lineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/drafts/line-items" + search,
    }),
});

const patchLineItem = (data: PartialOf<DraftOrderLineItem>, id: UUID) =>
  queryFetch<DraftOrderLineItem>({
    method: "PATCH",
    url: `/external-manufacturing/orders/drafts/line-items/${id}`,
    data,
  });

const deleteLineItem = (lineItemId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/external-manufacturing/orders/drafts/line-items/" + lineItemId,
  });

const postLineItem = (data: AddLineItemDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/external-manufacturing/orders/drafts/add-line-item",
    data,
  });

const postDraft = (data: CreateDraftOrderDTO) =>
  queryFetch<CreateDraftOrderPreview>({
    method: "POST",
    url: "/external-manufacturing/orders/drafts/create",
    data,
  });

const confirmDraft = (data: ConfirmDraftOrderDTO) =>
  queryFetch<ConfirmDraftOrderPreview>({
    method: "POST",
    url: "/external-manufacturing/orders/drafts/confirm",
    data,
  });

export const draftOrdersApi = {
  confirmDraft,
  deleteItem,
  deleteLineItem,
  getItem,
  getItems,
  getLineItems,
  patchLineItem,
  postDraft,
  postLineItem,
};
