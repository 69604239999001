import { LongHeldPackageTab } from "api/unique-packages/models";

export const getTabs = (): { label: string; name: LongHeldPackageTab }[] => {
  return [
    { label: "Wszystkie", name: LongHeldPackageTab.ALL },
    { label: "Na stanie", name: LongHeldPackageTab.NOT_RELEASED },
    { label: "Wydane", name: LongHeldPackageTab.RELEASED },
    { label: "Archiwum", name: LongHeldPackageTab.SETTLED },
  ];
};
