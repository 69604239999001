import {
  ManufacturingPriority,
  ManufacturingUnitListViewItem,
  MaterialStageStatus,
} from "api/manufacturing/units/models";
import { ISODateTime, QueryParams, UUID } from "api/types";
import { dateFns, queryString } from "./utilities";
import { BoardFormat } from "api/manufacturing/schemas/models";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { StageBoardAttributeKind, StageBoardDefaultAttributesKind } from "api/manufacturing/models";

type SectionTitle = string;
const getSortedManufacturingUnitGroups = <
  T extends {
    startedAt: ISODateTime;
    finishedAt: ISODateTime;
    priority: ManufacturingPriority;
  }
>(
  units: T[] | null,
  key: "startedAt" | "finishedAt",
): (SectionTitle | T)[] => {
  if (!units) return [];
  const now = new Date();
  const todayUntil13 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0, 0);
  const todayUntil10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0);
  const todayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  const orderedUnits = units.reduce<Record<string, T[]>>(
    (acc, unit) => {
      const parsedDay = new Date(unit[key]);

      if (parsedDay > todayUntil13) {
        acc[""].push(unit);
        return acc;
      }

      if (parsedDay < todayUntil13 && parsedDay > todayUntil10) {
        acc["Dzisiaj do 13:00"].push(unit);
        return acc;
      }

      if (parsedDay < todayUntil10 && parsedDay > todayMidnight) {
        acc["Dzisiaj do 10:00"].push(unit);
        return acc;
      }

      if (dateFns.isYesterday(parsedDay)) {
        acc["Wczoraj"].push(unit);
        return acc;
      }

      if (!acc[`${dateFns.format(parsedDay, "d.MM.yyyy")}`]) {
        Object.assign(acc, { [`${dateFns.format(parsedDay, "d.MM.yyyy")}`]: [unit] });
      } else {
        acc[`${dateFns.format(parsedDay, "d.MM.yyyy")}`].push(unit);
      }

      return acc;
    },
    {
      "": [],
      "Dzisiaj do 13:00": [],
      "Dzisiaj do 10:00": [],
      Wczoraj: [],
    },
  );

  return Object.entries(orderedUnits)
    .filter(([, units]) => units.length)
    .map(([label, unitsSegment]) => [
      label,
      ...unitsSegment.sort((a, b) => {
        const priorityOrder = { A: 1, B: 2, C: 3, "": 4 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      }),
    ])
    .flat();
};

const getTodoUnitColumnSearch = (query: QueryParams, stageId: UUID) =>
  queryString.stringify({
    ...query,
    masterStages: stageId,
    orderBy: "priority",
    pageSize: query.pageSize,
    priorities: ["A", "B"].join(","),
    stageStatus: "READY",
    isInGroup: "false",
    search: query.todoUnitsSearch,
  });

const getStatisticsSearch = (query: QueryParams, stageId: UUID) => {
  return {
    search: queryString.stringify(query),
    stageId,
  };
};

const getTodoGroupColumnSearch = (
  query: QueryParams,
  boardFormat: BoardFormat,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    ...query,
    attributesKinds: attributesKind.join(","),
    groupByModel: manufacturingStagesConstants.groupByModelDict[boardFormat],
    search: query.todoGroupsSearch,
  });

const getAttributesKinds = (defaultFilters: StageBoardDefaultAttributesKind[]) => {
  return defaultFilters.map(defaultFilter => defaultFilter.attributeKind);
};

const hasFabric = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.FABRIC,
  );
const hasSize = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIZE,
  );
const hasSide = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIDE,
  );

const isUnitUrgent = (priority: ManufacturingPriority): boolean =>
  priority === ManufacturingPriority.A || priority === ManufacturingPriority.B;

const isUnitCritical = (priority: ManufacturingPriority): boolean =>
  priority === ManufacturingPriority.A;

const areSomeMaterialsNotOrdered = (
  customOrderedMaterials: ManufacturingUnitListViewItem["materials"],
): boolean => {
  if (customOrderedMaterials.some(material => !Boolean(material.orders.length))) return true;
  return customOrderedMaterials.some(material =>
    material.orders.some(order => order.status === MaterialStageStatus.NOT_ORDERED),
  );
};

const areSomeMaterialsOrdered = (
  customOrderedMaterials: ManufacturingUnitListViewItem["materials"],
): boolean => {
  return customOrderedMaterials.some(material =>
    material.orders.some(order => order.status === MaterialStageStatus.ORDERED),
  );
};

export const manufacturingStagesUtils = {
  areSomeMaterialsNotOrdered,
  areSomeMaterialsOrdered,
  getAttributesKinds,
  getSortedManufacturingUnitGroups,
  getStatisticsSearch,
  getTodoUnitColumnSearch,
  getTodoGroupColumnSearch,
  hasFabric,
  hasSide,
  hasSize,
  isUnitCritical,
  isUnitUrgent,
};
