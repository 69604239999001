import { dateFns, dateUtils } from "utilities";
import { DraftInvoiceRouteFilters } from "../../AddRouteToDraft";
import styles from "../../AddRouteToDraft.module.css";
import { useState } from "react";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";

interface Props {
  filters: DraftInvoiceRouteFilters;
  setFilter: <T extends keyof DraftInvoiceRouteFilters, U extends DraftInvoiceRouteFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

export const FiltersSection = ({ filters, setFilter }: Props) => {
  const [isCustomRangeSelected, setIsCustomRangeSelected] = useState(false);
  const months = getMonthsFilterContent();

  const resetPage = () => setFilter("page", 1);

  const isMonthTagSelected = (minDate: string, maxDate: string) => {
    return Boolean(
      minDate &&
        filters.minCreatedDate === minDate &&
        maxDate &&
        filters.maxCreatedDate === maxDate,
    );
  };

  return (
    <div className={styles.filters}>
      <TextField
        containerClassName="w-100"
        endIcon={<MdiSearch color="neutralBlack48" size="18" />}
        label="Trasa"
        onChange={event => setFilter("search", event.target.value)}
        placeholder="Szukaj..."
        value={filters.search ?? ""}
      />
      <div className={styles.monthsFilter}>
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          Zakres:
        </Typography>
        {months.map(month => (
          <div
            className={styles.selectableMonthTag}
            key={month.label}
            onClick={() => {
              setFilter(
                "minCreatedDate",
                isMonthTagSelected(month.minDate, month.maxDate) ? "" : month.minDate,
              );
              setFilter(
                "maxCreatedDate",
                isMonthTagSelected(month.minDate, month.maxDate) ? "" : month.maxDate,
              );
              resetPage();
              setIsCustomRangeSelected(false);
            }}
          >
            <Tag
              label={month.label}
              type={isMonthTagSelected(month.minDate, month.maxDate) ? "filled" : "outlined"}
              variant={
                isMonthTagSelected(month.minDate, month.maxDate) ? "deepPurple50" : "quaternary"
              }
            />
          </div>
        ))}
        <Popover
          variant="DARK"
          hideOnClickOutside={false}
          content={({ hide }) => (
            <ClickOutsideHandler
              onClickOutside={hide}
              outsideClickIgnoreClass={ignoreClickOutsideClassName}
            >
              <div className={styles.customDates}>
                <Typography className="mb-1" fontSize="12" fontWeight="600">
                  Wybierz zakres dat:
                </Typography>
                <div className={styles.datePickersContainer}>
                  <DatePicker
                    calendarClassName={ignoreClickOutsideClassName}
                    isNullable
                    label="Od"
                    onChange={date => {
                      if (!date) {
                        setFilter("minCreatedDate", "");
                        resetPage();
                        if (!filters.maxCreatedDate) setIsCustomRangeSelected(false);
                      } else {
                        setFilter("minCreatedDate", dateUtils.formatDateToIso(date));
                        resetPage();
                        setIsCustomRangeSelected(true);
                      }
                    }}
                    placeholder="Wybierz"
                    value={filters.minCreatedDate}
                  />
                  <DatePicker
                    calendarClassName={ignoreClickOutsideClassName}
                    isNullable
                    label="Do"
                    onChange={date => {
                      if (!date) {
                        setFilter("maxCreatedDate", "");
                        resetPage();
                        if (!filters.minCreatedDate) setIsCustomRangeSelected(false);
                      } else {
                        setFilter("maxCreatedDate", dateUtils.formatDateToIso(date));
                        resetPage();
                        setIsCustomRangeSelected(true);
                      }
                    }}
                    placeholder="Wybierz"
                    value={filters.maxCreatedDate}
                  />
                </div>
              </div>
            </ClickOutsideHandler>
          )}
        >
          <div className={styles.selectableMonthTag}>
            <Tag
              label={
                isCustomRangeSelected
                  ? `${filters.minCreatedDate &&
                      dateUtils.formatDateToDisplayOnlyDate(
                        filters.minCreatedDate,
                      )} - ${filters.maxCreatedDate &&
                      dateUtils.formatDateToDisplayOnlyDate(filters.maxCreatedDate)}`
                  : "własny zakres"
              }
              type={isCustomRangeSelected ? "filled" : "outlined"}
              variant={isCustomRangeSelected ? "deepPurple50" : "quaternary"}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

const getMonthsFilterContent = () => {
  const today = new Date();

  return [
    {
      label: "ten miesiąc",
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(today)),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(today)),
    },
    {
      label: "poprzedni miesiąc",
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(dateFns.subMonths(today, 1))),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(dateFns.subMonths(today, 1))),
    },
    {
      label: dateFns.format(dateFns.subMonths(today, 2), "LLLL", { locale: dateFns.pl }),
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(dateFns.subMonths(today, 2))),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(dateFns.subMonths(today, 2))),
    },
  ];
};

const ignoreClickOutsideClassName = "add-route-to-draft-popover-ignore-class-name";
