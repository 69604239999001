import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { PartialOf } from "typeUtilities";
import { ApiFetcher } from "hooks/createApiQuery";
import { CreateRamp, RampListItem } from "./models";
import { wmsRampKeys } from "./keys";
import { createPreviewFactory } from "utilities/createPreviewMiddleware";

const getRamps = (search: string = ""): ApiFetcher<Pagination<RampListItem>> => ({
  key: wmsRampKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/ramps/items" + search,
    }),
});

const getRamp = (id: UUID): ApiFetcher<RampListItem> => ({
  key: wmsRampKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/ramps/items/" + id,
    }),
});

const postRamp = (data: CreateRamp) =>
  queryFetch<RampListItem>({
    method: "POST",
    url: "/wms/ramps/items",
    data,
  });

const patchRamp = (data: PartialOf<RampListItem>, id: UUID | number) =>
  queryFetch<RampListItem>({
    method: "PATCH",
    url: "/wms/ramps/items/" + id,
    data,
  });

const deleteRamp = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/ramps/items/" + id,
  });

export const wmsRampsPreviewFactory = createPreviewFactory({
  rampLabel: (rampId: RampListItem["id"]) => ({
    url: `/wms/ramps/get-labels/${rampId}`,
  }),
});

export const wmsRampApi = {
  getRamps,
  getRamp,
  postRamp,
  patchRamp,
  deleteRamp,
};
