import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiStateMedium = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6 6.4H17.4V17.6H18.6V6.4ZM16 5V19H20V5H16Z"
      fill="#2B8EFA"
    />
  </SvgWrapper>
);
