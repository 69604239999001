import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useSelector, useStateModal, useToggle } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { CreateCorrectionManuallyModal } from "./createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
} from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { useDrawer } from "hooks/useDrawer";
import {
  FileDownloadHandler,
  useFileDownload,
} from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { DownloadWdtPdf } from "pages/tradingDocuments/shared/components/DownloadWdfPdf";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { assertIsDefined } from "utilities/assertIsDefined";
import { TradingDocumentOptionsMenuFactory } from "pages/tradingDocuments/shared/optionsMenuFactory/TradingDocumentOptionsMenuFactory";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TransportInfo } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/OrderMaterialModal";
import { useNavigate } from "hooks/useNavigate";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MdiDownloadSpecification } from "components/miloDesignSystem/atoms/icons/MdiDownloadSpecification";
import styles from "../../../shared/TradingDocumentsLists.module.css";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiDownloadOptima } from "components/miloDesignSystem/atoms/icons/MdiDownloadOptima";
import { DEFAULT_OPTION_MENU_ICON_SIZE } from "CONSTANTS";
import { MdiViewCMR } from "components/miloDesignSystem/atoms/icons/MdiViewCMR";
import { MdiElte } from "components/miloDesignSystem/atoms/icons/MdiElte";
import { useTradingDocumentsSettings } from "api/milo-settings/hooks";
import { financesPreviewFactory } from "api/finances/api";

export const PanelHeader = () => {
  const { close } = useDrawer("salesInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const previewCorrectionMutation = tradingDocumentsActions.useCorrectionPreview(
    createCorrectionManuallyModal.open,
  );
  const { isTriggerElementHidden } = useScrollableContext();
  assertIsDefined(tradingDocument);
  const optionsMenu = TradingDocumentOptionsMenuFactory({ close, tradingDocument });
  const transportModalController = useToggle();
  const navigate = useNavigate();
  const { download: downloadOptima, isLoading: isOptimaDownloadLoading } = useFileDownload({
    factoryFn: () =>
      tradingDocumentFileFactory.salesInvoiceOptimaTradeModuleXml([tradingDocument.id]),
    type: "xml",
  });
  const tradingDocumentsSettingsId = useSelector(
    store => store.partials.finances.tradingDocumentSettings[0].id,
  );
  assertIsDefined(tradingDocumentsSettingsId);
  const {
    data: tradingDocumentSettings,
    isLoading: areSettingsLoading,
  } = useTradingDocumentsSettings({
    id: tradingDocumentsSettingsId,
  });
  const sendToOptimaUsingElteMutation = tradingDocumentsActions.useSendTradingDocumentsToOptimaUsingElte();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />
            <div>Faktura VAT&nbsp;{tradingDocument.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {tradingDocument.status === "CONFIRMED" && (
            <Button
              className="mr-1 text-uppercase"
              isLoading={previewCorrectionMutation.isLoading}
              onClick={() =>
                previewCorrectionMutation.mutate({
                  id: tradingDocument.id,
                  expectedPaymentForm: tradingDocument.expectedPaymentForm,
                })
              }
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Utwórz korektę
            </Button>
          )}
          <Tooltip title="Podgląd CMR">
            <IconButton
              icon={<MdiViewCMR size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />}
              onClick={() => {
                window.open(
                  financesPreviewFactory.tradingDocumentCmrPDF(tradingDocument).url,
                  "_blank",
                );
              }}
              variant="transparent"
            />
          </Tooltip>
          <FileDownloadHandler
            factoryFn={() => tradingDocumentFileFactory.specification(tradingDocument)}
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz specyfikację">
                <IconButton
                  icon={<MdiDownloadSpecification size="18" />}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          {tradingDocumentSettings?.isAutoSendToOptimaByElteEnabled && (
            <Tooltip title="Wyślij do Optimy z użyciem Elte">
              <IconButton
                icon={MdiElte}
                isLoading={sendToOptimaUsingElteMutation.isLoading || areSettingsLoading}
                onClick={() => sendToOptimaUsingElteMutation.mutate([tradingDocument.id])}
                variant="transparent"
              />
            </Tooltip>
          )}
          <DownloadWdtPdf tradingDocument={tradingDocument} />
          <CreateCorrectionManuallyModal
            close={createCorrectionManuallyModal.close}
            isOpen={createCorrectionManuallyModal.isOpen}
            state={createCorrectionManuallyModal.state}
            type="SALES"
          />
          <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
          <Menu
            className={styles.optionsMenu}
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              optionsMenu.downloadTradingDocumentXML(),
              {
                type: MenuItemType.ICON,
                icon: isOptimaDownloadLoading ? (
                  <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
                ) : (
                  <MdiDownloadOptima size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
                ),
                onClick: downloadOptima,
                options: {
                  typographyProps: {
                    fontWeight: "600",
                  },
                },
                text: "Pobierz XML moduł handel (optima)",
              },
              optionsMenu.downloadRevisor(),
              optionsMenu.downloadFakir(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.downloadDuplicatePDF(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.tradingDocumentHistory(panelId),
              { type: MenuItemType.DIVIDER },
              optionsMenu.editTransportDocuments(transportModalController.open),
              { type: MenuItemType.DIVIDER },
              {
                type: MenuItemType.ICON,
                icon: <MdiEdit size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />,
                onClick: () =>
                  navigate(`/finances/create-work-in-progress-document/${tradingDocument.id}`),
                options: {
                  disabled:
                    tradingDocument.kind !== "OWN_ENTITY" ||
                    tradingDocument.status !== "WORK_IN_PROGRESS",
                  typographyProps: {
                    fontWeight: "600",
                  },
                },
                text: "Edytuj",
              },
              optionsMenu.deleteTradingDocument(),
            ]}
          />

          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
      {transportModalController.isOpen && (
        <TransportInfo close={transportModalController.close} tradingDocument={tradingDocument} />
      )}
    </>
  );
};
