import { DeliveryMethodChoices } from "api/common/enums";
import { ListOrder, OrderPaymentStatus, OrderPaymentType, Status } from "api/orders/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import { Colors } from "components/utils";
import { ReactNode } from "react";

const orderStatusDict: Record<Status, string> = {
  CANCELED: "anulowane",
  DELIVERED: "dostarczone",
  ON_ROUTE: "w trasie",
  PROCESSING: "w trakcie przetwarzania",
  RECEIVED: "otrzymane",
  ROUTE_READY: "gotowe",
  SETTLED: "rozliczone",
};

const orderStatusVariants: Record<
  Status,
  { label: string; type: TagProps<string>["type"]; variant: TagProps<string>["variant"] }
> = {
  CANCELED: { label: orderStatusDict["CANCELED"], type: "filled", variant: "danger" },
  DELIVERED: { label: orderStatusDict["DELIVERED"], type: "filled", variant: "success" },
  ON_ROUTE: { label: orderStatusDict["ON_ROUTE"], type: "filled", variant: "warning" },
  PROCESSING: { label: orderStatusDict["PROCESSING"], type: "outlined", variant: "deepPurple400" },
  RECEIVED: { label: orderStatusDict["RECEIVED"], type: "outlined", variant: "quaternary" },
  ROUTE_READY: { label: orderStatusDict["ROUTE_READY"], type: "filled", variant: "info" },
  SETTLED: { label: orderStatusDict["SETTLED"], type: "filled", variant: "deepPurple400" },
};

const paymentStatusDict: Record<OrderPaymentStatus, string> = {
  OVERPAID: "Nadpłata",
  PAID: "Opłacono",
  PARTIALLY_PAID: "Częściowo opłacono",
  UNPAID: "Nieopłacono",
};

const paymentStatusItems: { id: OrderPaymentStatus; name: string }[] = Object.entries(
  paymentStatusDict,
).map(([id, name]) => ({ id: id as OrderPaymentStatus, name }));

const paymentTypeDict: Record<OrderPaymentType, string> = {
  CASH_ON_DELIVERY: "Gotówka",
  DEBIT_CARD: "Karta",
  INSTALMENT: "Raty",
  ONLINE: "Przelew",
};

const paymentTypeColors: Record<OrderPaymentType, { color: Colors; name: string }> = {
  CASH_ON_DELIVERY: { color: "yellow", name: paymentTypeDict["CASH_ON_DELIVERY"] },
  DEBIT_CARD: { color: "red-orange", name: paymentTypeDict["DEBIT_CARD"] },
  INSTALMENT: { color: "pink", name: paymentTypeDict["INSTALMENT"] },
  ONLINE: { color: "primary", name: paymentTypeDict["ONLINE"] },
};

const paymentTypeItems: { id: OrderPaymentType; name: string }[] = Object.entries(
  paymentTypeDict,
).map(([id, name]) => ({
  id: id as OrderPaymentType,
  name,
}));

export const getDeliveryMethodConstants = (
  deliveryMethod: DeliveryMethodChoices,
): { tag: ReactNode } => {
  switch (deliveryMethod) {
    case DeliveryMethodChoices.EXTERNAL_SHIPMENT: {
      return {
        tag: <Tag label="transport zewnętrzny" variant="warning" type="outlined" />,
      };
    }
    case DeliveryMethodChoices.PERSONAL_COLLECTION: {
      return { tag: <Tag label="Odbiór osobisty" variant="info" type="outlined" /> };
    }
    case DeliveryMethodChoices.SELF_SHIPPED: {
      return { tag: <Tag label="transport własny" variant="deepPurple400" type="outlined" /> };
    }
    default: {
      const exhaustiveCheck: never = deliveryMethod;
      console.error(`Unhandled delivery method: ${exhaustiveCheck}`);
      return { tag: "" };
    }
  }
};

function getPaymentSourceTag(source: ListOrder["payment"]["source"]) {
  switch (source) {
    case "ONLINE":
      return (
        <Tag.WithCustomColor backgroundColor="purple32" label="Przelew" textColor="purple500" />
      );

    case "ON_DELIVERY":
      return <Tag.WithCustomColor backgroundColor="blue32" label="Pobranie" textColor="blue600" />;

    default:
      const exhaustiveCheck: never = source;
      console.error(`Unhandled order payment source: ${exhaustiveCheck}`);
      return null;
  }
}

export const orderConstants = {
  getPaymentSourceTag,
  orderStatusDict,
  orderStatusVariants,
  paymentStatusDict,
  paymentStatusItems,
  paymentTypeColors,
  paymentTypeDict,
  paymentTypeItems,
};
