import { Shipment } from "api/shipping/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { countryToFlagDict } from "constants/countriesFlags";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  shipment: Shipment;
}

export const DeliverySection = ({ shipment }: Props) => {
  return (
    <div className="px-3  pb-2 flex-1">
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
        Dane dostawy
      </Typography>
      <Typography fontSize="16" fontWeight="700" className="mb-2">
        {shipment.companyName}
      </Typography>
      <Typography fontSize="14" fontWeight="600" className="mb-1">
        {shipment.firstName} {shipment.lastName}
      </Typography>
      <Typography fontSize="14" fontWeight="600" className="mb-1">
        {shipment.city || EMPTY_VALUE}
      </Typography>
      <div className="d-flex mb-1 flex-wrap">
        <Typography fontSize="14" fontWeight="600" className="mr-1">
          {shipment.postCode || EMPTY_VALUE},
        </Typography>
        <Typography fontSize="14" fontWeight="600">
          {shipment.street || EMPTY_VALUE}
        </Typography>
      </div>

      {shipment.countryCode && (
        <div className="d-flex align-items-center gap-1 mb-1">
          <img alt="Flaga kraju" src={countryToFlagDict[shipment.countryCode]} />
          <Typography fontSize="10" fontWeight="700">
            {shipment.countryCode}
          </Typography>
        </div>
      )}

      <Typography fontSize="14" fontWeight="600" className="mb-1">
        {shipment.phone || EMPTY_VALUE}
      </Typography>
      <Typography fontSize="14" fontWeight="600" className="mb-1">
        {shipment.email || EMPTY_VALUE}
      </Typography>
    </div>
  );
};
