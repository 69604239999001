import palletIcon from "assets/images/favicons/fv_pallet.svg";
import externalManufacturingIcon from "assets/images/pallet.svg";
import homeIcon from "assets/images/homeLight.svg";
import favicon from "assets/images/favicons/fv_producer.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiOrdersGroup } from "components/miloDesignSystem/atoms/icons/MdiOrdersGroup";
import { MdiAnalytics } from "components/miloDesignSystem/atoms/icons/MdiAnalytics";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { MdiGroupByProducer } from "components/miloDesignSystem/atoms/icons/MdiGroupByProducer";
import { NavigationDivider } from "../../components/navigationDivider/NavigationDivider";
import { indexes } from "./routes/indexes";
import { draftOrders } from "./routes/draftOrders";
import { orders } from "./routes/orders";
import { orderLineItems } from "./routes/orderLineItems";

export const externalManufacturingConfig: ModuleNavigation = {
  favicon,
  color: colorPalette.pink200,
  navigationConfig: {
    label: "Zlecenia do producentów",
    icon: { src: palletIcon, background: colorPalette.pink600 },
  },
  menuDropdownIcon: externalManufacturingIcon,
  name: "EXTERNAL_MANUFACTURING",
  url: "/external-manufacturing",
  dashboard: {
    url: "dashboard",
    label: "Pulpit",
    icon: homeIcon,
    routing: () => <></>,
    isAccessible: false,
  },
  navigationSections: [
    orders,
    orderLineItems,
    {
      url: "link3",
      label: "Zgrupowane zlecenia producenta",
      icon: MdiOrdersGroup,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "link4",
      label: "Zlecenia zgrupowane po producencie",
      icon: MdiGroupByProducer,
      routing: () => <></>,
      isAccessible: false,
    },
    draftOrders,
    <NavigationDivider />,
    indexes,
    <NavigationDivider />,
    {
      url: "link5",
      label: "Analityka",
      icon: MdiAnalytics,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "link6",
      label: "Ustawienia",
      icon: MdiSettings,
      routing: () => <></>,
      isAccessible: false,
    },
  ],
};
