import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexDetails, IndexPackageListItem } from "api/manufacturing/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiBoxAdd } from "components/miloDesignSystem/atoms/icons/MdiBoxAdd";
import { MdiBoxRemove } from "components/miloDesignSystem/atoms/icons/MdiBoxRemove";
import { MdiPackage } from "components/miloDesignSystem/atoms/icons/MdiPackage";
import { MdiQrCodeDownload } from "components/miloDesignSystem/atoms/icons/MdiQrCodeDownload";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { indexesUtils } from "utilities/indexes";
import { usePackagesColumns } from "./usePackagesColumns";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { pluralize } from "utilities";
import { useState } from "react";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { indexesPreviewFactory } from "api/manufacturing/indexes/api";

interface Props {
  indexDetails: IndexDetails;
}

export const PackagesSection = ({ indexDetails }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = indexesUtils.getIndexPackagesListSearch(panelId);
  const customMadeMutation = indexesActions.usePatchIndexCustomMadePackages();
  const notCustomMadeMutation = indexesActions.usePatchIndexCustomMadePackages();
  const generatePackagesMutation = indexesActions.useGeneratePackagesForIndexes();
  const {
    data: packages,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = indexesActions.useIndexPackages(search);
  const [labelsAmount, setLabelsAmount] = useState("1");

  const tableProps = useTableFeatureConnector({
    rows: packages,
    withPagination: { pagination, defaultPaginationVisibility: false },
  });

  const columns = usePackagesColumns();

  return (
    <RightPanelSection
      title={
        <div className="d-flex align-items-center justify-content-between">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            Paczki
          </Typography>
          <div className="d-flex align-items-center justify-content-end gap-1">
            <Tooltip title="Wygeneruj i przypisz paczki dla wariantu">
              <IconButton
                icon={MdiPackage}
                isLoading={generatePackagesMutation.isLoading}
                onClick={() =>
                  generatePackagesMutation.mutate({
                    indexes: [panelId],
                  })
                }
                variant="transparent"
              />
            </Tooltip>
            <Tooltip title="Zwiąż wszystkie paczki wariantu z zamówieniem">
              <IconButton
                icon={MdiBoxAdd}
                isLoading={customMadeMutation.isLoading}
                onClick={() =>
                  customMadeMutation.mutate({
                    data: {
                      isCustomMade: true,
                    },
                    search,
                  })
                }
                variant="transparent"
              />
            </Tooltip>
            <Tooltip title="Odwiąż wszystkie paczki wariantu od zamówienia">
              <IconButton
                icon={MdiBoxRemove}
                isLoading={notCustomMadeMutation.isLoading}
                onClick={() =>
                  notCustomMadeMutation.mutate({
                    data: {
                      isCustomMade: false,
                    },
                    search,
                  })
                }
                variant="transparent"
              />
            </Tooltip>
            <Popover
              hideOnClickOutside={false}
              content={({ hide }) => (
                <ClickOutsideHandler
                  onClickOutside={() => {
                    hide();
                    setLabelsAmount("1");
                  }}
                  outsideClickIgnoreClass={ignoreClickOutsideClassName}
                >
                  <div className="d-flex flex-column gap-2">
                    <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                      Dla ilu produktów chcesz pobrać etykiety?
                    </Typography>
                    <TextField
                      onChange={event => {
                        if (Number(event.target.value) >= 0) setLabelsAmount(event.target.value);
                      }}
                      type="number"
                      value={labelsAmount}
                    />
                    <div className="d-flex align-items-center gap-3 py-2">
                      {Number(labelsAmount) > 0 ? (
                        <a
                          href={
                            indexesPreviewFactory.indexLabels(indexDetails.id, labelsAmount).url
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Button
                            className="text-uppercase"
                            onClick={hide}
                            size="medium"
                            variant="deepPurple"
                          >
                            Pobierz{" "}
                            {pluralize.pl(Number(labelsAmount), {
                              singular: "etykietę",
                              other: "etykiety",
                              plural: "etykiety",
                            })}
                          </Button>
                        </a>
                      ) : (
                        <Button
                          className="text-uppercase"
                          disabled
                          size="medium"
                          variant="deepPurple"
                        >
                          Pobierz etykiety
                        </Button>
                      )}
                    </div>
                  </div>
                </ClickOutsideHandler>
              )}
              variant="DARK"
            >
              <Button
                className="text-uppercase"
                disabled={!Boolean(packages.length)}
                size="small"
                startIcon={MdiQrCodeDownload}
                variant="gray"
              >
                Etykieta na paczki
              </Button>
            </Popover>
          </div>
        </div>
      }
    >
      <div className="pb-2 mt-2">
        <Table<IndexPackageListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={comfortableListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              hideHeader: !Boolean(packages.length),
              noResultComponent: <EmptySection label="Brak paczek" />,
            };
          }}
        />
        <div className="pt-3 pb-1">
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj paczkę
          </Button>
        </div>
      </div>
    </RightPanelSection>
  );
};

const ignoreClickOutsideClassName = "index-right-panel-labels-popover-ignore-class-name";
