import styles from "../MessagesWithCustomer.module.css";
import cx from "classnames";
import { OrderMessagesSchema } from "api/orders/models";
import { dateFns } from "utilities";
import { statusDict } from "./statusDict";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";

interface Props {
  messagesData: OrderMessagesSchema["messages"][number][];
  clientAvatar: string | null;
}

export const MessagesReceived = ({ messagesData, clientAvatar }: Props) => {
  const { timestamp, createdBy, status, failureReason } = messagesData[0];
  return (
    <div className="d-flex flex-column w-100 align-items-start">
      <div className="w-100 d-flex justify-content-center pb-3">
        <div className="fs-12 fw-400 text-black-6">
          {createdBy && <span>{statusDict[status]} </span>}
          {timestamp && dateFns.format(new Date(timestamp), "d MMM yyyy HH:mm")}
        </div>
      </div>
      <div className="pl-4 pr-5 pb-4">
        <div>
          <div className={styles.errorMsg}>{failureReason}</div>
          {messagesData.map(message => (
            <div
              className={cx(styles.cloud, styles.cloudClient, {
                [styles.customerConfirmation]:
                  message.kind === "REQUEST_FOR_CUSTOMER_CONFIRMATION" ||
                  message.kind === "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPAIR" ||
                  message.kind === "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPLACEMENT" ||
                  message.kind === "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_RESIGNATION" ||
                  message.kind === "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_SUPPLEMENT",
              })}
            >
              {message.body}
            </div>
          ))}
        </div>

        <div className="d-flex align-items-center gap-2 pt-2">
          {createdBy && (
            <AvatarOrInitials
              firstName={createdBy.firstName}
              lastName={createdBy.lastName}
              className="avatarSmall my-0"
              avatarSrc={clientAvatar}
            />
          )}
          <div className="fs-12 text-black-6">
            {createdBy?.firstName} {createdBy?.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};
