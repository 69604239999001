import { shippingActions } from "api/shipping/actions";
import { PostInternalShipmentsPreview } from "api/shipping/models";
import { QueryFetchError } from "api/types";
import {
  FailedResponse,
  FailedResponseModal,
} from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { StateModalHandler } from "hooks";
import { UseMutationResult } from "react-query";

interface Props {
  children: ({
    openResponseModal,
    createShipmentsMutation,
  }: {
    openResponseModal: (response: FailedResponse) => void;
    createShipmentsMutation: UseMutationResult<
      PostInternalShipmentsPreview,
      QueryFetchError,
      {
        orders: number[];
      },
      unknown
    >;
  }) => JSX.Element;
  onResponseModalClose?: () => void;
}

export const CreateShipmentHandler = ({ children, onResponseModalClose }: Props) => {
  const createShipmentsMutation = shippingActions.usePostInternalShipments();

  return (
    <StateModalHandler<FailedResponse>>
      {({ close, open, state }) => (
        <>
          {children({ createShipmentsMutation, openResponseModal: open })}
          <FailedResponseModal
            title="Status tworzenia przesyłek"
            data={state}
            close={() => {
              close();
              onResponseModalClose?.();
            }}
          />
        </>
      )}
    </StateModalHandler>
  );
};
