import { LongHeldPackageTab } from "api/unique-packages/models";
import { RadioFilter } from "components/common/filters/filterComponents/filterSelect/FilterSelect";
import { filterFactory } from "components/utils/withFilters";
import { useQuery } from "hooks";
import { useNavigate } from "hooks/useNavigate";
import { useParams } from "react-router";
import { queryString } from "utilities";

const IsReleasedFilter = () => {
  const navigate = useNavigate();
  const params = useParams<{ tab: LongHeldPackageTab }>();
  const { tab } = params;
  const { query } = useQuery();

  return (
    <div>
      <RadioFilter
        name="released"
        option={{
          label: "Wydane",
          value: "true",
        }}
        checked={tab === LongHeldPackageTab.RELEASED}
        handleChange={value => {
          if (value === "true") {
            return navigate(
              `/wms/long-held-packages/list/${LongHeldPackageTab.RELEASED}${queryString.stringify({
                ...query,
                page: 1,
              })}`,
            );
          }
          return navigate(
            `/wms/long-held-packages/list/${LongHeldPackageTab.ALL}${queryString.stringify({
              ...query,
              page: 1,
            })}`,
          );
        }}
      />
      <RadioFilter
        name="notReleased"
        option={{
          label: "Niewydane",
          value: "false",
        }}
        checked={tab === LongHeldPackageTab.NOT_RELEASED}
        handleChange={value => {
          if (value === "false") {
            return navigate(
              `/wms/long-held-packages/list/${
                LongHeldPackageTab.NOT_RELEASED
              }${queryString.stringify({
                ...query,
                page: 1,
              })}`,
            );
          }
          return navigate(
            `/wms/long-held-packages/list/${LongHeldPackageTab.ALL}${queryString.stringify({
              ...query,
              page: 1,
            })}`,
          );
        }}
      />
    </div>
  );
};
export const longHeldPackagesFilters = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "customerId",
    searchBy: "customers",
  },
  {
    type: FilterType.Number,
    label: "l. dni w magazynie większa niż",
    placeholder: "0",
    name: "daysInStockFrom",
  },
  {
    type: FilterType.Number,
    label: "l. dni w magazynie mniejsza niż",
    placeholder: "0",
    name: "daysInStockTo",
  },
  {
    type: FilterType.Custom,
    label: "Status wydania",
    render: IsReleasedFilter,
    name: "isReleased",
    filterBarValueRenderer: () => {
      return [];
    },
  },
]);
