import { UUID } from "api/types";
import { wmsActions } from "api/wms/actions";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { cx, queryString } from "utilities";
import { useScannedPositionsColumns } from "./useScannedPositionsColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ScannedPositions } from "api/wms/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Assign } from "utility-types";
import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "./CreateSalesInvoicesModal.module.css";
import { useStateModal } from "hooks";
import { CreateInvoicesForMultipleOrdersModal } from "pages/customers/customerOrdersList/OrdersList/createInvoicesForMultipleOrdersModal/CreateInvoicesForMultipleOrdersModal";
import { PreviewTradingDocument } from "api/trading-documents/models";
import { useQueryClient } from "react-query";
import { wmsKeys } from "api/wms/keys";
import cuid from "cuid";

interface Props {
  close: () => void;
  loadingsIds: UUID[];
}

export type NormalizedScannedPosition = Assign<ScannedPositions, { id: UUID }>;

export const CreateSalesInvoicesModal = ({ close, loadingsIds }: Props) => {
  const search = queryString.stringify({ loadingsIds });
  const {
    data: scannedPositions,
    error,
    isLoading,
    isPreviousData,
  } = wmsActions.useScannedPositionsInvoicingStatus(search);
  const tableProps = useTableFeatureConnector({
    rows: getNormalizedScannedPositions(scannedPositions || []),
  });
  const createSalesInvoiceForOrdersModal = useStateModal<{
    preview: PreviewTradingDocument;
    customerId?: number;
  }>();
  const columns = useScannedPositionsColumns(createSalesInvoiceForOrdersModal.open);
  const queryClient = useQueryClient();

  return (
    <>
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Utwórz fakturę sprzedażową
          </Typography>
        }
        width={688}
      >
        <div>
          <div className="d-flex flex-column flex-1 overflow-hidden py-2 px-3">
            <Table<NormalizedScannedPosition>
              columns={columns}
              error={error}
              isLoading={isLoading || isPreviousData}
              uiSchema={{
                cell: { height: "34" },
                header: { backgroundColor: "neutralWhite100" },
              }}
              {...tableProps}
              overrides={() => ({
                ...tableProps.overrides,
                row: row => {
                  return {
                    className: cx(
                      { [styles.invoicedRow]: Boolean(row.createdInvoices.length) },
                      tableProps.overrides?.().row?.(row).className,
                    ),
                  };
                },
              })}
            />
          </div>
          <div className="p-3">
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Zamknij
            </Button>
          </div>
        </div>
      </Modal>
      {createSalesInvoiceForOrdersModal.isOpen && (
        <CreateInvoicesForMultipleOrdersModal
          close={createSalesInvoiceForOrdersModal.close}
          isOpen={createSalesInvoiceForOrdersModal.isOpen}
          state={createSalesInvoiceForOrdersModal.state}
          onSuccess={() => {
            queryClient.invalidateQueries(wmsKeys.scannedPositionsInvoicingStatus.list(search));
          }}
        />
      )}
    </>
  );
};

const getNormalizedScannedPositions = (
  scannedPositions: ScannedPositions[],
): NormalizedScannedPosition[] => {
  return scannedPositions.map(position => ({
    id: position.customer?.id.toString() || cuid(),
    ...position,
  }));
};
