import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { Route } from "api/routes/models";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { useSelector } from "hooks";
import styles from "../RightPanel.module.css";
import carIcon from "assets/images/24.svg";
import cx from "classnames";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { Icon } from "components/utils";
import rampIcon from "assets/images/172g.svg";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  route: Route;
}

export const DriversSection = ({ route }: Props) => {
  const drivers = useSelector(store => store.partials.drivers);
  const cars = useSelector(store => store.partials.cars);
  const ramps = useSelector(state => state.partials.ramps);
  const driverMutation = useRoutePatchMutation();
  const secondaryDriverMutation = useRoutePatchMutation();
  const carMutation = useRoutePatchMutation();
  const rampMutation = useRoutePatchMutation();

  const carsForSelect = cars.map(car => ({
    id: car.id,
    name: `${car.brand}, ${car.registrationNumber}`,
    filters: {
      brand: car.brand,
      registrationNumber: car.registrationNumber,
    },
  }));

  const rampsForSelect = ramps.map(ramp => ({
    id: ramp.id,
    name: ramp.name,
    filters: {
      code: ramp.code,
      name: ramp.name,
    },
  }));

  const mappedDrivers = drivers
    .filter(driver => driver.id !== route.secondaryDriver?.id)
    .map(driver => ({
      id: driver.id,
      avatar: driver.avatar || "",
      name: `${driver.firstName} ${driver.lastName}`,
      firstName: driver.firstName,
      lastName: driver.lastName,
    }));

  const mappedSecondaryDrivers = drivers
    .filter(driver => driver.id !== route.driver?.id)
    .map(driver => ({
      id: driver.id,
      avatar: driver.avatar || "",
      name: `${driver.firstName} ${driver.lastName}`,
      firstName: driver.firstName,
      lastName: driver.lastName,
    }));

  return (
    <RightPanelSection>
      <InfoLabel className={cx(styles.driverLabel, "pb-3")} title="kierowca">
        <AssignedUser
          enableClear={true}
          fixedWidth={155}
          items={mappedDrivers}
          onChange={value => {
            const driver = value && drivers.find(driver => driver.id === value.id);
            driverMutation.mutate({
              id: route.id,
              toUpdate: { driver },
            });
          }}
          overrideWrapper={{}}
          overrideToggleButton={{ style: { height: 28, width: "155px", border: "1px solid #ddd" } }}
          type="driver"
          value={route.driver}
        />
        {route.driver && (
          <div className="d-flex align-items-center gap-2 ml-3">
            <div className={styles.labelText}>Nr kierowcy: </div>
            <div className="body-14 fw-500">{route.driver ? route.driver.phone : EMPTY_VALUE}</div>
          </div>
        )}
      </InfoLabel>
      <InfoLabel className={cx(styles.driverLabel, "pb-3")} title="drugi kierowca">
        <AssignedUser
          enableClear={true}
          fixedWidth={155}
          items={mappedSecondaryDrivers}
          onChange={value => {
            const secondaryDriver = value && drivers.find(driver => driver.id === value.id);
            secondaryDriverMutation.mutate({
              id: route.id,
              toUpdate: { secondaryDriver },
            });
          }}
          overrideWrapper={{}}
          overrideToggleButton={{ style: { height: 28, width: "155px", border: "1px solid #ddd" } }}
          type="driver"
          value={route.secondaryDriver}
        />
        {route.secondaryDriver && (
          <div className="d-flex align-items-center gap-2 ml-3">
            <div className={styles.labelText}>Nr kierowcy: </div>
            <div className="body-14 fw-500">
              {route.secondaryDriver ? route.secondaryDriver.phone : EMPTY_VALUE}
            </div>
          </div>
        )}
      </InfoLabel>
      <InfoLabel className={cx(styles.driverLabel, styles.carLabel)} title="samochód">
        <AdvancedSelect
          inputPlaceholder="Szukaj samochodu..."
          onChange={value => {
            const car = value && cars.find(car => car.id === value.id);
            carMutation.mutate({ id: route.id, toUpdate: { car } });
          }}
          selectedItem={route.car?.id ?? null}
          itemToDisplaySelected={item => (
            <div className="d-flex align-items-center body-14 fw-500">
              <Icon img={carIcon} type="round" className={cx(styles.advancedSelectIcon, "mr-1")} />
              <span className="text-nowrap text-truncate overflow-hidden">
                {item ? item.name : "Przypisz"}
              </span>
            </div>
          )}
          items={carsForSelect}
          width={155}
          itemsWidth={350}
          overrides={{
            list: { className: styles.advancedSelectList },
            toggleButton: { style: { border: "1px solid #ddd", paddingBottom: 1, paddingTop: 1 } },
          }}
        />
      </InfoLabel>
      <InfoLabel className={cx(styles.driverLabel, styles.carLabel)} title="rampa">
        <AdvancedSelect
          inputPlaceholder="Szukaj rampy..."
          onChange={value => {
            const ramp = value && ramps.find(ramp => ramp.id === value.id);
            rampMutation.mutate({ id: route.id, toUpdate: { ramp } });
          }}
          selectedItem={route.ramp?.id ?? null}
          itemToDisplaySelected={item => (
            <div className="d-flex align-items-center body-14 fw-500">
              <Icon img={rampIcon} type="round" className={cx("mr-1", styles.advancedSelectIcon)} />
              <span className="text-nowrap text-truncate overflow-hidden">
                {item ? item.name : "Przypisz"}
              </span>
            </div>
          )}
          items={rampsForSelect}
          width={155}
          itemsWidth={350}
          overrides={{
            list: { className: styles.advancedSelectList },
            toggleButton: { style: { border: "1px solid #ddd", paddingBottom: 1, paddingTop: 1 } },
          }}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
