import { pluralize, yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const maxLengthMsg = (field: string, chars: number): string => {
  return `Pole ${field} powinno zawierać maksymalnie ${chars} ${pluralize.pl(chars, {
    singular: "znak",
    plural: "znaki",
    other: "znaków",
  })}`;
};

export const validationSchema = yup.object().shape({
  paymentNumber: yup.string().max(128, maxLengthMsg("Nr płatności", 128)),
  amount: yup.string().required(requiredMsg("Kwota")),
  bankTransactionNumber: yup.string().max(50, maxLengthMsg("Nr przelewu", 50)),
  provider: yup.string().max(50, maxLengthMsg("Usługa", 50)),
  items: yup
    .array()
    .when("kind", {
      is: "ADVANCE",
      then: yup.array().min(1, "Wymagana przynajmniej jedna pozycja faktury dla zaliczki"),
      otherwise: yup.array(),
    })
    .of(
      yup.object().shape({
        totalAmount: yup.number().when("kind", {
          is: "ADVANCE",
          then: yup
            .number()
            .min(0.01, "Wartość musi wynosić minimum 0.01")
            .required("Kwota jest wymagana")
            .max(
              yup.ref("initialAmount"),
              "Wartość nie może być większa niż kwota pozycji na fakturze",
            ),
          otherwise: yup.number(),
        }),
      }),
    ),
});
