import { createApiQuery } from "hooks/createApiQuery";
import { packagesApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { useMutation } from "hooks/useMutation";
import { PackageListItem } from "api/warehouse/models";
import { Pagination } from "api/types";
import { packagesKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getAnyErrorKey } from "utilities";

const usePackage = createApiQuery(packagesApi.getPackageQuery);
const useItems = createPaginatedApiQuery(packagesApi.getItems);

const useDeleteAssignment = (search: string) => {
  return withDeleteConfirmation(
    useMutation(packagesApi.deleteAssignment, ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevList = queryClient.getQueryData<Pagination<PackageListItem>>(
          packagesKeys.package.list(search),
        );

        queryClient.setQueryData<Pagination<PackageListItem>>(
          packagesKeys.package.list(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.filter(_package => _package.id !== args.package),
            };
          },
        );

        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(packagesKeys.package.list(search));
      },
      onError: (error, _, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryClient.setQueryData<Pagination<PackageListItem>>(
          packagesKeys.package.list(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: onMutateReturn.prevList!.results,
            };
          },
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();
};

const usePostAssignment = () =>
  useMutation(packagesApi.postAssignment, ({ queryUtils }) => queryUtils.handleCommonResponse);

export const packagesActions = {
  useDeleteAssignment,
  useItems,
  usePackage,
  usePostAssignment,
};
