import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiListAlt } from "components/miloDesignSystem/atoms/icons/MdiListAlt";
import { ordersUtils } from "pages/externalManufacturing/orders/ordersUtils";
import { ExternalManufacturingOrdersRouting } from "routes/externalManufacturing/ExternalManufacturingOrdersRouting";

export const orders: ModuleLink = {
  url: "orders",
  label: "Lista zleceń do producentów",
  icon: MdiListAlt,
  subSection: {
    renderer: (
      <TabSubSection baseUrl="/external-manufacturing/orders/list" tabs={ordersUtils.tabsDict} />
    ),
    isExpandedByDefault: true,
  },
  routing: ExternalManufacturingOrdersRouting,
  isAccessible: true,
};
