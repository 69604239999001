import { UserWithInitialsExtendedDetails } from "api/users/models";

export enum MessageType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export enum EmailType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export enum EmailStatus {
  CLICKED_LINK = "CLICKED_LINK",
  DECLINED = "DECLINED",
  OPENED = "OPENED",
  SENT = "SENT",
}

export enum SmsMessageKind {
  AUTOMATIC_SEND = "AUTOMATIC_SEND",
  COMPLAINT_REPAIR = "COMPLAINT_REPAIR",
  COMPLAINT_REPLACEMENT = "COMPLAINT_REPLACEMENT",
  COMPLAINT_RESIGNATION = "COMPLAINT_RESIGNATION",
  COMPLAINT_SUPPLEMENT = "COMPLAINT_SUPPLEMENT",
  ORDER = "ORDER",
  ORDER_DELIVERY_DATE_CONFIRMED = "ORDER_DELIVERY_DATE_CONFIRMED",
  ORDER_STATUS_CHANGE = "ORDER_STATUS_CHANGE",
  REQUEST_FOR_CUSTOMER_CONFIRMATION = "REQUEST_FOR_CUSTOMER_CONFIRMATION",
  REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPAIR = "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPAIR",
  REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_SUPPLEMENT = "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_SUPPLEMENT",
  REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPLACEMENT = "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_REPLACEMENT",
  REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_RESIGNATION = "REQUEST_FOR_CUSTOMER_CONFIRMATION_COMPLAINT_RESIGNATION",
  ROUTE = "ROUTE",
}

export interface SmsMessage {
  id: number;
  countryCode: string;
  created: string;
  name: string;
  body: string;
  kind: SmsMessageKind;
  type: MessageType;
  salesAccount: {
    id: number;
    name: string;
    channel: string;
    color: string;
    currency: string;
  };
  isDefault: boolean;
  user: UserWithInitialsExtendedDetails;
}

export interface SmsMessageDetails {
  id: number;
  countryCode: string;
  created: string;
  name: string;
  body: string;
  kind: SmsMessageKind;
  type: MessageType;
  salesAccount: {
    id: number;
    name: string;
    channel: string;
    color: string;
    currency: string;
  };
  isDefault: boolean;
  user: UserWithInitialsExtendedDetails;
}

export interface EmailMessage {
  body: string;
  created: string;
  createdBy: {
    avatar: string | null;
    firstName: string;
    lastName: string;
    id: number;
  };
  emailFrom: string;
  emailTo: string;
  emailType: EmailType;
  eventType: string;
  externalId: string;
  failureReason: string;
  id: number;
  isConfirmationEmail: boolean;
  lastModified: string;
  modifiedAt: string | null;
  order: number;
  route: number;
  status: EmailStatus;
  subject: string;
}
