import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedLineItem } from "./LineItemsSection";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";

export const useLineItemsColumns = (page: number) => {
  return useCreateTableColumns<NormalizedLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "#",
        size: 25,
        cell: info => {
          const counter = info.row.index;
          const isNested = info.row.depth;

          if (isNested) return null;

          return (
            <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
              {page > 1 ? counter + 1 + (page - 1) * 30 : counter + 1}.
            </Typography>
          );
        },
      }),
      columnHelper.expandable(),
      columnHelper.accessor(row => row, {
        header: "nazwa",
        size: 310,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          return (
            <div className="d-flex flex-1 align-items-center gap-2 overflow-hidden">
              {lineItem.sourceKind === DraftOrderLineItemSourceKind.PACKAGE || isNested ? (
                <MdiPackage2 color="yellow400" size="12" />
              ) : (
                <MdiChair color="magenta300" size="12" />
              )}
              <Typography fontSize="12" fontWeight={isNested ? "400" : "600"} noWrap>
                {lineItem.name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.code, {
        header: "EAN/SKU",
        size: 110,
        typographyProps: {
          fontSize: "12",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "producent",
        size: 100,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.quantity, {
        id: "quantity",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            zamawiam
          </Typography>
        ),
        size: 80,
        cell: info => {
          const quantity = info.getValue();

          return (
            <div className="d-flex w-100 text-right justify-content-end align-items-baseline gap-1">
              <Typography fontSize="14" fontWeight="600">
                {quantity}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
