import { ISODateTime } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { dateFns, dateUtils } from "utilities";

interface Props {
  date: ISODateTime | null;
}

export const DateDisplay = ({ date }: Props) => {
  if (!date) return null;

  return (
    <div className="d-flex align-items-center gap-1">
      <Typography fontSize="12" fontWeight="400" noWrap>
        {dateFns.format(new Date(date), "d LLL yyyy", {
          locale: dateFns.pl,
        })}
        ,
      </Typography>
      <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
        {dateUtils.formatDateToTime(date)}
      </Typography>
    </div>
  );
};
