import { NormalizedPickingPackageGroup, PickingPackageStatus } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { pickingPackageStatusDict } from "./LineItemsDetailsModal";
import { dateFns } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { parseLocation } from "utilities/parseLocation";

export const useLineItemsDetailsColumns = () =>
  useCreateTableColumns<NormalizedPickingPackageGroup>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.name, {
        header: "nazwa paczki",
        size: 330,
        cell: info => {
          const name = info.getValue();
          const isNested = info.row.depth;

          return (
            <Typography
              color={isNested ? "neutralBlack88" : "neutralBlack48"}
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {name || EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.locations, {
        header: "lokalizacja",
        size: 140,
        cell: info => {
          const isNested = info.row.depth;
          if (!isNested) return null;
          const locations = info.getValue() || [];
          if (!locations.length) return <EmptyValue fontSize="14" fontWeight="700" />;
          return (
            <Typography fontSize="14" fontWeight="700" noWrap>
              {locations.map(location => parseLocation(location).name).join(", ")}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 80,
        cell: info => {
          const status = info.getValue();
          const isNested = info.row.depth;
          if (!isNested) return null;
          if (!status) return <EmptyValue />;
          return (
            <Tag
              label={pickingPackageStatusDict[status].label}
              variant={pickingPackageStatusDict[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          const status = info.getValue().status;
          const pickedUpAt = info.getValue().pickedUpAt;
          const placeOnRampAt = info.getValue().placeOnRampAt;
          const isNested = info.row.depth;

          if (!isNested) return null;

          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {(status === PickingPackageStatus.ON_THE_WAY ||
                status === PickingPackageStatus.PICKED_UP) &&
              pickedUpAt
                ? dateFns.formatRelative(new Date(pickedUpAt), "dd.MM.yyyy, H:mm")
                : status === PickingPackageStatus.ON_RAMP && placeOnRampAt
                ? dateFns.formatRelative(new Date(placeOnRampAt), "dd.MM.yyyy, H:mm")
                : EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborator, {
        header: "kto?",
        size: 26,
        cell: info => {
          const isNested = info.row.depth;
          const collaborator = info.getValue();
          if (!isNested) return null;
          return (
            <div className="d-flex align-items-center">
              <Avatar size="sm" theme="light" user={collaborator} />
            </div>
          );
        },
      }),
    ];
  });
