import { getIndexGreatestWeight, patchIndexCarryingAmount } from "api/indexes/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { indexesApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";

const useIndexGreatestWeight = createApiQuery(getIndexGreatestWeight);
const useIndexes = createPaginatedApiQuery(indexesApi.getIndexes);
const useProductSetIndexes = createPaginatedApiQuery(indexesApi.getProductSetIndexes);
const usePriceListIndexes = createPaginatedApiQuery(indexesApi.getPriceListIndexes);
const useIndex = createApiQuery(indexesApi.getIndex);
const useIndexExist = createApiQuery(indexesApi.getIndexExist);
const useIndexesForExternalManufacturing = createPaginatedApiQuery(
  indexesApi.getIndexesForExternalManufacturing,
);
const useIndexForExternalManufacturing = createApiQuery(
  indexesApi.getIndexForExternalManufacturing,
);

const usePatchIndexCarryingAmountBasedOnOrdersItem = () =>
  useMutation(indexesApi.patchIndex, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        text: "Pomyślnie zaktualizowano cenę wniesienia indeksu",
        title: "Sukces",
        type: "success",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));

const usePatchIndexCarryingAmount = () =>
  useMutation(patchIndexCarryingAmount, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        text: "Pomyślnie zaktualizowano ceny wniesienia",
        title: "Sukces",
        type: "success",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));

const useBulkUpdateProductIndexes = () =>
  useMutation(
    indexesApi.bulkPatchProductIndexes,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

const useBulkUpdateIndexes = () =>
  useMutation(indexesApi.bulkUpdateIndexes, ({ queryUtils }) => queryUtils.handleCommonResponse);

export const indexesActions = {
  useBulkUpdateIndexes,
  useBulkUpdateProductIndexes,
  useIndexGreatestWeight,
  usePatchIndexCarryingAmount,
  usePatchIndexCarryingAmountBasedOnOrdersItem,
  useIndex,
  useIndexExist,
  useIndexes,
  useIndexesForExternalManufacturing,
  useIndexForExternalManufacturing,
  useProductSetIndexes,
  usePriceListIndexes,
};
