import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiSingleOrders } from "components/miloDesignSystem/atoms/icons/MdiSingleOrders";
import { externalManufacturingUtils } from "pages/externalManufacturing/shared/utils/externalManufacturingUtils";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";

export const orderLineItems: ModuleLink = {
  url: "manufacturer-line-items",
  label: "Poj. zlecenia do producentów",
  icon: MdiSingleOrders,
  subSection: {
    renderer: (
      <TabSubSection
        baseUrl="/external-manufacturing/manufacturer-line-items/list"
        tabs={externalManufacturingUtils.orderLineItemsTabsDict}
      />
    ),
    isExpandedByDefault: true,
  },
  routing: ManufacturerLineItems,
};
