import { ordersKeys } from "api/keys";
import { patchMessage } from "api/messages/calls";
import { ListOrder, OrderMessagesSchema, SmsStatus } from "api/orders/models";
import { Pagination } from "api/types";
import { useOrderMessages } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useMarkIncomingMessagesAsRead = (
  data: OrderMessagesSchema | null,
  orderId: string,
  search?: string,
) => {
  const markMessagesAsReadMutation = useMutation(patchMessage);
  const queryClient = useQueryClient();
  const { refetch } = useOrderMessages(orderId);

  useEffect(() => {
    if (data) {
      data.messages
        .filter(message => message.wayOfCommunication === "INCOMING" && !message.isRead)
        .map(message => message.id)
        .forEach(messageId =>
          markMessagesAsReadMutation.mutate({
            id: String(messageId),
            data: {
              isRead: true,
            },
          }),
        );
      if (
        search &&
        !Boolean(data.messages.filter(message => message.wayOfCommunication === "INCOMING").length)
      ) {
        queryClient.setQueryData<Pagination<ListOrder>>(
          ordersKeys.orders(search),
          (currentList: Pagination<ListOrder> | undefined) => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(order => {
                if (order.id === Number(orderId)) {
                  order.smsStatus = SmsStatus.SEEN;
                }
                return order;
              }),
            };
          },
        );
        queryClient.invalidateQueries(ordersKeys.orders(search));
        queryClient.invalidateQueries(ordersKeys.order(orderId));
        refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
