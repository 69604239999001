import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "../shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);
  return [
    factory.invoiceDeliveryDateRange,
    factory.invoiceIssueDateRange,
    factory.countryCode,
    factory.salesAccount,
    factory.routeSignature,
    factory.orderGroupSignature,
    factory.sellers,
    factory.hasAllOrdersDelivered,
    {
      type: FilterType.Select,
      label: "Czy posiada zaliczki?",
      name: "hasAdvanceInvoice",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    },
    factory.hasRecipientTaxID,
    factory.hasBuyerTaxID,
    factory.isFiscalized,
    factory.canBeFiscalized,
    factory.status,
    factory.customerKind,
    factory.correspondingReceiptPrintingStatus,
    factory.taxProcedure,
    factory.isPaid,
    factory.currency,
    factory.pickupID,
    factory.shipmentTrackingNumber,
    factory.paymentType,
    factory.paymentProvider,
    factory.isAssigned,
    factory.customers,
    factory.transferDate,
    factory.isXMLOptimaDownloaded,
    factory.isPaymentOverdue,
    factory.transferNumber,
    factory.isSelfShipped,
    {
      type: FilterType.Select,
      label: "Czy email do odbiorcy został wysłany?",
      name: "isSentToRecipient",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    },
  ];
});
