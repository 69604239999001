import { TradingDocument } from "api/trading-documents/models";
import styles from "../../StatusInExternalServiceSection.module.css";
import { StepLabel } from "../../shared/components/stepLabel/StepLabel";
import { SendingToExternalServiceStatus } from "api/trading-documents/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DateDisplay } from "../../shared/components/dateDisplay/DateDisplay";
import { cx } from "utilities";

interface Props {
  tradingDocument: TradingDocument;
}

export const ExternalServiceReceiving = ({ tradingDocument }: Props) => {
  const receivingInExternalServices = tradingDocument.statusesInExternalService.receiving;

  return (
    <div className={styles.row}>
      <StepLabel
        label="Odbiór"
        status={receivingInExternalServices.receivedInExternalServiceStatus}
      />
      <div className={cx(styles.content, "d-flex align-items-center gap-2 py-1 pl-0")}>
        <StatusTag status={receivingInExternalServices.receivedInExternalServiceStatus} />
        <ReceivingPerStatus receivingInExternalServices={receivingInExternalServices} />
      </div>
    </div>
  );
};

const StatusTag = ({ status }: { status: SendingToExternalServiceStatus }) => {
  switch (status) {
    case SendingToExternalServiceStatus.DONE:
      return <Tag label="Odebrano" variant="success" />;
    case SendingToExternalServiceStatus.FAILED:
    case SendingToExternalServiceStatus.PENDING:
    case SendingToExternalServiceStatus.NOT_STARTED:
      return <Tag label="Nie odebrano" type="outlined" variant="quaternary" />;
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled receiving status: ${exhaustiveCheck}`);
      return null;
    }
  }
};

const ReceivingPerStatus = ({
  receivingInExternalServices,
}: {
  receivingInExternalServices: TradingDocument["statusesInExternalService"]["receiving"];
}) => {
  switch (receivingInExternalServices.receivedInExternalServiceStatus) {
    case SendingToExternalServiceStatus.DONE:
      return <DateDisplay date={receivingInExternalServices.receivedInExternalServiceAt} />;
    case SendingToExternalServiceStatus.FAILED:
      return (
        <div className="d-flex align-items-center gap-2 overflow-hidden">
          <Tag.WithCustomColor
            backgroundColor="red32"
            label="Błąd odbioru"
            startIcon={MdiEmergencyHome}
            textColor="red500"
          />
          {Boolean(receivingInExternalServices.receivedInExternalServiceErrorNote.length) && (
            <div className={cx(styles.errorMessage, "d-flex flex-1 align-items-center gap-1")}>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                powód:
              </Typography>
              <Typography color="danger600" fontSize="12" fontWeight="400" noWrap>
                {receivingInExternalServices.receivedInExternalServiceErrorNote}
              </Typography>
            </div>
          )}
          <DateDisplay date={receivingInExternalServices.receivedInExternalServiceAt} />
        </div>
      );
    case SendingToExternalServiceStatus.NOT_STARTED:
    case SendingToExternalServiceStatus.PENDING:
      return null;
    default: {
      const exhaustiveCheck: never = receivingInExternalServices.receivedInExternalServiceStatus;
      console.error(`Unhandled receiving status: ${exhaustiveCheck}`);
      return null;
    }
  }
};
