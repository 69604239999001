import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OverviewReleasedForCustomer } from "api/wms/models";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { ClientGenericTable, GenericTable } from "typeUtilities";
import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { formatGenericTableDate } from "components/common/genericTable/utils";
import { DatepointerDetails } from "pages/wms/shared/components/DatepointerDetails";
import { getDatepointerColor } from "pages/wms/shared/utils/getDatepointerColor";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const columnHelper = createColumnHelper<
  ClientGenericTable<OverviewReleasedForCustomer>["rows"][number]
>();

const dynamicColumnHelper = createColumnHelper<ClientGenericTable<string>["rows"][number]>();

export const useOverviewReleasedForCustomerListColumns = (
  tableData: GenericTable<OverviewReleasedForCustomer> | null,
  filters: TableFilters,
) => {
  return useMemo(() => {
    if (!tableData) return [];

    const dynamicColumns = tableData
      ? tableData.columns.filter(column => !isNaN(Number(column.identifier)))
      : [];

    return [
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        header: "data",
        size: 60,
        cell: info => (
          <Typography
            color={getDatepointerColor(info.getValue(), filters.rowBy)}
            fontSize="12"
            fontWeight="600"
          >
            {formatGenericTableDate(tableData.rowsBy, info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        id: "DATEPOINTER_DAYNAME_RELEASED",
        header: "",
        size: 40,
        cell: info => <DatepointerDetails date={info.getValue()} rowBy={filters.rowBy} />,
      }),
      columnHelper.accessor(row => row.CUSTOMER.value, {
        id: "CUSTOMER",
        header: () => (
          <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
            {tableData.columns.find(
              column => column.identifier === OverviewReleasedForCustomer.CUSTOMER,
            )?.name || ""}
          </Typography>
        ),
        size: 78,
        cell: info => (
          <Tooltip title={info.getValue()}>
            <div className="w-100 overflow-hidden">
              <Typography fontSize="12" fontWeight="700" noWrap>
                {info.getValue() || EMPTY_VALUE}
              </Typography>
            </div>
          </Tooltip>
        ),
      }),
      ...dynamicColumns.map(dynamicColumn => {
        return dynamicColumnHelper.accessor(row => row[dynamicColumn.identifier].value, {
          id: dynamicColumn.identifier,
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(column => column.identifier === dynamicColumn.identifier)
                ?.name || ""}
            </Typography>
          ),
          size: 65,
          cell: info => (
            <Typography className="text-right w-100" fontSize="12" fontWeight="600">
              {info.getValue() || EMPTY_VALUE}
            </Typography>
          ),
        });
      }),

      columnHelper.accessor(row => row.OTHERS.value, {
        id: "OTHER_ROW",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            {tableData.columns.find(
              column => column.identifier === OverviewReleasedForCustomer.OTHERS,
            )?.name || ""}
          </Typography>
        ),
        size: 65,
        cell: info => (
          <Typography className="text-right w-100" fontSize="12" fontWeight="600">
            {info.getValue() || EMPTY_VALUE}
          </Typography>
        ),
      }),
    ];
  }, [tableData, filters.rowBy]);
};
