import { useCallback, useEffect } from "react";
import { UUID } from "api/types";
import { ctrlOrMetaKey } from "./useTableMultiSelect";

export const useTableKeyboard = <T extends { id: UUID | number }>({
  setSelectedRows,
  rows,
}: {
  setSelectedRows: (value: React.SetStateAction<T[]>) => void;
  rows: T[];
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        (activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA" ||
          activeElement.tagName === "SELECT")
      ) {
        return; // Skip handling keyboard events for these elements
      }

      if (event.key === "Escape") {
        setSelectedRows([]);
      }
      if (ctrlOrMetaKey(event) && event.key === "a") {
        event.preventDefault();

        setSelectedRows(prev => [
          ...prev.filter((el): boolean => !rows.find(row => row.id === el.id)),
          ...rows,
        ]);
        return;
      }
    },
    [rows, setSelectedRows],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
};
