import { withFilters } from "components/utils/withFilters";
import { useQuery, useRedux, useStateModal } from "hooks";
import { HistoryPayload } from "../listView/ListView";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "../subcomponents/Header";
import { UnitHistory } from "../listView/subcomponents/UnitHistory";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { ManufacturingPriority } from "api/manufacturing/units/models";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "../listView/ListView.module.css";
import { cx, queryString } from "utilities";
import { useEffect } from "react";
import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturingItem } from "api/manufacturing/models";
import { useManufacturingItemsColumns } from "./useManufacturingItemsColumns";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { RightPanel } from "./rightPanel/RightPanel";
import { OrderTypeChoices } from "api/orders/enums";
import { filterList } from "./filterList";

export const AllManufacturingItemsList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });

  const search = queryString.stringify(query);
  const {
    data: units,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useGetManufacturingItems(search);
  const historyModal = useStateModal<HistoryPayload>();
  const [dispatch, { ui }] = useRedux();

  const stageListMultiSelect = useTableMultiSelect({ rows: units });

  const tableProps = useTableFeatureConnector({
    rows: units,
    withDrawer: "manufacturingStageNormalList",
    withMultiSelect: stageListMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  useEffect(() => {
    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
  }, [dispatch, ui]);

  const columns = useManufacturingItemsColumns();

  return (
    <PageWrapper>
      <Header isListOfAllUnits />
      <div className="d-flex flex-column flex-1 overflow-hidden gap-2">
        <Table<ManufacturingItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              row: row => {
                return {
                  className: cx(tableProps?.overrides?.().row?.(row).className, {
                    [styles.criticalRow]: row.priority === ManufacturingPriority.A,
                    [styles.complaintRow]: row.order?.type === OrderTypeChoices.COMPLAINT,
                  }),
                };
              },
            };
          }}
        />
        <RightPanel />
        <TableToolbar
          close={stageListMultiSelect.clear}
          numberOfSelectedItems={stageListMultiSelect.selectedRows.length}
        >
          {/* <Tooltip title="Zamów materiały">
            <IconButton
              icon={MdiFabric}
              onClick={orderMaterialModal.open}
              theme="dark"
              variant="transparent"
            />
          </Tooltip> */}

          <div>
            <FileDownloadHandler
              factoryFn={() =>
                manufacturingFileFactory.manufacturingItemPdf(
                  stageListMultiSelect.selectedRows.map(({ id }) => id),
                  "Zlecenia-produkcyjne",
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz etykiety zleceń">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    theme="dark"
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
          </div>
        </TableToolbar>
      </div>
      {historyModal.isOpen && <UnitHistory close={historyModal.close} state={historyModal.state} />}
    </PageWrapper>
  );
});
