import { OrderLineItemDetails } from "api/external-manufacturing/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { ExternalManufacturingOrderStatusTag } from "pages/externalManufacturing/shared/components/ExternalManufacturingOrderStatusTag";

interface Props {
  lineItemDetails: OrderLineItemDetails;
}

export const TitleSection = ({ lineItemDetails }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {lineItemDetails.signature}
        </Typography>
        <ExternalManufacturingOrderStatusTag status={lineItemDetails.orderStatus} />
      </div>
    </RightPanelSection>
  );
};
