import { useMutation, useMutationOptions } from "hooks/useMutation";
import {
  patchPurchaseInvoiceStatus,
  patchTradingDocumentStatus,
} from "api/trading-documents/calls";
import { BulkSalesInvoiceConfirmPreview, TradingDocument } from "api/trading-documents/models";
import { Pagination } from "api/types";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { getAnyErrorKey, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery, useToastr } from "hooks";
import { QueryClient } from "react-query";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { proformasApi } from "api/trading-documents/proforma/api";
import { advanceApi } from "api/trading-documents/advance/api";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { tradingDocumentUtils } from "utilities/tradingDocuments";

export type ReplyModal = {
  isOpen: boolean;
  open: (stateToSet: BulkSalesInvoiceConfirmPreview) => void;
  close: () => void;
  state: BulkSalesInvoiceConfirmPreview | null;
};

interface Props {
  route?: string | number;
  tradingDocument: TradingDocument;
  replyModal?: ReplyModal;
}

export const ConfirmCheckbox = ({ route, tradingDocument, replyModal }: Props) => {
  const { query } = useQuery();
  const { panelId, ...filters } = query;
  const removeInvoiceConfirmationMutation = tradingDocumentsActions.useRemoveInvoiceConfirmationPatch();
  const toastr = useToastr();

  const handleRollback = (
    prevList: Pagination<TradingDocument>,
    prevPanel: TradingDocument,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<Pagination<TradingDocument>>(
      tradingDocumentsKeys.tradingDocument.list(
        route
          ? queryString.stringify({
              ...filters,
              routeId: route,
            })
          : queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
      ),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          results: prevList.results,
        };
      },
    );

    if (panelId && panelId === tradingDocument.id) {
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        currentDocument => {
          assertIsDefined(currentDocument);
          return {
            ...currentDocument,
            status: prevPanel.status,
          };
        },
      );
    }
  };

  const updateStatusOptions = useMutationOptions(
    () => {
      const data = { tradingDocument: tradingDocument.id };
      if (tradingDocument.invoiceType === "ADVANCE") return advanceApi.patchAdvanceStatus(data);
      if (tradingDocument.invoiceType === "PROFORMA") return proformasApi.patchProformaStatus(data);
      if (tradingDocument.invoiceType === "PURCHASE") return patchPurchaseInvoiceStatus(data);
      return patchTradingDocumentStatus(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: () => {
        const prevList = queryClient.getQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
        );
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );

        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : tradingDocument.type === "RECEIPT"
              ? queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: "CONFIRMED" };
                }
                return result;
              }),
            };
          },
        );

        if (panelId && panelId === tradingDocument.id) {
          queryClient.setQueryData<TradingDocument>(
            tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
            currentDocument => {
              assertIsDefined(currentDocument);
              return {
                ...currentDocument,
                status: "CONFIRMED",
              };
            },
          );
        }

        return { prevList, prevPanel };
      },
      onSuccess: payload => {
        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: payload.status, signature: payload.signature };
                }
                return result;
              }),
            };
          },
        );

        if (panelId && panelId === tradingDocument.id) {
          queryClient.setQueryData<TradingDocument>(
            tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
            currentDocument => {
              assertIsDefined(currentDocument);
              return {
                ...currentDocument,
                status: payload.status,
                signature: payload.signature,
              };
            },
          );
        }

        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());

        if (
          (tradingDocument.invoiceType === "SALES" ||
            tradingDocument.invoiceType === "ADVANCE" ||
            tradingDocument.invoiceType === "PROFORMA") &&
          Boolean(Object.keys(payload.message.invalid).length) &&
          replyModal
        ) {
          replyModal.open(payload.message);
        }

        if (
          tradingDocument.invoiceType !== "SALES" &&
          tradingDocument.invoiceType !== "ADVANCE" &&
          tradingDocument.invoiceType !== "PROFORMA"
        ) {
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: "Zatwierdzono dokument",
          });
        }
      },
      onError: (error, _, context) => {
        const { prevList, prevPanel } = context as {
          prevList: Pagination<TradingDocument>;
          prevPanel: TradingDocument;
        };
        handleRollback(prevList, prevPanel, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateStatusMutation = useMutation(updateStatusOptions.mutationFn, updateStatusOptions);

  return (
    <IconButton
      disabled={tradingDocumentUtils.isTradingDocumentConfirmationDisabled({
        tradingDocument,
      })}
      isLoading={updateStatusMutation.isLoading || removeInvoiceConfirmationMutation.isLoading}
      icon={MdiCheck}
      onMouseDown={event => event.stopPropagation()}
      onClick={event => {
        event.stopPropagation();
        if (tradingDocument.status === "CONFIRMED") {
          removeInvoiceConfirmationMutation.mutate({
            tradingDocuments: [tradingDocument.id],
          });
        }
        if (tradingDocument.status !== "CONFIRMED") {
          if (!tradingDocument.pitTaxObligationDate || !tradingDocument.vatTaxObligationDate) {
            toastr.open({
              type: "warning",
              title: "Wymagane działanie",
              text: getValidationMessage(tradingDocument),
            });
            return;
          }
          updateStatusMutation.mutate({});
        }
      }}
      size="small"
      variant={tradingDocument.status === "CONFIRMED" ? "success" : "outline"}
    />
  );
};

function getValidationMessage(tradingDocument: TradingDocument) {
  if (!tradingDocument.pitTaxObligationDate && !tradingDocument.vatTaxObligationDate)
    return "Uzupełnij datę rejestru vat i obowiązku podatkowego";

  if (!tradingDocument.vatTaxObligationDate) return "Uzupełnij datę rejestru vat";

  return "Uzupełnij datę obowiązku podatkowego";
}
