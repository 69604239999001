import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { UUID } from "api/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import styles from "../NotificationsSection.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { FormInput } from "components/utils";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  close: () => void;
  orderId: UUID;
}

export const SendToSpecificAddressModal = ({ close, orderId }: Props) => {
  const sendEmailNotification = externalManufacturingActions.useSendEmailNotification();

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Wyślij e-mail na wskazany adres
        </Typography>
      }
      width={400}
    >
      <Formik
        initialValues={{ recipientEmail: "" }}
        onSubmit={values => {
          const mutationValues = {
            orderId,
            recipientEmail: values.recipientEmail,
          };
          sendEmailNotification.mutate(mutationValues, {
            onSuccess: close,
          });
        }}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            className={cx({ "was-validated": !isValid }, styles.sections)}
          >
            <div className="p-3">
              <FormInput className="mt-3" type="email" name="recipientEmail" label="E-mail" />
              <Button
                className="text-uppercase"
                disabled={sendEmailNotification.isLoading}
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Wyślij{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
