import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { marketplaceStatusesKeys } from "./keys";
import { MarketplaceStatusAttempt, MarketplaceStatusItem } from "./models";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

const getAttempts = (search: string = ""): ApiFetcher<Pagination<MarketplaceStatusAttempt>> => ({
  key: marketplaceStatusesKeys.attempts(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/marketplace-statuses/attempts" + search,
    }),
});

const getItems = (search: string = ""): ApiFetcher<Pagination<MarketplaceStatusItem>> => ({
  key: marketplaceStatusesKeys.attempts(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/marketplace-statuses/items" + search,
    }),
});

const postRetry = (data: { statusEventId: UUID }) =>
  queryFetch<ManufacturingStage>({
    method: "POST",
    url: `/marketplace-statuses/items/${data.statusEventId}/retry`,
  });

const postOrdersStatusAsSent = (data: { ordersIds: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/marketplace-statuses/set-orders-status-as-sent",
    data,
  });

export const marketplaceStatusesApi = {
  getAttempts,
  getItems,
  postOrdersStatusAsSent,
  postRetry,
};
