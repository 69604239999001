import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { useSelector } from "hooks";
import { OrderPackageExchange } from "api/order-packages-exchange/models";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { Link } from "components/miloDesignSystem/atoms/link";

interface Props {
  orderExchange: OrderPackageExchange;
}

export const GeneralInfoSection = ({ orderExchange }: Props) => {
  const users = useSelector(store => store.partials.users);
  const hall = useSelector(store => store.partials.halls);

  const createdBy = users.find(user => user.uuid === orderExchange.createdBy);
  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <InfoLabel title="nazwa zamówienia">
          <Typography fontSize="14" fontWeight="500">
            {orderExchange.order.name || EMPTY_VALUE}
          </Typography>
        </InfoLabel>
        <InfoLabel title="utworzono">
          <Typography fontSize="14" fontWeight="500">
            {dateUtils.formatDateToDisplayDateAndTime(orderExchange.createdAt)}
          </Typography>
        </InfoLabel>
        <InfoLabel title="utworzono przez">
          {createdBy ? (
            <div className="d-flex align-items-center gap-1">
              <Avatar user={createdBy} size="sm" />
              <Typography fontSize="12" fontWeight="600" noWrap>
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </div>
          ) : (
            <EmptyValue fontSize="14" />
          )}
        </InfoLabel>
        <InfoLabel title="zakończono">
          <Typography fontSize="14" fontWeight="500">
            {orderExchange.finishedAt
              ? dateUtils.formatDateToDisplayDateAndTime(orderExchange.finishedAt)
              : EMPTY_VALUE}
          </Typography>
        </InfoLabel>

        <InfoLabel title="lokalizacje">
          {orderExchange.locations.length ? (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                {orderExchange.locations.slice(0, 3).map(location => (
                  <Typography key={location} fontSize="14" fontWeight="400">
                    {location}
                  </Typography>
                ))}
                {orderExchange.locations.length - 3 > 0 && (
                  <MoreDataCounter
                    counter={orderExchange.locations.length - 3}
                    style={{ width: "max-content" }}
                  />
                )}
              </div>
              <Link
                to={`/wms/warehouse-schema?hallId=${hall[0].id}&searchCollectionId=${orderExchange.baseOrderId}&kind=ORDER&collectionName=${orderExchange.signature}`}
                fontSize="14"
                fontWeight="500"
                className="ml-1"
              >
                Zobacz na schemacie
              </Link>
            </div>
          ) : (
            <EmptyValue fontSize="14" />
          )}
        </InfoLabel>

        <InfoLabel title="skanowanie paczek">
          <div className="d-flex gap-1">
            {Boolean(orderExchange.confirmedBy?.length) ? (
              orderExchange.confirmedBy.map(user => {
                return (
                  <div className="d-flex align-items-center gap-1 " key={user.id}>
                    <Avatar size="sm" user={user} theme="light" />
                    <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <div className="d-flex align-items-start">
                <EmptyValue fontSize="14" fontWeight="700" />
              </div>
            )}
          </div>
        </InfoLabel>
      </RightPanelSection>
    </>
  );
};
