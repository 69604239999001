import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import barcodeDarkIcon from "assets/images/barcodeDark.svg";
import { MultipleCheckedItemsToolbar } from "components/common/multipleCheckedItemsToolbar/MultipleCheckedItemsToolbar";
import { HighlightedRow } from "api/other/models";
import { usePrintReceipts } from "../hooks/usePrintReceipts";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { routesPreviewFactory } from "api/routes/api";

interface Props {
  close: () => void;
  quantity: number;
  highlightedRows?: HighlightedRow[];
}

export const ActionToolbar = ({ close, quantity, highlightedRows }: Props) => {
  const printReceiptsMutation = usePrintReceipts();

  return (
    <MultipleCheckedItemsToolbar
      actionButtons={
        <>
          <Tooltip title="Podgląd PDF dla zaznaczonych tras">
            <Button size="square-s" kind="transparent-white">
              <div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    routesPreviewFactory.routesProductionPDF(
                      highlightedRows ? highlightedRows.map(row => row.id).join(",") : "",
                    ).url
                  }
                >
                  <div className="btnBase btnBaseSmall">
                    <img alt="Podgląd PDF" src={downloadPdfIcon} />
                  </div>
                </a>
              </div>
            </Button>
          </Tooltip>
          <Tooltip title="Drukuj paragony dla zaznaczonych tras">
            <Button
              kind="transparent-white"
              onClick={() => {
                if (highlightedRows) {
                  printReceiptsMutation.mutate({ routes: parseRows(highlightedRows).array });
                }
              }}
              size="square-s"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="" src={barcodeDarkIcon} />
              </div>
            </Button>
          </Tooltip>
        </>
      }
      close={close}
      quantity={quantity}
    />
  );
};

const parseRows = (rows: HighlightedRow[]): { string: any; array: number[] } => {
  let stringArray = rows.map(tradingDocument => Number(tradingDocument.id));
  return {
    string: stringArray.join(","),
    array: stringArray,
  };
};
