import styles from "./FilterSelect.module.css";
import { useQuery } from "hooks";
import { FilterSelectType as SelectFilterType } from "../../types";
import { Chip } from "../shared/shared";

type Option = SelectFilterType["options"][number];

export const FilterSelect = <T extends string>({
  name,
  options,
  kind = "checkbox",
  multiple,
}: SelectFilterType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value: Option["value"] = query[name];

  const handleChange = (optionValue: Option["value"] | null) => {
    if (optionValue && multiple) {
      const values = (() => {
        const appliedFilters = value?.split(",");
        if (appliedFilters?.includes(optionValue)) {
          return appliedFilters?.filter(appliedValue => appliedValue !== optionValue).join(",");
        }

        return [...(appliedFilters || []), optionValue].join(",");
      })();

      updateQuery({ [name]: values, page: 1 });
      return;
    }

    if (optionValue) {
      updateQuery({ [name]: optionValue, page: 1 });
      return;
    }

    updateQuery({ [name]: "", page: 1 });
  };

  return (
    <div>
      {options.map(option => {
        const checked =
          value === option.value || (multiple && value?.split(",").includes(option.value));
        if (kind === "checkbox") {
          return (
            <RadioFilter
              checked={Boolean(checked)}
              handleChange={handleChange}
              name={name}
              option={option}
            />
          );
        }

        return (
          <Chip
            key={option.value}
            label={option.label}
            value={option.value}
            multiple={multiple}
            icon={option.icon}
            content={option.content}
            name={name}
            checked={checked || false}
            handleChange={handleChange}
          />
        );
      })}
    </div>
  );
};

export const RadioFilter = ({
  option,
  checked,
  name,
  handleChange,
}: {
  option: Option;
  checked: boolean;
  name: string;
  handleChange: (optionValue: Option["value"] | null) => void;
}) => {
  return (
    <label className={styles.option} key={option.value}>
      <input
        type="radio"
        value={option.value}
        checked={checked}
        onClick={() => {
          if (checked) {
            handleChange(null);
          }
        }}
        onChange={() => handleChange(option.value)}
        className={styles.input}
        name={name}
      />
      <div className="d-flex align-items-center">
        <span className={styles.radioMark}></span>
        <span className={styles.label}>{option.label}</span>
      </div>
    </label>
  );
};
