import { MyPurchaseInvoiceToReview } from "api/trading-documents/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../../shared/TradingDocumentsLists.module.css";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay/AmountDisplay";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { MoreDataCounter } from "components/common/moreDataCounter/MoreDataCounter";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { useNavigate } from "hooks/useNavigate";
import { useLocation } from "react-router";
import { cx } from "utilities";

export const useMyInvoicesColumns = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCreateTableColumns<MyPurchaseInvoiceToReview>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "status",
        size: 70,
        cell: info => {
          const tradingDocument = info.getValue();
          return <ReviewStatus status={tradingDocument.reviewStatus} />;
        },
      }),
      columnHelper.text(
        row => row.sellerCompanyName ?? `${row.sellerFirstName} ${row.sellerLastName}`,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            fontSize: "14",
            fontWeight: "700",
          },
        },
      ),
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 115,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kwota",
        size: 105,
        cell: info => {
          const tradingDocument = info.getValue();
          if (tradingDocument.totalWithTax)
            return (
              <AmountDisplay
                amount={tradingDocument.totalWithTax.toString()}
                decimalTypographyProps={{
                  className: styles.invoiceSpacing,
                  fontSize: "10",
                  fontWeight: "700",
                }}
                integerTypographyProps={{
                  className: styles.invoiceSpacing,
                  fontSize: "12",
                  fontWeight: "700",
                }}
              />
            );
          return (
            <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="600">
              brak
            </Typography>
          );
        },
      }),
      columnHelper.date(row => row.invoiceIssueDate, {
        header: "wystawiono",
        size: 80,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontSize: "12",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.reviewers, {
        header: "recenzenci",
        size: 130,
        cell: info => {
          const reviewers = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <div className={styles.avatarBox}>
                {reviewers.slice(0, 7).map(reviewer => (
                  <Avatar className="mr-n1" key={reviewer.user.id} user={reviewer.user} size="xs" />
                ))}
              </div>
              {reviewers.length > 7 && (
                <MoreDataCounter counter={reviewers.length - 1} style={{ width: "max-content" }} />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(
        row => row.recipientCompanyName ?? `${row.recipientFirstName} ${row.recipientLastName}`,
        {
          header: "nabywca",
          size: 110,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
      columnHelper.accessor(row => row, {
        id: "goToConfirmation",
        header: " ",
        size: 205,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <Button
              className={cx(styles.confirmationBtn, "text-uppercase")}
              onClick={event => {
                event.stopPropagation();
                navigate(`/finances/import-invoices/check?id=${tradingDocument.id}`, {
                  fromMyInvoices: `${location.pathname}${location.search}`,
                });
              }}
              size="small"
              startIcon={MdiCheck}
              variant="gray"
            >
              Przejdź do zatwierdzania
            </Button>
          );
        },
      }),
    ];
  });
};

const ReviewStatus = ({ status }: { status: MyPurchaseInvoiceToReview["reviewStatus"] }) => {
  switch (status) {
    case "CLOSED":
      return <Tag label="Zamknięta" type="outlined" variant="deepPurple400" />;
    case "OPEN":
      return <Tag label="Otwarta" type="outlined" variant="success" />;
    default: {
      const exhaustiveCheck = status;
      console.error(`Unhandled review status: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
    }
  }
};
