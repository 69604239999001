import jwtDecode from "jwt-decode";
import { queryFetch } from "./queryFetch";
import { storeConnector } from "./storeConnector";

type TokenResponseDTO = {
  access: string;
  refresh: string;
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userUUID");
  storeConnector.dispatch?.({ type: "RESET_STORE" });
};

const isAccessTokenExpired = () => {
  const refreshToken = getRefreshToken();
  const accessToken = getAccessToken();
  if (refreshToken && accessToken) {
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    return decoded.exp * 1000 < Date.now();
  }
  return true;
};

const refreshToken = async () => {
  const refreshToken = getRefreshToken();

  if (isRefreshTokenExpired(refreshToken)) {
    logout();
    return false;
  }

  const response: TokenResponseDTO = await queryFetch({
    method: "POST",
    url: "users/token/refresh",
    data: {
      refresh: refreshToken,
    },
  });

  localStorage.setItem(ACCESS_TOKEN, response.access);
  localStorage.setItem(REFRESH_TOKEN, response.refresh ? response.refresh : refreshToken!);

  return true;
};

export const authService = {
  getAccessToken,
  isAccessTokenExpired,
  refreshToken,
};

const ACCESS_TOKEN = "token";
const REFRESH_TOKEN = "refreshToken";

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

const isRefreshTokenExpired = (refreshToken: string | null) => {
  if (!refreshToken) return true;
  const decoded = jwtDecode<{ exp: number }>(refreshToken);
  return decoded.exp * 1000 < Date.now();
};
