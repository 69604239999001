import { useParams } from "react-router";
import { IndexesTab } from "./constants/indexesConstants";
import { useQuery } from "hooks";
import { indexesUtils } from "./utils/indexesUtils";
import { indexesActions } from "api/indexes/actions";
import { useIndexesColumns } from "./useIndexesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { PageHeader } from "components/common";
import { indexes } from "components/common/moduleNavigation/moduleConfig/externalManufacturing/routes/indexes";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { WithdrawIndexes } from "./components/tableToolbar/withdrawIndexes/WithdrawIndexes";
import { RightPanel } from "./rightPanel/RightPanel";
import { CreateDraftOrders } from "./components/tableToolbar/createDraftOrders/CreateDraftOrders";

export const Indexes = () => {
  const { tab } = useParams<{ tab: IndexesTab }>();
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = indexesUtils.getIndexesSearch({
    query,
    tab,
  });
  const {
    data: indexes,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = indexesActions.useIndexesForExternalManufacturing(search);
  const columns = useIndexesColumns();
  const tableMultiSelect = useTableMultiSelect({
    rows: indexes,
  });
  const tableProps = useTableFeatureConnector({
    rows: indexes,
    withDrawer: "externalManufacturingIndexes",
    withMultiSelect: tableMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<IndexForExternalManufacturingListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <CreateDraftOrders
            close={tableMultiSelect.clear}
            indexes={tableMultiSelect.selectedRows}
          />
          <WithdrawIndexes close={tableMultiSelect.clear} indexes={tableMultiSelect.selectedRows} />
        </TableToolbar>
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  return (
    <PageHeader
      searchInput={{
        label: "Szukaj...",
        tags: [],
      }}
      tabs={{
        list: indexesUtils.getIndexesTabs(),
        routesRoot: `external-manufacturing/${indexes.url}`,
        urlSpan: "list",
      }}
      viewLabel="EXTERNAL_MANUFACTURING_INDEXES"
    />
  );
};
