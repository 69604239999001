import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { createDraftOrderUtils } from "../utils/createDraftOrderUtils";

interface Props {
  items: number[];
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SelectAllItemsCheckbox = ({ items, selected, setSelected }: Props) => {
  const {
    areAllItemsSelected,
    areSomeItemsSelected,
    setAllItems,
  } = createDraftOrderUtils.useSelectItems(items, selected, setSelected);

  return (
    <Checkbox
      checked={areAllItemsSelected}
      indeterminate={areSomeItemsSelected && !areAllItemsSelected}
      onChange={setAllItems}
      size="sx"
    />
  );
};
