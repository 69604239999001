import { Index } from "api/indexes/models";
import trashIcon from "assets/images/81.svg";
import cx from "classnames";
import { Button } from "components/common";
import {
  AsyncInput,
  Autocomplete,
  AutocompleteAsyncHandler,
  ErrorMessage,
  Modal,
  Spinner,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import { useState } from "react";
import { getAnyErrorKey } from "utilities";
import styles from "./AssignPackageModal.module.css";
import { deleteIndexPackage, addIndexPackage, patchIndex } from "api/indexes/calls";
import { getPackages } from "api/warehouse/calls";
import { InferResult } from "typeUtilities";
import { useConfirmModal, useSelector } from "hooks";

interface Props {
  modal: {
    isOpen: boolean;
    close: () => void;
    state: { indexId: number } | null;
  };
}

type Package = Index["packages"][number];

export const AssignPackageModal = ({ modal }: Props) => {
  const [packages, setPackages] = useState<Index["packages"]>([]);
  const [packageError, setPackageError] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [numberOfPackages, setNumberOfPackages] = useState(1);
  const isWarehouseInUse = useSelector(store => store.partials.configuration.isWarehouseInUse);
  const confirmModal = useConfirmModal();

  async function handleDeletePackage(index: number, _package: Package) {
    async function handleDeleteSubmission() {
      setInProgress(true);
      const [, error] = await deleteIndexPackage({ index, package: _package.id });
      setInProgress(false);
      if (!error) {
        const index = packages.findIndex(el => el.id === _package.id);
        const newPackages = [...packages];
        newPackages.splice(index, 1);
        setPackages(newPackages);
      } else {
        setPackageError(getAnyErrorKey(error));
      }
    }

    confirmModal.open({
      text: `Czy na pewno chcesz usunąć paczkę "${_package.name}"?`,
      confirmText: "Usuń",
      callback: () => handleDeleteSubmission(),
    });
  }

  async function handleAddPackage(index: number, _package: Package) {
    setInProgress(true);
    const [, error] = await addIndexPackage({ index, package: _package.id });
    setInProgress(false);
    if (!error) {
      setPackages(prev => [...prev, { ..._package }]);
    } else {
      setPackageError(getAnyErrorKey(error));
    }
  }

  async function handleUpdateNumberOfPackages(value: number, helpers: StatusHandlerHelpers) {
    if (!modal.state?.indexId) return;
    const { indexId } = modal.state;
    helpers.startFetching();
    setInProgress(true);
    const [payload, error] = await patchIndex(indexId, { numberOfPackages: value });
    setInProgress(false);
    if (payload) {
      helpers.stopFetching();
      setNumberOfPackages(value);
    } else if (error) {
      helpers.stopFetching({ message: getAnyErrorKey(error, "numberOfPackages") });
    }
  }

  return (
    <Modal
      isOpen={modal.isOpen}
      close={() => {
        if (!inProgress) {
          modal.close();
        }
      }}
      size={{ lg: { width: "80vw" } }}
    >
      <>
        {isWarehouseInUse ? (
          <>
            <div className="mt-3">
              <AutocompleteAsyncHandler fetchFrom={getPackages}>
                <Autocomplete
                  placeholder="Szukaj paczki"
                  selectedItems={[]}
                  onBlur={() => setPackageError("")}
                  onChange={(packages: InferResult<typeof getPackages>["results"]) => {
                    if (!packages.length || !modal.state?.indexId) return;
                    const { name, internalId, id } = packages[0];
                    handleAddPackage(modal.state.indexId, { name, internalId, id });
                  }}
                  multiple={false}
                />
              </AutocompleteAsyncHandler>
              <ErrorMessage type="text" text={packageError} />
            </div>
            <div className="mt-3">
              {!packages.length && <span className="mt02">Brak paczek</span>}
              {packages.map(({ id, internalId, name }, index) => (
                // package list will not be longer than 10 elements, so we don't care about performance.
                // we don't use cuid here as it adds a lot of code complexity
                <div key={index} className={styles.package}>
                  <div>
                    <div>
                      <strong>{name}</strong>
                    </div>
                    <div className={cx("mt-2 mb-1 badge text-color-grey", styles.badge)}>
                      {internalId}
                    </div>
                  </div>
                  <div>
                    <Button
                      kind="secondary"
                      size="round-s"
                      onClick={() => {
                        if (!modal.state?.indexId) return;
                        handleDeletePackage(modal.state.indexId, { id, internalId, name });
                      }}
                    >
                      <img src={trashIcon} alt="Usuń paczkę" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="mt-3">
            <StatusHandler>
              {helpers => (
                <AsyncInput
                  look="common"
                  label="Liczba paczek"
                  name="numberOfPackages"
                  disabled={helpers.isFetching}
                  onChange={value => handleUpdateNumberOfPackages(value, helpers)}
                  value={numberOfPackages}
                  error={helpers.errors.message}
                />
              )}
            </StatusHandler>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-end">
          <Button
            kind="primary"
            disabled={inProgress}
            onClick={modal.close}
            className={cx("position-relative mt-3", styles.btn)}
          >
            Zakończ
            <Spinner on={inProgress} color="white" size="small" />
          </Button>
        </div>
      </>
    </Modal>
  );
};
