import * as React from "react";
import minusIcon from "assets/images/14.svg";
import plusIcon from "assets/images/15.svg";
import styles from "./Counter.module.css";
import cx from "classnames";
import { Button } from "components/common";

interface Props {
  onChange: (arg: number) => void;
  value: number;
  canSetZero?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  onFocus?: () => void;
  onBlur?: (value: number) => void;
  /**
   * How many digits can input display
   */
  numSize?: 3 | 4 | 5;
  overwrites?: {
    container?: { className?: string };
    plusIcon?: JSX.Element;
    minusIcon?: JSX.Element;
  };
}

export const Counter = ({
  onChange,
  value,
  disabled,
  inactive,
  onFocus,
  canSetZero = false,
  onBlur,
  numSize = 3,
  overwrites = {},
}: Props) => {
  function handleManualChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    if (isNaN(Number(val))) {
      return undefined;
    } else {
      return onChange(Number(val));
    }
  }

  function handleBlur() {
    onBlur?.(value);
  }
  return (
    <div
      className={cx(
        " d-flex align-items-center ml-2",
        styles.counter,
        overwrites.container?.className,
      )}
    >
      {!disabled && (
        <Button
          disabled={inactive}
          onFocus={onFocus}
          onBlur={handleBlur}
          type="button"
          kind="secondary"
          size="round-s"
          onClick={() => (value >= (canSetZero ? 1 : 2) ? onChange(value - 1) : undefined)}
        >
          {overwrites.minusIcon ?? <img src={minusIcon} alt="Mniej" />}
        </Button>
      )}
      <input
        onFocus={onFocus}
        onBlur={handleBlur}
        disabled={disabled || inactive}
        className={cx("d-inline-flex", {
          [styles.input3]: numSize === 3,
          [styles.input4]: numSize === 4,
          [styles.input5]: numSize === 5,
        })}
        type="text"
        value={value}
        onChange={handleManualChange}
      />
      {!disabled && (
        <Button
          disabled={inactive}
          onFocus={onFocus}
          onBlur={handleBlur}
          type="button"
          kind="secondary"
          size="round-s"
          onClick={() => onChange(value + 1)}
        >
          {overwrites.plusIcon ?? <img src={plusIcon} alt="Więcej" />}
        </Button>
      )}
    </div>
  );
};
