import { TradingDocument } from "api/trading-documents/models";
import styles from "../../StatusInExternalServiceSection.module.css";
import { StepLabel } from "../../shared/components/stepLabel/StepLabel";
import { SendingToExternalServiceStatus } from "api/trading-documents/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { cx } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { DateDisplay } from "../../shared/components/dateDisplay/DateDisplay";

interface Props {
  tradingDocument: TradingDocument;
}

export const ExternalServiceSendingOrder = ({ tradingDocument }: Props) => {
  const orderSendingInExternalService = tradingDocument.statusesInExternalService.orderSending;

  return (
    <div className={styles.row}>
      <StepLabel
        label="Zlecenie wysyłki"
        status={orderSendingInExternalService.orderSendingToExternalServiceStatus}
      />
      <div className={cx(styles.content, "d-flex align-items-center gap-2 py-1 pl-2")}>
        <StatusTag status={orderSendingInExternalService.orderSendingToExternalServiceStatus} />
        <OrderSendingPerStatus
          orderSendingInExternalService={orderSendingInExternalService}
          tradingDocument={tradingDocument}
        />
      </div>
      <div
        className={cx(styles.service, "d-flex w-100 justify-content-end align-items-start h-100")}
      >
        <div className="d-flex align-items-center gap-1">
          <img
            alt="serwis"
            className={styles.serviceIcon}
            src={
              tradingDocumentConstants.getExternalServiceDetails(tradingDocument.sentToService).icon
            }
          />
          <Typography fontSize="12" fontWeight="600" noWrap>
            {
              tradingDocumentConstants.getExternalServiceDetails(tradingDocument.sentToService)
                .label
            }
          </Typography>
        </div>
      </div>
    </div>
  );
};

const OrderSendingPerStatus = ({
  orderSendingInExternalService,
  tradingDocument,
}: {
  orderSendingInExternalService: TradingDocument["statusesInExternalService"]["orderSending"];
  tradingDocument: TradingDocument;
}) => {
  const sendToOptimaUsingElteMutation = tradingDocumentsActions.useSendTradingDocumentsToOptimaUsingElte();

  switch (orderSendingInExternalService.orderSendingToExternalServiceStatus) {
    case SendingToExternalServiceStatus.DONE:
      return (
        <div className="d-flex align-items-center gap-2">
          <DateDisplay date={orderSendingInExternalService.orderSendingToExternalServiceAt} />
          {orderSendingInExternalService.orderSendingToExternalServiceBy && (
            <div className="d-flex align-items-center gap-1">
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                przez:
              </Typography>
              <Avatar
                size="sm"
                user={orderSendingInExternalService.orderSendingToExternalServiceBy}
              />
              <Typography fontSize="10" fontWeight="400" noWrap>
                {orderSendingInExternalService.orderSendingToExternalServiceBy.firstName}{" "}
                {orderSendingInExternalService.orderSendingToExternalServiceBy.lastName}
              </Typography>
            </div>
          )}
        </div>
      );
    case SendingToExternalServiceStatus.FAILED:
      return (
        <div className="d-flex align-items-center gap-2 overflow-hidden">
          <Tag.WithCustomColor
            backgroundColor="red32"
            label="Błąd zlecenia"
            startIcon={MdiEmergencyHome}
            textColor="red500"
          />
          {Boolean(orderSendingInExternalService.orderSendingToExternalServiceErrorNote.length) && (
            <div className={cx(styles.errorMessage, "d-flex flex-1 align-items-center gap-1")}>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                powód:
              </Typography>
              <Typography color="danger600" fontSize="12" fontWeight="400" noWrap>
                {orderSendingInExternalService.orderSendingToExternalServiceErrorNote}
              </Typography>
            </div>
          )}
          <Button
            className="text-uppercase"
            endIcon={MdiRepeat}
            isLoading={sendToOptimaUsingElteMutation.isLoading}
            onClick={() => sendToOptimaUsingElteMutation.mutate([tradingDocument.id])}
            size="small"
            variant="gray"
          >
            Ponów
          </Button>
        </div>
      );
    case SendingToExternalServiceStatus.NOT_STARTED:
      return (
        <Button
          className="text-uppercase"
          endIcon={MdiSend}
          isLoading={sendToOptimaUsingElteMutation.isLoading}
          onClick={() => sendToOptimaUsingElteMutation.mutate([tradingDocument.id])}
          size="small"
          variant="gray"
        >
          Zleć wysyłkę
        </Button>
      );
    case SendingToExternalServiceStatus.PENDING:
      return null;
    default: {
      const exhaustiveCheck: never =
        orderSendingInExternalService.orderSendingToExternalServiceStatus;
      console.error(`Unhandled sending order status: ${exhaustiveCheck}`);
      return null;
    }
  }
};

const StatusTag = ({ status }: { status: SendingToExternalServiceStatus }) => {
  switch (status) {
    case SendingToExternalServiceStatus.DONE:
      return <Tag label="Zlecono" variant="success" />;
    case SendingToExternalServiceStatus.FAILED:
    case SendingToExternalServiceStatus.PENDING:
    case SendingToExternalServiceStatus.NOT_STARTED:
      return <Tag label="Nie zlecono" type="outlined" variant="quaternary" />;
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled sending order status: ${exhaustiveCheck}`);
      return null;
    }
  }
};
