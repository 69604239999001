import { ordersKeys } from "api/keys";
import { ListOrder, Order } from "api/orders/models";
import { OrderCollectionSource } from "api/wh-entries/enums";
import {
  ClientDetailsToUpdate,
  DeliveryAddressDetailsToUpdate,
  OrderDeliveryUpdateModal,
} from "components/common/orderDeliveryUpdateModal/OrderDeliveryUpdateModal";
import { Action, OrdersAction } from "pages/orderGroups/creator/duck";
import { useQueryClient } from "react-query";

interface Props {
  closeModal: () => void;
  isModalOpened: boolean;
  order: ListOrder;
  orderGroupDispatch: React.Dispatch<Action>;
  ordersDispatch: React.Dispatch<OrdersAction>;
}

export const OrderDeliveryUpdateModalWrapper = ({
  closeModal,
  isModalOpened,
  order,
  orderGroupDispatch,
  ordersDispatch,
}: Props) => {
  const queryClient = useQueryClient();

  const refetchOrderDetails = () => {
    const cachedOrder = queryClient.getQueryData<Order>(ordersKeys.order(String(order.id)));
    if (cachedOrder) queryClient.invalidateQueries(ordersKeys.order(String(order.id)));
  };

  const updateOrderGroupClient = (toUpdate: ClientDetailsToUpdate) => {
    refetchOrderDetails();

    ordersDispatch({
      type: "UPDATE_ORDER_CLIENT",
      payload: {
        orderId: order.id,
        toUpdate,
      },
    });

    orderGroupDispatch({
      type: "UPDATE_ORDER",
      payload: {
        id: order.id,
        client: {
          ...order.client,
          ...toUpdate,
        },
      },
    });

    dispatchOrderPointsDelivery(toUpdate);
  };

  const updateOrderGroupDelivery = async (toUpdate: DeliveryAddressDetailsToUpdate) => {
    refetchOrderDetails();

    ordersDispatch({
      type: "UPDATE_ORDER_DELIVERY",
      payload: {
        orderId: order.id,
        toUpdate: {
          city: toUpdate.city,
          point: toUpdate.point!,
          postCode: toUpdate.postCode,
          street: toUpdate.street,
        },
      },
    });

    orderGroupDispatch({
      type: "UPDATE_ORDER_POINTS_LOCATION",
      payload: {
        id: order.id,
        point: toUpdate.point!,
      },
    });

    orderGroupDispatch({
      type: "UPDATE_ORDER",
      payload: {
        id: order.id,
        deliveryAddress: {
          ...order.deliveryAddress,
          city: toUpdate.city,
          point: toUpdate.point!,
          postCode: toUpdate.postCode,
          street: toUpdate.street,
        },
      },
    });

    dispatchOrderPointsDelivery(toUpdate);
  };

  const dispatchOrderPointsDelivery = (toUpdate: {
    city?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    companyName?: string;
    point?: { lat: number; lng: number } | null;
    postCode?: string;
    street?: string;
  }) => {
    orderGroupDispatch({
      type: "UPDATE_ORDER_POINT_DELIVERY",
      payload: {
        city: toUpdate.city,
        id: order.id,
        firstName: toUpdate.firstName,
        lastName: toUpdate.lastName,
        phone: toUpdate.phone,
        point: toUpdate.point || undefined,
        postCode: toUpdate.postCode,
        street: toUpdate.street,
      },
    });
  };

  if (!isModalOpened) return null;

  return (
    <OrderDeliveryUpdateModal
      close={closeModal}
      source={OrderCollectionSource.ORDER_GROUP}
      normalizedOrder={{
        client: order.client,
        customer: order.customer,
        delivery: order.deliveryAddress,
        id: order.id,
        signature: order.signature,
      }}
      onClientDetailsUpdateSuccess={({ toUpdate }) => updateOrderGroupClient(toUpdate)}
      onGeolocalizationSuccess={({ toUpdate }) => updateOrderGroupDelivery(toUpdate)}
    />
  );
};
