import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiStateNone = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6 14.4H5.4V17.6H6.6V14.4ZM4 13V19H8V13H4Z"
      fill="#F03D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6 10.4H11.4V17.6H12.6V10.4ZM10 9V19H14V9H10Z"
      fill="#F03D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6 6.4H17.4V17.6H18.6V6.4ZM16 5V19H20V5H16Z"
      fill="#F03D3D"
    />
  </SvgWrapper>
);
