import { QueryParams, TabProps } from "api/types";
import { IndexesTab, indexesConstants } from "../constants/indexesConstants";
import { dictToList, queryString } from "utilities";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { MdiStateHigh } from "components/miloDesignSystem/atoms/icons/MdiStateHigh";
import { MdiStateMedium } from "components/miloDesignSystem/atoms/icons/MdiStateMedium";
import { MdiStateLow } from "components/miloDesignSystem/atoms/icons/MdiStateLow";
import { MdiStateNone } from "components/miloDesignSystem/atoms/icons/MdiStateNone";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export interface IndexesSearchProps {
  query: QueryParams;
  tab: IndexesTab;
}

const getIndexesSearch = ({ query, tab }: IndexesSearchProps) => {
  const tabsQuery = {
    isDeleted: tab === IndexesTab.WITHDRAWN ? "true" : "false",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

const getIndexesTabs = (): TabProps =>
  dictToList(indexesConstants.indexesTabDict).map(({ key, value }) => ({
    label: value,
    name: key,
  }));

const getWarehouseStateLegend = ({
  warehouseState,
}: {
  warehouseState: IndexForExternalManufacturingListItem["warehouseState"];
}) => {
  const { maxAvailable, minAvailable, safeAvailable, state } = warehouseState;

  if (maxAvailable === 0 && minAvailable === 0 && safeAvailable === 0 && state === 0) {
    return {
      icon: <MdiStateNone color="danger500" />,
      textColor: "danger500",
      tag: <Tag label="Brak w magazynie" startIcon={MdiStateNone} variant="danger" />,
    };
  }
  if (state >= maxAvailable)
    return {
      icon: <MdiStateHigh color="success500" />,
      textColor: "success500",
      tag: <Tag label="Stan maksymalny" startIcon={MdiStateHigh} variant="success" />,
    };
  if (safeAvailable <= state && state < maxAvailable) {
    return {
      icon: <MdiStateMedium color="blue200" />,
      textColor: "blue200",
      tag: (
        <Tag.WithCustomColor
          backgroundColor="blue50"
          label="Duża ilość"
          startIcon={MdiStateMedium}
          textColor="blue600"
        />
      ),
    };
  }
  if (minAvailable >= state && state <= safeAvailable) {
    return {
      icon: <MdiStateLow color="warning600" />,
      textColor: "warning600",
      tag: <Tag label="poniżej stanu bezpiecznego" startIcon={MdiStateLow} variant="warning" />,
    };
  }
  if (state > 0 && state < minAvailable) {
    return {
      icon: <MdiStateLow color="danger500" />,
      textColor: "danger500",
      tag: <Tag label="poniżej stanu minimalnego" startIcon={MdiStateLow} variant="danger" />,
    };
  }
  return {
    icon: <MdiStateNone color="danger500" />,
    textColor: "danger500",
    tag: <Tag label="Brak w magazynie" startIcon={MdiStateNone} variant="danger" />,
  };
};

export const indexesUtils = {
  getIndexesSearch,
  getIndexesTabs,
  getWarehouseStateLegend,
};
