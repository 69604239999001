import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OrderLineItemDetails } from "api/external-manufacturing/models";

export const useLabelsColumns = () =>
  useCreateTableColumns<OrderLineItemDetails["labels"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 110,
        }),
        columnHelper.text(row => row.description, {
          header: "opis",
          typographyProps: { fontWeight: "600" },
          size: 340,
        }),
        columnHelper.accessor(row => row.parameters.weight, {
          header: "waga",
          size: 80,
          cell: info => {
            const weight = info.getValue();
            return (
              <Typography fontSize="10" fontWeight="700">
                {weight} kg
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.parameters, {
          header: "wymiary",
          size: 110,
          cell: info => {
            const parameters = info.getValue();
            return (
              <Typography fontSize="10" fontWeight="700">
                {parameters.length}x{parameters.width}x{parameters.height} cm
              </Typography>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
