import { cx } from "utilities";
import { MapTheme, useMapTheme } from "../hooks/useMapTheme";
import styles from "./MapThemeSelector.module.css";
import blackMapTheme from "assets/images/blackMapTheme.png";
import defaultMapTheme from "assets/images/defaultMapTheme.png";
import whiteMapTheme from "assets/images/whiteMapTheme.png";
import greenMapTheme from "assets/images/greenMapTheme.png";

export const MapThemeSelector = () => {
  const { mapTheme, setTheme } = useMapTheme();

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.theme, { [styles.selected]: mapTheme === MapTheme.DEFAULT })}
        onClick={() => setTheme(MapTheme.DEFAULT)}
      >
        <img src={defaultMapTheme} alt="default map theme" />
      </div>
      <div
        className={cx(styles.theme, { [styles.selected]: mapTheme === MapTheme.WHITE })}
        onClick={() => setTheme(MapTheme.WHITE)}
      >
        <img src={whiteMapTheme} alt="white map theme" />
      </div>
      <div
        className={cx(styles.theme, { [styles.selected]: mapTheme === MapTheme.BLACK })}
        onClick={() => setTheme(MapTheme.BLACK)}
      >
        <img src={blackMapTheme} alt="black map theme" />
      </div>
      <div
        className={cx(styles.theme, { [styles.selected]: mapTheme === MapTheme.GREEN })}
        onClick={() => setTheme(MapTheme.GREEN)}
      >
        <img src={greenMapTheme} alt="green map theme" />
      </div>
    </div>
  );
};
