import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../ManufacturingPlansList.module.css";
import { manufacturingPlansListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { dateFns, dateUtils } from "utilities";
import { useManufacturingPlanOnListPatchMutation } from "api/manufacturingNew/hooks";
import { CheckboxGreen } from "components/utils/checkboxGreen";
import { StateLabel } from "components/common/stateLabel";
import { AvatarWithName } from "components/common/avatarWithName";
import {
  ManufacturingPlanListItem,
  statusStyleDictionary,
  manufacturingPlanStatusDict,
} from "api/manufacturingNew/models";
import { statusIconDictionary } from "CONSTANTS";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/downloadPdfNew.svg";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  manufacturingPlan: ManufacturingPlanListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  manufacturingPlan,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  const patchMutation = useManufacturingPlanOnListPatchMutation();

  return (
    <>
      <ModuleRow
        className={cx("nowrap", {
          active: isActive(String(manufacturingPlan.id)),
          highlighted:
            isHighlighted(String(manufacturingPlan.id)) ||
            highlightedRows.some(row => row.id === manufacturingPlan.id),
        })}
        gridStyle={manufacturingPlansListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, manufacturingPlan.id, index, () =>
            togglePanel(String(manufacturingPlan.id)),
          )
        }
      >
        <div className="d-flex align-items-center" onMouseDown={e => e.stopPropagation()}>
          <CheckboxGreen
            checked={manufacturingPlan.isConfirmed}
            disabled={patchMutation.isLoading}
            name="isRequired"
            onChange={() => {
              patchMutation.mutate({
                id: manufacturingPlan.id,
                toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
              });
            }}
          />
        </div>
        <div className="fs-14">
          {manufacturingPlan.signature.length > 0 ? manufacturingPlan.signature : "--"}
        </div>
        {manufacturingPlan.masterStage && (
          <div className={styles.showTooltip}>
            <StateLabel
              overwrites={{ container: { className: styles.stageStatusLabel } }}
              kind="none"
              style={{
                backgroundColor: manufacturingPlan.masterStage.backgroundColor,
                color: manufacturingPlan.masterStage.textColor,
              }}
              className="uppercase fw-700 narrowLetters d-flex align-items-center  border-0"
            >
              <div className={cx("cut-text", styles.fixedWidth)}>
                {manufacturingPlan.masterStage.name}
              </div>

              <img src={statusIconDictionary[manufacturingPlan.status]} alt="Status" />
            </StateLabel>
            <div className={cx({ firstItemHintboxPosition: index === 0 }, styles.tooltip)}>
              {manufacturingPlan.masterStage.name},&nbsp;
              {manufacturingPlanStatusDict[manufacturingPlan.status].toLowerCase()}
            </div>
          </div>
        )}

        <div>
          <StateLabel className="lowercase" kind={statusStyleDictionary[manufacturingPlan.status]}>
            {manufacturingPlanStatusDict[manufacturingPlan.status]}
          </StateLabel>
        </div>
        <div>
          {manufacturingPlan.rangeOfDates.firstDate.length > 0
            ? dateFns.format(new Date(manufacturingPlan.rangeOfDates.firstDate), "dd.MM")
            : "--"}
        </div>
        <div>
          {manufacturingPlan.rangeOfDates.lastDate.length > 0
            ? dateFns.format(new Date(manufacturingPlan.rangeOfDates.lastDate), "dd.MM")
            : "--"}
        </div>

        <div>
          {manufacturingPlan.weeksOfYear.length > 0 &&
            [...manufacturingPlan.weeksOfYear].sort().join(", ")}
        </div>

        <div className="d-flex gap-2 align-items-center">
          {manufacturingPlan.countries.map(countryCode => {
            return <CountryFlag key={countryCode} countryCode={countryCode} />;
          })}
        </div>
        <div className="fw-700">
          {manufacturingPlan.slotsSummary.taken}/{manufacturingPlan.slotsSummary.total}
        </div>
        <div>
          <Button
            kind="transparent-black"
            size="square-s"
            rel="noopener noreferrer"
            href="/"
            target="_blank"
            as="a"
            onMouseDown={evt => evt.stopPropagation()}
            title="Pobierz PDF"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz PDF" src={downloadPdfIcon} />
            </div>
          </Button>
        </div>
        <div>
          {manufacturingPlan.createdBy && (
            <AvatarWithName cutOverflowPx="65px" user={manufacturingPlan.createdBy} />
          )}
        </div>
        <div>{dateUtils.formatDateToDisplayOnlyDate(manufacturingPlan.created)}</div>
      </ModuleRow>
    </>
  );
};
