import { format } from "date-fns";
import { dateFns } from "./utilities";

interface DateRangeOption {
  id: number;
  from: string;
  to: string;
  label: string;
}

const getDateRangeLabel = (from: Date, to: Date, label: string): DateRangeOption => {
  return {
    id: 0,
    from: format(from, "yyyy-MM-dd"),
    to: format(to, "yyyy-MM-dd"),
    label,
  };
};

export const getDateRangesForFilters = (): DateRangeOption[] => {
  let idCounter = 0;

  const getNextId = (): number => {
    return idCounter++;
  };

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const startOfThisWeek = new Date(today);
  startOfThisWeek.setDate(today.getDate() - today.getDay());
  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(today.getDate() + (6 - today.getDay()));

  const startOfLastWeek = new Date(startOfThisWeek);
  startOfLastWeek.setDate(startOfThisWeek.getDate() - 7);
  const endOfLastWeek = new Date(endOfThisWeek);
  endOfLastWeek.setDate(endOfThisWeek.getDate() - 7);

  const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const last30DaysStart = new Date();
  last30DaysStart.setDate(today.getDate() - 30);
  const last90DaysStart = new Date();
  last90DaysStart.setDate(today.getDate() - 90);

  const last12MonthsStart = new Date(today);
  last12MonthsStart.setFullYear(today.getFullYear() - 1);
  const last12MonthsEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  const thisYearStart = new Date(today.getFullYear(), 0, 1);
  const thisYearEnd = new Date(today.getFullYear(), 11, 31);

  return [
    getDateRangeLabel(today, today, "Dzisiaj"),
    getDateRangeLabel(yesterday, yesterday, "Wczoraj"),
    getDateRangeLabel(startOfThisWeek, today, "Ten tydzień"),
    getDateRangeLabel(startOfLastWeek, endOfLastWeek, "Poprzedni tydzień"),
    getDateRangeLabel(startOfThisMonth, endOfThisMonth, "Ten miesiąc"),
    getDateRangeLabel(startOfLastMonth, endOfLastMonth, "Poprzedni miesiąc"),
    getDateRangeLabel(last30DaysStart, today, "Ostatnie 30 dni"),
    getDateRangeLabel(last90DaysStart, today, "Ostatnie 90 dni"),
    getDateRangeLabel(last12MonthsStart, last12MonthsEnd, "Ostatnie 12 miesięcy"),
    getDateRangeLabel(thisYearStart, thisYearEnd, "Ten rok"),
  ].map(range => ({ ...range, id: getNextId() }));
};

export const getDateRangeSimpleList = (): DateRangeOption[] => {
  let idCounter = 0;

  const getNextId = (): number => {
    return idCounter++;
  };

  const today = new Date();

  const last14Days = new Date();
  last14Days.setDate(today.getDate() - 14);

  const last30DaysStart = new Date();
  last30DaysStart.setDate(today.getDate() - 30);

  const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return [
    getDateRangeLabel(last14Days, today, "Ostatnie 2 tyg."),
    getDateRangeLabel(last30DaysStart, today, "Ostatnie 30 dni"),
    getDateRangeLabel(startOfLastMonth, endOfLastMonth, dateFns.format(startOfLastMonth, "LLLL")),
  ].map(range => ({ ...range, id: getNextId() }));
};
