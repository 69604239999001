import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { ExternalManufacturingOrderStatusTag } from "pages/externalManufacturing/shared/components/ExternalManufacturingOrderStatusTag";

interface Props {
  order: ExternalManufacturingOrder;
}

export const TitleSection = ({ order }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {order.signature}
        </Typography>
        <ExternalManufacturingOrderStatusTag status={order.orderStatus} />
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
              na mag. przyjęto:
            </Typography>
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="12" fontWeight="600">
                {order.packagesSummary.ready}
              </Typography>
              <Typography fontSize="12" fontWeight="600">
                /{order.packagesSummary.total}
              </Typography>
            </div>
          </div>
          <ProgressBar progress={order.packagesSummary.progress} maxWidth={112} />
        </div>
      </div>
    </RightPanelSection>
  );
};
