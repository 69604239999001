import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { InfoLabel } from "components/common/infoLabel";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { indexesUtils } from "pages/externalManufacturing/indexes/utils/indexesUtils";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

interface Props {
  index: IndexForExternalManufacturingDetails;
}

export const GeneralInfoSection = ({ index }: Props) => {
  return (
    <RightPanelSection padding="pt-0 pb-2 px-3">
      <InfoLabel title="jest wariantem produktu">
        <Link fontSize="16" fontWeight="500" to={`/products/list/all?panelId=${index.product.id}`}>
          {index.product.name}
        </Link>
      </InfoLabel>
      <InfoLabel title="EAN">
        <Typography fontSize="14" fontWeight="500" noWrap>
          {index.ean || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="dodano">
        <Typography fontSize="14" fontWeight="400">
          {dateUtils.formatDateToDisplayOnlyDate(index.created)}
        </Typography>
      </InfoLabel>
      <InfoLabel title="oferta">
        <div className="d-flex align-items-center gap-1">
          <Typography color={index.isDeleted ? "red300" : "info300"} fontSize="14" fontWeight="700">
            {index.isDeleted ? "wycofano ze sprzedaży" : "w sprzedaży"}
          </Typography>
          {/* TODO: waiting for backend */}
          {/* <Tooltip title={index.isDeleted ? "Przywróć do sprzedaży" : "Wycofaj ze sprzedaży"}>
            <IconButton
              icon={index.isDeleted ? MdiRestore : MdiSaleWithdrawn}
              variant="transparent"
            />
          </Tooltip> */}
        </div>
      </InfoLabel>
      <InfoLabel title="stan ogółem">
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-baseline gap-1">
            <Typography
              color={
                indexesUtils.getWarehouseStateLegend({ warehouseState: index.warehouseState })
                  .textColor as ColorPalette
              }
              fontSize="14"
              fontWeight="700"
            >
              {index.warehouseState.state}
            </Typography>
            <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
              szt.
            </Typography>
          </div>
          {indexesUtils.getWarehouseStateLegend({ warehouseState: index.warehouseState }).tag}
        </div>
      </InfoLabel>
      <InfoLabel title="producent">
        <div className="d-flex align-items-center gap-2 w-70">
          <Typography fontSize="14" fontWeight="700" noWrap>
            {index.manufacturer?.name || EMPTY_VALUE}
          </Typography>
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};
