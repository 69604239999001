import { ReceptionListItem } from "api/wh-entries/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { WhEntryStatus } from "pages/finances/shared/components/whEntryStatus/WhEntryStatus";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useReceptionsColumns = () => {
  return useCreateTableColumns<ReceptionListItem>(({ columnHelper }) => {
    return [
      columnHelper.link({
        header: "przyjęcie",
        size: 200,
        textRenderer: row => row.signature,
        to: row => `/finances/receptions/list/all?panelId=${row.id}`,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.date(row => row.created, {
        header: "data utworzenia",
        size: 100,
        typographyProps: {
          fontSize: "12",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 100,
        cell: info => {
          const status = info.getValue();

          return <WhEntryStatus status={status} />;
        },
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "przez kogo",
        size: 120,
        cell: info => {
          const createdBy = info.getValue();

          if (!createdBy) return <EmptyValue />;

          return (
            <div className="d-flex align-items-center gap-2">
              <Avatar size="sm" user={createdBy} />
              <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
