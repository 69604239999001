import { MdiInfo } from "components/miloDesignSystem/atoms/icons/MdiInfo";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import styles from "./ConfirmationSuccessModal.module.css";
import { cx } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useManufacturingOrdersColumns } from "./useManufacturingOrdersColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ConfirmDraftOrderPreview } from "api/external-manufacturing/orders/drafts/models";

interface Props {
  close: () => void;
  state: ConfirmDraftOrderPreview;
}

export const ConfirmationSuccessModal = ({ close, state }: Props) => {
  const tableProps = useTableFeatureConnector({
    rows: state.manufacturingOrders,
  });
  const columns = useManufacturingOrdersColumns();

  return (
    <Modal close={close} isOpen removeHeader width={430}>
      <>
        <div className={cx("d-flex justify-content-between p-3", styles.modalTitle)}>
          <div className="d-flex align-items-center gap-2">
            <MdiInfo color="neutralWhite100" size="20" />
            <Typography color="neutralWhite100" fontSize="20" fontWeight="700">
              Dodano do ZDP
            </Typography>
          </div>
          <IconButton icon={MdiClose} onClick={close} theme="dark" variant="transparent" />
        </div>
        <div className="d-flex flex-column">
          <div className="p-3">
            <Table<ConfirmDraftOrderPreview["manufacturingOrders"][number]>
              columns={columns}
              isLoading={false}
              error={null}
              uiSchema={{ cell: { height: "26" }, header: { backgroundColor: "neutralWhite100" } }}
              {...tableProps}
            />
          </div>
          <div className="p-3">
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Zamknij
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
