import { DraftOrderDetails } from "api/external-manufacturing/orders/drafts/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";

interface Props {
  draftOrder: DraftOrderDetails;
}

export const GeneralInfoSection = ({ draftOrder }: Props) => {
  return (
    <RightPanelSection padding="pt-2 pb-2 px-3">
      <InfoLabel title="utworzono">
        <div className="d-flex align-items-center gap-2">
          <Avatar size="sm" user={draftOrder.createdBy} />
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="12" fontWeight="700" noWrap>
              {draftOrder.createdBy.firstName} {draftOrder.createdBy.lastName},
            </Typography>
            <Typography fontSize="12" fontWeight="700">
              {dateUtils.formatDateToDisplayOnlyDate(draftOrder.createdAt)}
            </Typography>
            <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
              {dateUtils.formatDateToTime(draftOrder.createdAt)}
            </Typography>
          </div>
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};
