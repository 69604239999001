import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { wmsKeys } from "./keys";
import {
  AddLineItemToUnloadingPayload,
  AddOrdersToUnloading,
  CargoUnload,
  CreateWarehouseman,
  DailySummaryReceivedAndReleasedIdentifiers,
  LastLoadedOrder,
  LastPickedOrder,
  LastUnloadedGroup,
  Loading,
  LoadingDetails,
  LoadingLineItem,
  LoadingLineItemDetails,
  LoadingWorkspaceInventory,
  OverviewReceivedByProductCategory,
  OverviewReceivedForCustomer,
  OverviewReleasedAndReceivedListColumns,
  OverviewReleasedByProductCategory,
  OverviewReleasedForCustomer,
  PackagesReceivedAndReleasedPerDayIdentifiers,
  PackagesReceivedAndReleasedPerMonthIdentifiers,
  Picking,
  PickingDetails,
  PickingLineItem,
  PickingLineItemDetails,
  PickingWorkspaceInventory,
  ReleasedOrReceivedPerCategoryIdentifiers,
  Unloading,
  UnloadingDetails,
  UnloadingLineItem,
  UnloadingLineItemDetails,
  UnloadingWorkspaceInventory,
  WarehouseWorkerDetails,
  WarehouseWorkerListItem,
} from "./models";
import { Assign } from "utility-types";
import { GenericTable, PartialOf } from "typeUtilities";
import { FieldDetails } from "./layout/models";
import { AddOrdersToUnloadingResponse } from "api/orders/models";
import { fileFactoryUtils } from "utilities/fileFactory";

export const getCargoUnloads = (search: string = ""): ApiFetcher<Pagination<CargoUnload>> => ({
  key: wmsKeys.cargoUnloads.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/cargo-unloads/items" + search,
    }),
});

export const getUnloads = (search: string = ""): ApiFetcher<Pagination<Unloading>> => ({
  key: wmsKeys.unloads.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/items" + search,
    }),
});

export const postUnloading = (data: { id: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/unloading/web/items",
    data,
  });

export const addLineItemToUnloading = (data: AddLineItemToUnloadingPayload) =>
  queryFetch<Omit<AddLineItemToUnloadingPayload, "productElements">>({
    method: "POST",
    url: "/wms/unloading/add-line-item-to-unloading",
    data,
  });

export const deleteUnloading = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/unloading/web/items/" + id,
  });

export const getUnloading = (id: UUID): ApiFetcher<UnloadingDetails> => ({
  key: wmsKeys.unloads.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/items/" + id,
    }),
});

export const getUnloadingOrders = (
  search: string = "",
): ApiFetcher<Pagination<UnloadingLineItem>> => ({
  key: wmsKeys.unloads.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/line-items" + search,
    }),
});

export const getUnloadingLineItemDetails = (id: UUID): ApiFetcher<UnloadingLineItemDetails> => ({
  key: wmsKeys.unloads.lineItemDetails(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/line-items/" + id,
    }),
});

export const getUnloadingWorkspaceInventory = (
  search: string = "",
): ApiFetcher<UnloadingWorkspaceInventory> => ({
  key: wmsKeys.unloads.workspaceInventory(String(search.split("$")[1])),
  fetcher: () => {
    const [unloading, user] = search.split("$");
    return queryFetch({
      method: "GET",
      url: `/wms/unloading/web/inventory/${unloading}/user/${user}`,
    });
  },
});

export const patchUnloading = (data: PartialOf<UnloadingDetails>, id: UUID | number) =>
  queryFetch<UnloadingDetails>({
    method: "PATCH",
    url: "/wms/unloading/web/items/" + id,
    data,
  });

export const getLastUnloadedGroups = (
  search: string = "",
): ApiFetcher<Pagination<LastUnloadedGroup>> => ({
  key: wmsKeys.unloads.lastUnloadedGroups(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/last-unloaded-groups" + search,
    }),
});

export const getPickings = (search: string = ""): ApiFetcher<Pagination<Picking>> => ({
  key: wmsKeys.picking.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/picking/web/items" + search,
    }),
});

export const getPicking = (id: UUID): ApiFetcher<PickingDetails> => ({
  key: wmsKeys.picking.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/picking/web/items/" + id,
    }),
});

export const patchPicking = (data: PartialOf<PickingDetails>, id: UUID | number) =>
  queryFetch<PickingDetails>({
    method: "PATCH",
    url: "/wms/picking/web/items/" + id,
    data,
  });

export const unloadingCancellation = (data: {
  unloadingId: UUID;
  kind: "UNLOADING_LINE_ITEMS" | "UNLOADING_SINGLE_QUANTITY_ITEMS";
  ids: UUID[];
}) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/unloading/cancellation",
    data,
  });

export const deletePicking = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/picking/web/items/" + id,
  });

export const getPickingLineItems = (
  search: string = "",
): ApiFetcher<Pagination<PickingLineItem>> => ({
  key: wmsKeys.picking.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/picking/web/picking-groups" + search,
    }),
});

export const getPickingLineItemDetails = (id: UUID): ApiFetcher<PickingLineItemDetails> => ({
  key: wmsKeys.picking.lineItemsDetails(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/picking/web/picking-groups/" + id,
    }),
});

export const getPickingWorkspaceInventory = (
  search: string = "",
): ApiFetcher<PickingWorkspaceInventory> => ({
  key: wmsKeys.picking.workspaceInventory(search.split("$")[1]),
  fetcher: () => {
    const [picking, user] = search.split("$");
    return queryFetch({
      method: "GET",
      url: `/wms/picking/web/inventory/${picking}/user/${user}`,
    });
  },
});

export const getLastPickedOrders = (
  search: string = "",
): ApiFetcher<Pagination<LastPickedOrder>> => ({
  key: wmsKeys.picking.lastPickedOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/picking/web/last-picked-package-groups" + search,
    }),
});

export const addOrdersToUnloadingBaseOnExternalIds = (data: AddOrdersToUnloading) =>
  queryFetch<AddOrdersToUnloadingResponse>({
    method: "POST",
    url: "/orders/add-orders-to-unloading-base-on-external-ids",
    data,
  });

export const getLoadings = (search: string = ""): ApiFetcher<Pagination<Loading>> => ({
  key: wmsKeys.loading.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/loading/web/items" + search,
    }),
});

export const getLoading = (id: UUID): ApiFetcher<LoadingDetails> => ({
  key: wmsKeys.loading.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/loading/web/items/" + id,
    }),
});

export const deleteLoading = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/loading/web/items/" + id,
  });

export const patchLoading = (data: PartialOf<LoadingDetails>, id: UUID | number) =>
  queryFetch<LoadingDetails>({
    method: "PATCH",
    url: "/wms/loading/web/items/" + id,
    data,
  });

export const getLoadingLineItems = (
  search: string = "",
): ApiFetcher<Pagination<LoadingLineItem>> => ({
  key: wmsKeys.loading.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/loading/web/loading-groups" + search,
    }),
});

export const getLoadingLineItemDetails = (id: UUID): ApiFetcher<LoadingLineItemDetails> => ({
  key: wmsKeys.loading.lineItemsDetails(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/loading/web/loading-groups/" + id,
    }),
});

export const getLastLoadedOrders = (
  search: string = "",
): ApiFetcher<Pagination<LastLoadedOrder>> => ({
  key: wmsKeys.loading.lastLoadedOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/loading/web/last-loaded-groups" + search,
    }),
});

export const getLoadingWorkspaceInventory = ({
  loading,
  user,
}: {
  loading: UUID;
  user: number | string;
}): ApiFetcher<LoadingWorkspaceInventory> => ({
  key: wmsKeys.loading.workspaceInventory(String(user)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/loading/web/inventory/${loading}/user/${user}`,
    }),
});

export const getWarehouseWorkers = (
  search: string = "",
): ApiFetcher<Pagination<WarehouseWorkerListItem>> => ({
  key: wmsKeys.warehouseWorkers.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/warehouse-workers/items" + search,
    }),
});

export const getWarehouseWorker = (id: string | number): ApiFetcher<WarehouseWorkerDetails> => ({
  key: wmsKeys.warehouseWorkers.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/warehouse-workers/items/" + id,
    }),
});

export const getPackagesReceivedAndReleasedPerDay = (
  search: string = "",
): ApiFetcher<GenericTable<PackagesReceivedAndReleasedPerDayIdentifiers>> => ({
  key: wmsKeys.analytics.receivedAndReleasedPackagesPerDay(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/released-and-received" + search,
    }),
});

export const getDailySummaryReceivedAndReleased = (
  search: string = "",
): ApiFetcher<GenericTable<DailySummaryReceivedAndReleasedIdentifiers>> => ({
  key: wmsKeys.analytics.dailySummaryReceivedAndReleasedPackages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/daily-summary-received-and-released" + search,
    }),
});

export const getPackagesReceivedAndReleasedPerMonth = (
  search: string = "",
): ApiFetcher<GenericTable<PackagesReceivedAndReleasedPerMonthIdentifiers>> => ({
  key: wmsKeys.analytics.receivedAndReleasedPackagesPerMonth(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/monthly-summary-report" + search,
    }),
});

export const getReleasedPackagesPerCategory = (
  search: string = "",
): ApiFetcher<GenericTable<ReleasedOrReceivedPerCategoryIdentifiers>> => ({
  key: wmsKeys.analytics.releasedPerCategory(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/released" + search,
    }),
});

export const getReceivedPackagesPerCategory = (
  search: string = "",
): ApiFetcher<GenericTable<ReleasedOrReceivedPerCategoryIdentifiers>> => ({
  key: wmsKeys.analytics.receivedPerCategory(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/received" + search,
    }),
});

export const postWarehouseman = (data: CreateWarehouseman) =>
  queryFetch<Assign<CreateWarehouseman, { id: number }>>({
    method: "POST",
    url: "/wms/warehouse-workers/items",
    data,
  });

export const getOverviewReleasedAndReceived = (
  search: string = "",
): ApiFetcher<GenericTable<OverviewReleasedAndReceivedListColumns>> => ({
  key: wmsKeys.overviewReleasedAndReceived(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/dashboard/released-and-received" + search,
    }),
});

export const getOverviewReceivedByProductCategory = (
  search: string = "",
): ApiFetcher<GenericTable<OverviewReceivedByProductCategory>> => ({
  key: wmsKeys.overviewReceivedByProductCategory.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/dashboard/received-by-product-category" + search,
    }),
});

export const getOverviewReleasedByProductCategory = (
  search: string = "",
): ApiFetcher<GenericTable<OverviewReleasedByProductCategory>> => ({
  key: wmsKeys.overviewReleasedByProductCategory.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/dashboard/released-by-product-category" + search,
    }),
});

export const getOverviewReleasedForCustomer = (
  search: string = "",
): ApiFetcher<GenericTable<OverviewReleasedForCustomer>> => ({
  key: wmsKeys.overviewReleasedForCustomer.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/dashboard/released-for-customer" + search,
    }),
});

export const getOverviewReceivedForCustomer = (
  search: string = "",
): ApiFetcher<GenericTable<OverviewReceivedForCustomer>> => ({
  key: wmsKeys.overviewReceivedForCustomer.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/analytics/dashboard/received-for-customer" + search,
    }),
});

export const wmsFileFactory = (() => {
  return {
    rackPdf: (rack: Pick<FieldDetails, "wmsId" | "name">) => ({
      url: `wh-rows/download-cells-labels?whRows=${rack.wmsId}`,
      name: `${rack.name}-${fileFactoryUtils.getDate()}`,
    }),
    unloadingPdf: (unloading: Pick<Unloading, "id" | "signature">) => ({
      url: `wms/unloading/get-labels/${unloading.id}`,
      name: `${fileFactoryUtils.formatSignature(
        unloading.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
    pickingPdf: (picking: Pick<Picking, "id" | "signature">) => ({
      url: `wms/picking/get-labels/${picking.id}`,
      name: `${fileFactoryUtils.formatSignature(picking.signature)}-${fileFactoryUtils.getDate()}`,
    }),
    loadingPdf: (loading: Pick<Loading, "id" | "signature">) => ({
      url: `wms/loading/get-labels/${loading.id}`,
      name: `${fileFactoryUtils.formatSignature(loading.signature)}-${fileFactoryUtils.getDate()}`,
    }),
    unloadingLineItemPdf: (
      unloadingLineItem: Pick<UnloadingLineItem, "unloading" | "signature" | "id">,
    ) => ({
      url: `wms/unloading/get-labels/${unloadingLineItem.unloading}?lineItemIds=${unloadingLineItem.id}`,
      name: `${fileFactoryUtils.formatSignature(
        unloadingLineItem.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
    pickingLineItemPdf: (
      pickingLineItem: Pick<PickingLineItem, "picking" | "signature" | "id">,
    ) => ({
      url: `wms/picking/get-labels/${pickingLineItem.picking}?lineItemIds=${pickingLineItem.id}`,
      name: `${fileFactoryUtils.formatSignature(
        pickingLineItem.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
    loadingLineItemPdf: (
      loadingLineItem: Pick<LoadingLineItem, "loading" | "signature" | "id">,
    ) => ({
      url: `wms/loading/get-labels/${loadingLineItem.loading}?lineItemIds=${loadingLineItem.id}`,
      name: `${fileFactoryUtils.formatSignature(
        loadingLineItem.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
    loadingSummaryPdf: (loading: Pick<LoadingDetails, "id" | "signature">) => ({
      url: `wms/loading/web/items/${loading.id}/download-summary`,
      name: `${fileFactoryUtils.formatSignature(loading.signature)}-${fileFactoryUtils.getDate()}`,
    }),
    releasedAndReceivedCsv: (user: WarehouseWorkerDetails, dateFrom: string, dateTo: string) => ({
      url: `/wms/analytics/csv/released-and-received?userId=${user.id}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      name: `zestawienie_mebli_${user.firstName}-${user.lastName}_${fileFactoryUtils.getDate()}`,
    }),
    inOutReport: (dateFrom: string, dateTo: string) => ({
      url: `/wms/analytics/csv/wms-in-out-report?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      name: `zestawienie_dzienne_z_ruchu_magazynowego_${dateFrom}-${dateTo}_${fileFactoryUtils.getDate()}`,
    }),
  };
})();
