import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiChairAlt2 } from "components/miloDesignSystem/atoms/icons/MdiChairAlt2";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";
import { useNavigate } from "hooks/useNavigate";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  index: IndexForExternalManufacturingDetails;
}

export const PanelHeader = ({ index }: Props) => {
  const { close } = useDrawer("externalManufacturingIndexes");
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();
  const createDraftOrderMutation = draftOrdersActions.useCreateDraftOrder();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper>
          <Typography fontSize="16" fontWeight="700" noWrap>
            {index.name}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <div />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Button
          className="text-uppercase"
          endIcon={MdiArrowForward}
          onClick={() => navigate(`/manufacturing/indexes/${index.id}/details`)}
          size="small"
          startIcon={MdiChairAlt2}
          variant="gray"
        >
          Szczegóły wariantu
        </Button>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.ICON,
              icon: createDraftOrderMutation.isLoading ? (
                <Spinner size={22} />
              ) : (
                <MdiAdd size="22" />
              ),
              onClick: () =>
                createDraftOrderMutation.mutate(
                  {
                    ids: [index.id],
                    sourceKind: DraftOrderLineItemSourceKind.INDEX,
                  },
                  {
                    onSuccess: payload =>
                      navigate(
                        `/external-manufacturing/modify-draft-order/${payload.externalManufacturingOrderDraft}`,
                      ),
                  },
                ),
              text: "Utwórz zlecenie do dostawcy",
            },
          ]}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
