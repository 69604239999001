import { Loading } from "api/wms/models";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { LoadingStatus } from "./components/LoadingStatus";
import { EstimatedTime } from "../../shared/components/EstimatedTime";
import { UserWithInitials } from "api/users/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import styles from "./LoadingsList.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LinkToPage } from "components/utils/linkToPage";

export const useLoadingsListColumns = () =>
  useCreateTableColumns<Loading>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const loading = info.getValue();
          return (
            <LinkToPage
              content={loading.signature}
              url={`/wms/loadings/list/all?panelId=${loading.id}`}
            />
          );
        },
      }),
      columnHelper.text(
        row => (row.sellers ? row.sellers.map(seller => seller.name).join(", ") : null),
        {
          header: "kontrahent/produc.",
          size: 110,
        },
      ),
      columnHelper.text(row => (Boolean(row.description.length) ? row.description : EMPTY_VALUE), {
        header: "nazwa",
        size: 120,
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 110,
        cell: info => {
          return <LoadingStatus loading={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row.estimatedTimeOfDeparture, {
        header: "wyjazd",
        size: 100,
        cell: info => {
          return <EstimatedTime date={info.getValue()} />;
        },
      }),
      columnHelper.text(row => (row.ramp ? row.ramp.name : null), {
        header: "rampa",
        size: 40,
      }),
      columnHelper.text(row => `${row.totalProgress}%`, {
        header: "postęp",
        size: 45,
      }),
      // TODO: SCANNING
      // columnHelper.text(row => "BACKEND", {
      //   header: "skanowanie",
      //   size: 70,
      //   typographyProps: {
      //     color: "deepPurple400",
      //   },
      // }),
      // TODO: LOCALIZATION
      // columnHelper.text(row => "BACKEND", {
      //   header: "lokalizowanie",
      //   size: 75,
      //   typographyProps: {
      //     color: "blue300",
      //   },
      // }),
      columnHelper.accessor(row => row.loadingGroupsCounters, {
        header: "wydano",
        size: 60,
        cell: info => {
          const loadingGroupsCounters = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="12" fontWeight="700">
                {Number(loadingGroupsCounters.completed)}
              </Typography>
              <Typography fontSize="12" fontWeight="700">
                /{Number(loadingGroupsCounters.total)}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborators, {
        header: "zaangażowani",
        // TODO: size -> 75
        size: 100,
        cell: info => {
          const collaborators: UserWithInitials[] = info.getValue();
          return (
            <div className="d-flex align-items-center">
              {Boolean(collaborators.length) ? (
                collaborators.map(user => (
                  <div className={styles.avatarCover} key={user.id}>
                    <Avatar size="xs" theme="light" user={user} />
                  </div>
                ))
              ) : (
                <EmptyValue />
              )}
            </div>
          );
        },
      }),
    ];
  });
