import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { OrderLineItemDetails } from "api/external-manufacturing/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  lineItemDetails: OrderLineItemDetails;
}

export const PanelHeader = ({ lineItemDetails }: Props) => {
  const { close } = useDrawer("externalManufacturingOrdersLineItems");
  const handleDownloadExternalManufacturingLineItemLabelPdf = externalManufacturingActions.useDownloadExternalManufacturingLineItemsLabelsPdf();
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2 noWrap">
          <ChangeLineItemCancellationStatus lineItemDetails={lineItemDetails} />
          {lineItemDetails.signature}
        </SlidingHeaderWrapper>
      ) : (
        <ChangeLineItemCancellationStatus lineItemDetails={lineItemDetails} />
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Pobierz etykietę">
          <IconButton
            icon={<MdiQrCode size="16" />}
            onClick={() => {
              handleDownloadExternalManufacturingLineItemLabelPdf(
                lineItemDetails.id,
                lineItemDetails.signature,
              );
            }}
            variant="transparent"
          />
        </Tooltip>
        <FileDownloadHandler
          factoryFn={() =>
            externalManufacturingFileFactory.externalManufacturingLineItemsCsv(
              [lineItemDetails.id],
              lineItemDetails.signature,
            )
          }
          type="csv"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz CSV zlecenia">
              <IconButton
                icon={MdiDownloadCsv}
                isLoading={isLoading}
                onClick={download}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};

const ChangeLineItemCancellationStatus = ({ lineItemDetails }: Props) => {
  const isCancelledMutation = externalManufacturingActions.useLineItemsChangeCancelStatus();

  return (
    <Button
      className="text-uppercase"
      isLoading={isCancelledMutation.isLoading}
      onClick={() =>
        isCancelledMutation.mutate({
          cancel: !lineItemDetails.isCancelled,
          externalManufacturingLineItemIds: [lineItemDetails.id],
        })
      }
      size="small"
      startIcon={lineItemDetails.isCancelled ? MdiRepeat : MdiCancel}
      variant="gray"
    >
      {lineItemDetails.isCancelled ? "Przywróć zlecenie" : "Anuluj zlecenie"}
    </Button>
  );
};
