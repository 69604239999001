import { useMutation } from "hooks/useMutation";
import {
  deleteSeller,
  getSeller,
  getSellers,
  patchSeller,
  patchSellerLogo,
  postSeller,
} from "./calls";
import { sellersKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { parsePatchData } from "utilities/parsePatchData";
import { UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import { CreateSeller, Seller } from "./models";
import { getAnyErrorKey } from "utilities";
import { useRedux } from "hooks";
import { useNavigate } from "hooks/useNavigate";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";

const useSellers = createPaginatedApiQuery(getSellers);
const useSeller = createApiQuery(getSeller);

const usePatchSeller = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<Seller> }) => {
      return patchSeller(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevDetails = queryUtils.handleMutate(
          sellersKeys.seller.details(String(id)),
          toUpdate,
        );
        const prevList = queryUtils.handlePaginatedListUpdate(
          sellersKeys.seller.list(),
          id,
          toUpdate,
        );
        return { prevDetails, prevList };
      },
      onSuccess: () => {
        refetchPartials();
      },
      onError: (error, { id }, context) => {
        assertIsDefined(context);
        queryUtils.rollback(sellersKeys.seller.details(String(id)), context.prevDetails, error);
        queryUtils.rollbackList(sellersKeys.seller.list(), context.prevList, id);
      },
    }),
  );
};

const usePatchSellersLogo = () => {
  return useMutation(patchSellerLogo, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano logo podmiotu biznesowego",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCreateSeller = () => {
  const navigate = useNavigate();
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(
    (formData: CreateSeller) => postSeller(formData),
    ({ toastr }) => ({
      onSuccess: payload => {
        navigate("/business-entities/list");
        refetchPartials();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono podmiot biznesowy "${payload.companyName}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDeleteSeller = (close: () => void) => {
  return withDeleteConfirmation(
    useMutation(deleteSeller, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(sellersKeys.seller.list());
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    })),
  )();
};

export const sellersActions = {
  usePatchSeller,
  useCreateSeller,
  useDeleteSeller,
  usePatchSellersLogo,
  useSellers,
  useSeller,
};
