import { useCallCenterRoute, useCallCenterRouteOrders } from "api/call-center/routes/hooks";
import {
  CallCenterRouteDetails,
  CallCenterRouteOrderListItem,
} from "api/call-center/routes/models";
import { useQuery, useStateModal } from "hooks";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import { ListHeader } from "./components/ListHeader";
import { getSearch } from "./utils/getSearch";
import { CallInProgressPopUp } from "./components/CallInProgressPopUp";
import { RightPanel } from "./rightPanel/RightPanel";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useRouteOrdersColumns } from "./hooks/useRouteOrdersColumns";
import styles from "./RouteOrdersList.module.css";
import { cx } from "utilities";
import { isFromLastXHours } from "./utils/isFromLastFourHours";
import { UnreadSmsModalWrapper } from "../shared/UnreadSmsModalWrapper";
import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

type LocationState = { route: CallCenterRouteDetails } | undefined;

export const RouteOrdersList = withFilters(filterList, () => {
  const [callInProgress, setCallInProgress] = useState<number | null>(null);
  const location = useLocation<LocationState>();
  const locationRoute = location.state?.route;
  const params = useParams<{ routeId: string }>();
  const { data: fetchedRoute } = useCallCenterRoute(
    { id: params.routeId },
    { enabled: !Boolean(locationRoute) },
  );
  const route = locationRoute ?? fetchedRoute;
  const { query } = useQuery({ exclude: ["panelId"] });
  const unreadSmsModal = useStateModal<{ orderId: number }>();
  const search = getSearch({ query, routeId: params.routeId });
  const { data: orders, error, isLoading, isPreviousData, pagination } = useCallCenterRouteOrders(
    search,
  );
  const columns = useRouteOrdersColumns(callInProgress, setCallInProgress, unreadSmsModal);
  const tableProps = useTableFeatureConnector({
    rows: orders,
    withDrawer: "callCenterRouteOrders",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  useEffect(() => {
    if (route) {
      document.title = route.signature;
    }
  }, [route, isLoading, query]);

  if (!route) return null;

  const additionalListParams = {
    route: String(route.id),
  };

  return (
    <>
      <PageWrapper>
        <ListHeader additionalListParams={additionalListParams} route={route} />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<CallCenterRouteOrderListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
            overrides={() => {
              return {
                row: row => {
                  return {
                    ...tableProps?.overrides?.(),
                    className: cx(
                      {
                        [styles.editedRow]:
                          row.pinnedToRouteDate && isFromLastXHours(row.pinnedToRouteDate, 3),
                      },
                      tableProps?.overrides?.().row?.(row).className,
                    ),
                  };
                },
              };
            }}
          />
          {callInProgress && (
            <CallInProgressPopUp
              callInProgressId={callInProgress}
              setCallInProgress={setCallInProgress}
            />
          )}
          <RightPanel />
        </div>
      </PageWrapper>
      {unreadSmsModal.isOpen && unreadSmsModal.state && (
        <UnreadSmsModalWrapper
          search={search}
          orderId={unreadSmsModal.state.orderId}
          close={unreadSmsModal.close}
        />
      )}
    </>
  );
});
