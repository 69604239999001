import styles from "../NotificationSection.module.css";
import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { MdiAttachEmail } from "components/miloDesignSystem/atoms/icons/MdiAttachEmail";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Formik, FormikHelpers } from "formik";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { SendToSpecificAddressModal } from "./SendToSpecificAddressModal";
import { useToggle } from "hooks";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { NotificationAttachmentKind } from "api/trading-documents/enums";

interface Props {
  tradingDocument: TradingDocument;
}

export const NotificationActions = ({ tradingDocument }: Props) => {
  const sendEmailNotificationMutation = tradingDocumentsActions.useSendEmailNotification();
  const modalController = useToggle();

  return (
    <div className="py-2 d-flex align-items-center gap-2">
      <Button
        className="text-uppercase"
        isLoading={sendEmailNotificationMutation.isLoading}
        onClick={() =>
          sendEmailNotificationMutation.mutate({
            tradingDocumentId: tradingDocument.id,
          })
        }
        size="small"
        startIcon={tradingDocument.notificationsSent.length === 0 ? MdiSend : MdiRestartAlt}
        variant="gray"
      >
        {tradingDocument.notificationsSent.length === 0 ? "Wyślij teraz" : "Wyślij ponownie"}
        Wyślij teraz
      </Button>
      <Button
        className="text-uppercase"
        onClick={modalController.open}
        size="small"
        startIcon={MdiSend}
        variant="gray"
      >
        Wskaż adres i wyślij
      </Button>
      <Popover
        content={({ hide }) => (
          <DocumentsPopoverContent tradingDocument={tradingDocument} hide={hide} />
        )}
        variant="DARK"
      >
        <Button className="text-uppercase" size="small" startIcon={MdiAttachEmail} variant="gray">
          wyślij z kompletem dokumentów
        </Button>
      </Popover>
      {modalController.isOpen && (
        <SendToSpecificAddressModal
          close={modalController.close}
          tradingDocumentId={tradingDocument.id}
        />
      )}
    </div>
  );
};
interface Values {
  invoice: boolean;
  specification: boolean;
  cmr: boolean;
  wdt: boolean;
  email: string;
}

const DocumentsPopoverContent = ({
  hide,
  tradingDocument,
}: {
  hide: () => void;
  tradingDocument: TradingDocument;
}) => {
  const sendEmailNotificationMutation = tradingDocumentsActions.useSendEmailNotification();
  const handleSubmit = (values: Values, actions: FormikHelpers<Values>): void => {
    sendEmailNotificationMutation.mutate(
      {
        tradingDocumentId: tradingDocument.id,
        recipientEmail: values.email,
        attachments: getAttachments(values),
      },
      handleSubmitResponse(actions, {
        onSuccess: () => {
          hide();
        },
      }),
    );
  };
  const initialValues: Values = {
    invoice: true,
    cmr: true,
    specification: true,
    wdt: true,
    email: tradingDocument.recipientEmail,
  };

  return (
    <div className={styles.popoverWrapper}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography fontSize="20" fontWeight="700">
          Załącz i wyślij dokumenty
        </Typography>
        <IconButton icon={MdiClose} variant="transparent" onClick={hide} />
      </div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-2 mb-4">
              <Checkbox.Form<Values> label="Faktura / paragon" name="invoice" size="sm" />
              <Checkbox.Form<Values> label="Specyfikacja" name="specification" size="sm" />
              <Checkbox.Form<Values> label="CMR / dokumenty transportowe" name="cmr" size="sm" />
              <Checkbox.Form<Values> label="WDT" name="wdt" size="sm" />
            </div>
            <div className="mb-4">
              <Typography fontSize="12" fontWeight="700" className="mb-2 text-uppercase">
                Na adres:
              </Typography>
              <TextField.Form<Values>
                label="Adres email"
                name="email"
                placeholder="Wpisz adres email"
                size="small"
              />
            </div>

            <div className="d-flex align-items-center gap-2 py-1">
              <Button className="text-uppercase" onClick={hide} size="medium" variant="transparent">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Wyślij do klienta
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

function getAttachments(values: Values): NotificationAttachmentKind[] {
  const attachmentMap: Record<keyof Values, NotificationAttachmentKind | null> = {
    cmr: NotificationAttachmentKind.CMR,
    invoice: NotificationAttachmentKind.INVOICE,
    specification: NotificationAttachmentKind.SPECIFICATION,
    wdt: NotificationAttachmentKind.WDT,
    email: null,
  };

  return Object.entries(values)
    .filter(([key, value]) => value && key in attachmentMap && attachmentMap[key as keyof Values])
    .map(([key]) => attachmentMap[key as keyof Values])
    .filter(Boolean) as NotificationAttachmentKind[];
}
