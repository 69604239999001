import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadWDT } from "components/miloDesignSystem/atoms/icons/MdiDownloadWDT";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  tradingDocument: TradingDocument;
}

export const DownloadWdtPdf = ({ tradingDocument }: Props) => {
  return (
    <FileDownloadHandler
      factoryFn={() => tradingDocumentFileFactory.wdtPdf(tradingDocument)}
      type="pdf"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz WDT">
          <IconButton
            icon={<MdiDownloadWDT size="18" />}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
