import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { AwaitingTransportDetails } from "api/wms/awaiting-transports/models";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { Link } from "components/miloDesignSystem/atoms/link";
import { FLAVOR } from "CONSTANTS";

interface Props {
  awaitingTransport: AwaitingTransportDetails;
}

export const GeneralInfoSection = ({ awaitingTransport }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <InfoLabel title="utworzono">
        <Typography fontSize="14" fontWeight="500">
          {awaitingTransport.createdAt
            ? dateUtils.formatDateToDisplayDateAndTime(awaitingTransport.createdAt)
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="zakończono">
        <Typography fontSize="14" fontWeight="500">
          {awaitingTransport.finishedAt
            ? dateUtils.formatDateToDisplayDateAndTime(awaitingTransport.finishedAt)
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kontrahent">
        <Typography fontSize="14" fontWeight="700">
          {awaitingTransport.customer?.name || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="opis">
        <TextField.Async
          containerClassName="flex-1"
          mutationHook={wmsAwaitingTransportsActions.usePatchAwaitingTransport}
          placeholder="Wpisz opis"
          size="small"
          transformQueryData={description => ({
            id: awaitingTransport.id,
            description,
          })}
          value={awaitingTransport.description}
        />
      </InfoLabel>
      {FLAVOR === "main" && (
        <InfoLabel title="rozładunek">
          {awaitingTransport.unloading ? (
            <Link
              fontSize="14"
              fontWeight="500"
              to={`/wms/unloadings/list/all?panelId=${awaitingTransport.unloading.result}`}
            >
              {awaitingTransport.unloading.signature}
            </Link>
          ) : (
            <EmptyValue fontSize="14" fontWeight="500" />
          )}
        </InfoLabel>
      )}
    </RightPanelSection>
  );
};
