import { DOMAIN } from "ENDPOINTS";
import { Path } from "api/types";
import { logout } from "apiConnectors/authService";
import { queryString } from "./utilities";

type PreviewConfig<T extends Record<string, (...args: any[]) => { url: Path }>> = {
  [K in keyof T]: (
    ...args: Parameters<T[K]>
  ) => { url: Path; urlWithCustomGuid: (customGuid: string) => Path };
};

export const createPreviewFactory = <T extends Record<string, (...args: any[]) => { url: Path }>>(
  config: T,
): PreviewConfig<T> => {
  return Object.keys(config).reduce((acc, key) => {
    acc[key as keyof T] = (...args: Parameters<T[typeof key]>) => {
      const token = localStorage.getItem("userUUID");

      if (!token) {
        logout();
      }

      const { url } = config[key as keyof T](...args);
      const pathName = new URL(url, DOMAIN).pathname.replace(/^\/+/, "");
      const params = queryString.parse(url);
      const search = queryString.stringify({
        ...params,
        guid: token!,
      });
      return {
        url: `${DOMAIN}${pathName}${search}`,
        urlWithCustomGuid: (customGuid: string) =>
          `${DOMAIN}${pathName}${queryString.stringify({
            ...params,
            guid: customGuid,
          })}`,
      };
    };

    return acc;
  }, {} as PreviewConfig<T>);
};
