import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { CommissionListItem, ManufacturingOrderStatus } from "api/manufacturing/models";
import { dateFns, dateUtils } from "utilities";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";

export const useManufacturerOrderColumns = () => {
  return useCreateTableColumns<CommissionListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 130,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => dateFns.formatRelative(new Date(row.created)), {
        header: "utworzono",
        size: 110,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.items.length, {
        header: "l. pozycji",
        size: 70,
        textAlign: "right",
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "progres realizacji",
        size: 150,
        cell: info => {
          const row = info.getValue();
          const receivedOrders = row.items.filter(
            order => order.status === ManufacturingOrderStatus.RECEIVED,
          ).length;
          const progress = row.items.length ? (receivedOrders * 100) / row.items.length : 0;

          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <ProgressBar progress={progress} />
              <Typography fontSize="12" fontWeight="700">
                {progress.toFixed()}%
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status zlecenia",
        size: 130,
        cell: info => {
          const row = info.getValue();
          if (row.isCancelled) {
            return <Tag label="Anulowano" variant="warning" type="outlined" />;
          }
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[row.status]} />;
        },
      }),
      columnHelper.text(row => row.contractor?.name, {
        header: "podwykonawca",
        size: 200,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.date(row => row.scheduledEmailSendAt, {
        header: "planowana wysyłka",
        size: 120,
        format: "formatRelative",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.emailStatus, {
        header: "status email",
        size: 80,
        cell: info => {
          const emailStatus = info.getValue();
          if (!emailStatus) return <Tag label="nie wysłano" type="outlined" variant="danger" />;

          return <Tag {...manufacturingConstants.emailStatusToTag[emailStatus.status]} />;

          // TODO: Handle btns
        },
      }),
      columnHelper.date(row => row.orderedAt, {
        header: "zlecono dn.",
        size: 84,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.date(row => row.deliveryDeadlineAt, {
        header: "termin",
        size: 84,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.expectedDeliveryAt, {
        header: "przewidywany termin odbioru",
        size: 180,
        cell: info => {
          const expectedDeliveryAt = info.getValue();
          if (!expectedDeliveryAt) return <EmptyValue fontSize="14" fontWeight="600" />;

          const daysLeft = dateFns.differenceInDays(new Date(expectedDeliveryAt), new Date());

          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <Tag
                label={dateUtils.formatDateToDisplayOnlyDate(expectedDeliveryAt)}
                variant={daysLeft <= 1 ? "danger" : "quaternary"}
                type={daysLeft <= 1 ? "filled" : "outlined"}
              />
              {daysLeft >= 0 ? (
                <Typography
                  fontSize="12"
                  fontWeight="700"
                  color={daysLeft <= 1 ? "danger500" : "neutralBlack100"}
                >
                  pozostało {daysLeft} dni
                </Typography>
              ) : (
                <EmptyValue fontSize="12" fontWeight="700" />
              )}
            </div>
          );
        },
      }),
      columnHelper.date(row => row.receivedAt, {
        header: "odebrano",
        size: 100,
        format: "formatRelative",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      // TODO: waiting for backend
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const order = info.getValue();
          return (
            <FileDownloadHandler
              factoryFn={() =>
                manufacturingFileFactory.manufacturingItemPdf(
                  order.items.map(materialOrder => materialOrder.id),
                  order.signature,
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz etykietę">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
          );
        },
      }),
    ];
  });
};
