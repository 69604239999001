import { RouteOrder } from "api/routes/models";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import qrCodeIcon from "assets/images/qrCode.svg";
import { useOrderPdfDownload } from "../../../hooks/useOrderPdfDownload";
import { StatusHandler } from "components/utils";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { orderPreviewFactory } from "api/orders/api";

interface Props {
  order: RouteOrder;
}

export const RoutePointDocuments = ({ order }: Props) => {
  const downloadOrderPdf = useOrderPdfDownload();

  return (
    <div className="d-flex align-items-center gap-1">
      <StatusHandler>
        {helpers => (
          <Tooltip title="Pobierz PDF zamówienia">
            <Button
              disabled={helpers.isFetching}
              kind="transparent-black"
              onClick={() => {
                downloadOrderPdf(order, helpers);
              }}
              size="square-s"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="PDF" src={downloadPdfIcon} />
              </div>
            </Button>
          </Tooltip>
        )}
      </StatusHandler>
      <Tooltip title="Podgląd etykiety">
        <Button
          as="a"
          href={orderPreviewFactory.orderLabel(order.id, order.signature).url}
          kind="transparent-black"
          rel="noopener noreferrer"
          size="square-s"
          target="_blank"
        >
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Etykieta" src={qrCodeIcon} />
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};
