import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { queryString } from "utilities";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { useSelector } from "hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useNavigate } from "hooks/useNavigate";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import {
  carrierOrdersFileFactory,
  carrierOrdersPreviewFactory,
} from "api/logistics/carrierOrders/api";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";

interface Props {
  carrierOrder: CarrierOrder;
  close: () => void;
}

export const PanelHeader = ({ carrierOrder, close }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();
  const me = useSelector(store => store.auth.user!);
  const navigate = useNavigate();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2 nowrap">
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              onClick={() => navigate(`/routes/list/active?panelId=${carrierOrder.route.id}`)}
              size="small"
              variant="gray"
            >
              Przejdź do trasy
            </Button>
            <Typography fontSize="16" fontWeight="700" noWrap>
              {carrierOrder.route.signature}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <Button
            className="text-uppercase"
            endIcon={MdiArrowForward}
            onClick={() => navigate(`/routes/list/active?panelId=${carrierOrder.route.id}`)}
            size="small"
            variant="gray"
          >
            Przejdź do trasy
          </Button>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <FileDownloadHandler
            factoryFn={() =>
              carrierOrdersFileFactory.carrierOrderCSV(
                [carrierOrder.id],
                carrierOrder.route.signature,
              )
            }
            type="csv"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz CSV ze zleceniem przewozu">
                <IconButton
                  icon={MdiDownloadCsv}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <Tooltip title="Podgląd PDF ze zleceniem przewozu">
            <IconButton
              icon={MdiViewPdf}
              onClick={() => {
                window.open(
                  carrierOrdersPreviewFactory.carrierOrderPDF(
                    queryString.stringify({
                      routesIds: carrierOrder.route.id,
                      userId: me.id,
                    }),
                  ).url,
                  "_blank",
                );
              }}
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
    </>
  );
};
