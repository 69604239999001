import { Typography } from "../typography";
import { ProgressCircleProps, ProgressCircleTheme } from "./types";
import { ColorPalette, colorPalette } from "../colorsPalette/colorPalette";
import styled from "@emotion/styled";

export const ProgressCircle = ({ percentage, size, theme = "light" }: ProgressCircleProps) => {
  const strokeWidth = 4;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <StyledProgressWrapper size={size}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={colorPalette[themeVariants[theme].backgroundColor]}
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={colorPalette[themeVariants[theme].progressColor]}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <StyledTypographyWrapper>
        <Typography color={themeVariants[theme].textColor} fontSize="10" fontWeight="500">
          {percentage}%
        </Typography>
      </StyledTypographyWrapper>
    </StyledProgressWrapper>
  );
};

const themeVariants: Record<
  ProgressCircleTheme,
  {
    backgroundColor: ColorPalette;
    progressColor: ColorPalette;
    textColor: ColorPalette;
  }
> = {
  dark: {
    backgroundColor: "neutralWhite16",
    progressColor: "success500",
    textColor: "neutralWhite100",
  },
  light: {
    backgroundColor: "neutralBlack12",
    progressColor: "success500",
    textColor: "neutralBlack88",
  },
};

const StyledProgressWrapper = styled.div<{
  size: ProgressCircleProps["size"];
}>`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

const StyledTypographyWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  alignitems: center;
  justifycontent: center;
`;
