import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { createDraftOrderUtils } from "../utils/createDraftOrderUtils";

interface Props {
  itemId: number;
  items: number[];
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SelectItemCheckbox = ({ itemId, items, selected, setSelected }: Props) => {
  const { isItemSelected, setItem } = createDraftOrderUtils.useSelectItems(
    items,
    selected,
    setSelected,
  );

  return <Checkbox checked={isItemSelected(itemId)} onChange={() => setItem(itemId)} size="sx" />;
};
