import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { OrderLineItemListItem } from "api/external-manufacturing/models";
import { UUID } from "api/types";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import styles from "../../shared/ExternalManufacturing.module.css";
import { cx } from "utilities";
import { useLineItemsColumns } from "./useLineItemsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  id: UUID;
}

export const LineItemsSection = ({ id }: Props) => {
  const { searchParams, setFilter } = useFilters({
    page: 1,
    manufacturingOrder: id,
    ordering: "position",
  });
  const {
    data: lineItems,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = externalManufacturingActions.useOrderLineItems(searchParams);
  const columns = useLineItemsColumns() as OrderLineItemListItem[];
  const tableProps = useTableFeatureConnector({
    rows: lineItems,
  });

  return (
    <RightPanelSection title="Lista pojedynczych">
      <div className="pb-3">
        <Table<OrderLineItemListItem>
          columns={columns}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={comfortableListUiSchema}
          pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
          {...tableProps}
          overrides={() => {
            return {
              row: row => {
                return {
                  className: cx({
                    [styles.cancelledRow]: row.isCancelled,
                  }),
                };
              },
              hideHeader: !lineItems.length,
              noResultComponent: <EmptySection label="Brak pojedynczych zleceń" />,
            };
          }}
        />
      </div>
    </RightPanelSection>
  );
};
