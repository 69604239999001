import { OwnerConfig } from "./types";
import { config as defaultConfig } from "./default";
import { configMerger } from "./utils";

export const config: OwnerConfig = {
  main: {
    ...defaultConfig.main,
  },
  manufacturing: {
    ...defaultConfig.manufacturing,
  },
  externalManufacturing: {
    ...defaultConfig.externalManufacturing,
  },
  // @Example1:
  // main: immer(defaultConfig.main, draft => {
  //   draft.orderPanel.deliverySection._show = false;
  // }),
  // @Example2:
  // b2b: configMerger(defaultConfig.b2b, {
  //   orderPanel: { deliverySection: { manualDeliveryPoint: false } },
  // }),
  b2b: configMerger(defaultConfig.b2b, {
    orderPanel: {
      protocolPdf: true,
      allowOrderConfirmation: false,
    },
  }),
  transport: {
    ...defaultConfig.transport,
  },
};
