import { EMPTY_VALUE } from "./tableColumnsUtilities/createTableColumns/createTableColumns";

export const RAMP_PREFIX = "AGFKZXM";
export const LOCATION_PREFIX = "B2R5BG";
export const BASKET_PREFIX = "A29ZEG";
export const MATERIAL_PREFIX = "GYAUB";

export const MAIN_SEPARATOR = ".";
export const PREFIX_SEPARATOR = ":";
export const META_SEPARATOR = ";";

export function parseLocation(
  location: string | null,
): { prefix: string; name: string; code: string } {
  if (!location) return { prefix: "", name: EMPTY_VALUE, code: "" };
  if (location.includes(RAMP_PREFIX)) {
    const [name, code] = location.replace(`${RAMP_PREFIX}:`, "").split(MAIN_SEPARATOR);
    return { prefix: RAMP_PREFIX, name, code };
  }
  if (!location.includes(".") && !location.includes(PREFIX_SEPARATOR))
    return { prefix: "", name: location, code: "" };

  if (location.includes(PREFIX_SEPARATOR)) {
    const [prefix, restOfLocation] = location.split(PREFIX_SEPARATOR);
    if (restOfLocation.includes(".")) {
      const [name, code] = restOfLocation.split(".");
      return { prefix, name, code };
    }

    return { prefix, name: restOfLocation, code: "" };
  }

  if (location.includes(".")) {
    const [name, code] = location.split(".");
    return { prefix: "", name, code };
  }

  return { prefix: "", name: location, code: "" };
}
