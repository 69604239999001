import { PostInternalShipmentsPreview } from "api/shipping/models";
import { FailedResponse } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { pluralize } from "utilities";

export const openShipmentCreationSummary = (
  payload: PostInternalShipmentsPreview,
  open: (stateToSet: FailedResponse) => void,
) => {
  const failedCount =
    payload.refusedOrders.ordersCanceled.length +
    payload.refusedOrders.ordersDelivered.length +
    payload.refusedOrders.ordersNotConfirmed.length +
    payload.refusedOrders.ordersOnRoute.length +
    payload.refusedOrders.ordersSettled.length +
    payload.refusedOrders.ordersWithIncorrectDeliveryMethod.length +
    payload.refusedOrders.ordersWithShipments.length;
  const hasCreatedAnyShipment = Boolean(payload.success.length);
  const reasons = [];

  const getPluralizedOrderLabel = (ordersQuantity: number): string =>
    pluralize.pl(ordersQuantity, {
      singular: "zamówienie",
      plural: "zamówienia",
      other: "zamówień",
    });

  if (payload.refusedOrders.ordersCanceled.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersCanceled.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersCanceled.length,
      )} anulowane`,
      signatures: payload.refusedOrders.ordersCanceled.map(order => order.signature),
    });
  }

  if (payload.refusedOrders.ordersDelivered.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersDelivered.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersDelivered.length,
      )} już dostarczone`,
      signatures: payload.refusedOrders.ordersDelivered.map(order => order.signature),
    });
  }

  if (payload.refusedOrders.ordersNotConfirmed.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersNotConfirmed.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersNotConfirmed.length,
      )} niepotwierdzone`,
      signatures: payload.refusedOrders.ordersNotConfirmed.map(order => order.signature),
    });
  }

  if (payload.refusedOrders.ordersOnRoute.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersOnRoute.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersOnRoute.length,
      )} na trasie`,
      signatures: payload.refusedOrders.ordersOnRoute.map(order => order.signature),
    });
  }

  if (payload.refusedOrders.ordersSettled.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersSettled.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersSettled.length,
      )} rozliczone`,
      signatures: payload.refusedOrders.ordersSettled.map(order => order.signature),
    });
  }

  if (payload.refusedOrders.ordersWithIncorrectDeliveryMethod.length) {
    reasons.push({
      message: `${
        payload.refusedOrders.ordersWithIncorrectDeliveryMethod.length
      } ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersWithIncorrectDeliveryMethod.length,
      )} z niewłaściwą metodą dostawy - wymagany jest "transport zewnętrzny"`,
      signatures: payload.refusedOrders.ordersWithIncorrectDeliveryMethod.map(
        order => order.signature,
      ),
    });
  }

  if (payload.refusedOrders.ordersWithShipments.length) {
    reasons.push({
      message: `${payload.refusedOrders.ordersWithShipments.length} ${getPluralizedOrderLabel(
        payload.refusedOrders.ordersWithShipments.length,
      )} z nadanymi przesyłkami (w celu utworzenia duplikatu przesyłki przejdź na panel przesyłki w module Logistyki.)`,
      signatures: payload.refusedOrders.ordersWithShipments.map(order => order.signature),
    });
  }

  open({
    success: hasCreatedAnyShipment
      ? {
          message: `Udało się utworzyć ${payload.success.length} ${pluralize.pl(
            payload.success.length,
            {
              singular: "przesyłkę",
              plural: "przesyłki",
              other: "przesyłek",
            },
          )}`,
        }
      : null,
    failed: failedCount
      ? {
          message: `Nie udało się utworzyć ${failedCount} ${pluralize.pl(failedCount, {
            singular: "przesyłki",
            plural: "przesyłek",
            other: "przesyłek",
          })}`,
          reasons,
        }
      : null,
  });
  return;
};
