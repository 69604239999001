import { queryFetch } from "apiConnectors/queryFetch";
import { AddUnloadingDTO, AddUnloadingPreview, ExternalManufacturerItem } from "./models";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const patchManufacturerItem = (
  data: Assign<Partial<ExternalManufacturerItem>, { id: ExternalManufacturerItem["id"] }>,
) =>
  queryFetch<ExternalManufacturerItem>({
    method: "PATCH",
    url: "/external-manufacturing/orders/items/" + data.id,
    data: parsePatchData(data),
  });

const postUnloading = (data: AddUnloadingDTO) =>
  queryFetch<AddUnloadingPreview>({
    method: "POST",
    url: "/external-manufacturing/orders/create-unloading",
    data,
  });

export const externalManufacturingOrdersApi = {
  patchManufacturerItem,
  postUnloading,
};
