import { SendingToExternalServiceStatus } from "api/trading-documents/enums";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette/colorPalette";
import styles from "../../../StatusInExternalServiceSection.module.css";
import { cx } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";

export const StepLabel = ({
  label,
  status,
}: {
  label: string;
  status: SendingToExternalServiceStatus;
}) => {
  return (
    <div className={cx(styles.label, "d-flex align-items-center gap-1")}>
      <StatusIcon status={status} />
      <Typography
        color={status === SendingToExternalServiceStatus.DONE ? "success500" : "neutralBlack24"}
        fontSize="12"
        fontWeight="600"
        noWrap
      >
        {label}
      </Typography>
    </div>
  );
};

const StatusIcon = ({ status }: { status: SendingToExternalServiceStatus }) => {
  return (
    <div
      className={styles.icon}
      style={{
        borderColor:
          status === SendingToExternalServiceStatus.DONE
            ? colorPalette["success500"]
            : colorPalette["neutralBlack24"],
      }}
    >
      <MdiCheckSmall
        color={status === SendingToExternalServiceStatus.DONE ? "success500" : "neutralBlack24"}
        size="14"
      />
    </div>
  );
};
