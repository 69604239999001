import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { RightPanelHeader } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { useNavigate } from "hooks/useNavigate";
import { ConfirmationSuccessModal } from "pages/externalManufacturing/draftOrders/shared/components/confirmationSuccessModal/ConfirmationSuccessModal";
import { useStateModal } from "hooks";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import {
  ConfirmDraftOrderPreview,
  DraftOrderDetails,
} from "api/external-manufacturing/orders/drafts/models";
import { MdiVisibility } from "components/miloDesignSystem/atoms/icons/MdiVisibility";

interface Props {
  draftOrder: DraftOrderDetails;
}

export const PanelHeader = ({ draftOrder }: Props) => {
  const { close } = useDrawer("externalManufacturingDraftOrders");
  const navigate = useNavigate();
  const confirmDraftOrderMutation = draftOrdersActions.useConfirmDraftOrder();
  const confirmationSuccessModal = useStateModal<ConfirmDraftOrderPreview>();
  const deleteMutation = draftOrdersActions.useDeleteItem();

  return (
    <>
      <RightPanelHeader>
        {Boolean(draftOrder.manufacturingOrders.length) ? (
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiCheck}
            variant="success"
          >
            Potwierdzono
          </Button>
        ) : (
          <Button
            className="text-uppercase"
            isLoading={confirmDraftOrderMutation.isLoading}
            onClick={() =>
              confirmDraftOrderMutation.mutate(
                {
                  externalManufacturingOrderDraft: draftOrder.id,
                },
                {
                  onSuccess: payload => {
                    confirmationSuccessModal.open(payload);
                  },
                },
              )
            }
            size="small"
            startIcon={MdiCheck}
            variant="outline"
          >
            Potwierdź i dodaj do ZDP
          </Button>
        )}
        <div />
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Tooltip
            title={
              Boolean(draftOrder.manufacturingOrders.length) ? "Podgląd szkicu" : "Edytuj szkic"
            }
          >
            <IconButton
              icon={Boolean(draftOrder.manufacturingOrders.length) ? MdiVisibility : MdiEdit}
              onClick={() =>
                navigate(`/external-manufacturing/modify-draft-order/${draftOrder.id}`)
              }
              variant="transparent"
            />
          </Tooltip>
          <Tooltip title="Usuń robocze zlecenie">
            <IconButton
              disabled={Boolean(draftOrder.manufacturingOrders.length)}
              icon={MdiDelete}
              isLoading={deleteMutation.isLoading}
              onClick={() =>
                deleteMutation.mutate(draftOrder.id, {
                  onSuccess: () => {
                    close();
                  },
                })
              }
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {confirmationSuccessModal.isOpen && confirmationSuccessModal.state && (
        <ConfirmationSuccessModal
          close={confirmationSuccessModal.close}
          state={confirmationSuccessModal.state}
        />
      )}
    </>
  );
};
