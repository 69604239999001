import { createPreviewFactory } from "utilities/createPreviewMiddleware";
import { ListOrderGroup, OrderGroup } from "./models";
import { formatSignatureForPreview } from "utilities";

export const orderGroupsPreviewFactory = createPreviewFactory({
  tradingDocumentPDF: (orderGroupId: number, orderGroupSignature: string) => ({
    url: `/order-groups/trading-document-pdf/${orderGroupId}/${orderGroupSignature}`,
  }),
  productionPDF: (orderGroupId: number, orderGroupSignature: string) => ({
    url: `/order-groups/production-pdf/${orderGroupId}/${orderGroupSignature}`,
  }),
  orderGroupPDF: (orderGroupUuid: OrderGroup["uuidId"], orderGroupSignature: string) => ({
    url: `/order-groups/order-group-pdf/${orderGroupUuid}/${orderGroupSignature}`,
  }),
  groupedProtocolsPDF: (orderGroupId: number, orderGroupSignature: string) => ({
    url: `/order-groups/grouped-protocols-pdf/${orderGroupId}/${orderGroupSignature}`,
  }),
  cmrPDF: (
    orderGroupId: OrderGroup["id"],
    signature: OrderGroup["signature"],
    search?: string,
  ) => ({
    url: search
      ? `/order-groups/cmr-pdf/${orderGroupId}/${formatSignatureForPreview(signature)}${search}`
      : `/order-groups/cmr-pdf/${orderGroupId}/${formatSignatureForPreview(signature)}`,
  }),
  cmrPerOrderPDF: (orderGroupId: OrderGroup["id"], search: string = "") => ({
    url: `/order-groups/cmr-pdf-per-order/${orderGroupId}` + search,
  }),
  productsWithPackagesAndWeightPDF: (orderGroup: OrderGroup) => ({
    url: `/orders/products-with-packages-and-weight-pdf?orderGroup=${orderGroup.id}`,
  }),
  packingSlip: (orderGroup: OrderGroup) => ({
    url: `/utils/packing-slip/order-group/${orderGroup.id}`,
  }),
  packingSlipGroupedByOrders: (orderGroup: OrderGroup) => ({
    url: `/utils/packing-slip-grouped-by-orders/order-group/${orderGroup.id}`,
  }),
  orderGroupsProductionPDF: (orderGroupsIds: string) => ({
    url: `/utils/collection-production-pdf/order-groups-production-pdf?orderGroups=${encodeURIComponent(
      orderGroupsIds,
    )}`,
  }),
  ordersWithWeightPDF: (orderGroup: OrderGroup) => ({
    url: `/orders/orders-with-weight-pdf?orderGroup=${orderGroup.id}`,
  }),
  ordersLabels: (orderGroup: ListOrderGroup | OrderGroup) => ({
    url: `/utils/labels/order-group/${orderGroup.id}/${formatSignatureForPreview(
      orderGroup.signature,
    )}`,
  }),
});
