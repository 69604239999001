import { TradingDocument } from "api/trading-documents/models";
import styles from "../../StatusInExternalServiceSection.module.css";
import { StepLabel } from "../../shared/components/stepLabel/StepLabel";
import { SendingToExternalServiceStatus } from "api/trading-documents/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiTimer } from "components/miloDesignSystem/atoms/icons/MdiTimer";
import { MdiData } from "components/miloDesignSystem/atoms/icons/MdiData";
import { DateDisplay } from "../../shared/components/dateDisplay/DateDisplay";
import { cx } from "utilities";
import { useToggle } from "hooks";
import { ErrorDataModal } from "./errorDataModal/ErrorDataModal";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { parseISO, differenceInMinutes } from "date-fns";
import { ISODateTime } from "api/types";

const DURATION_FORECAST_MINUTES = 3 as const;

interface Props {
  tradingDocument: TradingDocument;
}

export const ExternalServiceSending = ({ tradingDocument }: Props) => {
  const errorDataModal = useToggle();
  const sendingInExternalService = tradingDocument.statusesInExternalService.sending;

  return (
    <div className={styles.row}>
      <StepLabel label="Wysyłka" status={sendingInExternalService.sendingToExternalServiceStatus} />
      <div className={cx(styles.content, "d-flex align-items-center gap-2 py-1")}>
        <StatusTag status={sendingInExternalService.sendingToExternalServiceStatus} />
        <SendingPerStatus
          sendingInExternalService={sendingInExternalService}
          tradingDocument={tradingDocument}
        />
        <div className="w-100 d-flex justify-content-end">
          <Tooltip
            disabled={Boolean(sendingInExternalService.sendingToExternalServiceData)}
            title="Brak danych"
          >
            <IconButton
              disabled={!sendingInExternalService.sendingToExternalServiceData}
              icon={MdiData}
              onClick={errorDataModal.open}
              variant="transparent"
            />
          </Tooltip>
        </div>
      </div>
      {errorDataModal.isOpen && sendingInExternalService.sendingToExternalServiceData && (
        <ErrorDataModal
          close={errorDataModal.close}
          data={sendingInExternalService.sendingToExternalServiceData}
        />
      )}
    </div>
  );
};

const SendingPerStatus = ({
  sendingInExternalService,
  tradingDocument,
}: {
  sendingInExternalService: TradingDocument["statusesInExternalService"]["sending"];
  tradingDocument: TradingDocument;
}) => {
  const orderSendingInExternalService = tradingDocument.statusesInExternalService.orderSending;

  switch (sendingInExternalService.sendingToExternalServiceStatus) {
    case SendingToExternalServiceStatus.DONE:
      return <DateDisplay date={sendingInExternalService.sendingToExternalServiceAt} />;
    case SendingToExternalServiceStatus.FAILED:
      return (
        <div className="d-flex align-items-center gap-2 overflow-hidden">
          <Tag.WithCustomColor
            backgroundColor="red32"
            label="Błąd wysyłki"
            startIcon={MdiEmergencyHome}
            textColor="red500"
          />
          {Boolean(sendingInExternalService.sendingToExternalServiceErrorNote.length) && (
            <div className={cx(styles.errorMessage, "d-flex flex-1 align-items-center gap-1")}>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                powód:
              </Typography>
              <Typography.OverflowTextWithTooltip
                color="danger600"
                fontSize="12"
                fontWeight="400"
                noWrap
              >
                {sendingInExternalService.sendingToExternalServiceErrorNote}
              </Typography.OverflowTextWithTooltip>
            </div>
          )}
          <DateDisplay date={sendingInExternalService.sendingToExternalServiceAt} />
        </div>
      );
    case SendingToExternalServiceStatus.NOT_STARTED:
      return null;
    case SendingToExternalServiceStatus.PENDING: {
      if (
        orderSendingInExternalService.orderSendingToExternalServiceAt &&
        orderSendingInExternalService.orderSendingToExternalServiceStatus ===
          SendingToExternalServiceStatus.DONE
      ) {
        return (
          <>
            {getRemainingMinutes(orderSendingInExternalService.orderSendingToExternalServiceAt!) >
            0 ? (
              <Tag.WithCustomColor
                backgroundColor="yellow32"
                label={`może potrwać do ${getRemainingMinutes(
                  orderSendingInExternalService.orderSendingToExternalServiceAt!,
                )} min`}
                startIcon={MdiTimer}
                textColor="yellow500"
              />
            ) : (
              <Tag.WithCustomColor
                backgroundColor="red32"
                label="Wysyłanie trwa dłużej niż oczekiwano. Sprawdź później"
                textColor="red500"
              />
            )}
          </>
        );
      }
      return null;
    }
    default: {
      const exhaustiveCheck: never = sendingInExternalService.sendingToExternalServiceStatus;
      console.error(`Unhandled sending status: ${exhaustiveCheck}`);
      return null;
    }
  }
};

const StatusTag = ({ status }: { status: SendingToExternalServiceStatus }) => {
  switch (status) {
    case SendingToExternalServiceStatus.NOT_STARTED:
    case SendingToExternalServiceStatus.FAILED:
      return <Tag label="Nie wysłano" type="outlined" variant="quaternary" />;
    case SendingToExternalServiceStatus.DONE:
      return <Tag label="Wysłano plik XML" variant="success" />;
    case SendingToExternalServiceStatus.PENDING:
      return <Tag label="Trwa wysyłanie" startIcon={MdiPoint} variant="info" />;
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled sending status: ${exhaustiveCheck}`);
      return null;
    }
  }
};

const getRemainingMinutes = (orderSendingAt: ISODateTime) => {
  const targetDate = parseISO(orderSendingAt);
  const now = new Date();

  const minutesElapsed = differenceInMinutes(now, targetDate);
  const remainingMinutes = DURATION_FORECAST_MINUTES - minutesElapsed;

  return remainingMinutes > 0 ? remainingMinutes : 0;
};
