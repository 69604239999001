import { useState } from "react";
import styles from "./ManufacturerOrderDetails.module.css";
import cx from "classnames";
import avatarIcon from "assets/images/avatar7.svg";
import leafIcon from "assets/images/182w.svg";
import printPdfIcon from "assets/images/162.svg";
import viewPdfIcon from "assets/images/161.svg";
import flagIcon from "assets/images/25w.svg";
import confirmIcon from "assets/images/7w.svg";
import tickIcon from "assets/images/7c.svg";
import viewLabelImg from "assets/images/263.svg";
import chatIcon from "assets/images/45.svg";
import { RouteComponentProps } from "react-router";
import { dateFns, getAnyErrorKey } from "utilities";
import { CommonError, Spinner, StatusHandlerHelpers, StatusHandler, Label } from "components/utils";
import { useAsyncActions, useDidMount } from "hooks";
import { Button } from "components/common";
import {
  confirmManufacturerOrder,
  getManufacturerOrderLinkInfo,
  manufacturerOrderFileFactory,
  manufacturerOrderPreviewFactory,
} from "api/production-orders/calls-manufacturer";
import { ManufacturerOrderLinkInfo } from "api/production-orders/models";
import { TextToClipboard, UrlToClipboard } from "components/utils/toClipboard";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { Typography } from "components/miloDesignSystem/atoms/typography";

type Props = RouteComponentProps<{ orderId: string; token: string }>;
const initialState: ManufacturerOrderLinkInfo | null = null;

const formatSignatureForPreview = (signature: string) => signature.replace(/\s|\//g, "-");

export const ManufacturerOrderDetails = ({ match }: Props) => {
  const { orderId, token } = match.params;
  const [state, { request, success, failure }] = useAsyncActions({
    initialState: { result: initialState as ManufacturerOrderLinkInfo | null },
  });
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { result, error, fetching } = state;
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  useDidMount(async () => {
    request();
    const [payload, , { status }] = await getManufacturerOrderLinkInfo(orderId, token);
    if (payload) {
      success(payload);
      setIsConfirmed(payload.isConfirmed);
    } else {
      failure({ status });
    }
  });

  async function handleDownloadManufacturerOrderPdf() {
    if (!result) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = manufacturerOrderFileFactory.manufacturerOrderPdf(result);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  }

  async function handleConfirmOrder(helpers: StatusHandlerHelpers) {
    helpers.startFetching();
    const [, error] = await confirmManufacturerOrder(orderId, token);
    helpers.stopFetching();

    if (!error) {
      setIsConfirmed(true);
    } else {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  if (error && error.status !== 403) {
    return <CommonError status={error.status} />;
  }

  if (fetching || !result) {
    return <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.header, "border-bottom")}>
        <div className="d-flex align-items-center justify-content-center  ml-3">
          <h3 className="font-weight-bold mb-0 mr-2">{result.signature}</h3>
          <TextToClipboard text={result.signature || ""} />
          <UrlToClipboard />
          <i className={cx(styles.divider)}></i>
          {result.isFinished ? (
            <Label color="green" className={styles.statusIcon}>
              <img src={flagIcon} alt="liść" className="mr-2" />
              <span>Doręczone</span>
            </Label>
          ) : (
            <Label color="green" className={styles.statusIcon}>
              <img src={leafIcon} alt="liść" className="mr-2" />
              <span>Nowe</span>
            </Label>
          )}
          <i className={cx(styles.divider)}></i>
          <div className={styles.orderDate}>
            {dateFns.format(new Date(result.created), "dd.MM.yyyy HH:mm")}
          </div>
        </div>
        <div className="mr-3">
          <Button
            kind="secondary"
            size="round-s"
            rel="noopener noreferrer"
            href={manufacturerOrderPreviewFactory
              .productionOrdersPackageLabels(result.id)
              .urlWithCustomGuid(token)}
            target="_blank"
            as="a"
            title="Podgląd etykiet zamówień"
          >
            <img alt="" src={viewLabelImg} />
          </Button>
          <Button
            type="button"
            kind="secondary"
            size="round-s"
            onClick={handleDownloadManufacturerOrderPdf}
            title="Pobierz PDF"
          >
            <img alt="" src={printPdfIcon} />
          </Button>
          <Button
            kind="secondary"
            size="round-s"
            rel="noopener noreferrer"
            href={manufacturerOrderPreviewFactory
              .manufacturersProductionPDF(
                result.id,
                formatSignatureForPreview(result.signature || ""),
              )
              .urlWithCustomGuid(token)}
            target="_blank"
            as="a"
            title="Podgląd PDF"
          >
            <img alt="" src={viewPdfIcon} />
          </Button>
          <Button
            kind="secondary"
            size="round-s"
            rel="noopener noreferrer"
            href={manufacturerOrderPreviewFactory
              .productionOrdersLabels(result.id)
              .urlWithCustomGuid(token)}
            target="_blank"
            as="a"
            title="Podgląd etykiet paczek"
          >
            <img alt="" src={viewPdfIcon} />
          </Button>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.info}>
          <div className={cx(styles.itemsContainer, "mt-3 border-bottom")}>
            <div className={styles.title}>Dane klienta</div>
            <div>{result.client.street}</div>
            <div>
              {result.client.postCode} {result.client.city}
            </div>
          </div>
          <div className={cx(styles.itemsContainer, "mt-3 border-bottom")}>
            <div className={styles.title}>Dane dostawy</div>
            <div>{result.deliveryAddress.street}</div>
            <div>
              {result.deliveryAddress.postCode} {result.deliveryAddress.city}
            </div>
          </div>
        </div>
        <div>
          {isConfirmed ? (
            <div
              className={cx(
                styles.section,
                styles.confirmSection,
                "d-flex justify-content-center flex-column mt-0",
              )}
            >
              <div className="mb-2">
                <div className="d-flex ml-4 align-items-center">
                  <img src={tickIcon} alt="" className={styles.confirmedIcon} />
                  <h5 className={cx(styles.collectDateText, "text-color-blue ml-2 mb-0")}>
                    Potwierdzono przyjęcie zamówienia
                  </h5>
                  {result.confirmationDate && (
                    <>
                      <i className={cx(styles.divider)}></i>
                      <div className={styles.orderDate}>
                        {dateFns.format(new Date(result.confirmationDate), "dd.MM.yyyy HH:mm")}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={cx(
                styles.section,
                styles.confirmSection,
                "d-flex justify-content-center align-items-center flex-column mt-0",
              )}
            >
              <div className="mb-2">Prosimy, potwierdź przyjęcie zamówienia, klikając poniżej:</div>
              <StatusHandler>
                {helpers => (
                  <Button
                    kind="primary"
                    size="large"
                    className={styles.confirmBtn}
                    onClick={() => handleConfirmOrder(helpers)}
                    disabled={helpers.isFetching}
                  >
                    <img src={confirmIcon} alt="" /> Potwierdzam przyjęcie zamówienia
                  </Button>
                )}
              </StatusHandler>
            </div>
          )}
          <div className={cx(styles.section, styles.indexes)}>
            <div className={cx(styles.title, "mb-3")}>Indeksy w zamówieniu</div>
            {result.indexes.map((index, listIndex) => (
              <div className={cx("mb-3 pb-3", styles.dottedBorder)} key={listIndex}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      {index.name}
                      <div className="ml-2 text-color-grey">
                        {index.count} / {index.maxCount}
                      </div>
                    </div>
                    <div className="d-flex mr-2">
                      {index.isReceived && (
                        <div className="d-flex ml-4">
                          <img src={tickIcon} alt="" />
                          <span className={cx(styles.collectDateText, "text-color-blue ml-2")}>
                            Dostarczono
                          </span>
                        </div>
                      )}
                      {index.isSentToManufacturer && (
                        <div className="d-flex ml-4">
                          <img src={tickIcon} alt="" />
                          <span className={cx(styles.collectDateText, "text-color-blue ml-2")}>
                            Zlecono
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.attributesContainer}>
                  {index.attributes &&
                    index.attributes.map(({ attribute, value }, index) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}s</strong>
                      </div>
                    ))}
                </div>
                {index.order && (
                  <div className="pt-1">
                    <Typography fontFamily="cabin" fontSize="14" fontWeight="600" noWrap>
                      {index.order.signature}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cx(styles.communicatorWrapper, "d-flex align-items-end justify-content-end")}>
        <div className="d-flex">
          <div className={cx(styles.cloud, "d-flex justify-content-between align-items-center")}>
            <div className={cx(styles.avatar, "d-flex justify-content-between mr-2")}>
              <img src={avatarIcon} alt="Zdjęcie użytkownika" />
            </div>
            <div className={styles.helloMessage}>Hej! W czym możemy Tobie dzisiaj pomóc?</div>
          </div>
          <div className={styles.btnBox}>
            <button className="p-1 d-flex align-items-center justify-content-center">
              <img src={chatIcon} alt="czat" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
