import { createPaginatedKey } from "api/keys";

export const indexesKeys = {
  indexes: createPaginatedKey("indexes"),
  indexesForExternalManufacturing: {
    list: createPaginatedKey("indexesForExternalManufacturing"),
    details: (indexId: string) => ["indexForExternalManufacturing", indexId],
  },
  indexesFulfillment: createPaginatedKey("indexesFulfillment"),
  indexesManufacturers: createPaginatedKey("indexesManufacturers"),
  indexGreatestWeight: () => ["indexGreatestWeight"],
  index: (id: string) => ["index", id],
  indexExist: createPaginatedKey("indexExist"),
  productSetIndexes: createPaginatedKey("productSetIndexes"),
  priceListIndexes: createPaginatedKey("priceListIndexes"),
};
