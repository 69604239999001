import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturerSingleOrderListItem } from "api/manufacturing/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { dateFns, dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturerSingleOrdersColumns = () => {
  return useCreateTableColumns<ManufacturerSingleOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.material.name, {
        header: "materiał",
        size: 240,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.material.category?.name, {
        header: "kategoria",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "dostawca",
        size: 200,
        typographyProps: {
          fontSize: "16",
        },
      }),
      // TODO: Add Link when all orders list is completed
      columnHelper.text(row => row.manufacturingItem?.signature, {
        header: "zlecenie",
        size: 170,
        typographyProps: {
          fontSize: "16",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "materialCount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            ilość/liczba
          </Typography>
        ),
        size: 70,
        cell: info => {
          const singleOrder = info.getValue();
          return (
            <div className="d-flex align-items-center w-100 justify-content-end gap-1">
              <Typography
                className="text-right"
                color="neutralBlack88"
                fontSize="12"
                fontWeight="700"
                noWrap
              >
                {singleOrder.quantity}
              </Typography>
              <Typography
                className="text-right"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="600"
                noWrap
              >
                {singleOrder.material.unit.shortName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.material.externalId || EMPTY_VALUE, {
        header: "nr referencyjny",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "utworzono",
        size: 120,
        format: "formatRelative",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status realizacji",
        size: 120,
        cell: info => {
          const status = info.getValue();
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[status]} />;
        },
      }),
      columnHelper.accessor(row => row, {
        id: "receivedAt",
        header: () => (
          <Typography className="text-center" color="neutralBlack48" fontSize="12" fontWeight="400">
            odebrano
          </Typography>
        ),
        size: 60,
        cell: info => {
          const row = info.getValue();
          return (
            <div className="d-flex align-items-center justify-content-center flex-1">
              <Checkbox.Async
                label=""
                checked={Boolean(row.receivedAt)}
                mutationHook={manufacturingActions.usePostManufacturingOrdersReceive}
                transformQueryData={() => ({ materialsOrdersItems: [row.id] })}
                disabled={Boolean(row.receivedAt)}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.date(row => row.orderedAt, {
        header: "zlecono dn.",
        size: 80,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.date(row => row.scheduledDeadline, {
        header: "termin",
        size: 80,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.link({
        header: "ZZS",
        size: 110,
        to: row => `/manufacturing/manufacturer-orders/list/all?panelId=${row.materialOrder.id}`,
        textRenderer: row => row.materialOrder.signature,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.scheduledReception, {
        header: "przewidywany termin dostawy",
        size: 180,
        cell: info => {
          const scheduledReception = info.getValue();
          if (!scheduledReception) return <EmptyValue fontSize="14" fontWeight="600" />;

          const daysLeft = dateFns.differenceInDays(new Date(scheduledReception), new Date());

          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <Tag
                label={dateUtils.formatDateToDisplayOnlyDate(scheduledReception)}
                variant={
                  daysLeft <= 1
                    ? "danger"
                    : daysLeft <= 3 && daysLeft > 1
                    ? "warning"
                    : "quaternary"
                }
                type={daysLeft <= 3 ? "filled" : "outlined"}
              />
              {daysLeft >= 0 ? (
                <Typography
                  fontSize="12"
                  fontWeight="700"
                  color={
                    daysLeft <= 1
                      ? "danger500"
                      : daysLeft <= 3 && daysLeft > 1
                      ? "warning500"
                      : "neutralBlack100"
                  }
                >
                  pozostało {daysLeft} dni
                </Typography>
              ) : (
                <EmptyValue fontSize="12" fontWeight="700" />
              )}
            </div>
          );
        },
      }),
      columnHelper.date(row => row.receivedAt, {
        header: "odebrano",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.delivery, {
        header: "adresy dostaw",
        size: 180,
        cell: info => {
          const delivery = info.getValue();
          return (
            <Typography color="neutralBlack88" fontSize="14" fontWeight="400" noWrap>
              {delivery.street}
              {delivery.street && ","} {delivery.postCode} {delivery.city}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "zlecił/-a",
        size: 55,
        cell: info => {
          const createdBy = info.getValue();
          return (
            <div className="d-flex w-100 justify-content-center">
              <Avatar size="sm" user={createdBy} />
            </div>
          );
        },
      }),
      // TODO: label - waiting for backend
    ];
  });
};
