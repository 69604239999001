import { routeKeys } from "api/keys";
import { Route, RouteOrder } from "api/routes/models";
import { OrderCollectionSource } from "api/wh-entries/enums";
import {
  ClientDetailsToUpdate,
  DeliveryAddressDetailsToUpdate,
  OrderDeliveryUpdateModal,
} from "components/common/orderDeliveryUpdateModal/OrderDeliveryUpdateModal";
import { useQueryUtils, useToastr } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Assign } from "utility-types";
import immer from "immer";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "../../../utils";
import { graphhopper } from "api/graphhopper/graphhopper";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import { useRoutePutMutation } from "hooks/apiPrimitives";

interface Props {
  closeModal: () => void;
  isModalOpened: boolean;
  order: Assign<
    RouteOrder,
    {
      order: number | null;
    }
  >;
  route: Route;
}

export const OrderDeliveryUpdateModalWrapper = ({
  closeModal,
  isModalOpened,
  order,
  route,
}: Props) => {
  const { handleMutate } = useQueryUtils();
  const toastr = useToastr();
  const actions = useRouteViewState("slave", state => state.actions);
  const updateMutation = useRoutePutMutation();

  const updateRouteClient = (toUpdate: ClientDetailsToUpdate) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(foundOrder => foundOrder.id === order.id);
      assertIsDefined(orderToUpdate);
      Object.assign(orderToUpdate.client, toUpdate);
      return draft;
    });
  };

  const updateRouteDelivery = (toUpdate: Partial<Route["orders"][number]["delivery"]>) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(foundOrder => foundOrder.id === order.id);
      assertIsDefined(orderToUpdate);
      Object.assign(orderToUpdate.delivery, toUpdate);
      return draft;
    });
  };

  const updateRouteDetails = async (values: DeliveryAddressDetailsToUpdate) => {
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    actions.openLoader("Trwa zmiana punktu zamówienia");

    const newOrdersPositions = immer(route.ordersPositions, draft => {
      const orderToUpdate = draft.find(foundOrder => Number(foundOrder.id) === order.id);
      assertIsDefined(orderToUpdate);
      orderToUpdate.meta.point = values.point!;
    });

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      updateRouteDelivery(
        values as Assign<DeliveryAddressDetailsToUpdate, { point: { lat: number; lng: number } }>,
      );
      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          ordersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić punktu zamówienia",
        text: "",
      });
    }
  };

  if (!isModalOpened) return null;

  return (
    <OrderDeliveryUpdateModal
      close={closeModal}
      source={OrderCollectionSource.ROUTE}
      normalizedOrder={{
        client: {
          ...order.client,
          hasDropShipping: order.customer?.hasDropShipping ?? false,
        },
        customer: order.customer,
        delivery: {
          ...order.delivery,
          phone: order.client.phone,
        },
        id: order.id,
        signature: order.signature,
      }}
      onClientDetailsUpdateSuccess={({ toUpdate }) => updateRouteClient(toUpdate)}
      onGeolocalizationSuccess={({ toUpdate }) => updateRouteDetails(toUpdate)}
    />
  );
};
