import { Table } from "components/miloDesignSystem/molecules/table";
import { usePiecesColumns } from "./usePiecesColumns";
import { Shipment, ShippingPiece } from "api/shipping/models";
import { shippingActions } from "api/shipping/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Switch } from "components/miloDesignSystem/atoms/switch";
import styles from "../../RightPanel.module.css";
import { cx, queryString } from "utilities";
import { ErrorType } from "hooks/createApiQuery";
import { CommonError } from "components/utils";
import { memo, useMemo } from "react";
import { useFilters } from "hooks/useFilters";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { ShippingService } from "constants/shippingServiceConstants";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  shipment: Shipment;
}

interface Package {
  id: number;
  name: string;
  packages: ShippingPiece[];
}

export const PiecesSection = ({ shipment }: Props) => {
  const { searchParams } = useFilters({ shipments: [shipment.id], pageSize: 999 });
  const { data: rows, isLoading, error } = shippingActions.useShippingPieces(searchParams);

  const patchShippingPiece = shippingActions.usePatchMultipleShippingPieces();

  const noResults = !rows.length && !isLoading && !error;
  const groupedRowsByProduct = useMemo(() => {
    return rows.reduce<Record<number, Package>>((acc, row) => {
      const key = row.itemId || row.productId;
      if (!acc[key]) {
        Object.assign(acc, {
          [key]: { id: row.productId, name: row.productName, packages: [row] },
        });
        return acc;
      }

      acc[key].packages.push(row);

      return acc;
    }, {});
  }, [rows]);

  if (isLoading)
    return (
      <div className="px-3 pt-1 pb-3 flex-1">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
          W przesyłce
        </Typography>

        <div className="ml-2 mt-2">
          <Spinner size={26} />
        </div>
      </div>
    );

  if (noResults) {
    return (
      <div className="mb-2">
        <EmptySection label="Brak paczek w przesyłce" />
      </div>
    );
  }

  if (error)
    return (
      <div className="mt-4">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  return (
    <div className="px-3 pt-1 pb-3 flex-1">
      <div className="d-flex align-items-center justify-content-between">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
          W przesyłce
        </Typography>
        {shipment.shippingService?.provider === ShippingService.GLS &&
          Boolean(rows.some(row => row.trackingNumber)) && (
            <div>
              <Button
                className="text-uppercase"
                onClick={() => {
                  window.open(
                    `https://gls-group.com/PL/pl/sledzenie-paczek/?match=${rows
                      .filter(row => Boolean(row.trackingNumber))
                      .map(row => row.trackingNumber)
                      .toString()}`,
                    "_blank",
                  );
                }}
                size="small"
                startIcon={MdiPackage2}
                variant="gray"
              >
                Śledź przesyłkę
              </Button>
            </div>
          )}
      </div>

      {Object.values(groupedRowsByProduct)?.map(product => (
        <div key={product.id}>
          <div
            className={cx(
              "d-flex align-items-center justify-content-between mb-1",
              styles.piecesSectionMargin,
            )}
          >
            <Typography
              fontSize="14"
              fontWeight="700"
              color={
                product.packages.every(({ isIncludedForSend }) => !isIncludedForSend)
                  ? "neutralBlack48"
                  : "neutralBlack100"
              }
              className={cx({
                "line-through": product.packages.every(
                  ({ isIncludedForSend }) => !isIncludedForSend,
                ),
              })}
            >
              {product.name}
            </Typography>
            <div className="d-flex align-items-center gap-2">
              {shipment.shippingService?.provider === ShippingService.SPT && (
                <PatchParcelPrice
                  packagesIds={product.packages.map(_package => _package.id)}
                  shipmentId={shipment.id}
                  productId={product.id}
                />
              )}
              <Switch
                checked={product.packages.every(({ isIncludedForSend }) => !isIncludedForSend)}
                onChange={value => {
                  patchShippingPiece.mutate({
                    isIncludedForSend: !value,
                    product: product.id,
                    shipments: [shipment.id],
                  });
                }}
                label="Usuń z przesyłki"
              />
            </div>
          </div>
          <ShippingPieceTable
            shipment={shipment}
            shippingPieces={product.packages}
            error={error}
            isLoading={isLoading}
          />
          <PostShippingPieceButton shipment={shipment} product={product} />
        </div>
      ))}
    </div>
  );
};

const ShippingPieceTable = memo(
  ({
    error,
    isLoading,
    shippingPieces,
    shipment,
  }: {
    isLoading: boolean;
    error: ErrorType | null;
    shippingPieces: ShippingPiece[];
    shipment: Shipment;
  }) => {
    const columns = usePiecesColumns();

    return (
      <Table<ShippingPiece>
        overrides={() =>
          shipment?.shippingService?.provider === ShippingService.AMBRO
            ? {
                tableInnerWrapper: { className: "overflow-visible" },
                table: { className: "overflow-visible" },
              }
            : {}
        }
        rows={shippingPieces}
        columns={columns}
        isLoading={isLoading}
        error={error}
        uiSchema={{
          header: {
            backgroundColor: "neutralWhite100",
          },
          cell: {
            height: "38",
          },
        }}
      />
    );
  },
);

const PostShippingPieceButton = ({
  product,
  shipment,
}: {
  shipment: Shipment;
  product: Package;
}) => {
  const postShippingPiece = shippingActions.usePostShippingPiece();
  return (
    <Button
      size="small"
      variant="gray"
      isLoading={postShippingPiece.isLoading}
      disabled={postShippingPiece.isLoading}
      startIcon={MdiAdd}
      className="text-uppercase mt-1"
      onClick={() => {
        postShippingPiece.mutate({
          shipment: shipment.id,
          productId: product.id,
          productName: product.name,
        });
      }}
    >
      Dodaj paczkę
    </Button>
  );
};

const PatchParcelPrice = ({
  productId,
  shipmentId,
  packagesIds,
}: {
  productId: number;
  shipmentId: Shipment["id"];
  packagesIds: ShippingPiece["id"][];
}) => {
  const { data, isLoading } = shippingActions.useShipmentsParcelProvider({
    shippingProviderId: ShippingService.SPT,
    search: queryString.stringify({ shipment: shipmentId }),
  });
  if (isLoading) {
    return <Spinner size={20} />;
  }
  return (
    <Select.Async
      textFieldProps={{ size: "default" }}
      items={[
        { text: "cennik kubaturowy", type: MenuItemType.TEXT, value: "1" },
        { text: "cennik wagowy", type: MenuItemType.TEXT, value: "2" },
      ]}
      mutationHook={shippingActions.usePatchShipmentsParcelProvider}
      transformQueryData={parcelPricing => ({
        parcels: packagesIds,
        pricing: parcelPricing as string,
        shippingProviderId: ShippingService.SPT,
      })}
      selected={
        data?.find(_package => String(_package.productId) === String(productId))?.pricing || null
      }
    />
  );
};
