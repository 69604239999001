import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { ExecutionStatus } from "../../components/ExecutionStatus";
import {
  ExecutionStatus as ExecutionStatusType,
  ExternalManufacturerLineItem,
} from "api/external-manufacturing/models";

export const useRemainingOrdersColumns = () =>
  useCreateTableColumns<ExternalManufacturerLineItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "sygnatura",
          size: 130,
          cell: info => {
            const lineItem = info.getValue();
            return (
              <div className="d-flex align-items-center justify-content-between flex-1 pr-1">
                <Typography fontSize="12" fontWeight="700">
                  {lineItem.signature}
                </Typography>
                {lineItem.isNew && !lineItem.isCancelled && <Tag label="nowe" variant="info" />}
                {lineItem.isCancelled &&
                  lineItem.executionStatus !== ExecutionStatusType.NOT_STARTED && (
                    <Tag label="anulowane" variant="warning" />
                  )}
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.executionStatus, {
          header: "status",
          size: 210,
          cell: info => {
            const status = info.getValue();
            return <ExecutionStatus status={status} />;
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
