import { yup } from "utilities";

export const validationSchema = yup.array().of(
  yup.object().shape({
    manufacturer: yup
      .object()
      .nullable()
      .when("isSelected", {
        is: true,
        then: () => {
          return yup
            .object()
            .nullable()
            .required("Brak producenta");
        },
        otherwise: () => {
          return yup.object().nullable();
        },
      }),
  }),
);
