import { SellerKind } from "api/sellers/models";
import { FilterBarValue } from "../pageHeader/components/filterBar/FilterBar";
import { useQuery } from "hooks";
import { ApiFetcher } from "hooks/createApiQuery";

export interface FilterSelectType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Select;
  options: { label: string; value: string; icon?: string; content?: React.ReactNode }[];
  kind?: "checkbox" | "label";
  multiple?: boolean;
}

export interface FilterSearchType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Search;
  searchBy:
    | "salesAccounts"
    | "customers"
    | "warehouses"
    | "users"
    | "businessEntities"
    | "manufacturingSchemas"
    | "manufacturers"
    | "shippingShippingServices";
  multiple?: boolean;
  overrides?: {
    businessEntitiesKind?: SellerKind;
  };
}

export interface FilterAsyncSearchType<FF, T = undefined> extends FilterDefinition<T> {
  fetchListFrom: FF;
  fetchDetailsFrom: (id: number | string) => ApiFetcher<any>;
  multiple?: boolean;
  placeholder?: string;
  type: FilterType.AsyncSearch;
  value: string;
  display: string;
}

export interface FilterSelectUserType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.SelectUser;
  kind: "user" | "car" | "carrier";
  searchBy: "standardUsers" | "drivers" | "users";
  multiple?: boolean;
}

export interface FilterDateRangeType<T = undefined> extends Omit<FilterDefinition<T>, "name"> {
  type: FilterType.DateRange;
  name: [string, string];
  showMonths?: boolean;
  additionalKeys?: [string, string];
}

export interface FilterDateRangeWithOptionsType<T = undefined>
  extends Omit<FilterDefinition<T>, "name"> {
  type: FilterType.DateRangeWithOptions;
  name: [string, string];
  options: { label: string; value: [string, string] }[];
}

export interface FilterTextType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Text;
  placeholder?: string;
}

export interface FilterNumberType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Number;
  placeholder?: string;
}

export interface FilterCustomType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Custom;
  render: React.FunctionComponent<{}>;
  filterBarValueRenderer: (queryUtils: ReturnType<typeof useQuery>) => FilterBarValue[];
}

export interface FilterDateType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Date;
  options?: { label: string; value: string }[];
}

interface FilterDefinition<T> {
  name: T extends undefined ? string : T;
  label: string;
}

export enum FilterType {
  AsyncSearch = "asyncSearch",
  Select = "select",
  Number = "number",
  SelectUser = "selectUser",
  Search = "search",
  DateRange = "dateRange",
  DateRangeWithOptions = "dateRangeWithOptions",
  Date = "date",
  Text = "text",
  Custom = "custom",
}

export type AvailableFilters<FF, T> =
  | FilterSelectType<T>
  | FilterSelectUserType<T>
  | FilterNumberType<T>
  | FilterDateRangeType<T>
  | FilterDateRangeWithOptionsType<T>
  | FilterDateType<T>
  | FilterSearchType<T>
  | FilterAsyncSearchType<FF, T>
  | FilterCustomType<T>
  | FilterTextType<T>;
