import React, { useEffect, useState } from "react";
import { ForwardedRef } from "react";
import styles from "./TextField.module.css";
import cx from "classnames";
import { Size, TextFieldProps, Theme } from "./types";
import { Typography } from "../typography";
import { ColorPalette } from "../colorsPalette";
import { Spinner } from "../spinner";
import { getAnyErrorKey, omit, yup } from "utilities";
import { AxiosError } from "axios";
import { usePrevious } from "hooks";
import { IconRenderer } from "../shared/IconRenderer";
import { useField } from "formik";
import { MDSAsyncType, MDSFormType } from "typeUtilities";
import cuid from "cuid";

const colorSelector: Record<Theme, ColorPalette> = {
  light: "neutralBlack88",
  dark: "neutralWhite48",
};

export const TextField = Object.assign(
  React.forwardRef(
    (
      {
        containerClassName,
        inputClassName,
        disabled,
        endIcon,
        error,
        helperText,
        label,
        readOnly,
        startIcon,
        EndInputSection,
        theme = Theme.LIGHT,
        size = Size.DEFAULT,
        ...rest
      }: TextFieldProps,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      const baseStyles = {
        [styles.textFieldInput]: true,
        [styles[size!]]: true,
        [styles[theme!]]: !disabled && !readOnly,
      };
      const disabledStyles = {
        [styles.disabledLight]: disabled && theme === Theme.LIGHT,
        [styles.disabledDark]: disabled && theme === Theme.DARK,
      };

      const readOnlyStyles = {
        [styles.readOnlyLight]: readOnly && theme === Theme.LIGHT,
        [styles.readOnlyDark]: readOnly && theme === Theme.DARK,
      };

      return (
        <div className={cx(containerClassName, { [styles.labelWrapper]: Boolean(label) })}>
          <div
            tabIndex={-1}
            className={cx(baseStyles, {
              ...disabledStyles,
              ...readOnlyStyles,
              "cursor-not-allowed": disabled || readOnly,
              [styles.error]: error,
            })}
          >
            {label && <Label size={size} label={label} theme={theme} />}
            {startIcon && (
              <IconRenderer
                color={colorSelector[theme]}
                icon={startIcon}
                size={DEFAULT_ICON_SIZE}
              />
            )}
            <input
              id={cuid()}
              tabIndex={-1}
              ref={ref}
              className={cx(
                styles.defaultInput,
                {
                  [styles.defaultInputLight]: theme === Theme.LIGHT,
                  [styles.defaultInputDark]: theme === Theme.DARK,
                },
                inputClassName,
              )}
              disabled={readOnly || disabled || false}
              {...rest}
            />
            {EndInputSection}
            {endIcon && (
              <IconRenderer color={colorSelector[theme]} icon={endIcon} size={DEFAULT_ICON_SIZE} />
            )}
          </div>
          {error && typeof error === "string" && (
            <div>
              <Typography className="mt-1" color="danger500" fontSize="10" fontWeight="500" noWrap>
                {error}
              </Typography>
            </div>
          )}
          {helperText && !error && (
            <HelperText helperText={helperText} theme={theme} disabled={disabled} />
          )}
        </div>
      );
    },
  ),
  {
    Async: AsyncTextField,
    Form: FormTextField,
  },
);

const Label = ({ label, theme, size }: Pick<TextFieldProps, "label" | "theme" | "size">) => {
  const labelColor = {
    [styles.lightLabel]: theme === Theme.LIGHT,
    [styles.darkLabel]: theme === Theme.DARK,
  };

  const labelSize = {
    [styles.smallLabel]: size === "small",
    [styles.defaultLabel]: size === "default",
  };

  return (
    <Typography
      color={theme === "light" ? "neutralBlack48" : "neutralWhite48"}
      fontSize="10"
      fontWeight="700"
      className={cx(styles.label, labelColor, labelSize)}
    >
      {label}
    </Typography>
  );
};

const HelperText = ({
  helperText,
  theme,
  disabled,
}: Pick<TextFieldProps, "helperText" | "disabled" | "theme">) => {
  const color: ColorPalette = (() => {
    if (theme === Theme.LIGHT) {
      return disabled ? "neutralBlack24" : "neutralBlack48";
    }

    return disabled ? "neutralWhite24" : "neutralWhite48";
  })();

  return (
    <Typography className="mt-1" color={color} fontSize="10" fontWeight="500" noWrap>
      {helperText}
    </Typography>
  );
};

const DEFAULT_ICON_SIZE = 18;

function AsyncTextField<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  props: MDSAsyncType<TextFieldProps, string, TData, TError, TVariables, TContext>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [value, setValue] = useState(props.value);
  const [validationError, setValidationError] = useState("");
  const prevValue = usePrevious(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const mutation = props.mutationHook();
  const mutate = () => {
    if (prevValue === value) return;
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData(String(value));
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };

  const handleOnKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        mutate();
        return;
      case "Esc":
      case "Escape":
        event.preventDefault();
        setValue(prevValue);
        return;
    }
  };

  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);

  return (
    <TextField
      {...propsToForward}
      disabled={mutation.isLoading || propsToForward.disabled}
      error={validationError || getAnyErrorKey(mutation.error as AxiosError)}
      endIcon={mutation.isLoading ? <Spinner size={16} /> : props.endIcon}
      onBlur={event => {
        mutate();
        !mutation.isLoading && props.onBlur?.(event);
      }}
      value={value}
      onChange={event => {
        setValue(event.target.value);
      }}
      onKeyDown={handleOnKeydown}
    />
  );
}

function FormTextField<TForm>(
  props: MDSFormType<TextFieldProps, TForm>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [field, meta] = useField(props.name as string);

  const propsToForward = omit(props, ["name"]);

  return (
    <TextField
      {...propsToForward}
      error={meta.touched && meta.error}
      {...field}
      onChange={e => field.onChange(e)}
      onBlur={e => field.onBlur(e)}
    />
  );
}
