import styles from "./AutomaticSmsScheduleModal.module.css";
import cx from "classnames";
import { AutomaticSmsSchedule } from "api/routes/models";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelTableRow } from "components/utils/drawer";
import { automaticSmsScheduleSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  smsSchedule: AutomaticSmsSchedule;
}

export const SmsSchedule = ({ smsSchedule }: Props) => {
  return (
    <RightPanelTableRow grid={automaticSmsScheduleSectionListConfig.grid}>
      <div className="fw-700">
        {smsSchedule.smsSendDate && smsSchedule.smsSendDate.length > 0
          ? smsSchedule.smsSendDate
          : EMPTY_VALUE}
      </div>
      <div className="fw-700">
        {smsSchedule.smsSendTime && smsSchedule.smsSendTime.length > 0
          ? smsSchedule.smsSendTime
          : EMPTY_VALUE}
      </div>
      <div className="d-flex justify-content-end">{smsSchedule.sentSuccess}</div>
      {smsSchedule.sentErrors === 0 ? (
        <div className="d-flex justify-content-end">{smsSchedule.sentErrors}</div>
      ) : (
        <div className={cx(styles.redLabel, "d-flex justify-content-end")}>
          {smsSchedule.sentErrors}
        </div>
      )}
      <div className="d-flex justify-content-center">
        {smsSchedule.sendStatus === "SENT" ? (
          <StateLabel kind="darkGreen">wysłano</StateLabel>
        ) : smsSchedule.sendStatus === "NOT_SENT" ? (
          <StateLabel kind="default">nie wysłano</StateLabel>
        ) : (
          <StateLabel kind="red">błąd wysyłki</StateLabel>
        )}
      </div>
    </RightPanelTableRow>
  );
};
