import {
  ExecutionStatus,
  ExternalManufacturerLineItemsTab,
} from "api/external-manufacturing/models";
import { GetSearchProps, TabProps } from "api/types";
import { queryString } from "utilities";

const orderLineItemsTabToQueryDict: Record<
  ExternalManufacturerLineItemsTab,
  Record<string, string>
> = {
  [ExternalManufacturerLineItemsTab["to-do"]]: {
    executionStatus: ExecutionStatus.NOT_STARTED,
  },
  [ExternalManufacturerLineItemsTab["in-production"]]: {
    executionStatus: ExecutionStatus.IN_PROGRESS,
  },
  [ExternalManufacturerLineItemsTab["ready-to-deliver"]]: {
    executionStatus: ExecutionStatus.READY_TO_DELIVER,
  },
  [ExternalManufacturerLineItemsTab["picked-up"]]: {
    pickedUpBySeller: "true",
  },
  [ExternalManufacturerLineItemsTab["cancelled"]]: { isCancelled: "true" },
  [ExternalManufacturerLineItemsTab["all"]]: {
    executionStatus: "",
  },
};

const getOrderLineItemsSearch = ({
  query,
  tab,
}: GetSearchProps<ExternalManufacturerLineItemsTab>) => {
  if (tab === ExternalManufacturerLineItemsTab["picked-up"]) {
    return queryString.stringify({
      ...query,
      pickedUpBySeller: orderLineItemsTabToQueryDict["picked-up"].pickedUpBySeller,
      format: "json",
    });
  }

  if (tab === ExternalManufacturerLineItemsTab["cancelled"]) {
    return queryString.stringify({
      ...query,
      isCancelled: orderLineItemsTabToQueryDict["cancelled"].isCancelled,
      format: "json",
    });
  }

  const tabsQuery = {
    executionStatus: orderLineItemsTabToQueryDict[tab].executionStatus,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
    format: "json",
  });
};

const orderLineItemsTabsDict: Record<ExternalManufacturerLineItemsTab, string> = {
  "to-do": "Do zrobienia",
  "in-production": "W produkcji",
  "ready-to-deliver": "Gotowe do odbioru",
  "picked-up": "Odebrane",
  cancelled: "Anulowane",
  all: "Wszystkie",
};

const getOrderLineItemsTabs = (): TabProps => {
  return Object.entries(orderLineItemsTabsDict).map(([name, label]) => ({ label, name }));
};

export const externalManufacturingUtils = {
  getOrderLineItemsSearch,
  getOrderLineItemsTabs,
  orderLineItemsTabsDict,
  orderLineItemsTabToQueryDict,
};
