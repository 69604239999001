import { EmailSentStatus, ExternalManufacturingOrder } from "api/external-manufacturing/models";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CreatedAtLabel } from "pages/externalManufacturing/shared/components/CreatedAtLabel";
import { PriorityLabel } from "pages/externalManufacturing/shared/components/PriorityLabel";
import { dateUtils } from "utilities";
import { EstimatedTimeOfSendingEmail } from "pages/externalManufacturing/shared/components/EstimatedTimeOfSendingEmail";
import { FulFillmentDate } from "pages/externalManufacturing/orders/components/FulfillmentDate";
import { OrderedByAvatars } from "pages/externalManufacturing/orders/components/OrderedByAvatars";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { ProgressCircle } from "components/miloDesignSystem/atoms/progressCircle";
import { ExternalManufacturingOrderStatusTag } from "pages/externalManufacturing/shared/components/ExternalManufacturingOrderStatusTag";
import { EmailSendingStatusTag } from "pages/externalManufacturing/orders/components/EmailSendingStatusTag";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { externalManufacturingActions } from "api/external-manufacturing/actions";

export const useOrdersColumns = () => {
  const sendEmailNotification = externalManufacturingActions.useSendEmailNotification();

  return useCreateTableColumns<ExternalManufacturingOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        typographyProps: {
          fontSize: "14",
        },
        size: 130,
      }),
      columnHelper.accessor(row => row, {
        id: "isCancelled",
        header: " ",
        size: 80,
        cell: info => {
          const order = info.getValue();
          if (!order.isCancelled) return null;
          return <Tag label="anulowane" variant="warning" />;
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        header: "utworzono",
        size: 100,
        cell: info => {
          const createdAt = info.getValue();

          return <CreatedAtLabel createdAt={createdAt} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "producent",
        size: 130,
      }),
      columnHelper.accessor(row => row.priority, {
        header: "priorytet",
        size: 46,
        cell: info => {
          const priority = info.getValue();

          return <PriorityLabel priority={priority} />;
        },
      }),
      columnHelper.accessor(row => row.lineItems.total, {
        id: "numOfLineItemsTotal",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            l. mebli
          </Typography>
        ),
        size: 50,
        cell: info => {
          const total = info.getValue();

          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {total}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "progres realizacji",
        size: 100,
        cell: info => {
          const order = info.getValue();

          return (
            <div className="d-flex align-items-center gap-2">
              <ProgressCircle percentage={order.packagesSummary.progress} size={40} />
              {order.packagesSummary && (
                <div className="d-flex align-items-center">
                  <Typography color="success500" fontSize="14" fontWeight="600">
                    {order.packagesSummary.ready}
                  </Typography>
                  <Typography fontSize="14" fontWeight="600">
                    /{order.packagesSummary.total}
                  </Typography>
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.orderStatus, {
        header: "status",
        size: 110,
        cell: info => {
          const status = info.getValue();

          return <ExternalManufacturingOrderStatusTag status={status} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status email",
        size: 110,
        cell: info => {
          const order = info.getValue();

          return (
            <div className="d-flex align-items-center gap-2">
              <EmailSendingStatusTag status={order.emailSentStatus} />
              {order.emailSentStatus === EmailSentStatus.ERROR && (
                <Tooltip title="Ponów wysyłkę email">
                  <IconButton
                    icon={MdiRestartAlt}
                    isLoading={sendEmailNotification.isLoading}
                    onClick={() =>
                      sendEmailNotification.mutate({
                        orderId: order.id,
                      })
                    }
                    variant="gray"
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.estimatedTimeOfSendingEmail, {
        header: "planowana wysyłka",
        size: 130,
        cell: info => {
          const date = info.getValue();
          return (
            <EstimatedTimeOfSendingEmail
              estimatedTimeOfSendingEmail={date}
              fontSize="12"
              fontWeight="600"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.fulfillmentAt, {
        header: "realizacja",
        size: 130,
        cell: info => {
          const date = info.getValue();
          return <FulFillmentDate fontSize="12" fontWeight="600" fulfillmentDate={date} />;
        },
      }),
      columnHelper.accessor(
        row =>
          row.deliveredToWarehouseAt
            ? dateUtils.formatDateToDisplayOnlyDate(row.deliveredToWarehouseAt)
            : null,
        {
          header: "dostawa do magazynu",
          size: 130,
          cell: info => {
            const deliveredToWarehouseAt = info.getValue();

            return (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="12" fontWeight="600">
                  {deliveredToWarehouseAt ?? EMPTY_VALUE}
                </Typography>
                {deliveredToWarehouseAt && <Tag label="OK" variant="success" />}
              </div>
            );
          },
        },
      ),
      columnHelper.text(
        row =>
          row.expectedWarehouseDeliveryDate
            ? dateUtils.formatDateToDisplayOnlyDate(row.expectedWarehouseDeliveryDate)
            : null,
        {
          header: "data odbioru",
          size: 80,
        },
      ),
      columnHelper.accessor(row => row.orderedBy, {
        header: "zlecono przez",
        size: 130,
        cell: info => {
          const orderedBy = info.getValue();
          return <OrderedByAvatars orderedBy={orderedBy} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const order = info.getValue();

          return (
            <div className="d-flex justify-content-center w-100">
              <FileDownloadHandler
                factoryFn={() =>
                  externalManufacturingFileFactory.externalManufacturingOrderPdf(order)
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <IconButton
                    isLoading={isLoading}
                    icon={MdiQrCode}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="gray"
                  />
                )}
              </FileDownloadHandler>
            </div>
          );
        },
      }),
    ];
  });
};
