import { ISODate, ISOTime, UUID } from "api/types";
import { BaseRamp, Loading, Picking, Unloading } from "../models";
import { Warehouse } from "api/warehouse/models";
import { Order } from "api/orders/models";

enum SearchCollectionKind {
  ROUTE = "ROUTE",
}

export enum CollectionKind {
  ORDER = "ORDER",
  ORDER_GROUP = "ORDER_GROUP",
  PACKAGE = "PACKAGE",
  PRODUCT = "PRODUCT",
  ROUTE = "ROUTE",
}

export interface SearchCollection {
  id: number;
  kind: SearchCollectionKind.ROUTE;
  signature: string;
}

export interface SchemaSearchCollection {
  color: string | null;
  data: string | null;
  id: number;
  kind: CollectionKind;
  name: string;
  ramp: BaseRamp | null;
  time: string | null;
}

export interface SchemaSearchRecentSearch {
  color: string | null;
  date: string | null;
  id: string;
  kind: CollectionKind;
  name: string;
  ramp: BaseRamp | null;
}

export interface SearchCollectionOverview {
  orderGroups: SchemaSearchCollection[];
  orders: SchemaSearchCollection[];
  packages: SchemaSearchCollection[];
  products: SchemaSearchCollection[];
  recentSearch: SchemaSearchRecentSearch[];
  routes: SchemaSearchCollection[];
}

enum Outline {
  VISIBLE = "VISIBLE",
  NOT_VISIBLE = "NOT_VISIBLE",
}

export enum FieldKind {
  RAMP = "RAMP",
  FIELD = "FIELD",
  RACK = "RACK",
  COLUMN = "COLUMN",
}

interface Field {
  id: number | UUID;
  wmsId: number | UUID;
  kind: FieldKind;
  verboseName: string;
  coords: { x: number; y: number };
  width: number;
  height: number;
  customProperties?: {
    transformOrigin?: string;
    transform?: string;
  };
}

export interface Area {
  id: UUID;
  verboseName: string;
  suggestLocations: boolean;
  coords: { x: number; y: number };
  width: number;
  height: number;
  outline: Outline;
  customProperties?: {
    clipPath?: string;
  };
  fields: Field[];
}

export interface LayoutSchema {
  id: number;
  defaultZoom: number;
  verboseName: string;
  position: number;
  areas: Area[];
}

export interface Hall {
  id: number;
  name: string;
  schema: LayoutSchema | null;
  warehouse: LayoutWarehouse;
}

interface LayoutWarehouse extends Warehouse {
  externalId: string;
  isDefault: boolean;
}

export interface CollectionDetails {
  color: string | null;
  data: ISODate | null;
  id: number;
  kind: CollectionKind;
  name: string;
  ramp: BaseRamp | null;
  time: ISOTime | null;
}

export enum RampActivity {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface RampStatus {
  id: UUID;
  name: string;
  activity: RampActivity;
  picking: Pick<Picking, "id" | "signature" | "status" | "startedAt" | "finishedAt" | "progress">;
  unloading: Pick<
    Unloading,
    "id" | "signature" | "status" | "startedAt" | "finishedAt" | "totalProgress"
  >;
  loading: Pick<
    Loading,
    "id" | "signature" | "status" | "car" | "totalProgress" | "startedAt" | "createdAt"
  >;
}

export interface FillingStatus {
  fields: number[];
  racks: number[];
  columns: number[];
  ramps: UUID[];
}

export interface CollectionLayout {
  reference: string;
  fields: number[];
  color: string;
  racks: number[];
  columns: number[];
  ramps: UUID[];
}

export interface FieldDetails {
  area: {
    id: UUID;
    name: string;
    suggestLocations: boolean;
  };
  id: UUID;
  name: string;
  wmsKind: FieldKind;
  packagesSummary: {
    totalWeight: number | null;
    totalPackagesCount: number;
  };
  wmsId: string;
  levels: {
    id: number;
    name: string;
  }[];
}

export interface PackageInLocation {
  code: string;
  id: number;
  order: Pick<Order, "id" | "isSettled" | "type" | "isCanceled" | "signature"> | null;
  package: {
    id: number;
    name: string;
    internalId: string;
    weight: string;
  };
}

export interface AssignField {
  wmsKind: FieldKind;
  wmsId: string | number | null;
  name: string;
}
