import { parse } from "date-fns";
import { pluralize } from "utilities";
import { formatHours } from "./formatHours";
import { formatMinutes } from "./formatMinutes";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const getParsedDuration = (duration: string): string => {
  const matchDays = duration.match(/^\d+\s/);
  const parsedTime = parse(
    matchDays ? duration.slice(matchDays[0].length) : duration,
    "HH:mm:ss.SSSSSS",
    new Date(),
  );
  if (!isNaN(parsedTime.getTime())) {
    if (matchDays) {
      const days = parseInt(matchDays[0], 10);
      return `${days} ${pluralize.pl(days, {
        singular: "dzień",
        plural: "dni",
        other: "dni",
      })}, ${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
    }
    return `${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
  }
  return EMPTY_VALUE;
};
