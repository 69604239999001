import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import cx from "classnames";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import { useSelector } from "hooks";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./ResponsibleForRoute.module.css";

interface Props {
  order: CallCenterRouteOrderListItem;
}

export const ResponsibleForRoute = ({ order }: Props) => {
  const assignResponsibleForRouteMutation = useRoutePatchMutation();
  const me = useSelector(store => store.auth.user!);

  return (
    <div>
      {order.responsible ? (
        <div className="d-flex align-items-center justify-content-between">
          <div className={cx("d-flex align-items-center gap-1", styles.avatarContainer)}>
            <Avatar size="sm" user={order.responsible} />
            <Typography fontSize="12" fontWeight="700" noWrap>
              {order.responsible.firstName} {order.responsible.lastName} dafodafl
            </Typography>
          </div>
          <IconButton
            icon={MdiClose}
            variant="transparent"
            onMouseDown={event => {
              event.stopPropagation();
              assignResponsibleForRouteMutation.mutate({
                id: order.id,
                toUpdate: {
                  responsible: null,
                },
              });
            }}
          />
        </div>
      ) : (
        <Tooltip title="Przydziel do mnie">
          <Avatar
            onClick={event => {
              event.stopPropagation();
              assignResponsibleForRouteMutation.mutate({
                id: order.id,
                toUpdate: {
                  responsible: me,
                },
              });
            }}
            size="sm"
            user={null}
          />
        </Tooltip>
      )}
    </div>
  );
};
