import { customerActions } from "api/customers/actions";
import { useParams } from "react-router";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Title } from "../../components/Title";
import { paymentTypeToTranslationDict } from "CONSTANTS";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { getAnyErrorKey, dateUtils } from "utilities";
import { MdiCopyright } from "components/miloDesignSystem/atoms/icons/MdiCopyright";
import { MdiAccountCircle } from "components/miloDesignSystem/atoms/icons/MdiAccountCircle";
import { MdiCall } from "components/miloDesignSystem/atoms/icons/MdiCall";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { MdiPinDrop } from "components/miloDesignSystem/atoms/icons/MdiPinDrop";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Customer } from "api/customers/models";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const CustomerSummary = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer, isLoading, error } = customerActions.useCustomer(Number(customerId));

  if (isLoading)
    return (
      <Paper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        minWidth="350px"
        maxWidth="500px"
        width="30%"
        minHeight={516}
      >
        <Spinner size={30} />
      </Paper>
    );

  if (error)
    return (
      <Paper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        minWidth="350px"
        maxWidth="500px"
        width="30%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </Paper>
    );

  assertIsDefined(customer);

  return (
    <Paper
      className="mr-3 mb-3 p-3 pb-4 align-self-start"
      width="30%"
      minWidth="350px"
      maxWidth="500px"
    >
      <Typography fontSize="26" fontWeight="700">
        {customer.name}
      </Typography>

      <Typography fontSize="10" fontWeight="400" className="mb-4">
        dodano: {dateUtils.formatDateToDisplayOnlyDate(customer.created)}
      </Typography>
      <Title className="mb-2">Dane do faktur i oficjalnej korespondencji</Title>
      <div className="d-flex align-items-center mb-2">
        <MdiCopyright className="mr-2" />

        <Typography fontSize="16" fontWeight="600">
          {customer.fullName || EMPTY_VALUE}
        </Typography>
      </div>
      <div className="d-flex align-items-center mb-2">
        <MdiAccountCircle className="mr-2" />

        <Typography fontSize="16" fontWeight="600">
          {!customer.ownerFirstName && !customer.ownerLastName && EMPTY_VALUE}
          {customer.ownerFirstName} {customer.ownerLastName}
        </Typography>
      </div>
      <div className="d-flex align-items-center mb-2">
        <MdiCall className="mr-2" />

        <Typography fontSize="16" fontWeight="600">
          {customer.phone}
        </Typography>
      </div>
      <div className="d-flex align-items-center mb-2">
        <MdiMail className="mr-2" />

        <Typography fontSize="16" fontWeight="600">
          {customer.email || EMPTY_VALUE}
        </Typography>
      </div>
      <div className="d-flex align-items-start mb-2">
        <MdiPinDrop className="mr-2" />

        <div>
          <Typography fontSize="16" fontWeight="600">
            {customer.street}
          </Typography>

          <Typography fontSize="16" fontWeight="600">
            {customer.postCode} {customer.city}
          </Typography>
          <Typography fontSize="16" fontWeight="600">
            {customer.countryCode}
          </Typography>
        </div>
      </div>
      <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          REGON
        </Title>
        <Typography fontSize="16" fontWeight="400">
          {customer.businessRegisterNumber || EMPTY_VALUE}
        </Typography>
      </div>
      <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          NIP
        </Title>
        <Typography fontSize="16" fontWeight="400">
          {customer?.taxId}
        </Typography>
      </div>
      {/* <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          Bank
        </Title>
        <Typography fontSize="16" fontWeight="400">
          ---
        </Typography>
      </div> */}
      {/* <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          Nr konta bankowego
        </Title>
        <Typography fontSize="16" fontWeight="400">
          ---
        </Typography>
      </div> */}
      <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          Waluta transakcji
        </Title>
        <div className="d-flex align-items-center gap-1 ">
          <CountryFlag currency={customer.defaultCurrency} />
          <Tag label="domyślna" variant="deepPurple50" />
        </div>
      </div>
      <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          Metoda płatności
        </Title>
        <div className="d-flex align-items-center gap-1 ">
          <Typography fontSize="16" fontWeight="400">
            {paymentTypeToTranslationDict[customer.defaultPaymentType]}
          </Typography>
          <Tag label="domyślna" variant="deepPurple50" />
        </div>
      </div>
      {/* <div className="ml-4 mb-2">
        <Title fontWeight="600" className="mb-0">
          Termin płatności
        </Title>
        <Typography fontSize="16" fontWeight="400">
          ---
        </Typography>
      </div> */}
      {customer.recurringInvoiceCreationStrategy !== "NONE" && (
        <div className="d-flex align-items-center ml-4 gap-1">
          <Tag label="faktury wystawiają się automatycznie" variant="success" />
          <Typography fontSize="12" fontWeight="400">
            {getInvoiceLabel(customer)}
          </Typography>
        </div>
      )}
    </Paper>
  );
};

function getInvoiceLabel(customer: Customer) {
  if (customer.recurringInvoiceCreationStrategy === "EVERY_X_DAYS") {
    if (!customer.invoiceCreationEveryXDays) return EMPTY_VALUE;

    return customer.invoiceCreationEveryXDays <= 1
      ? "codziennie"
      : `co ${customer.invoiceCreationEveryXDays} dni`;
  }
  return `${customer.invoiceCreationDay} dnia miesiąca`;
}
