import { FabricAttributeKind } from "api/attributes/models";
import { Product } from "api/products/models";

function findByArrayOfValues(attribute: Product["attributes"][number], arrayOfIds: number[]) {
  if (attribute.kind !== FabricAttributeKind.FABRIC) {
    return attribute.values.find(val => arrayOfIds.includes(val.id));
  }
  return attribute.categories.reduce(
    (
      acc: {
        id: number;
        picture: string | null;
        isAssignableToIndex: boolean;
        name: string;
      } | null,
      cat,
    ) => {
      const value = cat.values.find(val => arrayOfIds.includes(val.id));
      if (value) {
        acc = value;
      }
      return acc;
    },
    null,
  );
}

export const findSelectedAttributeValue = {
  byArrayOfValues: findByArrayOfValues,
};
