import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./StatusInExternalServiceSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ExternalServiceSendingOrder } from "./components/externalServiceSendingOrder/ExternalServiceSendingOrder";
import { ExternalServiceReceiving } from "./components/externalServiceReceiving/ExternalServiceReceiving";
import { ExternalServiceSending } from "./components/externalServiceSending/ExternalServiceSending";

interface Props {
  tradingDocument: TradingDocument;
}

export const StatusInExternalServiceSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Status faktury w serwisie zewnętrznym">
      <div className="d-flex align-items-center gap-2 px-2">
        <div className={styles.label} />
        <div className={styles.status}>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
            status
          </Typography>
        </div>
        <div className={styles.service}>
          <Typography
            className="w-100 text-right"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            system
          </Typography>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.progressLine}>
          <div>
            <ExternalServiceSendingOrder tradingDocument={tradingDocument} />
            <ExternalServiceSending tradingDocument={tradingDocument} />
            <ExternalServiceReceiving tradingDocument={tradingDocument} />
          </div>
        </div>
      </div>
    </RightPanelSection>
  );
};
