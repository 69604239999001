import { Unloading } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { formatRelative } from "date-fns";
import { pl } from "date-fns/esm/locale";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  unloading: Unloading;
}

const formatRelativeLocale = {
  yesterday: "'wczoraj', H:mm",
  today: "'dzisiaj', H:mm",
  tomorrow: "'jutro', H:mm",
  other: "dd.MM.yyyy, H:mm",
};

export const UnloadingEstimatedTimeOfArrival = ({ unloading }: Props) => {
  return (
    <Typography fontSize="12" fontWeight="700">
      {unloading.estimatedTimeOfArrival
        ? formatRelative(new Date(unloading.estimatedTimeOfArrival), new Date(), {
            locale: {
              ...pl,
              formatRelative: (token: "yesterday" | "today" | "tomorrow" | "other") =>
                formatRelativeLocale[token],
            },
          })
        : EMPTY_VALUE}
    </Typography>
  );
};
