import { createApiQuery } from "hooks/createApiQuery";
import { productSetApi } from "./api";

const useGetCustomerElements = createApiQuery(productSetApi.getCustomerElements);
const useGetCustomerElementsB2b = createApiQuery(productSetApi.getCustomerElementsB2b);

export const productSetActions = {
  useGetCustomerElements,
  useGetCustomerElementsB2b,
};
