import { CURRENCY_TYPE, CountryCode } from "CONSTANTS";
import { Seller } from "api/sellers/models";
import { TradingDocument, TradingDocumentCustomer } from "api/trading-documents/models";
import { ISODate, ISODateTime, UUID } from "api/types";
import { User } from "api/users/models";
import { Assign } from "utility-types";

export enum FinancesPaymentKind {
  ADVANCE = "ADVANCE",
  OTHER = "OTHER",
  PAYMENT = "PAYMENT",
  REFUND = "REFUND",
}

export enum FinancesPaymentMode {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum FinancesPaymentType {
  CASH = "CASH",
  ONLINE = "ONLINE",
}

export interface FinancesPaymentListItem {
  amount: string;
  bankTransaction: null;
  bankTransactionDate: ISODateTime | null;
  bankTransactionNumber: string;
  buyer: Pick<Seller, "companyName" | "firstName" | "lastName" | "taxId"> | null;
  commission: number | null;
  countryCode: CountryCode;
  createdAt: ISODateTime;
  createdBy: number;
  currency: CURRENCY_TYPE;
  customer: TradingDocumentCustomer | null;
  description: string;
  id: UUID;
  kind: FinancesPaymentKind;
  mode: FinancesPaymentMode;
  note: string;
  orderBankTransaction: null;
  paymentDate: ISODate;
  paymentNumber: string;
  provider: string;
  relatedTradingDocument: Pick<TradingDocument, "id" | "signature"> | null;
  seller: Pick<Seller, "companyName" | "firstName" | "id" | "lastName"> | null;
  signature: string;
  tradingDocument: UUID;
  type: FinancesPaymentType;
}

export type AddFinancesPayment = Assign<
  Pick<
    FinancesPaymentListItem,
    | "amount"
    | "bankTransactionDate"
    | "bankTransactionNumber"
    | "commission"
    | "createdAt"
    | "currency"
    | "kind"
    | "paymentDate"
    | "paymentNumber"
    | "provider"
    | "type"
  >,
  { createdBy: User; issuerId: Seller["id"] }
>;
