import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import styles from "../../CreateDraftOrder.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";

export interface IndexesAndPackagesFilters {
  demandsGreaterThanState: "true" | "";
  page: number;
  search: string;
  indexStateLowerThen: number | "MIN" | "MAX" | "SAFE" | "";
  storedLte: number | "";
  packagesAvailableStatus: "BELOW" | "MIN" | "SAFE" | "MAX" | "ZERO" | "";
}

interface Props {
  filters: IndexesAndPackagesFilters;
  setFilter: <T extends keyof IndexesAndPackagesFilters, U extends IndexesAndPackagesFilters[T]>(
    name: T,
    value: U,
  ) => void;
  sourceKind: DraftOrderLineItemSourceKind;
}

export const FiltersSection = ({ filters, setFilter, sourceKind }: Props) => {
  return (
    <div className="d-flex align-items-center gap-3 py-2 px-3">
      <TextField
        containerClassName="w-100"
        endIcon={<MdiSearch color="neutralBlack48" size="18" />}
        onChange={event => setFilter("search", event.target.value)}
        placeholder="Szukaj..."
        value={filters.search ?? ""}
      />
      <div className="mt-3">
        <Checkbox
          checked={filters.demandsGreaterThanState === "true"}
          label="Zapotrzebowanie większe niż stan"
          onChange={() => {
            if (filters.demandsGreaterThanState) {
              return setFilter("demandsGreaterThanState", "");
            }
            return setFilter("demandsGreaterThanState", "true");
          }}
          size="sm"
        />
      </div>
      {sourceKind === DraftOrderLineItemSourceKind.INDEX && (
        <IndexesFilters filters={filters} setFilter={setFilter} />
      )}
      {sourceKind === DraftOrderLineItemSourceKind.PACKAGE && (
        <PackagesFilters filters={filters} setFilter={setFilter} />
      )}
    </div>
  );
};

const IndexesFilters = ({ filters, setFilter }: Pick<Props, "filters" | "setFilter">) => {
  const resetPage = () => setFilter("page", 1);

  return (
    <div className={styles.stateFilter}>
      <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
        Stan:
      </Typography>
      <div className="d-flex flex-1 gap-1 flex-nowrap">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.indexStateLowerThen === "MIN") setFilter("indexStateLowerThen", "");
            else setFilter("indexStateLowerThen", "MIN");
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej minimum"
            startIcon={
              filters.indexStateLowerThen === "MIN"
                ? MdiRadioButtonChecked
                : MdiRadioButtonUnchecked
            }
            type={filters.indexStateLowerThen === "MIN" ? "filled" : "outlined"}
            variant={filters.indexStateLowerThen === "MIN" ? "deepPurple50" : "quaternary"}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.indexStateLowerThen === 10) setFilter("indexStateLowerThen", "");
            else setFilter("indexStateLowerThen", 10);
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej 10 szt."
            startIcon={
              filters.indexStateLowerThen === 10 ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
            }
            type={filters.indexStateLowerThen === 10 ? "filled" : "outlined"}
            variant={filters.indexStateLowerThen === 10 ? "deepPurple50" : "quaternary"}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.indexStateLowerThen === 15) setFilter("indexStateLowerThen", "");
            else setFilter("indexStateLowerThen", 15);
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej 15 szt."
            startIcon={
              filters.indexStateLowerThen === 15 ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
            }
            type={filters.indexStateLowerThen === 15 ? "filled" : "outlined"}
            variant={filters.indexStateLowerThen === 15 ? "deepPurple50" : "quaternary"}
          />
        </div>
      </div>
    </div>
  );
};

const PackagesFilters = ({ filters, setFilter }: Pick<Props, "filters" | "setFilter">) => {
  const resetPage = () => setFilter("page", 1);

  return (
    <div className={styles.stateFilter}>
      <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
        Stan:
      </Typography>
      <div className="d-flex flex-1 gap-1 flex-nowrap">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.packagesAvailableStatus === "MIN") {
              setFilter("packagesAvailableStatus", "");
              setFilter("storedLte", "");
            } else {
              setFilter("packagesAvailableStatus", "MIN");
              setFilter("storedLte", "");
            }
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej minimum"
            startIcon={
              filters.packagesAvailableStatus === "MIN"
                ? MdiRadioButtonChecked
                : MdiRadioButtonUnchecked
            }
            type={filters.packagesAvailableStatus === "MIN" ? "filled" : "outlined"}
            variant={filters.packagesAvailableStatus === "MIN" ? "deepPurple50" : "quaternary"}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.storedLte === 10) {
              setFilter("storedLte", "");
              setFilter("packagesAvailableStatus", "");
            } else {
              setFilter("storedLte", 10);
              setFilter("packagesAvailableStatus", "");
            }
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej 10 szt."
            startIcon={filters.storedLte === 10 ? MdiRadioButtonChecked : MdiRadioButtonUnchecked}
            type={filters.storedLte === 10 ? "filled" : "outlined"}
            variant={filters.storedLte === 10 ? "deepPurple50" : "quaternary"}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (filters.storedLte === 15) {
              setFilter("storedLte", "");
              setFilter("packagesAvailableStatus", "");
            } else {
              setFilter("storedLte", 15);
              setFilter("packagesAvailableStatus", "");
            }
            return resetPage();
          }}
        >
          <Tag
            label="Poniżej 15 szt."
            startIcon={filters.storedLte === 15 ? MdiRadioButtonChecked : MdiRadioButtonUnchecked}
            type={filters.storedLte === 15 ? "filled" : "outlined"}
            variant={filters.storedLte === 15 ? "deepPurple50" : "quaternary"}
          />
        </div>
      </div>
    </div>
  );
};
