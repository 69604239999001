import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiData = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M3 23C2.45 23 1.97917 22.8042 1.5875 22.4125C1.19583 22.0208 1 21.55 1 21V3C1 2.45 1.19583 1.97917 1.5875 1.5875C1.97917 1.19583 2.45 1 3 1H16L23 7V21C23 21.55 22.8042 22.0208 22.4125 22.4125C22.0208 22.8042 21.55 23 21 23H3ZM15 8V3H3V21H21V8H15Z" />
    <path d="M7.82068 10V14.9031H6.68174L6.56866 14.2811C6.46096 14.4911 6.3021 14.6581 6.09208 14.7819C5.88207 14.9058 5.64513 14.9677 5.38126 14.9677C4.96123 14.9677 4.62466 14.8169 4.37157 14.5153C4.12386 14.2138 4 13.7614 4 13.1583C4 12.7006 4.05385 12.3236 4.16155 12.0275C4.26925 11.7313 4.3958 11.5186 4.5412 11.3893C4.63274 11.3086 4.81852 11.2412 5.09855 11.1874C5.38395 11.1335 5.63705 11.1066 5.85784 11.1066C6.0517 11.1066 6.25094 11.1255 6.45557 11.1632C6.43942 10.9047 6.43134 10.762 6.43134 10.7351V10.0565L7.82068 10ZM5.98708 13.8853C6.13247 13.8853 6.28325 13.8611 6.43942 13.8126V12.0598C6.28325 12.0382 6.11093 12.0275 5.92246 12.0275C5.7609 12.0275 5.65859 12.0409 5.61551 12.0679C5.55627 12.1109 5.50781 12.2348 5.47011 12.4394C5.4378 12.6387 5.42165 12.8487 5.42165 13.0695C5.42165 13.398 5.47011 13.616 5.56704 13.7237C5.66936 13.8314 5.80937 13.8853 5.98708 13.8853Z" />
    <path d="M10.6966 14.9031L10.5674 14.3942C10.3196 14.7981 9.97501 15 9.53343 15C9.17264 15 8.87646 14.9004 8.6449 14.7011C8.41873 14.4965 8.30565 14.2111 8.30565 13.8449C8.30565 13.6187 8.37027 13.4222 8.49951 13.2553C8.63413 13.0829 8.81722 12.9618 9.04878 12.8918C9.35034 12.8056 9.81615 12.7571 10.4462 12.7464C10.4462 12.391 10.43 12.2025 10.3977 12.1809C10.3708 12.1594 10.2846 12.1486 10.1392 12.1486C10.0477 12.1486 9.92116 12.154 9.7596 12.1648C9.50112 12.1756 9.1511 12.2052 8.70952 12.2536C8.66644 11.9628 8.63413 11.6505 8.61259 11.3166C8.898 11.2574 9.19687 11.2089 9.5092 11.1712C9.82153 11.1282 10.1016 11.1066 10.3493 11.1066C10.7801 11.1066 11.1032 11.1793 11.3186 11.3247C11.534 11.4647 11.674 11.6505 11.7386 11.8821C11.8032 12.1136 11.8328 12.4071 11.8275 12.7625L11.8194 13.9499L11.8436 14.9031H10.6966ZM10.0019 14.0953C10.1419 14.0953 10.29 14.0684 10.4462 14.0145V13.4168H10.3816C9.98308 13.4168 9.7623 13.4383 9.71922 13.4814C9.69229 13.5083 9.67075 13.5487 9.6546 13.6026C9.64383 13.6564 9.63844 13.7076 9.63844 13.7561C9.63844 13.8853 9.66806 13.9742 9.72729 14.0226C9.78653 14.0711 9.87808 14.0953 10.0019 14.0953Z" />
    <path d="M16.0456 11.4216C16.0941 11.4809 16.1291 11.629 16.1506 11.8659C16.1776 12.1029 16.191 12.4637 16.191 12.9483V14.9031H14.8098V12.6333C14.8098 12.461 14.7963 12.3533 14.7694 12.3102C14.7263 12.2563 14.6267 12.2294 14.4705 12.2294C14.3951 12.2294 14.3386 12.2321 14.3009 12.2375C14.1501 12.2429 13.9939 12.2779 13.8324 12.3425V14.9031H12.4511V11.1874H13.5739L13.7274 11.8094C14.1151 11.3409 14.5782 11.1066 15.1167 11.1066C15.3106 11.1066 15.491 11.1335 15.6579 11.1874C15.8302 11.2412 15.9595 11.3193 16.0456 11.4216Z" />
    <path d="M19.0827 13.9338C19.2174 13.9338 19.4678 13.9203 19.8339 13.8934C19.8824 13.8934 19.9713 13.888 20.1005 13.8772L20.2217 14.7092C19.807 14.9031 19.2685 15 18.6062 15C17.9869 15 17.5103 14.8438 17.1764 14.5315C16.8479 14.2192 16.6837 13.748 16.6837 13.1179C16.6837 12.7948 16.7241 12.4825 16.8049 12.1809C16.891 11.8794 16.988 11.6694 17.0957 11.5509C17.2087 11.4324 17.4188 11.3301 17.7257 11.2439C18.0326 11.1578 18.3369 11.1147 18.6385 11.1147C19.1231 11.1147 19.4866 11.2009 19.7289 11.3732C19.9713 11.5455 20.1301 11.7851 20.2055 12.0921C20.2809 12.3936 20.3186 12.8002 20.3186 13.3118C19.9524 13.3333 19.5943 13.3441 19.2443 13.3441H18.0973C18.0973 13.5164 18.1323 13.6457 18.2023 13.7318C18.2777 13.818 18.3827 13.8745 18.5173 13.9015C18.6519 13.923 18.8404 13.9338 19.0827 13.9338ZM18.178 12.0759C18.1457 12.0921 18.1215 12.1621 18.1053 12.2859C18.0892 12.4098 18.0784 12.5498 18.073 12.706H18.4931C18.7462 12.706 18.9293 12.7033 19.0423 12.6979C19.0423 12.4394 19.0046 12.2563 18.9293 12.1486C18.8593 12.0409 18.7354 11.9871 18.5577 11.9871C18.4123 11.9871 18.2857 12.0167 18.178 12.0759Z" />
  </SvgWrapper>
);
