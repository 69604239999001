import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { FullDelivery } from "./models";
import { PartialOf } from "typeUtilities";
import { queryFetch } from "apiConnectors/queryFetch";
import { OrderForRoute } from "api/orders/models";
import { CountryCode } from "CONSTANTS";

const api = tokenRefreshMiddleware(apiFetch);

export const patchDelivery = (id: string | number, data: Partial<FullDelivery>) =>
  api<FullDelivery>({
    method: "PATCH",
    url: "/deliveries/items/" + id,
    data,
  });

export const getDelivery = (id: string | number) =>
  api<FullDelivery>({
    method: "GET",
    url: "/deliveries/items/" + id,
  });

export const patchDeliveryQuery = (
  id: string | number,
  data: PartialOf<OrderForRoute["delivery"]>,
) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/deliveries/items/" + id,
    data,
  });

export const patchDeliveryAddress = (
  id: string | number,
  data: {
    street: string;
    postCode: string;
    city: string;
    point: { lat: number; lng: number } | null;
    countryCode: CountryCode;
  },
) =>
  api<{
    geolocation: {
      address: string;
      city: string;
      postCode: string;
      formattedAddress: string;
      point: { lat: number; lng: number };
    };
    isValid: boolean;
    geocodingService: string;
  }>({
    method: "PATCH",
    url: "/deliveries/items/" + id + "/address",
    data,
  });
