import { ExternalManufacturingOrder, OrderStatus } from "api/external-manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  status: ExternalManufacturingOrder["orderStatus"];
}

export const ExternalManufacturingOrderStatusTag = ({ status }: Props) => {
  switch (status) {
    case OrderStatus.IN_STOCK:
      return <Tag label="przyjęto na magazyn" variant="success" />;
    case OrderStatus.NOT_SENT:
      return <Tag label="nie zlecono" variant="warning" />;
    case OrderStatus.SENT:
      return <Tag label="zlecono" variant="info" />;
    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled external manufacturing order status: ${exhaustiveCheck}`);
      return <EmptyValue />;
  }
};
