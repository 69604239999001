import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ToggleHookState, useSettings, useToggle } from "hooks";
import styles from "pages/routes/shared/pdfPopover/PdfPopover.module.css";
import viewPdfIcon from "assets/images/161.svg";
import { Formik } from "formik";
import { cx, queryString } from "utilities";
import { Order } from "api/orders/models";
import { Assign } from "utility-types";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useOrderColumns } from "./useOrderColumns";
import modalStyles from "./ViewOrdersWithWeightsModal.module.css";
import { Summary, useOrderSummaryColumns } from "./useOrderSummaryColumns";

export interface NormalizedOrder {
  id: Order["id"];
  weight: number;
  signature: Order["signature"];
  buyer: string;
  address: string;
}

interface Props {
  url: string;
  label: string;
  orders: NormalizedOrder[];
}

export const ViewOrdersWithWeightsModal = ({ url, label, orders }: Props) => {
  const modalController = useToggle();

  const { transport } = useSettings();

  if (!transport.useCustomWeightInOrdersWithWeightPdf) {
    return (
      <a className={styles.pdf} rel="noopener noreferrer" href={url} target="_blank">
        <img alt="" src={viewPdfIcon} />
        <span>{label}</span>
      </a>
    );
  }

  return (
    <div>
      <div className={styles.pdf} onClick={modalController.open}>
        <img alt="" src={viewPdfIcon} />
        <span>{label}</span>
      </div>
      {modalController.isOpen && (
        <OrdersWithWeightsModal orders={orders} url={url} modalController={modalController} />
      )}
    </div>
  );
};

type FormValues = Pick<Props, "orders">;

const OrdersWithWeightsModal = ({
  modalController,
  url,
  orders,
}: Assign<
  Omit<Props, "label">,
  {
    modalController: ToggleHookState;
  }
>) => {
  const handleSubmit = (values: FormValues) => {
    const searchDict = values.orders.reduce((acc, order) => {
      Object.assign(acc, {
        [`customWeight${order.id}`]: order.weight,
      });
      return acc;
    }, {});
    const urlQueryParams = queryString.parse(url);
    const search = queryString.stringify({ ...searchDict, ...urlQueryParams });
    const Url = new URL(url);
    window.open(`${Url.origin}${Url.pathname}${search}`, "_blank");
  };

  const initialValues: FormValues = {
    orders,
  };

  const columns = useOrderColumns();
  const summaryColumns = useOrderSummaryColumns();
  const tableProps = useTableFeatureConnector({
    rows: orders,
  });

  return (
    <Modal
      close={modalController.close}
      isOpen
      width={940}
      title={
        <Typography fontSize="20" fontWeight="700">
          Waga na dokumencie
        </Typography>
      }
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isValid, values }) => {
          return (
            <form
              className={cx({ "was-validated": !isValid }, modalStyles.modal)}
              onSubmit={handleSubmit}
            >
              <div className="d-flex flex-1 overflow-hidden justify-content-between px-3 pt-3 pb-0">
                <Table<NormalizedOrder>
                  columns={columns}
                  error={null}
                  isLoading={false}
                  uiSchema={{
                    cell: { height: "36" },
                    header: { backgroundColor: "neutralWhite100" },
                  }}
                  {...tableProps}
                  overrides={() => ({
                    ...tableProps.overrides,
                    header: { className: modalStyles.tableHeader },
                    row: row => {
                      const isLastOrder = row.id === orders[orders.length - 1].id;
                      return {
                        className: cx(
                          { "border-bottom-0": isLastOrder },
                          tableProps.overrides?.().row?.(row).className,
                        ),
                      };
                    },
                  })}
                />
              </div>
              <div className="mx-3 pt-1 border-top">
                <Table<Summary>
                  rows={[
                    {
                      id: 1,
                      weight: values.orders
                        .reduce((acc, order) => acc + order.weight, 0)
                        .toFixed(2),
                    },
                  ]}
                  columns={summaryColumns}
                  error={null}
                  isLoading={false}
                  uiSchema={{
                    cell: { height: "36" },
                    header: { backgroundColor: "neutralWhite100" },
                  }}
                  overrides={() => ({
                    hideHeader: true,
                  })}
                />
              </div>

              <div className="d-flex align-items-center gap-2 p-3">
                <Button
                  className="text-uppercase"
                  onClick={modalController.close}
                  size="medium"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button className="text-uppercase" size="medium" type="submit" variant="deepPurple">
                  Pobierz PDF
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
