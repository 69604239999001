import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { ClickOutsideHandler, Toggle } from "components/utils";
import { FilterDropdown as Filter } from "./filterDropdown";
import { FilterDropdown as FilterRange } from "./filterDropdown/FilterDropdownRange";
import { usePrevious, useQuery, useToggle } from "hooks";
import { Button } from "components/common";
import arrowIcon from "assets/images/18.svg";
import closeIcon from "assets/images/11.svg";
import styles from "./Toolbar.module.css";
import cx from "classnames";
import { Assign } from "utility-types";
import { SearchField } from "components/utils/searchField/SearchField";
import { FiltersDrawer } from "components/common/filtersDrawer/FiltersDrawer";
import cuid from "cuid";
import {
  ToolbarStringFilter,
  ToolbarRangeFilter,
  ToolbarSearchFilter,
  ToolbarDateFilter,
  ToolbarSearchMultipleAttributeFilter,
  ToolbarSelectWithProductsFilter,
  ToolbarExcludeWithProductsWithProductsFilter,
  ToolbarSearchMultipleFilter,
  ToolbarAdvancedDateFilter,
  ToolbarSearchSelectFilter,
  ToolbarTextFilter,
} from "./types";
import { FilterDropdownSearchSelect } from "./filterDropdown/FilterDropdownSearchSelect";
import {
  FilterDrawerRenderer,
  outsideClickIgnoreClassFilterDrawer,
} from "../moduleNavigation/components/filtersSection/FilterDrawerRenderer";
import { useFilterContext } from "components/utils/withFilters";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiTune } from "components/miloDesignSystem/atoms/icons/MdiTune";
import { useDrawer } from "hooks/useDrawer";

/**
 * TYPES
 */

type FilterType = "signature" | "client" | "search" | "productName" | "multipleNameFinder";
// this type is not really necessary, but it helps to avoid errors when adding new filter types
type NewQueryModel = Assign<{ [K in FilterType]: string | number }, { page: number }>;

export interface Props {
  overrides?: { optionItem?: { className?: string }; toolbar?: { className?: string } };
  searchInput?: boolean;
  searchVariants?: FilterType[];
  filters?: (ToolbarStringFilter | ToolbarRangeFilter | ToolbarSearchSelectFilter)[];
  filtersBtn?: React.ReactNode;
  drawerFilters?: (
    | ToolbarStringFilter
    | ToolbarSearchFilter
    | ToolbarDateFilter
    | ToolbarSelectWithProductsFilter
    | ToolbarExcludeWithProductsWithProductsFilter
    | ToolbarSearchMultipleAttributeFilter
    | ToolbarSearchMultipleFilter
    | ToolbarTextFilter
    | ToolbarAdvancedDateFilter
  )[];
}

/**
 * CODE
 */

export const clickOutsideIgnoreClassToolbar = "ignore-outside-click-" + cuid();

const filterDictionary = {
  signature: "sygnatura",
  multipleNameFinder: "wiele sygnatur",
  client: "klient",
  productName: "produkt",
  search: "wszystko",
};

const propsAreEqual = (prev: Props, next: Props) => {
  const areEqual = prev.filtersBtn === next.filtersBtn && prev.filters === next.filters;

  return areEqual;
};

export const Toolbar = memo(
  ({
    searchVariants = [],
    filters = [],
    searchInput = true,
    filtersBtn = null,
    drawerFilters,
    overrides = {},
  }: Props) => {
    const { query, setQuery } = useQuery();
    const { close } = useDrawer("accountancyAccountsList");

    const filterContext = useFilterContext();
    function getInitialState() {
      const initialQueryParameterFromUrl = Object.entries(query).find(
        ([key, val]: [any, string]) => searchVariants.includes(key) && val,
      );
      const initialState = initialQueryParameterFromUrl?.[0] ?? searchVariants[0] ?? "search";
      return initialState as FilterType;
    }

    const [filterType, setFilterType] = useState<FilterType>(getInitialState);
    const filtersControls = useToggle();

    const initialMount = useRef(true);
    const previousFilterType = usePrevious(filterType);
    const search = query[filterType] || query[previousFilterType] || "";
    /**
     * queryRef is necessary to get actual query object in updateSearch function,
     * which is used for throttling / debouncing.
     * If you want to know how it works, learn about closures, lexical scope and the difference
     * between state and ref in react.
     */
    const queryRef = useRef(query);
    const filterTypeRef = useRef(filterType);

    const currentDrawerFilters = useMemo(() => {
      const validatedDrawerFilters = drawerFilters ? drawerFilters : [];
      return [
        ...validatedDrawerFilters,
        { name: "isCollectionDateConfirmed" },
        { name: "selectWithProducts" },
      ]
        .map(filter => {
          return query[filter.name];
        })
        .filter(Boolean);
    }, [query, drawerFilters]);

    useEffect(() => {
      queryRef.current = query;
    }, [query]);

    useEffect(() => {
      filterTypeRef.current = filterType;
    }, [filterType]);

    useEffect(() => {
      if (initialMount.current) return;
      const newQuery: NewQueryModel = {
        ...queryRef.current,
        multipleNameFinder: "",
        signature: "",
        client: "",
        search: "",
        productName: "",
        page: 1,
      };
      if (previousFilterType) {
        newQuery[filterType] = queryRef.current[previousFilterType];
      }
      setQuery(newQuery);
    }, [filterType, setQuery, previousFilterType]);

    const updateSearch = useCallback(
      (searchValue: string) => {
        const newQuery: NewQueryModel = {
          ...queryRef.current,
          multipleNameFinder: "",
          signature: "",
          client: "",
          search: "",
          productName: "",
          [filterTypeRef.current]: searchValue,
          page: 1,
        };
        setQuery(newQuery);
      },
      [setQuery],
    );

    useEffect(() => {
      initialMount.current = false;
    }, []);

    return (
      <div className={cx(styles.toolbar, overrides.toolbar?.className)}>
        <div className="d-flex align-items-center">
          {searchInput && <SearchField value={search} onUpdate={updateSearch} />}
          {Boolean(searchVariants.length) && (
            <Toggle initialValue={false}>
              {({ isOpen, toggle, close }) => (
                <div className={styles.searchOptionsBox}>
                  <strong>{filterDictionary[filterType]}</strong>
                  <Button
                    kind="secondary"
                    size="round-s"
                    className={`ml-1 mr-1 ${clickOutsideIgnoreClassToolbar}`}
                    onClick={toggle}
                  >
                    <img src={arrowIcon} alt="Lista opcji" />
                  </Button>

                  <ClickOutsideHandler
                    onClickOutside={close}
                    outsideClickIgnoreClass={clickOutsideIgnoreClassToolbar}
                  >
                    <div
                      className={styles.optionsList}
                      role="menu"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      {searchVariants.map(searchVariant => (
                        <button
                          key={searchVariant}
                          className={cx(styles.optionsItem, {
                            active: filterType === searchVariant,
                          })}
                          onClick={() => {
                            setFilterType(searchVariant);
                            close();
                          }}
                        >
                          <strong>{filterDictionary[searchVariant]}</strong>
                        </button>
                      ))}
                    </div>
                  </ClickOutsideHandler>
                </div>
              )}
            </Toggle>
          )}
        </div>
        <div className={styles.filterGroup}>
          {filtersBtn}

          {filters.map((filter, index) => {
            if (filter.type === "string") {
              return (
                <Filter
                  key={index}
                  customValue={filter.customValue}
                  name={filter.name}
                  label={filter.label}
                  options={filter.options}
                  defaultValue={filter.default}
                  keepTextFormatting={filter.keepTextFormatting}
                />
              );
            } else if (filter.type === "searchSelect") {
              return (
                <FilterDropdownSearchSelect
                  key={index}
                  name={filter.name}
                  label={filter.label}
                  options={filter.options}
                  defaultValue={filter.default}
                  placeholder={filter.placeholder}
                />
              );
            } else if (filter.type === "range") {
              return (
                <FilterRange
                  key={index}
                  disabled={filter.disabled}
                  name={filter.range}
                  label={filter.label}
                  options={filter.options}
                  defaultValue={filter.default}
                  rangeType={filter.rangeType}
                />
              );
            } else {
              return null;
            }
          })}
          {drawerFilters && currentDrawerFilters.length !== 0 && (
            <span className={styles.drawerFiltersCount}>+ {currentDrawerFilters.length}</span>
          )}
          {drawerFilters && (
            <>
              <Button
                className={clickOutsideIgnoreClassToolbar}
                kind="secondary-grey"
                size="rounded"
                onClick={filtersControls.toggle}
              >
                {filtersControls.isOpen ? (
                  <img className={styles.closeIcon} src={closeIcon} alt="close Icon" />
                ) : (
                  <div className={styles.menuIcon}>...</div>
                )}
              </Button>
              {filtersControls.isOpen && (
                <ClickOutsideHandler
                  onClickOutside={filtersControls.close}
                  outsideClickIgnoreClass={clickOutsideIgnoreClassToolbar}
                >
                  <FiltersDrawer
                    filters={drawerFilters}
                    filtersDrawerOpen={filtersControls.isOpen}
                  />
                </ClickOutsideHandler>
              )}
            </>
          )}
          {Boolean(filterContext?.filters.length) && filterContext?.appliedFilters.length !== 0 && (
            <span className={styles.drawerFiltersCount}>
              + {filterContext?.appliedFilters.length}
            </span>
          )}
          {Boolean(filterContext?.filters.length) && (
            <IconButton
              className={cx(outsideClickIgnoreClassFilterDrawer, "mr-1")}
              icon={MdiTune}
              variant="gray"
              onClick={() => {
                filterContext.toggleFilterDrawer();
                close();
              }}
            />
          )}
          <FilterDrawerRenderer />
        </div>
      </div>
    );
  },
  propsAreEqual,
);
