import { WarehousePackagesInOrOutSummary } from "api/trading-documents/models";
import styles from "../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { LinkToPage } from "components/utils/linkToPage";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  packageIn: WarehousePackagesInOrOutSummary;
  togglePanel: (id: string) => void;
}

export const PackageInRow = ({ index, isActive, isHighlighted, packageIn, togglePanel }: Props) => {
  const sumAmount = packageIn.inCount ? Number(packageIn.amount) * packageIn.inCount : 0;

  return (
    <div
      className={cx(styles.tableRow, styles.tableRowWarehouseState, {
        active: isActive(String(packageIn.package)),
        highlighted: isHighlighted(String(packageIn.package)),
      })}
      onClick={() => togglePanel(String(packageIn.package))}
    >
      <div className="fw-700">{index + 1}.</div>
      <div className={cx("body-14-600", styles.overflow)}>{packageIn.name}</div>
      <div className="fw-700">{packageIn.internalId || EMPTY_VALUE}</div>
      {packageIn.lastWhEntryIn ? (
        <LinkToPage
          content={<>{packageIn.lastWhEntryIn.signature}</>}
          stopPropagation
          url={`/warehouse/documents?panelId=${packageIn.lastWhEntryIn.id}`}
          overrides={{
            link: { className: styles.linkWidth },
          }}
        />
      ) : (
        <EmptyValue />
      )}
      <div className="d-flex align-items-center justify-content-end">{packageIn.amount} PLN</div>
      <div className="d-flex align-items-center justify-content-end">
        {packageIn.inCount ? packageIn.inCount : 0} szt.
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {sumAmount.toFixed(2)} PLN
      </div>
    </div>
  );
};
