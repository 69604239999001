import styles from "./Attributes.module.css";
import { AttributesAsString, AttributesProps } from "./types";
import { Attribute } from "components/miloDesignSystem/atoms/attribute";
import { AttributeProps } from "components/miloDesignSystem/atoms/attribute/types";
import { useLayoutEffect, useRef, useState } from "react";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { cx } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const regex = /([^;:\s]+):\s*([^;]+)/g;

export const Attributes = ({ attributes }: AttributesProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const normalizedAttributes = getNormalizedAttributes(attributes);
  const [shouldAttributesBeWrapped, setShouldAttributesBeWrapped] = useState(false);
  const [visibleAttributesNumber, setVisibleAttributesNumber] = useState(0);

  useLayoutEffect(() => {
    const parentWidth = ref.current?.clientWidth || 0;
    let childrenWidth = 0;
    ref.current?.childNodes.forEach(node => {
      childrenWidth += (node as HTMLDivElement).clientWidth;
    });

    const shouldBeWrapped = parentWidth < childrenWidth;
    if (shouldBeWrapped && attributes.length > 1) {
      setShouldAttributesBeWrapped(true);
    }
    let counter = 0;
    let widthCounter = 0;
    const spaceForCounter = 30;

    ref.current?.childNodes.forEach(node => {
      const width = (node as HTMLDivElement).clientWidth;
      widthCounter += width;
      if (widthCounter < parentWidth - spaceForCounter) {
        counter++;
      }
    });
    setVisibleAttributesNumber(counter || 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Boolean(attributes.length)) return <EmptyValue />;

  return (
    <div
      ref={ref}
      className={cx(styles.attributes, {
        [styles.attributesWithCounter]: attributes.length > 1,
      })}
    >
      {shouldAttributesBeWrapped ? (
        <Tooltip
          title={
            <div className={styles.tooltipAttributes}>
              {normalizedAttributes.map(attribute => (
                <Attribute key={attribute.id} {...attribute} />
              ))}
            </div>
          }
        >
          <div className={styles.attributes}>
            {normalizedAttributes.slice(0, visibleAttributesNumber).map(attribute => (
              <Attribute key={attribute.id} {...attribute} />
            ))}
            <Typography fontSize="12" fontWeight="700" color="deepPurple400">
              +{normalizedAttributes.length - visibleAttributesNumber}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        normalizedAttributes.map(attribute => <Attribute key={attribute.id} {...attribute} />)
      )}
    </div>
  );
};

export function getNormalizedAttributes(
  attributes: AttributesProps["attributes"],
): AttributeProps[] {
  if (typeof attributes === "string") {
    const matchArr = attributes.match(regex);

    if (matchArr) {
      return matchArr.map(match => {
        const [label, value] = (index => [match.substring(0, index), match.substring(index + 1)])(
          match.indexOf(":"),
        );

        return { id: value + label, label, value };
      });
    }

    return [];
  }
  return attributes;
}

export function hasAttributes(description: AttributesAsString | string) {
  const matchArr = description.match(regex);
  return Boolean(matchArr?.length);
}
