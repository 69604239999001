import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { uniquePackagesApi } from "./calls";
import { useMutation } from "hooks/useMutation";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { LongHeldPackage } from "./models";
import { uniquePackagesKeys } from "./keys";
import { getAnyErrorKey } from "utilities";

const useLongHeldPackages = createPaginatedApiQuery(uniquePackagesApi.getLongHeldPackages);

const usePatchLongHeldPackage = () => {
  return useMutation(
    ({ id, toUpdate }: { id: LongHeldPackage["id"]; toUpdate: PartialOf<LongHeldPackage> }) => {
      return uniquePackagesApi.patchLongHeldPackage(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          uniquePackagesKeys.longHeldPackagesWithOrders(),
          id,
          toUpdate,
        );
        return { prevList };
      },
      onError: (_, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(
          uniquePackagesKeys.longHeldPackagesWithOrders(),
          onMutateReturn.prevList,
          id,
        );
      },
    }),
  );
};

const usePatchGroup = () => {
  return useMutation(
    uniquePackagesApi.patchGroup,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );
};

const useBulkUpdateLongHeldPackages = () => {
  return useMutation(
    uniquePackagesApi.postBulkUpdateLongHeldPackages,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useBulkCreateLoading = () =>
  useMutation(uniquePackagesApi.postLoading, ({ queryUtils, toastr }) => ({
    onSuccess: () => {
      queryUtils.handleCommonResponse.onSuccess();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono załadunek",
      });
    },
    onError: queryUtils.handleCommonResponse.onError,
  }));

const useBulkCreatePicking = () =>
  useMutation(uniquePackagesApi.postPicking, ({ queryUtils, toastr }) => ({
    onSuccess: () => {
      queryUtils.handleCommonResponse.onSuccess();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono picking",
      });
    },
    onError: queryUtils.handleCommonResponse.onError,
  }));

export const uniquePackagesActions = {
  useBulkCreateLoading,
  usePatchGroup,
  useBulkCreatePicking,
  useBulkUpdateLongHeldPackages,
  useLongHeldPackages,
  usePatchLongHeldPackage,
};
