import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useNavigate } from "hooks/useNavigate";
import styles from "./CreateDraftOrders.module.css";
import { cx } from "utilities";

interface Props {
  close: () => void;
  indexes: IndexForExternalManufacturingListItem[];
}

export const CreateDraftOrders = ({ close, indexes }: Props) => {
  const navigate = useNavigate();
  const createDraftOrderMutation = draftOrdersActions.useCreateDraftOrder();

  return (
    <Button
      className={cx(styles.createDraftButton, "text-uppercase")}
      isLoading={createDraftOrderMutation.isLoading}
      onClick={() => {
        createDraftOrderMutation.mutate(
          {
            ids: indexes.map(index => index.id),
            sourceKind: DraftOrderLineItemSourceKind.INDEX,
          },
          {
            onSuccess: payload => {
              navigate(
                `/external-manufacturing/modify-draft-order/${payload.externalManufacturingOrderDraft}`,
              );
              close();
            },
          },
        );
      }}
      startIcon={MdiAdd}
      size="small"
      theme="dark"
      variant="gray"
    >
      Utwórz zlecenie
    </Button>
  );
};
