import { OrderLineItemListItem, OrderStatus } from "api/external-manufacturing/models";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { FLAVOR } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LinkToPage } from "components/utils/linkToPage";
import { EstimatedDelivery } from "pages/externalManufacturing/shared/components/EstimatedDelivery";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { useGetMiloSettings } from "api/milo-settings/hooks";
import { ExternalManufacturingOrderStatusTag } from "pages/externalManufacturing/shared/components/ExternalManufacturingOrderStatusTag";
import { Link } from "components/miloDesignSystem/atoms/link";

export const useLineItemsColumns = () => {
  const { data } = useGetMiloSettings("production");
  return useCreateTableColumns<OrderLineItemListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "sygnatura",
          size: 104,
        }),
        columnHelper.text(row => row.name, {
          header: "produkt",
          size: 130,
        }),
        columnHelper.accessor(row => row.orderStatus, {
          header: "status",
          size: 75,
          cell: info => {
            const status = info.getValue();

            return <ExternalManufacturingOrderStatusTag status={status} />;
          },
        }),
        data?.isManualExternalManufacturingItemStatusChangeAllowed
          ? columnHelper.accessor(row => row, {
              id: "receivedAt",
              header: () => (
                <Typography
                  className="text-center"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  zlecono
                </Typography>
              ),
              size: 56,
              cell: info => {
                const row = info.getValue();
                return (
                  <div
                    className="d-flex align-items-center justify-content-center flex-1"
                    onClick={e => e.stopPropagation()}
                  >
                    <Checkbox.Async
                      label=""
                      checked={row.orderStatus === OrderStatus.SENT}
                      disabled={row.orderStatus === OrderStatus.SENT}
                      mutationHook={externalManufacturingActions.usePatchOrderLineItem}
                      transformQueryData={() => ({
                        id: row.id,
                        toUpdate: { orderStatus: OrderStatus.SENT },
                      })}
                      size="sx"
                    />
                  </div>
                );
              },
            })
          : undefined,
        columnHelper.accessor(row => row.estimatedDeliveryDate, {
          header: "planowy odbiór",
          size: 140,
          cell: info => {
            const date = info.getValue();
            return <EstimatedDelivery estimatedDelivery={date} fontSize="12" fontWeight="600" />;
          },
        }),
        columnHelper.text(row => row.ean, {
          header: "EAN",
          size: 90,
        }),
        columnHelper.accessor(row => row.order, {
          header: "zamówienie",
          size: 110,
          cell: info => {
            const order = info.getValue();

            if (!order) return <EmptyValue />;

            if (FLAVOR === "externalManufacturing") {
              return (
                <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                  {order.signature}
                </Typography>
              );
            }
            return (
              <LinkToPage
                content={order.signature}
                url={`/orders/list/active/all?panelId=${order.id}`}
              />
            );
          },
        }),
        columnHelper.text(row => row.description, {
          header: "uwagi",
          size: 200,
          typographyProps: {
            fontWeight: "600",
          },
        }),
        columnHelper.accessor(row => row.labels, {
          header: "przyjęcia",
          size: 120,
          cell: info => {
            const labels = info.getValue();

            const labelsWithReceptions = labels.filter(label => label.received);

            if (!Boolean(labelsWithReceptions.length)) return <EmptyValue />;

            return (
              <div className="d-flex align-items-center gap-1">
                <Link
                  fontSize="12"
                  fontWeight="600"
                  to={`/finances/receptions/list/all?panelId=${
                    labelsWithReceptions[0].received!.whEntryId
                  }`}
                >
                  {labelsWithReceptions[0].received!.whEntrySignature}
                </Link>
                {labelsWithReceptions.length > 1 && (
                  <Typography fontSize="12" fontWeight="600">
                    +{labelsWithReceptions.length - 1}
                  </Typography>
                )}
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: row => `${row.position}.` },
  );
};
