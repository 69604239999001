import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./PeriodCalculationModal.module.css";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { MdiCalendarMonth } from "components/miloDesignSystem/atoms/icons/MdiCalendarMonth";
import { capitalizeFirstLetter, dateUtils, formatSubUnitsToDecimal } from "utilities";
import { MdiPayments } from "components/miloDesignSystem/atoms/icons/MdiPayments";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { WarehouseIconKind } from "api/wms/models";
import { useState } from "react";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Button } from "components/miloDesignSystem/atoms/button";
import { financialPackagesActions } from "api/wms/financial-packages/actions";
import { useToggle } from "hooks";
import { ResponseModal } from "./ResponseModal";

interface Props {
  close: () => void;
  state: FinancialWarehouseStateSummaryPerPeriod & {
    warehouse: {
      code: string;
      icon: WarehouseIconKind;
      id: number;
      name: string;
    };
  };
}

export const PeriodCalculationModal = ({ close, state }: Props) => {
  const [warehouse, setWarehouse] = useState("");
  const responseModal = useToggle();
  const calculatePeriodMutation = financialPackagesActions.useCalculatePeriod(responseModal.open);

  return (
    <>
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Przelicz
          </Typography>
        }
        width={600}
      >
        <div className="py-2 px-3">
          <div className="d-flex flex-column gap-4">
            <div className={styles.warning}>
              <Typography color="warning700" fontSize="14" fontWeight="500">
                Uwaga, zamknięcie miesiąca jest akcją nieodwoływalną i niemożliwą do zmiany.
              </Typography>
            </div>
            <div className="d-flex align-items-center gap-4 w-100">
              <div className="d-flex flex-column gap-2 w-50">
                <div className="d-flex align-items-center gap-2">
                  <IconRenderer color="neutralBlack100" icon={MdiCalendarMonth} size={16} />
                  <Typography fontSize="14" fontWeight="500">
                    Okres rozliczeniowy:
                  </Typography>
                </div>
                <div className="d-flex align-items-center gap-2 cut-text">
                  <Typography fontSize="18" fontWeight="700">
                    {capitalizeFirstLetter(dateUtils.getMonthPolishName(state.periodId))}
                  </Typography>
                  {(state.periodEndAt || state.periodStartAt) && (
                    <Typography fontSize="14" fontWeight="400">
                      (
                      {state.periodStartAt
                        ? dateUtils.formatDateToDisplayOnlyDate(state.periodStartAt)
                        : "od początku"}{" "}
                      - {dateUtils.formatDateToDisplayOnlyDate(state.periodEndAt)})
                    </Typography>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column gap-2 w-50">
                <div className="d-flex align-items-center gap-2">
                  <IconRenderer color="neutralBlack100" icon={MdiPayments} size={16} />
                  <Typography fontSize="14" fontWeight="500">
                    Kwota przeliczona na dziś ({dateUtils.formatDateToDisplayOnlyDate(new Date())}):
                  </Typography>
                </div>
                <AmountDisplay
                  amount={formatSubUnitsToDecimal(state.baselineValue + state.totalValue)}
                  currency="PLN"
                  decimalTypographyProps={{
                    fontSize: "14",
                    fontWeight: "500",
                  }}
                  integerTypographyProps={{
                    fontSize: "18",
                    fontWeight: "700",
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-2 pt-2">
              <Typography className="d-flex" fontSize="14" fontWeight="500">
                Podaj kod magazynu (
                <Typography fontSize="14" fontWeight="700">
                  {state.warehouse.code || state.warehouse.name}
                </Typography>
                ), dla którego chcesz wykonać zamknięcie
              </Typography>
              <TextField
                onChange={text => setWarehouse(text.target.value)}
                containerClassName="w-50"
                value={warehouse}
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-3 mt-2 gap-2">
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Anuluj
            </Button>
            <Button
              className="text-uppercase"
              disabled={
                Boolean(warehouse.length === 0) ||
                (Boolean(warehouse.length > 0) &&
                  state.warehouse.code !== warehouse &&
                  state.warehouse.name !== warehouse)
              }
              isLoading={calculatePeriodMutation.isLoading}
              onClick={() =>
                calculatePeriodMutation.mutate({
                  closedWarehouseStateId: state.id,
                  closeAt: dateUtils.formatDateToIso(new Date()),
                })
              }
              size="medium"
              variant="deepPurple"
            >
              Przeliczam miesiąc z odpowiedzialnością i znając konsekwencje
            </Button>
          </div>
        </div>
      </Modal>
      {responseModal.isOpen && <ResponseModal close={responseModal.close} closeParent={close} />}
    </>
  );
};
