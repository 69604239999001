import { countryCurrenciesFlags } from "CONSTANTS";
import { financesPaymentsActions } from "api/finances/payments/actions";
import {
  AddFinancesPayment,
  FinancesPaymentKind,
  FinancesPaymentType,
} from "api/finances/payments/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Formik, FormikHelpers } from "formik";
import { useSelector } from "hooks";
import { cx, dateUtils, dictToList } from "utilities";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";

interface Props {
  close: () => void;
}

export const CreatePayment = ({ close }: Props) => {
  const me = useSelector(store => store.auth.user!);
  const postMutation = financesPaymentsActions.useCreatePayment();
  const initialValues: AddFinancesPayment = {
    amount: "",
    bankTransactionDate: null,
    bankTransactionNumber: "",
    commission: null,
    createdAt: dateUtils.formatDateToIso(new Date()),
    createdBy: me,
    currency: "PLN",
    kind: FinancesPaymentKind.PAYMENT,
    paymentDate: dateUtils.formatDateToIso(new Date()),
    paymentNumber: "",
    provider: "",
    type: FinancesPaymentType.CASH,
    issuerId: "",
  };
  const currenciesOptions = dictToList(countryCurrenciesFlags).map(({ key, value }) => ({
    icon: value,
    text: key,
    type: MenuItemType.ICON,
    value: key,
  }));
  const handleSubmit = (values: AddFinancesPayment, actions: FormikHelpers<AddFinancesPayment>) => {
    postMutation.mutate(
      { ...values, amount: Number(values.amount).toFixed(2) },
      handleSubmitResponse(actions, {
        onSuccess: close,
      }),
    );
  };

  const businessEntities = useSelector(store => store.partials.businessEntities);
  const internalBusinessEntitiesOptions = businessEntities
    .filter(entity => entity.kind === "INTERNAL")
    .map(entity => ({
      value: entity.id,
      text: entity.companyName,
      type: MenuItemType.TEXT,
    }));

  return (
    <Modal
      close={close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj płatność
        </Typography>
      }
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-2 py-2 px-3">
              <div className="d-flex align-items-center gap-3 w-100">
                <div className="w-100">
                  <TextField.Form<AddFinancesPayment>
                    label="nr płatności"
                    name="paymentNumber"
                    size="default"
                  />
                </div>
                <DatePicker.Form<AddFinancesPayment>
                  isNullable
                  label="Data płatności"
                  name="paymentDate"
                />
              </div>
              <div className="d-flex align-items-center gap-3 w-100">
                <div className="w-100">
                  <TextField.Form<AddFinancesPayment>
                    label="Kwota"
                    name="amount"
                    size="default"
                    type="number"
                  />
                </div>
                <div className="w-100">
                  <TextField.Form<AddFinancesPayment>
                    label="Prowizja"
                    name="commission"
                    size="default"
                    type="number"
                  />
                </div>
              </div>
              <div>
                <Select.Form<AddFinancesPayment>
                  items={currenciesOptions}
                  label="Waluta"
                  name="currency"
                  textFieldProps={{
                    size: "default",
                  }}
                />
              </div>
              <div>
                <Select.Form<AddFinancesPayment>
                  items={internalBusinessEntitiesOptions}
                  label="Podmiot biznesowy"
                  name="issuerId"
                  textFieldProps={{
                    size: "default",
                  }}
                />
              </div>
              <div className="d-flex flex-column gap-1 pt-1">
                <Typography color="grey500" fontSize="10" fontWeight="500">
                  Sposób płatności
                </Typography>
                <Typography fontSize="16" fontWeight="700">
                  Gotówka
                </Typography>
              </div>
              <div className="d-flex flex-column gap-1 pt-1">
                <Typography color="grey500" fontSize="10" fontWeight="500">
                  Odpowiedzialny(/a)
                </Typography>
                <div className="d-flex align-items-center gap-2 pl-1 pb-1">
                  <Avatar size="sm" user={values.createdBy} />
                  <Typography fontSize="16" fontWeight="700" noWrap>
                    {values.createdBy.firstName} {values.createdBy.lastName}
                  </Typography>
                </div>
              </div>
              <div className="d-flex flex-column gap-1 pt-1">
                <Typography color="grey500" fontSize="10" fontWeight="500">
                  Dodano
                </Typography>
                <Typography fontSize="16" fontWeight="700">
                  {dateUtils.formatDateToDisplayOnlyDate(values.createdAt)}
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button
                className="text-uppercase"
                onClick={close}
                size="medium"
                variant="transparent"
              >
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Gotowe
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
