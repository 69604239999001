import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  index: IndexForExternalManufacturingDetails;
}

export const TitleSection = ({ index }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection className="d-flex flex-column" padding="px-3 pt-2 pb-2">
      <div className="d-flex align-items-center" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700" noWrap>
          {index.name}
        </Typography>
      </div>
      <div className="d-flex flex-wrap">
        <Attributes attributes={index.verboseAttributesValues} />
      </div>
    </RightPanelSection>
  );
};
