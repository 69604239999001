import { PreviewCorrectionDocument, TradingDocument } from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { currencyConvertSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { dateUtils } from "utilities";
import { CreateCorrectionValues } from "../../CreateCorrectionManuallyModal";
import { AmountPerVatRate } from "../AmountPerVatRate";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  currencyConvert: TradingDocument["amountSummaryPerVatRateCurrencyConvert"][number];
  preview: PreviewCorrectionDocument;
  values: CreateCorrectionValues;
}

export const CurrencyConvertionPerVatRate = ({ currencyConvert, preview, values }: Props) => {
  return (
    <RightPanelTableRow grid={currencyConvertSectionListConfig.grid}>
      <div>
        <div className="d-flex align-items-center gap-1">
          {preview.lastValidExchangeRate ? (
            <div className="d-flex align-items-center gap-1">
              <Typography className="mr-1" fontSize="14" fontWeight="800">
                {String(preview.lastValidExchangeRate).replace(/\./g, ",")}
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                PLN
              </Typography>
            </div>
          ) : (
            <EmptyValue fontSize="14" />
          )}
        </div>
      </div>
      <div>
        {preview.lastValidExchangeRateDate ? (
          <div>{dateUtils.formatDateToDisplayOnlyDate(preview.lastValidExchangeRateDate)}</div>
        ) : (
          <EmptyValue fontSize="14" />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end text-grey-35">w tym:</div>
      {currencyConvert.vatRate !== undefined && currencyConvert.vatRate !== null ? (
        <div className="fw-700">{currencyConvert.vatRate}%</div>
      ) : (
        "--"
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountPerVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="amountWithoutTax"
            vatRate={currencyConvert.vatRate}
            values={values}
          />
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountPerVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="taxAmountBeforeRounding"
            vatRate={currencyConvert.vatRate}
            values={values}
          />
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountPerVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="amountWithTax"
            vatRate={currencyConvert.vatRate}
            values={values}
          />
        ) : (
          "--"
        )}
      </div>
    </RightPanelTableRow>
  );
};
