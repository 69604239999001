import { ConfirmDraftOrderPreview } from "api/external-manufacturing/orders/drafts/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturingOrdersColumns = () => {
  return useCreateTableColumns<ConfirmDraftOrderPreview["manufacturingOrders"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "producent",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
            fontWeight: "700",
          },
        }),
        columnHelper.link({
          header: "zlecenie",
          size: 130,
          to: row => `/external-manufacturing/orders/list/all?panelId=${row.id}`,
          textRenderer: row => row.signature,
          typographyProps: {
            fontSize: "14",
            fontWeight: "500",
          },
        }),
      ];
    },
  );
};
