import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { CommonError } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { useNavigate } from "hooks/useNavigate";
import { useParams } from "react-router";
import {
  NormalizedLineItem,
  getNormalizedData,
} from "../rightPanel/components/lineItemsSection/LineItemsSection";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useIndexesAndPackagesColumns } from "./useIndexesAndPackagesColumns";
import styles from "../DraftOrders.module.css";
import { cx } from "utilities";
import { useStateModal, useToggle } from "hooks";
import { AddIndexToDraftOrderModal } from "./addIndexToDraftOrderModal/AddIndexToDraftOrderModal";
import { AddPackagesToDraftOrderModal } from "./addPackagesToDraftOrderModal/AddPackagesToDraftOrderModal";
import { ConfirmationSuccessModal } from "../shared/components/confirmationSuccessModal/ConfirmationSuccessModal";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { ConfirmDraftOrderPreview } from "api/external-manufacturing/orders/drafts/models";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";

interface Filters {
  externalManufacturingOrderDraft: UUID;
  page: number;
}

export const CreateDraftOrder = () => {
  const params = useParams<{ externalManufacturingOrderDraft: UUID }>();
  const {
    data: draftOrder,
    error: draftOrderError,
    isLoading: isDraftOrderLoading,
  } = draftOrdersActions.useItem(params.externalManufacturingOrderDraft, {
    enabled: Boolean(params.externalManufacturingOrderDraft),
  });
  const { filters, searchParams, setFilter } = useFilters<Filters>({
    externalManufacturingOrderDraft: params.externalManufacturingOrderDraft,
    page: 1,
  });
  const {
    data: lineItems,
    error: lineItemsError,
    isLoading: areLineItemsLoading,
    isPreviousData: haveLineItemsPreviousData,
    pagination,
  } = draftOrdersActions.useLineItems(searchParams);
  const confirmDraftOrderMutation = draftOrdersActions.useConfirmDraftOrder();
  const tableProps = useTableFeatureConnector({
    rows: getNormalizedData(lineItems),
  });
  const columns = useIndexesAndPackagesColumns(filters.page, draftOrder);
  const addIndexToDraftOrderModal = useToggle();
  const addPackagesToDraftOrderModal = useToggle();
  const confirmationSuccessModal = useStateModal<ConfirmDraftOrderPreview>();
  const navigate = useNavigate();

  if (draftOrderError)
    return (
      <PageWrapper>
        <PageHeader title={<PageHeaderTitle />} searchBar={false} viewLabel="CREATE_DRAFT_ORDER" />
        <div className="p-3">
          <CommonError status={draftOrderError._httpStatus_} />
        </div>
      </PageWrapper>
    );

  if (isDraftOrderLoading)
    return (
      <PageWrapper>
        <PageHeader title={<PageHeaderTitle />} searchBar={false} viewLabel="CREATE_DRAFT_ORDER" />
        <div className="p-3">
          <Spinner size={26} />
        </div>
      </PageWrapper>
    );

  if (!draftOrder) return null;

  return (
    <PageWrapper>
      <PageHeader title={<PageHeaderTitle />} searchBar={false} viewLabel="CREATE_DRAFT_ORDER" />
      <div className="d-flex p-3 gap-4 flex-column overflow-hidden flex-1">
        <Paper className="w-100 d-flex flex-column flex-1 overflow-hidden">
          <div className="p-3">
            {Boolean(draftOrder.manufacturingOrders.length) ? (
              <Button
                className="text-uppercase"
                disabled
                size="small"
                startIcon={MdiCheck}
                variant="success"
              >
                Potwierdzono
              </Button>
            ) : (
              <Button
                className="text-uppercase"
                isLoading={confirmDraftOrderMutation.isLoading}
                onClick={() =>
                  confirmDraftOrderMutation.mutate(
                    {
                      externalManufacturingOrderDraft: params.externalManufacturingOrderDraft,
                    },
                    {
                      onSuccess: payload => {
                        confirmationSuccessModal.open(payload);
                      },
                    },
                  )
                }
                size="small"
                startIcon={MdiCheck}
                variant="outline"
              >
                Potwierdź i dodaj do ZDP
              </Button>
            )}
          </div>
          <div className="pt-3 d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-1 px-3 pb-2">
              <Button
                className="text-uppercase"
                disabled={Boolean(draftOrder.manufacturingOrders.length)}
                onClick={addIndexToDraftOrderModal.open}
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Dodaj warianty
              </Button>
              <Button
                className="text-uppercase"
                disabled={Boolean(draftOrder.manufacturingOrders.length)}
                onClick={addPackagesToDraftOrderModal.open}
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Dodaj paczki
              </Button>
            </div>
          </div>
          <div className="d-flex flex-1 overflow-auto justify-content-between pb-3">
            <Table<NormalizedLineItem>
              expandable
              columns={columns}
              onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
              pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
              isLoading={areLineItemsLoading || haveLineItemsPreviousData}
              error={lineItemsError}
              uiSchema={{ cell: { height: "36" }, header: { backgroundColor: "neutralWhite100" } }}
              {...tableProps}
              overrides={() => {
                return {
                  ...tableProps.overrides?.(),
                  row: row => {
                    return {
                      className: cx(
                        {
                          [styles.lineItemSubRow]: Boolean(row.parent),
                          [styles.indexRow]: row.sourceKind === DraftOrderLineItemSourceKind.INDEX,
                        },
                        tableProps.overrides?.().row?.(row).className,
                      ),
                    };
                  },
                };
              }}
            />
          </div>
        </Paper>
      </div>
      {addIndexToDraftOrderModal.isOpen && (
        <AddIndexToDraftOrderModal
          close={addIndexToDraftOrderModal.close}
          externalManufacturingDraft={params.externalManufacturingOrderDraft}
        />
      )}
      {addPackagesToDraftOrderModal.isOpen && (
        <AddPackagesToDraftOrderModal
          close={addPackagesToDraftOrderModal.close}
          externalManufacturingDraft={params.externalManufacturingOrderDraft}
        />
      )}
      {confirmationSuccessModal.isOpen && confirmationSuccessModal.state && (
        <ConfirmationSuccessModal
          close={() => {
            confirmationSuccessModal.close();
            navigate(`/external-manufacturing/orders/list/all`);
          }}
          state={confirmationSuccessModal.state}
        />
      )}
    </PageWrapper>
  );
};

const PageHeaderTitle = () => {
  const params = useParams<{ externalManufacturingOrderDraft: UUID }>();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center gap-2">
      <IconButton
        icon={MdiArrowBack}
        onClick={() =>
          navigate(
            `/external-manufacturing/draft-orders/list/all?panelId=${params.externalManufacturingOrderDraft}`,
          )
        }
        variant="gray"
      />
      <Typography fontSize="20" fontWeight="700">
        Utwórz zlecenie do producentów
      </Typography>
    </div>
  );
};
