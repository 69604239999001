import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import styles from "./CallStatusSelector.module.css";
import { cx } from "utilities";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiPhoneForwarded } from "components/miloDesignSystem/atoms/icons/MdiPhoneForwarded";
import { MdiCall } from "components/miloDesignSystem/atoms/icons/MdiCall";
import { MdiPhoneInTalkB } from "components/miloDesignSystem/atoms/icons/MdiPhoneInTalkB";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";

interface Props {
  orderListItem: CallCenterRouteOrderListItem;
}

export const CallStatusSelector = ({ orderListItem }: Props) => {
  const noCallYetMutation = useRoutePatchMutation();
  const onCallMutation = useRoutePatchMutation();
  const calledMutation = useRoutePatchMutation();

  return (
    <Menu
      disclosure={
        <div
          className={cx(
            styles.callStatus,
            styles.callStatusSmall,
            "d-flex align-items-center justify-content-between gap-2 px-1",
          )}
          onMouseDown={event => event.stopPropagation()}
          role="button"
        >
          <div className="d-flex align-items-center justify-content-between gap-1">
            <div>{getCallStatusDetails({ callStatus: orderListItem.callStatus }).icon}</div>
            <div className="d-flex justify-content-end ml-3">
              <img alt="Wybierz" src={arrowDownIcon} />
            </div>
          </div>
        </div>
      }
      menuItems={[
        {
          type: MenuItemType.ICON,
          icon: getCallStatusDetails({ callStatus: "NO_CALL_YET" }).icon,
          onClick: () => {
            noCallYetMutation.mutate({
              id: orderListItem.id,
              toUpdate: { callStatus: "NO_CALL_YET" },
            });
          },
          text: getCallStatusDetails({ callStatus: "NO_CALL_YET" }).label,
        },
        {
          type: MenuItemType.ICON,
          icon: getCallStatusDetails({ callStatus: "ON_A_CALL" }).icon,
          onClick: () => {
            onCallMutation.mutate({
              id: orderListItem.id,
              toUpdate: { callStatus: "ON_A_CALL" },
            });
          },
          text: getCallStatusDetails({ callStatus: "ON_A_CALL" }).label,
        },
        {
          type: MenuItemType.ICON,
          icon: getCallStatusDetails({ callStatus: "CALLED" }).icon,
          onClick: () => {
            calledMutation.mutate({
              id: orderListItem.id,
              toUpdate: { callStatus: "CALLED" },
            });
          },
          text: getCallStatusDetails({ callStatus: "CALLED" }).label,
        },
      ]}
    />
  );
};

const getCallStatusDetails = ({
  callStatus,
}: {
  callStatus: CallCenterRouteOrderListItem["callStatus"];
}) => {
  switch (callStatus) {
    case "CALLED":
      return {
        icon: <MdiPhoneForwarded color="success500" size="16" />,
        label: "Dzwoniono",
      };
    case "NO_CALL_YET":
      return {
        icon: <MdiCall size="16" />,
        label: "Nie dzwoniono",
      };
    case "ON_A_CALL":
      return {
        icon: <MdiPhoneInTalkB color="deepPurple500" size="16" />,
        label: "W trakcie rozmowy",
      };
  }
};
