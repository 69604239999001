import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns, dateUtils } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { OrderGroup } from "api/order-groups/models";

interface Props {
  orderGroup: OrderGroup;
}

export const GeneralInfoSection = ({ orderGroup }: Props) => {
  const formattedTime =
    orderGroup.pickUpTime?.length === 8
      ? orderGroup.pickUpTime.slice(0, -3)
      : orderGroup.pickUpTime;

  return (
    <RightPanelSection>
      <InfoLabel title="utworzono">
        <div className="d-flex align-items-center gap-1">
          {orderGroup.createdBy.avatar && <Avatar size="sm" user={orderGroup.createdBy} />}
          <Typography fontSize="14" fontWeight="700">
            {orderGroup.createdBy.firstName} {orderGroup.createdBy.lastName}
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {orderGroup.created
              ? dateFns.formatRelative(new Date(orderGroup.created))
              : EMPTY_VALUE}
          </Typography>
        </div>
      </InfoLabel>

      <InfoLabel title="godz. i data odbioru">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="14" fontWeight="500" color="neutralBlack48">
            {orderGroup.pickUpTime ? formattedTime : "brak godziny"}
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {orderGroup.pickUpDate
              ? dateUtils.formatDateToDisplayOnlyDate(new Date(orderGroup.pickUpDate))
              : "brak daty"}
          </Typography>
        </div>
      </InfoLabel>

      <InfoLabel title="rampa">
        <Typography fontSize="14" fontWeight="500">
          {orderGroup.ramp ? orderGroup.ramp.name : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    </RightPanelSection>
  );
};
