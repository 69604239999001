import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { notificationSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { EmptyNotificationSection } from "./components/EmptyNotificationSection";
import { Notification } from "./components/Notification";
import { NotificationActions } from "./components/Actions";

interface Props {
  tradingDocument: TradingDocument;
}

export const NotificationSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Wysyłka">
      {tradingDocument.notificationsSent.length === 0 ? (
        <EmptyNotificationSection tradingDocument={tradingDocument} />
      ) : (
        <div className="pb-3">
          <RightPanelTableRow grid={notificationSectionListConfig.grid} hover={false}>
            <div>#</div>
            <div>akcja</div>
            <div>data</div>
            <div>na adres</div>
            <div>wysyłka</div>
            <div>przez kogo</div>
          </RightPanelTableRow>
          <div>
            {tradingDocument.notificationsSent.map((notification, index) => (
              <Notification key={index} notification={notification} position={index + 1} />
            ))}
          </div>
          <NotificationActions tradingDocument={tradingDocument} />
        </div>
      )}
    </RightPanelSection>
  );
};
