import { ReactNode } from "react";
import { ColorPalette } from "../colorsPalette";
import { BaseIcon } from "../icons/types";
import { IconRendererIcon } from "../shared/IconRenderer";
import { TypographyProps } from "../typography/types";

export enum MenuItemType {
  AVATAR = "AVATAR",
  DIVIDER = "DIVIDER",
  SECTION = "SECTION",
  TAG = "TAG",
  CUSTOM_RENDERER = "CUSTOM_RENDERER",
  TEXT = "TEXT",
  ICON = "ICON",
}

export interface BaseMenuItem {
  options?: {
    disabled?: boolean;
    divider?: React.ReactNode;
    color?: ColorPalette;
    className?: string;
    iconStart?: React.FunctionComponent<BaseIcon>;
    buttonEnd?: React.ReactNode;
    typographyProps?: Partial<TypographyProps>;
  };
  onClick: () => void;
  text: string;
  helperText?: string;
}

interface AvatarMenuItem extends BaseMenuItem {
  avatar: string;
  type: MenuItemType.AVATAR;
}

interface DividerMenuItem extends Omit<BaseMenuItem, "onClick" | "text"> {
  type: MenuItemType.DIVIDER;
  text?: never;
  onClick?: never;
}

interface TagMenuItem extends Omit<BaseMenuItem, "helperText"> {
  backgroundColor?: ColorPalette;
  helperText?: never;
  icon?: IconRendererIcon;
  textColor?: ColorPalette;
  type: MenuItemType.TAG;
}

export interface IconMenuItem extends BaseMenuItem {
  icon: IconRendererIcon;
  type: MenuItemType.ICON;
}
interface TextMenuItem extends BaseMenuItem {
  type: MenuItemType.TEXT;
}

interface SectionMenuItem extends BaseMenuItem {
  type: MenuItemType.SECTION;
  title?: string;
  menuItems: MenuItem[];
}

interface CustomRendererMenuItem extends Pick<BaseMenuItem, "options"> {
  type: MenuItemType.CUSTOM_RENDERER;
  renderer: () => ReactNode;
  /**
   * it is needed for "enter" action
   */
  onClick: () => void;
  text?: never;
}

export type MenuItem =
  | AvatarMenuItem
  | DividerMenuItem
  | TextMenuItem
  | SectionMenuItem
  | CustomRendererMenuItem
  | TagMenuItem
  | IconMenuItem;
