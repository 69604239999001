import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { packagesActions } from "api/packages/actions";
import { PackageListItem } from "api/warehouse/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackagesColumns = ({
  index,
  search,
}: {
  index: IndexForExternalManufacturingDetails;
  search: string;
}) => {
  const deleteIndexPackageAssignmentMutation = packagesActions.useDeleteAssignment(search);

  return useCreateTableColumns<PackageListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 304,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.text(row => row.internalId, {
          header: "SKU",
          size: 200,
          typographyProps: {
            fontSize: "14",
            fontWeight: "400",
          },
        }),
        columnHelper.stretchContent,
        columnHelper.accessor(row => row, {
          id: "removePackageFromIndex",
          header: " ",
          size: 40,
          cell: info => {
            const packageDetails = info.getValue();

            return (
              <div className="w-100 d-flex align-items-center justify-content-end">
                <Tooltip title="Usuń paczkę z indeksu">
                  <IconButton
                    icon={MdiClose}
                    isLoading={
                      deleteIndexPackageAssignmentMutation.isLoading &&
                      deleteIndexPackageAssignmentMutation.variables?.package === packageDetails.id
                    }
                    onClick={() =>
                      deleteIndexPackageAssignmentMutation.mutate({
                        index: index.id,
                        package: packageDetails.id,
                      })
                    }
                    variant="transparent"
                  />
                </Tooltip>
              </div>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
