export enum OrderTypeChoice {
  DELIVERY = "DELIVERY",
  RETURN = "RETURN",
}

export enum ServiceTimeWindowChoices {
  EIGHT_TWELVE = "08:00-12:00",
  TWELVE_SIXTEENTH = "12:00-16:00",
  SIXTEEN_TWENTY = "16:00-20:00",
  EIGHT_TWENTY = "08:00-20:00",
}
