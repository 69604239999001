import { EmailSentStatus, ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  status: ExternalManufacturingOrder["emailSentStatus"];
}

export const EmailSendingStatusTag = ({ status }: Props) => {
  switch (status) {
    case EmailSentStatus.DELIVERED:
      return <Tag label="dostarczone" type="outlined" variant="deepPurple400" />;
    case EmailSentStatus.ERROR:
      return <Tag label="błąd wysyłki" type="outlined" variant="danger" />;
    case EmailSentStatus.NOT_SENT:
      return <Tag label="niewysłane" type="outlined" variant="warning" />;
    case EmailSentStatus.SEEN:
      return <Tag label="odczytane" type="outlined" variant="success" />;
    case EmailSentStatus.SENT:
      return <Tag label="wysłane" type="outlined" variant="info" />;
    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled email sending status: ${exhaustiveCheck}`);
      return <EmptyValue />;
  }
};
