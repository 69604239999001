import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedLineItem } from "../rightPanel/components/lineItemsSection/LineItemsSection";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import {
  Attributes,
  hasAttributes,
} from "components/miloDesignSystem/molecules/attributes/Attributes";
import { memo, useMemo } from "react";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./CreateDraftOrder.module.css";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { indexesUtils } from "pages/externalManufacturing/indexes/utils/indexesUtils";
import { DraftOrderDetails } from "api/external-manufacturing/orders/drafts/models";

export const useIndexesAndPackagesColumns = (
  page: number,
  draftOrder: DraftOrderDetails | null,
) => {
  const isEditDisabled = Boolean(draftOrder?.manufacturingOrders.length);
  const removeIndexMutation = draftOrdersActions.useDeleteLineItem();

  return useCreateTableColumns<NormalizedLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "#",
        size: 25,
        cell: info => {
          const counter = info.row.index;
          const isNested = info.row.depth;

          if (isNested) return null;

          return (
            <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
              {page > 1 ? counter + 1 + (page - 1) * 30 : counter + 1}.
            </Typography>
          );
        },
      }),
      columnHelper.expandable(),
      columnHelper.accessor(row => row, {
        header: "nazwa",
        size: 420,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          return (
            <div className="d-flex flex-1 align-items-center gap-2 overflow-hidden">
              {lineItem.sourceKind === DraftOrderLineItemSourceKind.PACKAGE || isNested ? (
                <MdiPackage2 color="yellow400" size="12" />
              ) : (
                <MdiChair color="magenta300" size="12" />
              )}
              <Typography fontSize="12" fontWeight={isNested ? "400" : "600"} noWrap>
                {lineItem.name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "atrybuty / opis",
        size: 400,
        cell: info => {
          const lineItem = info.getValue();

          if (!Boolean(lineItem.description.length))
            return <EmptyValue fontSize="14" fontWeight="400" />;

          if (
            lineItem.sourceKind === DraftOrderLineItemSourceKind.INDEX &&
            hasAttributes(lineItem.description)
          )
            return <Attributes attributes={lineItem.description} />;

          return (
            <Typography color="neutralBlack88" fontSize="14" fontWeight="400" noWrap>
              {lineItem.description}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.code, {
        header: "EAN/SKU",
        size: 110,
        typographyProps: {
          fontSize: "12",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "producent",
        size: 130,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          if (!isNested)
            return <SelectProducer lineItem={lineItem} isEditDisabled={isEditDisabled} />;

          return (
            <Typography color="neutralBlack88" fontSize="14" fontWeight="400" noWrap>
              {lineItem.manufacturer ? lineItem.manufacturer.name : EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.currentlyInProduction, {
        id: "previouslyOrdered",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wcześniej zamówione
          </Typography>
        ),
        size: 170,
        cell: info => {
          const currentlyInProduction = info.getValue();

          if (!currentlyInProduction)
            return <EmptyValue className="text-right w-100" color="neutralBlack48" fontSize="14" />;

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="info400" fontSize="14" fontWeight="600">
                {currentlyInProduction.withOrder + currentlyInProduction.withoutOrder}
              </Typography>
              {(currentlyInProduction.withOrder !== 0 ||
                currentlyInProduction.withoutOrder !== 0) && (
                <Typography fontSize="12" fontWeight="700">
                  ({currentlyInProduction.withOrder} z zam., {currentlyInProduction.withoutOrder}{" "}
                  wolne)
                </Typography>
              )}
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.warehouseState, {
        id: "state",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan
          </Typography>
        ),
        size: 85,
        cell: info => {
          const warehouseState = info.getValue();

          if (!warehouseState)
            return (
              <Typography color="neutralBlack48" fontSize="14" fontWeight="400">
                Brak paczek
              </Typography>
            );

          return (
            <div className="d-flex align-items-center justify-content-end w-100 gap-1">
              <div className="d-flex align-items-baseline gap-1">
                <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
                  {warehouseState.state}
                </Typography>
                <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                  szt.
                </Typography>
              </div>
              <div>{indexesUtils.getWarehouseStateLegend({ warehouseState }).icon}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.warehouseState, {
        id: "reserved",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            zarezerwowane
          </Typography>
        ),
        size: 81,
        cell: info => {
          const warehouseState = info.getValue();

          if (!warehouseState)
            return (
              <Typography color="neutralBlack48" fontSize="14" fontWeight="400">
                Brak paczek
              </Typography>
            );

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
                {warehouseState.reservation}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.salesFromLastTwoWeeks, {
        id: "salesFromLastTwoWeeks",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            sprzedaż (ost. 2 tyg.)
          </Typography>
        ),
        size: 125,
        cell: info => {
          const salesFromLastTwoWeeks = info.getValue();
          const isNested = info.row.depth;

          if (isNested) return null;

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="neutralBlack64" fontSize="14" fontWeight="600">
                {salesFromLastTwoWeeks || 0}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "quantity",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack88"
            fontSize="12"
            fontWeight="700"
          >
            zamawiam:
          </Typography>
        ),
        size: 130,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          if (!isNested)
            return (
              <div className="d-flex align-items-center w-100 justify-content-end gap-1 pl-4">
                <TextField.Async
                  disabled={isEditDisabled}
                  inputClassName={styles.manufacturerSelectorInput}
                  mutationHook={draftOrdersActions.usePatchLineItem}
                  transformQueryData={quantity => ({
                    id: lineItem.id,
                    toUpdate: {
                      quantity: Number(quantity),
                    },
                  })}
                  size="small"
                  type="number"
                  value={lineItem.quantity}
                />
                <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                  szt.
                </Typography>
              </div>
            );

          return (
            <div className="w-100 d-flex align-items-baseline justify-content-end gap-1">
              <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
                {lineItem.quantity}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "removeIndex",
        header: " ",
        size: 30,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          if (isNested) return null;

          return (
            <IconButton
              disabled={isEditDisabled}
              icon={MdiClose}
              isLoading={
                removeIndexMutation.isLoading && removeIndexMutation.variables === lineItem.id
              }
              onClick={() => removeIndexMutation.mutate(lineItem.id)}
              variant="transparent"
            />
          );
        },
      }),
    ];
  });
};

const SelectProducer = memo(
  ({ lineItem, isEditDisabled }: { lineItem: NormalizedLineItem; isEditDisabled: boolean }) => {
    const manufacturers = useSelector(store => store.partials.manufacturers);
    const manufacturersOptions = useMemo(
      () =>
        manufacturers.map(manufacturer => ({
          text: manufacturer.name,
          type: MenuItemType.TEXT,
          value: manufacturer.id,
        })),
      [manufacturers],
    );

    return (
      <Select.Async
        disabled={isEditDisabled}
        items={manufacturersOptions}
        mutationHook={draftOrdersActions.usePatchLineItem}
        selected={lineItem.manufacturer?.id ?? null}
        textFieldProps={{
          containerClassName: styles.manufacturerSelector,
          inputClassName: styles.manufacturerSelectorInput,
        }}
        transformQueryData={manufacturerId => {
          const selectedManufacturer = manufacturers.find(
            manufacturer => manufacturer.id === manufacturerId,
          );
          assertIsDefined(selectedManufacturer);

          return {
            id: lineItem.id,
            toUpdate: {
              manufacturer: {
                email: selectedManufacturer.email,
                id: selectedManufacturer.id,
                name: selectedManufacturer.name,
              },
            },
          };
        }}
      />
    );
  },
);
