import { OrderForInvoicing } from "api/orders/models";
import { ItemsOrService } from "./addOrdersToDraft/AddOrdersToDraftContent";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Partials } from "api/other/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { RouteForInvoicing } from "api/routes/models";

const areAllPositionsAlreadyInvoiced = (order: OrderForInvoicing | RouteForInvoicing): boolean => {
  if (order.hasOwnProperty("isInvoiced")) {
    //@ts-ignore
    return order?.isInvoiced;
  }
  //@ts-ignore
  return order?.positions?.every(position => position?.isInvoiced);
};

const normalizePositionsForRoute = (selectedRoutes: RouteForInvoicing[]) => {
  return selectedRoutes.flatMap(selectedRoute =>
    selectedRoute.positions.map(position => ({
      ...position,
      discount: Number("0"),
      orderId: position.orderId ? Number(position.orderId) : null,
      amountWithTax: Number(position.amountWithTax),
      quantity: Number(position.quantity),
      vatRate: Number(position.vatRate),
    })),
  );
};

const normalizePositions = (
  itemsOrServices: ItemsOrService,
  selectedOrders: OrderForInvoicing[] | RouteForInvoicing[],
) => {
  if (itemsOrServices === "onlyItems")
    return selectedOrders.flatMap(selectedOrder =>
      selectedOrder.positions
        .filter(position => !position.isService)
        .map(position => ({
          ...position,
          discount: Number("0"),
          orderId: selectedOrder.id,
          amountWithTax: Number(position.amountWithTax),
          quantity: Number(position.quantity),
          vatRate: Number(position.vatRate),
        })),
    );
  if (itemsOrServices === "onlyServices")
    return selectedOrders.flatMap(selectedOrder =>
      selectedOrder.positions
        .filter(position => position.isService)
        .map(position => ({
          ...position,
          discount: Number("0"),
          orderId: selectedOrder.id,
          amountWithTax: Number(position.amountWithTax),
          quantity: Number(position.quantity),
          vatRate: Number(position.vatRate),
        })),
    );
  return selectedOrders.flatMap(selectedOrder =>
    selectedOrder.positions.map(position => ({
      ...position,
      discount: Number("0"),
      orderId: selectedOrder.id,
      amountWithTax: Number(position.amountWithTax),
      quantity: Number(position.quantity),
      vatRate: Number(position.vatRate),
    })),
  );
};

const getPricingMethod = (
  priceList?: { id?: number | string; name?: string },
  discount?: number | string,
  margin?: number | string,
  priceLists?: Partials["priceLists"],
): JSX.Element => {
  const foundPriceList = priceLists?.find(_priceList => _priceList.id === priceList?.id);
  if (priceList && Object.keys(priceList).length > 0)
    return (
      <div className="d-flex align-items-center gap-1">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
          Cena w oparciu o cennik
        </Typography>
        {(foundPriceList || priceList.name) && (
          <Typography className="italic" color="neutralBlack88" fontSize="14" fontWeight="600">
            {priceList.name ? [priceList.name] : foundPriceList ? foundPriceList.name : EMPTY_VALUE}
          </Typography>
        )}
        {Boolean(discount) && (
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            - rabat: {discount}%
          </Typography>
        )}
        {Boolean(margin) && (
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            - narzut: {margin}%
          </Typography>
        )}
      </div>
    );
  if (!priceList && discount)
    return (
      <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
        Cena - rabat {discount}%
      </Typography>
    );
  if (!priceList && margin)
    return (
      <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
        Cena - narzut {margin}%
      </Typography>
    );
  return (
    <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
      Cena bez modyfikacji
    </Typography>
  );
};

export const createDraftDocumentUtils = {
  areAllPositionsAlreadyInvoiced,
  normalizePositions,
  normalizePositionsForRoute,
  getPricingMethod,
};
