import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import styles from "./ErrorDataModal.module.css";
import { cx } from "utilities";
import { TradingDocument } from "api/trading-documents/models";
import ReactJson from "react-json-view";

interface Props {
  close: () => void;
  data: TradingDocument["statusesInExternalService"]["sending"]["sendingToExternalServiceData"];
}

type ParsedJSONResponse = {
  header: Record<string, string>;
  url: string;
  data: string | Record<string, string>;
  response: string;
};

export const ErrorDataModal = ({ close, data }: Props) => {
  const normalizedData = () => {
    const parsedData: ParsedJSONResponse = JSON.parse(data!);

    if (typeof parsedData.data === "string") parsedData.data = JSON.parse(parsedData.data);

    return parsedData;
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dane
        </Typography>
      }
      width={800}
    >
      <div className="p-3">
        <div className={cx(styles.jsonContainer, "pb-3 overflow-auto")}>
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={normalizedData()}
          />
        </div>
        <div className="pt-3 borderTop">
          <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
