import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import styles from "./CreateDraftDocument.module.css";
import { useParams } from "react-router";
import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError } from "components/utils";
import { cx } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { HeaderSection } from "./subcomponents/headerSection/HeaderSection";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ActionButtonsSection } from "./subcomponents/actionButtonsSection/ActionButtonsSection";
import { useNavigate } from "hooks/useNavigate";
import { DocumentPositions } from "./subcomponents/documentPositions/DocumentPositions";
import { SummarySection } from "./subcomponents/summarySection/SummarySection";
import { PaymentDetailsSection } from "./subcomponents/paymentDetailsSection/PaymentDetailsSection";
import { UUID } from "api/types";
import { GeneralInfoSection } from "./subcomponents/generalInfoSection/GeneralInfoSection";
import { ExchangeRateSection } from "./subcomponents/exchangeRateSection/ExchangeRateSection";
import { useSelector } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useTradingDocumentsSettings } from "api/milo-settings/hooks";
import { Line } from "components/miloDesignSystem/atoms/line";
import { Paper } from "components/miloDesignSystem/atoms/paper";

export const CreateDraftDocument = () => {
  const params = useParams<{ id: UUID }>();
  const { data: draftInvoice, error: draftError, isLoading: isDraftLoading } = useTradingDocument(
    { id: params.id },
    { enabled: Boolean(params.id) },
  );
  const navigate = useNavigate();
  const tradingDocumentsSettingsId = useSelector(
    store => store.partials.finances.tradingDocumentSettings[0].id,
  );
  assertIsDefined(tradingDocumentsSettingsId);
  const {
    data: tradingDocumentSettings,
    error: settingsError,
    isLoading: areSettingsLoading,
  } = useTradingDocumentsSettings({
    id: tradingDocumentsSettingsId,
  });

  if (draftError || settingsError)
    return (
      <PageWrapper>
        <PageHeader
          bigTitle="Faktura na podmiot własny"
          searchBar={false}
          viewLabel="CREATE_DRAFT_DOCUMENT"
        />
        <div
          className={cx(
            styles.mainContainer,
            "d-flex align-items-center justify-content-center mt-4",
          )}
        >
          <CommonError status={draftError?._httpStatus_} />
          <CommonError status={settingsError?._httpStatus_} />
        </div>
      </PageWrapper>
    );

  if (isDraftLoading || areSettingsLoading)
    return (
      <PageWrapper>
        <PageHeader
          bigTitle="Faktura na podmiot własny"
          searchBar={false}
          viewLabel="CREATE_DRAFT_DOCUMENT"
        />
        <div className={styles.mainContainer}>
          <Spinner size={26} />
        </div>
      </PageWrapper>
    );

  if (!draftInvoice) return null;

  return (
    <PageWrapper>
      <PageHeader
        bigTitle="Faktura na podmiot własny"
        searchBar={false}
        viewLabel="CREATE_DRAFT_DOCUMENT"
      />

      <Paper className="d-flex flex-column flex-1 m-3 overflow-hidden p-3 mb-4">
        <HeaderSection draftInvoice={draftInvoice} />

        <GeneralInfoSection
          draftInvoice={draftInvoice}
          tradingDocumentSettings={tradingDocumentSettings}
        />
        <Line lineStyle="dashed" />
        <ActionButtonsSection draftInvoice={draftInvoice} />
        <DocumentPositions draftInvoice={draftInvoice} />
        <div className="my-3">
          <SummarySection draftInvoice={draftInvoice} />
        </div>
        <PaymentDetailsSection draftInvoice={draftInvoice} />
        {draftInvoice.currency !== "PLN" && <ExchangeRateSection draftInvoice={draftInvoice} />}

        <div className="mt-3">
          <Button
            className="text-uppercase"
            onClick={() => navigate(`/finances/sales/list/all?panelId=${draftInvoice.id}`)}
            size="medium"
            variant="deepPurple"
          >
            Gotowe
          </Button>
        </div>
      </Paper>
    </PageWrapper>
  );
};
