import { indexesActions } from "api/indexes/actions";
import { UUID } from "api/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useFilters } from "hooks/useFilters";
import { FiltersSection, IndexesAndPackagesFilters } from "../shared/components/FiltersSection";
import { useState } from "react";
import { useIndexesColumns } from "./useIndexesColumns";
import styles from "../CreateDraftOrder.module.css";
import { cx } from "utilities";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";

interface Props {
  close: () => void;
  externalManufacturingDraft: UUID;
}

export const AddIndexToDraftOrderModal = ({ close, externalManufacturingDraft }: Props) => {
  const { filters, searchParams, setFilter } = useFilters<IndexesAndPackagesFilters>({
    demandsGreaterThanState: "",
    page: 1,
    search: "",
    indexStateLowerThen: "",
    packagesAvailableStatus: "",
    storedLte: "",
  });
  const {
    data: indexes,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = indexesActions.useIndexesForExternalManufacturing(searchParams);
  const [selected, setSelected] = useState<number[]>([]);
  const addLineItemMutation = draftOrdersActions.useAddLineItem();
  const columns = useIndexesColumns(
    indexes.map(index => index.id),
    selected,
    setSelected,
  );

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj warianty
        </Typography>
      }
      width={1140}
    >
      <div
        className={cx(
          styles.addIndexOrPackagesTable,
          "d-flex flex-column flex-1 overflow-hidden justify-content-between",
        )}
      >
        <div className="d-flex flex-column py-2 gap-3 flex-1 overflow-hidden justify-content-between">
          <FiltersSection
            filters={filters}
            setFilter={setFilter}
            sourceKind={DraftOrderLineItemSourceKind.INDEX}
          />
          <div className="d-flex flex-column flex-1 overflow-auto justify-content-between">
            <Table<IndexForExternalManufacturingListItem>
              columns={columns}
              rows={indexes}
              isLoading={isLoading || isPreviousData}
              error={error}
              pagination={pagination}
              onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
              uiSchema={{ cell: { height: "28" }, header: { backgroundColor: "neutralWhite100" } }}
            />
          </div>
        </div>
        <div className="d-flex align-items-center p-3 gap-2">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            isLoading={addLineItemMutation.isLoading}
            onClick={() =>
              addLineItemMutation.mutate(
                {
                  externalManufacturingOrderDraft: externalManufacturingDraft,
                  ids: selected,
                  sourceKind: DraftOrderLineItemSourceKind.INDEX,
                },
                {
                  onSuccess: () => close(),
                },
              )
            }
            size="medium"
            variant="deepPurple"
          >
            Dodaj do zlecenia
          </Button>
        </div>
      </div>
    </Modal>
  );
};
