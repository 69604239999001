export enum IndexesTab {
  ALL = "all",
  WITHDRAWN = "withdrawn",
}

const indexesTabDict: Record<IndexesTab, string> = {
  all: "Wszystkie",
  withdrawn: "Wycofane ze sprzedaży",
};

export const indexesConstants = {
  indexesTabDict,
};
