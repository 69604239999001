import { productsActions } from "api/products/actions";
import { Product, ProductKind } from "api/products/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { productsConstants } from "pages/inventory/products/constants/productsConstants";
import { SelectableOption } from "pages/inventory/products/shared/components/selectableOption/SelectableOption";
import { dateUtils, dictToList } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { AttributesVisibilityOnLabel } from "./AttributesVisibilityOnLabel";

interface Props {
  product: Product;
}

export const GeneralInfoSection = ({ product }: Props) => {
  const isSelfProducedMutation = productsActions.usePatchProduct();
  const productKindMutation = productsActions.usePatchProduct();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <InfoLabel title="utworzono">
        <div className="d-flex align-items-center gap-2">
          {product.createdBy && (
            <div className="d-flex align-items-center gap-1">
              <Avatar size="sm" user={product.createdBy} />
              <Typography fontSize="14" fontWeight="700" noWrap>
                {product.createdBy.firstName} {product.createdBy.lastName}
              </Typography>
            </div>
          )}
          <Typography fontSize="14" fontWeight="400">
            {dateUtils.formatDateToDisplayOnlyDate(product.created)}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="ostatnio edytowano">
        {product.lastModifiedBy ? (
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-1">
              <Avatar size="sm" user={product.lastModifiedBy} />
              <Typography fontSize="14" fontWeight="700" noWrap>
                {product.lastModifiedBy.firstName} {product.lastModifiedBy.lastName}
              </Typography>
            </div>
            <Typography fontSize="14" fontWeight="400">
              {dateUtils.formatDateToDisplayOnlyDate(product.lastModified)}
            </Typography>
          </div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="produkcja">
        <div className="d-flex align-items-center gap-1">
          <SelectableOption
            checked={product.isSelfProduced}
            isLoading={isSelfProducedMutation.isLoading}
            label="Własna"
            onClick={() =>
              isSelfProducedMutation.mutate({
                id: product.id,
                isSelfProduced: true,
              })
            }
          />
          <SelectableOption
            checked={!product.isSelfProduced}
            isLoading={isSelfProducedMutation.isLoading}
            label="Prod. zewnętrzna"
            onClick={() =>
              isSelfProducedMutation.mutate({
                id: product.id,
                isSelfProduced: false,
              })
            }
          />
        </div>
      </InfoLabel>
      <InfoLabel title="typ produktu">
        <div className="d-flex align-items-center gap-1">
          {dictToList(productsConstants.productsKindDict)
            .filter(({ key }) => key !== ProductKind.OTHER && key !== ProductKind.SET)
            .map(({ key, value }) => (
              <SelectableOption
                checked={product.kind === key}
                isLoading={productKindMutation.isLoading}
                key={key}
                label={value}
                onClick={() =>
                  productKindMutation.mutate({
                    id: product.id,
                    kind: key,
                  })
                }
              />
            ))}
        </div>
      </InfoLabel>
      <AttributesVisibilityOnLabel />
    </RightPanelSection>
  );
};
