import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { packagesActions } from "api/packages/actions";
import { PackageListItem } from "api/warehouse/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RightPanelSection } from "components/utils/drawer";
import { queryString } from "utilities";
import { usePackagesColumns } from "./usePackagesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { AssignPackage } from "./assignPackage/AssignPackage";
import { useToggle } from "hooks";

interface Props {
  index: IndexForExternalManufacturingDetails;
}

export const PackagesSection = ({ index }: Props) => {
  const assignmentController = useToggle();
  const search = queryString.stringify({
    index: index.id,
  });
  const { data: packages, error, isLoading, isPreviousData, pagination } = packagesActions.useItems(
    search,
  );
  const columns = usePackagesColumns({
    index,
    search,
  });
  const tableProps = useTableFeatureConnector({
    rows: packages,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <RightPanelSection padding="pt-2 pb-2 px-3" title="Paczki">
      <Table<PackageListItem>
        columns={columns}
        error={error}
        isLoading={isLoading || isPreviousData}
        uiSchema={{ cell: { height: "34" }, header: { backgroundColor: "neutralWhite100" } }}
        {...tableProps}
        overrides={() => {
          return {
            ...tableProps.overrides?.(),
            hideHeader: !packages.length,
            noResultComponent: <EmptySection label="Brak paczek w wariancie" />,
          };
        }}
      />
      <div className="pt-2">
        {!assignmentController.isOpen && (
          <Button
            className="text-uppercase"
            onClick={() => assignmentController.open()}
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj paczkę
          </Button>
        )}
        {assignmentController.isOpen && (
          <AssignPackage index={index} assignmentController={assignmentController} />
        )}
      </div>
    </RightPanelSection>
  );
};
