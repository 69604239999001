import { indexesActions } from "api/indexes/actions";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer/Drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { TitleSection } from "./components/titleSection/TitleSection";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { PackagesSection } from "./components/packagesSection/PackagesSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: index, error, isLoading } = indexesActions.useIndexForExternalManufacturing(
    panelId,
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!index) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={index.id}>
        <PanelHeader index={index} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection index={index} />
            <GeneralInfoSection index={index} />
            <PackagesSection index={index} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
