import {
  CreateTradingDocumentPayment,
  TradingDocument,
  TradingDocumentPaymentKind,
} from "api/trading-documents/models";
import { FormikContextType, useFormikContext } from "formik";
import styles from "./AddPaymentModal.module.css";
import { cx, dateUtils } from "utilities";
import { FormInput, FormSelect } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { currenciesToPick } from "CONSTANTS";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { AdvancePaymentItems } from "./subcomponents/AdvancePaymentItems";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  tradingDocument: TradingDocument;
}

export const AddPaymentForm = ({ tradingDocument }: Props) => {
  const context = useFormikContext<CreateTradingDocumentPayment>();

  return (
    <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
      {context.values.kind === "ADVANCE" ? (
        <AdvanceForm tradingDocument={tradingDocument} />
      ) : (
        <PaymentForm tradingDocument={tradingDocument} />
      )}
      <>
        <Typography color="grey500" fontSize="10" fontWeight="500">
          Odpowiedzialny(/a)
        </Typography>
        <div className="d-flex align-items-center gap-2 pl-1 pb-1">
          <Avatar size="sm" user={context.values.createdBy} />
          <Typography fontSize="16" fontWeight="700" noWrap>
            {context.values.createdBy.firstName} {context.values.createdBy.lastName}
          </Typography>
        </div>
      </>
      <>
        <Typography color="grey500" fontSize="10" fontWeight="500">
          Dodano
        </Typography>
        <Typography fontSize="16" fontWeight="700">
          {dateUtils.formatDateToDisplayOnlyDate(context.values.createdAt)}
        </Typography>
      </>
    </div>
  );
};

const PaymentForm = ({ tradingDocument }: Props) => {
  const context = useFormikContext<CreateTradingDocumentPayment>();
  const fields = fieldFactory(context, tradingDocument);

  return (
    <>
      {fields.kind()}
      <div className="d-flex align-items-center gap-3">
        {fields.paymentNumber()}
        {fields.paymentDate()}
      </div>
      {fields.amountAndCommission()}
      {fields.currency()}
      {fields.paymentType()}
      {fields.provider()}
      {fields.bankTransaction()}
    </>
  );
};

const AdvanceForm = ({ tradingDocument }: Props) => {
  const context = useFormikContext<CreateTradingDocumentPayment>();
  const fields = fieldFactory(context, tradingDocument);

  return (
    <>
      {fields.kind()}
      <div className="d-flex align-items-center gap-3">
        {fields.paymentNumber()}
        {fields.paymentDate()}
      </div>
      <AdvancePaymentItems tradingDocument={tradingDocument} />
      {fields.currency()}
      {fields.paymentType()}
      {fields.provider()}
      {fields.bankTransaction()}
    </>
  );
};

const fieldFactory = (
  context: FormikContextType<CreateTradingDocumentPayment>,
  tradingDocument: Props["tradingDocument"],
) => {
  return {
    kind: () => {
      return (
        <div className={cx(styles.select, "w-100")}>
          <FormSelect
            items={tradingDocumentConstants.tradingDocumentPaymentKindOptions}
            itemToSelection={item => (item ? item.id : null)}
            label="Typ płatności"
            name="kind"
            onChange={item => {
              if (item) {
                context.setFieldValue(
                  "amount",
                  getDefaultAmount(context, item.id, tradingDocument),
                );
                if (item.id === "ADVANCE") {
                  context.setFieldValue("type", "ONLINE");
                }
              }
            }}
            placeholder="Wybierz typ płatności"
            selectedItem={context.values.kind}
            width="full"
          />
        </div>
      );
    },
    paymentNumber: () => (
      <div className={cx(styles.inputBox, "w-100")}>
        <FormInput autoFocus label="Nr płatności" name="paymentNumber" />
      </div>
    ),
    paymentDate: () => (
      <div className={cx(styles.inputBox, styles.datePick)}>
        <DatePicker
          className={styles.datePickContainer}
          overwrites={{
            popup: { className: styles.datePickPopUp },
          }}
          portalId={undefined}
          label="Data płatności"
          look="common"
          onChange={date => {
            if (!date) {
              context.setFieldValue("paymentDate", null);
            } else {
              context.setFieldValue("paymentDate", dateUtils.formatDateToIso(date));
            }
          }}
          placeholder="dd/MM/yy"
          value={context.values.paymentDate || ""}
        />
      </div>
    ),
    currency: () => (
      <div className={cx(styles.select, "w-100")}>
        <FormSelect
          items={currenciesToPick.map(currency => ({
            id: currency.value,
            name: currency.label,
          }))}
          itemToSelection={item => (item ? item.id : null)}
          label="Waluta"
          name="currency"
          placeholder="Wybierz walutę"
          selectedItem={context.values.currency}
          width="full"
        />
      </div>
    ),
    provider: () => (
      <div className={cx(styles.select, "w-100")}>
        <FormSelect
          items={[
            { id: "", name: "nie dotyczy" },
            ...tradingDocumentConstants.tradingDocumentPaymentProviderOptions,
          ]}
          itemToSelection={item => (item ? item.id : null)}
          label="Usługa"
          name="provider"
          placeholder="Wybierz usługę"
          selectedItem={context.values.provider}
          width="full"
        />
      </div>
    ),
    amountAndCommission: () => (
      <div className="d-flex align-items-center w-100 gap-3">
        <div className="d-flex align-items-center w-100">
          <div className={cx(styles.inputBox, "w-100")}>
            <FormInput autoFocus label="Kwota" name="amount" type="number" />
          </div>
        </div>
        <div className="d-flex align-items-center w-100 fw-400">
          <div className={cx(styles.inputBox, "w-100")}>
            <FormInput label="Prowizja" name="commission" type="number" />
          </div>
        </div>
      </div>
    ),
    paymentType: () => (
      <div className={cx(styles.select, "w-100")}>
        <FormSelect
          disabled={context.values.kind === "ADVANCE"}
          items={tradingDocumentConstants.tradingDocumentPaymentTypeOptions}
          itemToSelection={item => (item ? item.id : null)}
          label="Sposób"
          name="type"
          onChange={item => {
            if (item) {
              context.setFieldValue(
                "amount",
                getDefaultAmount(context, context.values.kind, tradingDocument),
              );
            }
          }}
          placeholder="Wybierz sposób płatności"
          selectedItem={context.values.type}
          width="full"
        />
      </div>
    ),
    bankTransaction: () => {
      if (context.values.type === "CASH") return null;
      return (
        <div className="d-flex align-items-center gap-3">
          <div className={cx(styles.inputBox, "w-100")}>
            <FormInput label="Nr przelewu" name="bankTransactionNumber" />
          </div>
          <div className={cx(styles.inputBox, styles.datePick)}>
            <DatePicker
              className={styles.datePickContainer}
              overwrites={{
                popup: { className: styles.datePickPopUp },
              }}
              portalId={undefined}
              label="Data przelewu"
              look="common"
              onChange={date => {
                if (!date) {
                  context.setFieldValue("bankTransactionDate", null);
                } else {
                  context.setFieldValue("bankTransactionDate", dateUtils.formatDateToIso(date));
                }
              }}
              placeholder="dd/mm/yy"
              value={context.values.bankTransactionDate || ""}
            />
          </div>
        </div>
      );
    },
  };
};

export const getDefaultAmount = (
  context: FormikContextType<CreateTradingDocumentPayment>,
  paymentKind: Omit<TradingDocumentPaymentKind, "OTHER">,
  tradingDocument: TradingDocument,
): number => {
  if (paymentKind === "ADVANCE" && context.values.items && Boolean(context.values.items.length)) {
    return context.values.items.reduce((acc, tradingDocumentItem) => {
      return acc + tradingDocumentItem.totalAmount;
    }, 0);
  }
  if (paymentKind === "PAYMENT" && tradingDocument.amountSummary.totalWithTax) {
    const paymentsAmount = tradingDocument.payments.reduce((acc, payment) => {
      return (acc += Number(payment.amount.toFixed(2)));
    }, 0);
    return Number(
      (
        Number(Number(tradingDocument.amountSummary.totalWithTax).toFixed(2)) -
        Number(paymentsAmount.toFixed(2))
      ).toFixed(2),
    );
  }
  return 0;
};
