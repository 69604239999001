import austria from "assets/images/countries/austria.svg";
import czechRepublic from "assets/images/countries/czechRepublic.svg";
import france from "assets/images/countries/france.svg";
import germany from "assets/images/countries/germany.svg";
import hungary from "assets/images/countries/hungary.svg";
import lithuania from "assets/images/countries/lithuania.svg";
import croatia from "assets/images/countries/croatia.svg";
import estonia from "assets/images/countries/estonia.svg";
import unitedStates from "assets/images/countries/unitedStates.svg";
import poland from "assets/images/countries/poland.svg";
import slovakia from "assets/images/countries/slovakia.svg";
import unitedKingdom from "assets/images/countries/unitedKingdom.svg";
import ukraine from "assets/images/countries/ukraine.svg";
import romania from "assets/images/countries/romania.svg";
import italy from "assets/images/countries/italy.svg";
import latvia from "assets/images/countries/latvia.svg";
import slovenia from "assets/images/countries/slovenia.svg";
import luxembourg from "assets/images/countries/luxembourg.svg";
import ireland from "assets/images/countries/ireland.svg";
import spain from "assets/images/countries/spain.svg";
import belgium from "assets/images/countries/belgium.svg";
import netherlands from "assets/images/countries/netherlands.svg";
import bulgaria from "assets/images/countries/bulgaria.svg";
import russia from "assets/images/countries/russia.svg";
import serbia from "assets/images/countries/serbia.svg";
import denmark from "assets/images/countries/denmark.svg";
import switzerland from "assets/images/countries/switzerland.svg";
import norway from "assets/images/countries/norway.svg";
import sweden from "assets/images/countries/sweden.svg";
import finland from "assets/images/countries/finland.svg";
import portugal from "assets/images/countries/portugal.svg";
import albania from "assets/images/countries/albania.svg";
import belarus from "assets/images/countries/belarus.svg";
import bosnia from "assets/images/countries/bosnia.svg";
import greece from "assets/images/countries/greece.svg";
import kazakhstan from "assets/images/countries/kazakhstan.svg";
import moldova from "assets/images/countries/moldova.svg";
import montenegro from "assets/images/countries/montenegro.svg";
import turkey from "assets/images/countries/turkey.svg";
import georgia from "assets/images/countries/georgia.svg";
import monaco from "assets/images/countries/monaco.svg";
import { CountryCode } from "CONSTANTS";

export const countryToFlagDict: Record<CountryCode | string, string> = {
  PL: poland,
  CZ: czechRepublic,
  SK: slovakia,
  DE: germany,
  HU: hungary,
  LT: lithuania,
  AT: austria,
  UK: unitedKingdom,
  FR: france,
  UA: ukraine,
  GB: unitedKingdom,
  RO: romania,
  IT: italy,
  LV: latvia,
  SI: slovenia,
  LU: luxembourg,
  ES: spain,
  BE: belgium,
  NL: netherlands,
  IE: ireland,
  HR: croatia,
  US: unitedStates,
  EE: estonia,
  RU: russia,
  RS: serbia,
  BG: bulgaria,
  DK: denmark,
  CH: switzerland,
  NO: norway,
  SE: sweden,
  FI: finland,
  PT: portugal,
  AL: albania,
  BY: belarus,
  BA: bosnia,
  GR: greece,
  KZ: kazakhstan,
  MD: moldova,
  ME: montenegro,
  TR: turkey,
  GE: georgia,
  MC: monaco,
};
