import { ExecutionStatus as ExecutionStatusType } from "api/external-manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { executionStatusDict } from "pages/externalManufacturing/manufacturerOrderLineItems/components/ExecutionStatus";

interface Props {
  status: ExecutionStatusType;
}

export const ExecutionStatus = ({ status }: Props) => {
  return (
    <Tag label={executionStatusDict[status].label} variant={executionStatusDict[status].variant} />
  );
};
