import { CountryCode, POLAND_CENTER } from "CONSTANTS";
import { ListOrder } from "api/orders/models";
import { OrderCollectionSource } from "api/wh-entries/enums";
import { Button, useToastr } from "..";
import { Formik, FormikHelpers } from "formik";
import {
  AsyncInput,
  FormInput,
  Modal,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import styles from "./OrderDeliveryUpdateModal.module.css";
import { useGeocodeService } from "hooks";
import { cx, getAnyErrorKey } from "utilities";
import { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { MapOverlay } from "components/common/customerAddressModal/mapOverlay";
import markerImg from "assets/images/42.svg";
import routeMarkerImg from "assets/images/mapMarkers/p29.png";
import { patchDelivery, patchDeliveryAddress } from "api/deliveries/calls";

export interface ClientDetailsToUpdate {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  companyName?: string;
}

export type DeliveryAddressDetailsToUpdate = FormValues;

interface Props {
  close: () => void;
  source: OrderCollectionSource;
  normalizedOrder: NormalizedOrder;
  onGeolocalizationSuccess: ({ toUpdate }: { toUpdate: DeliveryAddressDetailsToUpdate }) => void;
  onClientDetailsUpdateSuccess: ({ toUpdate }: { toUpdate: ClientDetailsToUpdate }) => void;
}

interface NormalizedOrder {
  client: ListOrder["client"] & { email?: string };
  customer: {
    hasDropShipping: boolean;
  } | null;
  delivery: {
    city: string;
    id: number;
    phone: string;
    postCode: string;
    street: string;
  };
  id: number;
  signature: string;
}

interface FormValues {
  city: string;
  postCode: string;
  street: string;
  point: { lng: number; lat: number } | null;
  countryCode: CountryCode;
}

export const OrderDeliveryUpdateModal = ({
  close,
  normalizedOrder,
  onGeolocalizationSuccess,
  onClientDetailsUpdateSuccess,
  source,
}: Props) => {
  const toastr = useToastr();
  const geocodeService = useGeocodeService();
  const [geolocalizationError, setGeolocalizationError] = useState(false);

  const initialValues: FormValues = {
    street: normalizedOrder.delivery?.street,
    postCode: normalizedOrder.delivery?.postCode,
    city: normalizedOrder.delivery?.city,
    point: null,
    countryCode: "" as CountryCode,
  };

  const geocode = async (
    geocoderRequest: google.maps.GeocoderRequest,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    await geocodeService
      .handleGeocode(geocoderRequest)
      .then(response => {
        setFieldValue("point", response.point);
        setFieldValue("countryCode", response.countryCode);
      })
      .catch(error => {
        toastr.open({
          type: "warning",
          title: "Niepoprawna lokalizacja",
          text: getAnyErrorKey(error),
        });
      });
  };

  const handleSubmitForm = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const [payload, error] = await patchDeliveryAddress(normalizedOrder.delivery.id, values);

    if (payload) {
      setGeolocalizationError(false);
      actions.setTouched({ city: false, street: false, point: false, postCode: false });
      onGeolocalizationSuccess({ toUpdate: { ...values, point: payload.geolocation.point } });
      actions.setFieldValue("point", null);
    } else if (error) {
      setGeolocalizationError(true);
      actions.setErrors(error);
    }
  };

  async function handleUpdateClientDetails(
    toUpdate: {
      firstName?: string;
      lastName?: string;
      email?: string;
      phone?: string;
      companyName?: string;
    },
    helpers: StatusHandlerHelpers,
  ) {
    helpers.startFetching();
    const [payload, error] = await patchDelivery(normalizedOrder.delivery.id, toUpdate);
    if (payload) {
      helpers.stopFetching();
      onClientDetailsUpdateSuccess({ toUpdate });
    } else if (error) {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  return (
    <Modal
      isOpen
      close={close}
      overrides={{
        container: {
          className: cx(styles.orderUpdateModalContainer, {
            [styles.orderUpdateModalMargin]: geolocalizationError,
          }),
        },
      }}
    >
      <div>
        <div className="font-bold fs-18 mb-4">Edytuj dane klienta</div>
        <div className="fs-14 font-bold text-color-grey mb-2">Zamówienie</div>
        {geolocalizationError ? (
          <div>
            <div className="fs-14 font-bold ">{normalizedOrder.signature}</div>
            {normalizedOrder.customer && !normalizedOrder.customer.hasDropShipping ? (
              <div className="fs-14 font-bold ">{normalizedOrder.client.companyName}</div>
            ) : (
              <div className="d-flex align-item-center">
                <div className="fs-14 font-bold mr-1">{normalizedOrder.client.firstName}</div>
                <div className="fs-14 font-bold ">{normalizedOrder.client.lastName}</div>
              </div>
            )}
            <div className="fs-14 font-bold ">tel. {normalizedOrder.client.phone}</div>
          </div>
        ) : (
          <div className="fs-14 font-bold mb-3">{normalizedOrder.signature}</div>
        )}
        <div className="fs-14 font-bold text-color-grey mb-1">Dane dostawy</div>
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit} className={cx({ "was-validated": !isValid })}>
              {geolocalizationError && (
                <div className="flex-1 position-relative">
                  <GoogleMap
                    mapContainerStyle={{
                      height: "50vh",
                      width: "100%",
                    }}
                    onClick={(mapEvent: google.maps.MapMouseEvent) =>
                      geocode({ location: mapEvent.latLng }, setFieldValue)
                    }
                    zoom={6}
                    options={{
                      mapTypeControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      draggableCursor: "default",
                    }}
                    center={POLAND_CENTER}
                  >
                    {values.point && (
                      <Marker
                        position={values.point}
                        onClick={() => setFieldValue("point", null)}
                        icon={routeMarkerImg}
                      ></Marker>
                    )}
                  </GoogleMap>
                  <Button
                    disabled={
                      !values.street || !values.postCode || !values.city || geocodeService.isLoading
                    }
                    className={styles.localizeBtn}
                    kind="primary"
                    onClick={() =>
                      geocode(
                        { address: `${values.street} ${values.postCode} ${values.city}` },
                        setFieldValue,
                      )
                    }
                  >
                    Lokalizuj adres
                  </Button>
                  <MapOverlay />
                </div>
              )}
              <div className="my-3">
                <FormInput
                  name="street"
                  label="Adres"
                  onChange={() => setFieldTouched("street", true)}
                />
              </div>
              <div className="d-flex mt-3 mb-0">
                <div className="w-30 mr-2">
                  <FormInput
                    name="postCode"
                    label="Kod pocztowy"
                    className="mb-3"
                    onChange={() => setFieldTouched("postCode", true)}
                  />
                </div>
                <div className="w-70">
                  <FormInput
                    name="city"
                    label="Miejscowość"
                    className="mb-3"
                    onChange={() => setFieldTouched("city", true)}
                  />
                </div>
              </div>
              {geolocalizationError ? (
                <div className="mt-3">
                  <Button
                    kind="primary"
                    size="medium"
                    onClick={submitForm}
                    disabled={!values.point}
                  >
                    Zapisz
                  </Button>
                </div>
              ) : (
                <div className="mb-4">
                  <Button
                    kind="secondary-stroke"
                    size="small"
                    type="submit"
                    disabled={isSubmitting || !Object.values(touched).some(el => el)}
                    className="d-flex"
                  >
                    <img src={markerImg} alt="" className="mr-1" />
                    Geolokalizuj adres
                  </Button>
                  {Object.values(touched).some(el => el) && (
                    <span className="warning">
                      Aby upewnić się, że zmieniony adres jest poprawny kliknij przycisk powyżej
                    </span>
                  )}
                </div>
              )}
            </form>
          )}
        </Formik>

        {!geolocalizationError && (
          <div>
            {normalizedOrder.customer && !normalizedOrder.customer.hasDropShipping ? (
              <div className="my-3">
                <StatusHandler>
                  {helpers => (
                    <AsyncInput
                      look="common"
                      inProgress={helpers.isFetching}
                      disabled={false}
                      onChange={value => handleUpdateClientDetails({ companyName: value }, helpers)}
                      value={normalizedOrder.client.companyName}
                      label="Nazwa firmy"
                    />
                  )}
                </StatusHandler>
              </div>
            ) : (
              <div>
                <div className="my-3">
                  <StatusHandler>
                    {helpers => (
                      <AsyncInput
                        look="common"
                        inProgress={helpers.isFetching}
                        disabled={false}
                        onChange={value => handleUpdateClientDetails({ firstName: value }, helpers)}
                        value={normalizedOrder.client.firstName}
                        label="Imię"
                      />
                    )}
                  </StatusHandler>
                </div>
                <div className="my-3">
                  <StatusHandler>
                    {helpers => (
                      <AsyncInput
                        look="common"
                        disabled={false}
                        onChange={value => handleUpdateClientDetails({ lastName: value }, helpers)}
                        value={normalizedOrder.client.lastName}
                        inProgress={helpers.isFetching}
                        label="Nazwisko"
                      />
                    )}
                  </StatusHandler>
                </div>
              </div>
            )}
            <div className="my-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    disabled={false}
                    onChange={value => handleUpdateClientDetails({ phone: value }, helpers)}
                    value={normalizedOrder.client.phone}
                    label="Nr telefonu"
                    inProgress={helpers.isFetching}
                  />
                )}
              </StatusHandler>
            </div>
            {source === OrderCollectionSource.ROUTE && (
              <div className="my-3">
                <StatusHandler>
                  {helpers => (
                    <AsyncInput
                      look="common"
                      disabled={false}
                      onChange={value => handleUpdateClientDetails({ email: value }, helpers)}
                      value={normalizedOrder.client.email ?? ""}
                      label="Email"
                      inProgress={helpers.isFetching}
                    />
                  )}
                </StatusHandler>
              </div>
            )}
          </div>
        )}
        {!geolocalizationError && (
          <Button kind="primary" size="medium" onClick={close}>
            Gotowe
          </Button>
        )}
      </div>
    </Modal>
  );
};
