import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoSection } from "./GeneralInfoSection";
import { LineItemsSection } from "./LineItemsSection";
import { NotificationsSection } from "pages/externalManufacturing/orders/rightPanel/notificationsSection/NotificationsSection";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { TitleSection } from "./TitleSection";
import { PanelHeader } from "./PanelHeader";
import { ReceptionsSection } from "./receptionsSection/ReceptionsSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("externalManufacturingOrders");
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const {
    data: order,
    error,
    isLoading,
  } = externalManufacturingActions.useExternalManufacturingOrder(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(order);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader
          close={close}
          id={order.id}
          isCancelled={order.isCancelled}
          signature={order.signature}
          orders={order.orders}
        />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody hasCommentsModule>
            <TitleSection order={order} />
            <GeneralInfoSection order={order} />
            <ReceptionsSection order={order} />
            <LineItemsSection id={order.id} />
            <NotificationsSection order={order} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="manufacturingOrder"
            endpointUrl={`/external-manufacturing/orders/items-comments`}
            commentedObject={order}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
