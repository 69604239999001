import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx, dateUtils } from "utilities";
import styles from "./SearchEntityDetails.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { useQuery, useThresholdToggle } from "hooks";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { calculateTimeDifference } from "pages/wms/unloadingsList/utils/calculateTimeDifference";
import { CollectionKind } from "api/wms/layout/models";

export const SearchEntityDetails = () => {
  const { query } = useQuery();
  const collectionId = query["searchCollectionId"];
  const { isOpen: isNavbarOpen } = useThresholdToggle();

  const { data: collectionDetails, isLoading, error } = wmsLayoutActions.useCollectionDetails(
    { id: Number(collectionId), kind: query.kind as CollectionKind },
    {
      enabled: Boolean(collectionId) && Boolean(query.kind),
    },
  );

  if (error) {
    return (
      <div className={cx("p-3", styles.wrapper)}>
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={cx("p-3 d-flex align-items-center justify-content-center", styles.wrapper)}>
        <Spinner size={30} />
      </div>
    );
  }

  if (!collectionDetails) return null;

  return (
    <div
      className={cx("p-3", styles.wrapper, {
        [styles.wrapperForOpenedNavbar]: isNavbarOpen,
        [styles.wrapperForClosedNavbar]: !isNavbarOpen,
      })}
    >
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="16" fontWeight="700" color="neutralWhite100">
          {collectionDetails.name}
        </Typography>
        {collectionDetails.ramp && <Tag label={collectionDetails.ramp.name} variant="warning" />}
      </div>
      {(collectionDetails.kind === CollectionKind.ROUTE ||
        collectionDetails.kind === CollectionKind.ORDER_GROUP) && (
        <div className="d-flex align-items-center gap-2 pt-3">
          <Typography fontSize="12" fontWeight="700" color="grey500">
            data załadunku:
          </Typography>
          {collectionDetails.data && collectionDetails.time ? (
            <>
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="12" fontWeight="700" color="neutralWhite100">
                  {dateUtils.formatDateToDisplayOnlyDate(collectionDetails.data)},
                </Typography>
                <Typography fontSize="12" fontWeight="700" color="neutralWhite100">
                  {collectionDetails.time.slice(0, 5)}
                </Typography>
              </div>
              <Tag
                theme="dark"
                label={
                  calculateTimeDifference(
                    dateUtils.getDateFromIsoDateAndIsoTime(
                      collectionDetails.data,
                      collectionDetails.time,
                    ),
                  ).label
                }
                variant={
                  calculateTimeDifference(
                    dateUtils.getDateFromIsoDateAndIsoTime(
                      collectionDetails.data,
                      collectionDetails.time,
                    ),
                  ).variant
                }
              />
            </>
          ) : (
            <EmptyValue color="neutralWhite100" />
          )}
        </div>
      )}
    </div>
  );
};
