import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { OrderLineItemDetails } from "api/external-manufacturing/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  lineItemDetails: OrderLineItemDetails;
}

export const PanelHeader = ({ lineItemDetails }: Props) => {
  const { close } = useDrawer("externalManufacturerLineItems");
  const handleDownloadExternalManufacturingLineItemLabelPdf = externalManufacturingActions.useDownloadExternalManufacturingLineItemsLabelsPdf();
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden && (
        <SlidingHeaderWrapper className="noWrap">{lineItemDetails.signature}</SlidingHeaderWrapper>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Pobierz etykietę">
          <IconButton
            icon={<MdiQrCode size="16" />}
            onClick={() =>
              handleDownloadExternalManufacturingLineItemLabelPdf(
                lineItemDetails.id,
                lineItemDetails.signature,
              )
            }
            variant="transparent"
          />
        </Tooltip>
        <FileDownloadHandler
          factoryFn={() =>
            externalManufacturingFileFactory.externalManufacturingLineItemsCsv(
              [lineItemDetails.id],
              lineItemDetails.signature,
            )
          }
          type="csv"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz CSV zlecenia">
              <IconButton
                icon={MdiDownloadCsv}
                isLoading={isLoading}
                onClick={download}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
