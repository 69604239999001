import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { useReceptionsColumns } from "./useReceptionsColumns";
import { whEntriesActions } from "api/wh-entries/actions";
import { RightPanelSection } from "components/utils/drawer";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ReceptionListItem } from "api/wh-entries/models";
import { useFilters } from "hooks/useFilters";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  order: ExternalManufacturingOrder;
}

interface Filters {
  page: number;
  sourceId: ExternalManufacturingOrder["id"];
}

export const ReceptionsSection = ({ order }: Props) => {
  const { searchParams, setFilter } = useFilters<Filters>({
    page: 1,
    sourceId: order.id,
  });
  const {
    data: receptions,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = whEntriesActions.useReceptions(searchParams);
  const columns = useReceptionsColumns();
  const tableProps = useTableFeatureConnector({
    rows: receptions,
  });

  return (
    <RightPanelSection title="PZ">
      <div className="pb-3">
        <Table<ReceptionListItem>
          columns={columns}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={comfortableListUiSchema}
          pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
          {...tableProps}
          overrides={() => ({
            ...tableProps?.overrides?.(),
            hideHeader: !receptions.length,
            noResultComponent: <EmptySection label="Brak przyjęć" />,
          })}
        />
      </div>
    </RightPanelSection>
  );
};
