import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useDraftOrdersColumns } from "./useDraftOrdersColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";

export const DraftOrders = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: draftOrders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = draftOrdersActions.useItems(search);
  const columns = useDraftOrdersColumns();
  const tableProps = useTableFeatureConnector({
    rows: draftOrders,
    withDrawer: "externalManufacturingDraftOrders",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  return (
    <PageHeader
      searchInput={{
        label: "Szukaj...",
        tags: [],
      }}
      title="Szkice zleceń"
      viewLabel="EXTERNAL_MANUFACTURING_DRAFT_ORDERS"
    />
  );
};
