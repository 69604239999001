import { IndexForExternalManufacturingDetails } from "api/indexes/models";
import { packagesActions } from "api/packages/actions";
import { packagesApi } from "api/packages/api";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Search } from "components/miloDesignSystem/molecules/search/Search";
import { Formik, FormikHelpers } from "formik";
import { ToggleHookState } from "hooks";
import { cx } from "utilities";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";

interface FormValues {
  index: number;
  package: number | null;
}

interface Props {
  assignmentController: ToggleHookState;
  index: IndexForExternalManufacturingDetails;
}

export const AssignPackage = ({ assignmentController, index }: Props) => {
  const initialValues: FormValues = {
    index: index.id,
    package: null,
  };

  const postMutation = packagesActions.usePostAssignment();

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    postMutation.mutate(
      {
        ...values,
        package: values.package as number,
      },
      {
        ...handleSubmitResponse(actions),
        onSuccess: () => {
          assignmentController.close();
          handleSubmitResponse(actions).onSuccess();
        },
      },
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
        <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
          <div className="mx-3">
            <div className="mb-2">
              <Search.Form
                customOnChange={value => {
                  if (value) {
                    setFieldValue("package", value.id);
                  } else setFieldValue("package", null);
                }}
                fetcherFn={packagesApi.getItems}
                isNullable
                name="package"
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 px-3 py-2">
            <Button
              className="text-uppercase"
              onClick={assignmentController.close}
              size="medium"
              variant="transparent"
            >
              Anuluj
            </Button>
            <Button
              className="text-uppercase"
              disabled={!values.package}
              isLoading={isSubmitting}
              size="medium"
              type="submit"
              variant="deepPurple"
            >
              Dodaj
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
