import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedScannedPosition } from "./CreateSalesInvoicesModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { PreviewTradingDocument } from "api/trading-documents/models";

export const useScannedPositionsColumns = (
  open: (stateToSet: { preview: PreviewTradingDocument; customerId?: number }) => void,
) => {
  const previewMutation = tradingDocumentsActions.useCustomerTradingDocumentPreview();

  return useCreateTableColumns<NormalizedScannedPosition>(({ columnHelper }) => {
    return [
      columnHelper.text(
        row =>
          row.customer
            ? row.customer.name
            : Boolean(row.orders.length)
            ? `${row.orders[0].delivery.firstName} ${row.orders[0].delivery.lastName}`
            : EMPTY_VALUE,
        {
          header: "kontrahent / klient",
          size: 312,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
          },
        },
      ),
      columnHelper.accessor(row => row.orders, {
        header: "zamówienia",
        size: 150,
        cell: info => {
          const orders = info.getValue();

          if (!Boolean(orders.length)) return <EmptyValue fontSize="14" fontWeight="400" />;

          return (
            <div className="d-flex align-items-center gap-1">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="400">
                {orders[0].signature}
              </Typography>
              {orders.length > 0 && (
                <Typography color="deepPurple400" fontSize="14" fontWeight="500">
                  +{orders.length - 0}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "faktura",
        size: 150,
        cell: info => {
          const scannedPosition = info.getValue();
          if (Boolean(scannedPosition.createdInvoices.length))
            return (
              <div className="d-flex align-items-center gap-2">
                <Typography fontSize="14" fontWeight="500" noWrap>
                  {scannedPosition.createdInvoices[0].signature}
                </Typography>
                {scannedPosition.createdInvoices.length > 1 && (
                  <Typography color="deepPurple400" fontSize="14" fontWeight="500">
                    + {scannedPosition.createdInvoices.length - 1}
                  </Typography>
                )}
              </div>
            );
          return (
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              isLoading={
                previewMutation.isLoading && previewMutation.variables?.id === scannedPosition.id
              }
              onClick={() =>
                previewMutation.mutate(
                  {
                    orders: scannedPosition.orders.map(order => order.id),
                    id: scannedPosition.id,
                    items: scannedPosition.orders.flatMap(order =>
                      order.items.map(item => ({
                        id: item.id,
                        quantity: item.quantity,
                      })),
                    ),
                  },
                  {
                    onSuccess: payload => {
                      open({
                        preview: payload,
                        customerId: scannedPosition.customer?.id,
                      });
                    },
                  },
                )
              }
              size="small"
              variant="gray"
            >
              Utwórz fakturę
            </Button>
          );
        },
      }),
    ];
  });
};
