import exchangeImg from "assets/images/64.svg";
import warrantyImg from "assets/images/308.svg";
import resignationImg from "assets/images/58.svg";
import screwImg from "assets/images/63.svg";
import verificationImg from "assets/images/verification.svg";
import styles from "./ComplaintsStatusFlow.module.css";
import wrenchImg from "assets/images/57.svg";
import { ListOrder, Order } from "api/orders/models";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  kind: Order["kind"];
  order: ListOrder;
  warrantyStatus: Order["warrantyStatus"];
}

function getKindTag(kind: Order["kind"]): { name: string; img: string } {
  switch (kind) {
    case "REPAIR": {
      return { name: "Naprawa", img: wrenchImg };
    }
    case "REPLACEMENT": {
      return { name: "Wymiana", img: exchangeImg };
    }
    case "RESIGNATION": {
      return { name: "Rezygnacja", img: resignationImg };
    }
    case "SUPPLEMENT": {
      return { name: "Uzupełnienie", img: screwImg };
    }
    case "INSPECTION": {
      return {
        name: "Oględziny",
        img: verificationImg,
      };
    }
    default:
      const exhaustiveCheck: never = kind;
      console.error(`Unhandled trading document kind: ${exhaustiveCheck}`);
      return { img: "", name: "" };
  }
}

const warrantyDict: Record<Order["warrantyStatus"], { name: string; img: string }> = {
  WITHOUT_WARRANTY: { name: "Po gwarancji", img: warrantyImg },
  WITH_WARRANTY: { name: "Na gwarancji", img: warrantyImg },
  "": { name: "", img: "" },
};

export const ComplaintsStatusFlow = ({ kind, warrantyStatus, order }: Props) => {
  const kindObj = getKindTag(kind);
  const warrantyObj = warrantyDict[warrantyStatus];

  return (
    <div>
      {order.statusTemp && (
        <div className="pb-1">
          <Tag.WithCustomColor
            backgroundColor={order.statusTemp.color}
            textColor={order.statusTemp.textColor}
            label={order.statusTemp.name}
          />
        </div>
      )}
      <div className={styles.statuses}>
        {warrantyStatus && (
          <div className={styles.status} title={warrantyObj.name}>
            <div className="mr-2">
              <img src={warrantyObj.img} alt="" />
            </div>
            <div className="text-color-blue fs-12 nowrap">{warrantyObj.name}</div>
          </div>
        )}
        <div className={styles.status} title={kindObj.name}>
          <div className="mr-2">
            <img src={kindObj.img} alt="" />
          </div>
          <div className="text-color-blue fs-12 nowrap">{kindObj.name}</div>
        </div>
      </div>
      {order.route && (
        <Typography fontFamily="cabin" fontSize="12" fontWeight="500">
          {order.route.signature}
        </Typography>
      )}
      {order.orderGroup && (
        <Typography fontFamily="cabin" fontSize="12" fontWeight="500">
          {order.orderGroup.signature}
        </Typography>
      )}
    </div>
  );
};
