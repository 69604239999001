import cx from "classnames";
import styles from "../NotificationSection.module.css";
import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";
import { NotificationActions } from "./Actions";

interface Props {
  tradingDocument: TradingDocument;
}

export const EmptyNotificationSection = ({ tradingDocument }: Props) => {
  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <MdiFolderOpen color="neutralBlack48" />
        <Typography className={cx(styles.sectionEmptyTitle, "pt-2")} fontSize="12" fontWeight="700">
          Jeszcze nie wysłano żadnej faktury
        </Typography>

        <NotificationActions tradingDocument={tradingDocument} />
      </div>
    </div>
  );
};
