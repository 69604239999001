import { RightPanelSection } from "components/utils/drawer";
import { EmptyNotificationSection } from "./components/EmptyNotificationSection";
import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { useToggle } from "hooks";
import { SendToSpecificAddressModal } from "./components/SendToSpecificAddressModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { NotificationSectionTable } from "./components/NotificationSectionTable";

interface Props {
  order: ExternalManufacturingOrder;
}

export const NotificationsSection = ({ order }: Props) => {
  const sendEmailNotification = externalManufacturingActions.useSendEmailNotification();
  const recipientEmailAddressModal = useToggle();

  return (
    <>
      <RightPanelSection title="Wysyłka">
        {Boolean(order.notificationsSent.length) ? (
          <div>
            <NotificationSectionTable notifications={order.notificationsSent} />
            <div className="py-2 d-flex align-items-center gap-2">
              <Button
                className="text-uppercase"
                isLoading={sendEmailNotification.isLoading}
                onClick={() =>
                  sendEmailNotification.mutate({
                    orderId: order.id,
                  })
                }
                size="small"
                startIcon={MdiRestartAlt}
                variant="gray"
              >
                Wyślij ponownie
              </Button>
              <Button
                className="text-uppercase"
                onClick={recipientEmailAddressModal.open}
                size="small"
                startIcon={MdiSend}
                variant="gray"
              >
                Wskaż adres i wyślij
              </Button>
            </div>
          </div>
        ) : (
          <EmptyNotificationSection open={recipientEmailAddressModal.open} orderId={order.id} />
        )}
      </RightPanelSection>
      {recipientEmailAddressModal.isOpen && (
        <SendToSpecificAddressModal close={recipientEmailAddressModal.close} orderId={order.id} />
      )}
    </>
  );
};
