import { Courier } from "api/shipping/models";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { cx, dateUtils } from "utilities";
import { InfoLabel } from "components/common/infoLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  courier: Courier;
}

export const AuthorizationSection = ({ courier }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-3">
      <div className="d-flex align-items-center" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {courier.name}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-2 mt-2">
        {courier.isConnected ? (
          <Tag startIcon={MdiCheckSmall} label="Połączono" variant="success" />
        ) : (
          <Tag startIcon={MdiEmergencyHome} label="Nie połączono" variant="danger" />
        )}
        {courier.checkedConnectionAt && (
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
              ostatnio sprawdzono:
            </Typography>
            <Typography fontSize="12" fontWeight="600">
              {dateUtils.formatDateToDisplayOnlyDate(courier.checkedConnectionAt)}
            </Typography>
            <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
              {dateUtils.formatDateToTime(courier.checkedConnectionAt)}
            </Typography>
          </div>
        )}
      </div>

      {courier.authorizationStatusError && (
        <InfoLabel title="wystąpił następujący błąd" className="mt-2">
          <div className={cx(styles.errorCustomTag, "flex-1")}>
            <Typography fontSize="12" fontWeight="800" color="danger500">
              {courier.authorizationStatusError}
            </Typography>
          </div>
        </InfoLabel>
      )}
    </RightPanelSection>
  );
};
