import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiChairAlt2 } from "components/miloDesignSystem/atoms/icons/MdiChairAlt2";
import { IndexesRouting } from "routes/externalManufacturing/IndexesRouting";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Warianty",
  icon: MdiChairAlt2,
  routing: IndexesRouting,
};
