import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useOrderLineItemColumns } from "./useOrderLineItemsColumns";
import styles from "../shared/ExternalManufacturing.module.css";
import { cx } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import {
  ExternalManufacturerLineItemsTab,
  OrderLineItemListItem,
  OrderStatus,
} from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { TableToolbarActions } from "./components/TableToolbarActions";
import { withFilters } from "components/utils/withFilters";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { externalManufacturingUtils } from "../shared/utils/externalManufacturingUtils";
import { orderLineItemsFiltersList } from "../shared/utils/orderLineItemsFiltersList";

export const OrderLineItems = withFilters(
  orderLineItemsFiltersList,
  ({ match }: RouteComponentProps<{ tab: ExternalManufacturerLineItemsTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = externalManufacturingUtils.getOrderLineItemsSearch({ query, tab });
    const {
      data: manufacturerLineItems,
      error,
      isPreviousData,
      isLoading,
      pagination,
    } = externalManufacturingActions.useOrderLineItems(search);
    const columns = useOrderLineItemColumns();
    const tableMultiSelect = useTableMultiSelect({
      rows: manufacturerLineItems,
    });
    const tableProps = useTableFeatureConnector({
      rows: manufacturerLineItems,
      withDrawer: "externalManufacturingOrdersLineItems",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          tabs={{
            list: externalManufacturingUtils.getOrderLineItemsTabs(),
            routesRoot: `external-manufacturing/manufacturer-line-items`,
            urlSpan: "list",
          }}
          viewLabel="EXTERNAL_MANUFACTURER_LINE_ITEMS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
          <Table<OrderLineItemListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: 42,
              },
            }}
            {...tableProps}
            overrides={() => {
              return {
                row: row => {
                  return {
                    ...tableProps?.overrides?.(),
                    className: cx(
                      {
                        [styles.successRow]: row.orderStatus === OrderStatus.IN_STOCK,
                        dashedTableRow:
                          row.isCancelled &&
                          tableMultiSelect.selectedRows.every(
                            selectedRow => selectedRow.id !== row.id,
                          ),
                      },
                      tableProps?.overrides?.().row?.(row).className,
                    ),
                  };
                },
              };
            }}
          />
          <RightPanel />
          <TableToolbarActions
            clear={tableMultiSelect.clear}
            selectedRows={tableMultiSelect.selectedRows}
          />
        </div>
      </PageWrapper>
    );
  },
);
