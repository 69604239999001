import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRef } from "react";
import { useCallCenterRouteOrder } from "api/call-center/routes/hooks";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { MessagesWithCustomer } from "pages/callCenter/shared/messagesWithCustomer/MessagesWithCustomer";
import { PanelHeader } from "pages/callCenter/shared/PanelHeader";
import { GeneralInfoSection } from "pages/callCenter/shared/GeneralInfoSection";
import { OrderSignature } from "pages/callCenter/shared/OrderSignature";
import { InfoLabel } from "components/common/infoLabel";
import { LinkToPage } from "components/utils/linkToPage";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: routeOrder, error, isLoading } = useCallCenterRouteOrder(Number(panelId), {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!routeOrder) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={routeOrder.id}>
        <PanelHeader routeOrder={routeOrder} panelId={panelId} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
            <RightPanelSection>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-100">
                  <OrderSignature routeOrder={routeOrder} />
                  <InfoLabel title="trasa">
                    {routeOrder.route ? (
                      <LinkToPage
                        content={<>{routeOrder.route.signature}</>}
                        stopPropagation
                        url={`/call-center/routes/${routeOrder.route.id}/orders`}
                        overrides={{
                          link: { className: "fs-14" },
                        }}
                      />
                    ) : (
                      EMPTY_VALUE
                    )}
                  </InfoLabel>
                  <GeneralInfoSection routeOrder={routeOrder} />
                </div>
              </div>
            </RightPanelSection>
            <hr />
            <MessagesWithCustomer />
            <hr />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            commentedObject={{ ...routeOrder, id: String(routeOrder.id) }}
            paramName="order"
            endpointUrl="/orders/comments"
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
