import { externalManufacturingOrdersApi } from "./api";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useMutation } from "hooks/useMutation";
import { externalManufacturingKeys } from "../keys";

const usePatchManufacturerItem = () => {
  return useMutation(externalManufacturingOrdersApi.patchManufacturerItem, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(
        externalManufacturingKeys.main.manufacturingOrders.details(String(id)),
        toUpdate,
      );
      const prevList = queryUtils.handlePaginatedListUpdate(
        externalManufacturingKeys.main.manufacturingOrders.list(),
        id,
        toUpdate,
      );
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        externalManufacturingKeys.main.manufacturingOrders.details(String(id)),
        onMutateReturn.prevPanel,
        error,
      );
      queryUtils.rollbackList(
        externalManufacturingKeys.main.manufacturingOrders.list(),
        onMutateReturn.prevList,
        id,
      );
    },
  }));
};

const usePostUnloading = () =>
  useMutation(
    externalManufacturingOrdersApi.postUnloading,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

export const externalManufacturingOrdersActions = {
  usePatchManufacturerItem,
  usePostUnloading,
};
