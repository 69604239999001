import { Assign } from "utility-types";
import {
  Index,
  IndexForExternalManufacturingDetails,
  IndexForExternalManufacturingListItem,
  PriceListIndex,
  ProductSetIndex,
} from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { parsePatchData } from "utilities/parsePatchData";
import { ApiFetcher } from "hooks/createApiQuery";
import { indexesKeys } from "./keys";
import { Pagination } from "api/types";
import { PartialOf } from "typeUtilities";

const getIndexes = (search: string = ""): ApiFetcher<Pagination<Index>> => ({
  key: indexesKeys.indexes(search),
  fetcher: () => queryFetch({ method: "GET", url: "/indexes/items" + search }),
});

const getIndexesForExternalManufacturing = (
  search: string = "",
): ApiFetcher<Pagination<IndexForExternalManufacturingListItem>> => ({
  key: indexesKeys.indexesForExternalManufacturing.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/external-manufacturing" + search,
    }),
});

const getIndexForExternalManufacturing = (
  id: string,
): ApiFetcher<IndexForExternalManufacturingDetails> => ({
  key: indexesKeys.indexesForExternalManufacturing.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/external-manufacturing/" + id,
    }),
});

const getProductSetIndexes = (search: string = ""): ApiFetcher<Pagination<ProductSetIndex>> => ({
  key: indexesKeys.productSetIndexes(search),
  fetcher: () => queryFetch({ method: "GET", url: "/indexes/product-set" + search }),
});

const getPriceListIndexes = (search: string = ""): ApiFetcher<Pagination<PriceListIndex>> => ({
  key: indexesKeys.priceListIndexes(search),
  fetcher: () => queryFetch({ method: "GET", url: "/indexes/price-list" + search }),
});

const getIndex = (id: string): ApiFetcher<Index> => ({
  key: indexesKeys.index(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/items/" + id,
    }),
});

const patchIndex = (data: Assign<Partial<Index>, { id: Index["id"] }>) =>
  queryFetch<Index>({
    method: "PATCH",
    url: "/indexes/items/" + data.id,
    data: parsePatchData(data),
  });

const getIndexExist = (search: string): ApiFetcher<boolean> => ({
  key: indexesKeys.indexExist(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/exists" + search,
    }),
  globalOptions: {
    cacheTime: 0,
  },
});

const bulkPatchProductIndexes = (data: Assign<PartialOf<Index>, { productId: number }>) =>
  queryFetch<Assign<PartialOf<Index>, { productId: number }>>({
    method: "PATCH",
    url: "/indexes/items/bulk-update-product-indexes",
    data,
  });

const bulkUpdateIndexes = (data: Assign<PartialOf<Index>, { objects: Index["id"][] }>) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/indexes/items/bulk-update",
    data,
  });

export const indexesApi = {
  bulkPatchProductIndexes,
  bulkUpdateIndexes,
  patchIndex,
  getIndex,
  getIndexExist,
  getIndexes,
  getIndexesForExternalManufacturing,
  getIndexForExternalManufacturing,
  getProductSetIndexes,
  getPriceListIndexes,
};
