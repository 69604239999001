import { useSmsMessagesQuery } from "api/messages/hooks";
import { postOrderSms_query } from "api/orders/calls";
import { OrderMessagesSchema } from "api/orders/models";
import removeIcon from "assets/images/11.svg";
import listEmptyIcon from "assets/images/56g.svg";
import errorIcon from "assets/images/877.svg";
import cx from "classnames";
import { Button } from "components/common";
import { CommonError, Label, Spinner } from "components/utils";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useSelector } from "hooks";
import { useOrderMessages } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { dateFns, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Dropdown } from "../dropdown";
import styles from "./OrderMessages.module.css";
import { useMarkIncomingMessagesAsRead } from "./useMarkIncomingMessagesAsRead";

type Message = OrderMessagesSchema["messages"][number];
type Order = OrderMessagesSchema["order"];

interface Props {
  id: string;
  closeButton?: React.ReactNode;
  kind: "ROUTE" | "ORDER";
  search?: string;
}

export const OrderMessages = ({ id, closeButton, kind, search }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, error, refetch } = useOrderMessages(id);
  const [body, setBody] = useState("");
  const me = useSelector(state => state.auth.user!);

  useLayoutEffect(() => {
    const anchor = containerRef.current;
    anchor?.scrollIntoView();
  }, [data]);

  useMarkIncomingMessagesAsRead(data, id, search);

  const sendSmsMutation = useMutation(postOrderSms_query, {
    onMutate: () => {
      setBody("");
      const anchor = containerRef.current;
      anchor!.scrollIntoView();
    },
    onSuccess: () => refetch(),
  });

  const groupedMessages = useMemo(() => {
    if (!data) return [];
    const grouped: Message[][] = [];
    data.messages.forEach(message => {
      if (!grouped.length) {
        grouped.push([message]);
        return;
      }

      const lastArray = grouped[grouped.length - 1];
      const last = lastArray[lastArray.length - 1];
      const startTime = new Date(last.created);
      const endTime = new Date(message.created);
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);
      const shouldBeGrouped = resultInMinutes <= 5 && last.status === message.status;

      if (!last.createdBy && !message.createdBy && shouldBeGrouped) {
        lastArray.push(message);
        return;
      }

      if (
        last.createdBy &&
        message.createdBy &&
        last.createdBy.id === message.createdBy.id &&
        shouldBeGrouped
      ) {
        lastArray.push(message);
        return;
      }
      grouped.push([message]);
    });
    return grouped;
  }, [data]);

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className={styles.container}>
        <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
      </div>
    );
  }

  const submit = () => {
    assertIsDefined(data);
    sendSmsMutation.mutate({ message: body, orderId: data.order.id });
  };

  return (
    <div className={styles.container}>
      <div className={cx(styles.titleWrapper, "d-flex align-items-center justify-content-between")}>
        <div className={styles.title}>Historia komunikacji z klientem</div>
        {closeButton}
      </div>
      <div className={styles.header}>
        <div className="d-flex align-items-center">
          <AvatarOrInitials
            avatarSrc={data.client.avatar}
            firstName={data.client.firstName}
            lastName={data.client.lastName}
            className={styles.avatar}
          />
          <div className={styles.name}>{`${data.client.firstName} ${data.client.lastName}`}</div>
        </div>
        <div className={styles.signature}>{data.order.signature}</div>
      </div>

      <div className={styles.main}>
        {groupedMessages.length === 0 ? (
          <div className={styles.noContent}>
            <img className={styles.inputIcon} src={listEmptyIcon} alt="" />
            <div>Nie wysłano jeszcze powiadomienia SMS</div>
          </div>
        ) : (
          groupedMessages.map(el => (
            <Message
              key={el[0].id + "parent"}
              data={el}
              firstName={data.client.firstName}
              lastName={data.client.lastName}
            />
          ))
        )}
        <div ref={containerRef} />
      </div>

      <div className={styles.footer}>
        <AvatarOrInitials
          avatarSrc={me.avatar}
          firstName={me.firstName}
          lastName={me.lastName}
          className={styles.avatar}
        />

        <TextareaAutosize
          className={styles.textarea}
          placeholder="Napisz wiadomość"
          value={body}
          onChange={e => setBody(e.target.value)}
          maxRows={4}
          onKeyPress={e => {
            if (e.code === "Enter") {
              e.preventDefault();
              e.currentTarget.blur();
              submit();
            }
          }}
        />
        <div className={styles.submitBtn}>
          <Button kind="secondary-grey" disabled={sendSmsMutation.isLoading} onClick={submit}>
            <span>Wyślij</span>
          </Button>
          <SmsMessageDropdown order={data.order} setBody={body => setBody(body)} kind={kind} />
        </div>
      </div>
    </div>
  );
};

const SmsMessageDropdown = ({
  setBody,
  kind,
  order,
}: {
  setBody: (body: string) => void;
  kind: Props["kind"];
  order: Order;
}) => {
  const search = queryString.stringify({
    kind: kind === "ROUTE" ? "" : kind,
    pageSize: "999",
    salesAccount: order.salesAccount,
  });

  const { data } = useSmsMessagesQuery(search);
  const [value, setValue] = useState("");

  const labelDict: Record<string, ReactNode> = {
    ROUTE: <Label color="yellow">Trasa</Label>,
    ORDER: <Label color="blue">Zamówienie</Label>,
  };

  const filteredOrders = data?.filter(el => {
    if (el.name.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }
    return false;
  });

  return (
    <Dropdown
      overrides={{
        optionList: { className: styles.list },
        button: { className: styles.dropdownBtn },
      }}
      position="top"
      label=""
    >
      {({ close }) => (
        <div>
          <div className={styles.padding}>
            <div className={styles.inputContainer}>
              <input
                value={value}
                onChange={e => setValue(e.target.value)}
                className={styles.input}
                placeholder="Szukaj..."
              />
              {value && (
                <img
                  className={styles.inputIcon}
                  src={removeIcon}
                  alt=""
                  onClick={() => setValue("")}
                />
              )}
            </div>
          </div>
          <h6 className={styles.paddingX}>Wybierz szablon wiadomości</h6>
          {filteredOrders.length ? (
            filteredOrders.map(el => (
              <div
                onClick={() => {
                  setBody(el.body);
                  close();
                }}
                className={cx(
                  "d-flex flex-column align-items-start mb-12",
                  styles.smsItem,
                  styles.padding,
                )}
                key={el.id}
              >
                <div className={styles.smsTitle}>
                  {el.name} <div className="ml-1">{labelDict[el.kind]}</div>
                </div>
                <div className={styles.smsBody}>{el.body}</div>
              </div>
            ))
          ) : (
            <div className={cx(styles.padding, "mb-2")}>Brak szablonu</div>
          )}
        </div>
      )}
    </Dropdown>
  );
};

const Message = ({
  data,
  firstName,
  lastName,
}: {
  data: Message[];
  firstName: string;
  lastName: string;
}) => {
  const statusDict: Record<Message["status"], string> = {
    AWAITING: "oczekiwanie",
    DELIVERED: "doręczono",
    SENT: "wysłano",
    UNDELIVERED: "nie doręczono",
    UNSENT: "nie wysłano",
  };

  const { wayOfCommunication, timestamp, createdBy, status, failureReason } = data[0];

  return (
    <div
      className={cx(styles.item, {
        [styles.reversed]: wayOfCommunication === "OUTGOING",
        [styles.error]: failureReason,
      })}
    >
      <div className={styles.content}>
        <div className={styles.date}>
          {createdBy && <span>{statusDict[status]} </span>}
          {timestamp && dateFns.format(new Date(timestamp), "d MMM yyyy HH:mm")}
        </div>
        <div className={styles.errorMsg}>
          <img src={errorIcon} alt="" />
          {failureReason}
        </div>

        {data.map(el => (
          <div key={el.id} className={styles.body}>
            {el.body}
          </div>
        ))}
      </div>
      <div className={styles.msgNameInfo}>
        {createdBy ? (
          <>
            <AvatarOrInitials
              avatarSrc={createdBy.avatar}
              firstName={createdBy.firstName}
              lastName={createdBy.lastName}
              className={styles.avatar}
            />
            <div className={styles.msgName}>{`${createdBy.firstName} ${createdBy.lastName}`}</div>
          </>
        ) : (
          <>
            <AvatarOrInitials
              avatarSrc={null}
              firstName={firstName}
              lastName={lastName}
              className={styles.avatar}
            />
            <div className={styles.msgName}>{`${firstName} ${lastName}`}</div>
          </>
        )}
      </div>
    </div>
  );
};
