import { routeActions } from "api/routes/actions";
import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal/Modal";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { AddRouteToDraftContent } from "./AddRouteToDraftContent";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
}

export interface DraftInvoiceRouteFilters {
  minCreatedDate: string;
  maxCreatedDate: string;
  page: number;
  pageSize: number;
  search: string;
  buyerTaxId: string;
}

export const AddRouteToDraft = ({ close, draftInvoice }: Props) => {
  const { searchParams, filters, setFilter } = useFilters<DraftInvoiceRouteFilters>({
    maxCreatedDate: "",
    minCreatedDate: "",
    page: 1,
    pageSize: 15,
    search: "",
    buyerTaxId: draftInvoice.buyerTaxId,
  });
  const {
    data: routes,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = routeActions.useRoutesForInvoicing(searchParams);

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={740}
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj pozycje na podstawie trasy
          </Typography>
        }
      >
        <div>
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={740}
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj pozycje na podstawie trasy
          </Typography>
        }
      >
        <div className="pt-3 pb-5 px-3">
          <Spinner size={26} />
        </div>
      </Modal>
    );

  if (!routes) return null;

  return (
    <Modal
      close={close}
      isOpen
      width={740}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj pozycje na podstawie trasy
        </Typography>
      }
    >
      <AddRouteToDraftContent
        close={close}
        draftInvoice={draftInvoice}
        filters={filters}
        isPreviousData={isPreviousData}
        isLoading={isLoading}
        pagination={pagination}
        routes={routes}
        setFilter={setFilter}
      />
    </Modal>
  );
};
