import { PackageListItem } from "api/warehouse/models";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { SelectItemCheckbox } from "../shared/components/SelectItemCheckbox";
import { SelectAllItemsCheckbox } from "../shared/components/SelectAllItemsCheckbox";
import { AvailabilityStatus } from "api/warehouse/enums";
import { MdiStateNone } from "components/miloDesignSystem/atoms/icons/MdiStateNone";
import { MdiStateLow } from "components/miloDesignSystem/atoms/icons/MdiStateLow";
import { MdiStateMedium } from "components/miloDesignSystem/atoms/icons/MdiStateMedium";
import { MdiStateHigh } from "components/miloDesignSystem/atoms/icons/MdiStateHigh";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette/colorPalette";

export const usePackagesColumns = (
  items: number[],
  selected: number[],
  setSelected: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  return useCreateTableColumns<PackageListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectPackages",
        header: () => (
          <div className="d-flex align-items-center gap-2">
            <SelectAllItemsCheckbox items={items} selected={selected} setSelected={setSelected} />
          </div>
        ),
        size: 35,
        cell: info => {
          const _package = info.getValue();

          return (
            <SelectItemCheckbox
              itemId={_package.id}
              items={items}
              selected={selected}
              setSelected={setSelected}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.name, {
        header: "nazwa",
        size: 400,
        cell: info => {
          const name = info.getValue();

          return (
            <div className="d-flex flex-1 align-items-center gap-2 overflow-hidden">
              <MdiPackage2 color="yellow400" size="12" />
              <Typography fontSize="12" fontWeight="600" noWrap>
                {name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.description, {
        header: "opis",
        size: 381,
        typographyProps: {
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.internalId, {
        header: "SKU",
        size: 110,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "state",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan
          </Typography>
        ),
        size: 85,
        cell: info => {
          const _package = info.getValue();

          return (
            <div className="w-100 d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-baseline gap-1">
                <Typography
                  color={getPackagesStateLegend(_package.availabilityStatus).textColor}
                  fontSize="14"
                  fontWeight="700"
                >
                  {_package.currentState}
                </Typography>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  szt.
                </Typography>
              </div>
              {getPackagesStateLegend(_package.availabilityStatus).icon}
            </div>
          );
        },
      }),
    ];
  });
};

const getPackagesStateLegend = (
  status: AvailabilityStatus,
): { icon: JSX.Element | null; textColor: ColorPalette } => {
  switch (status) {
    case "ZERO":
      return {
        icon: <MdiStateNone color="danger500" />,
        textColor: "danger500",
      };
    case "BELOW":
      return {
        icon: <MdiStateLow color="danger500" />,
        textColor: "danger500",
      };
    case "MIN":
      return {
        icon: <MdiStateLow color="warning600" />,
        textColor: "warning600",
      };
    case "SAFE":
      return {
        icon: <MdiStateMedium color="blue200" />,
        textColor: "blue200",
      };
    case "MAX":
      return {
        icon: <MdiStateHigh color="success500" />,
        textColor: "success500",
      };
    default: {
      const exhaustiveCheck = status;
      console.error(`Unhandled package availability status: ${exhaustiveCheck}`);
      return { icon: null, textColor: "neutralBlack100" };
    }
  }
};
