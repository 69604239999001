import { DraftOrderListItem } from "api/external-manufacturing/orders/drafts/models";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useDraftOrdersColumns = () => {
  return useCreateTableColumns<DraftOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(
        row => row.manufacturers?.map(manufacturer => manufacturer.name).join(", "),
        {
          header: "producenci",
          size: 400,
          typographyProps: {
            fontSize: "14",
            fontWeight: "700",
          },
        },
      ),
      columnHelper.accessor(row => row.numberOfLineItems, {
        id: "quantity",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            liczba produktów
          </Typography>
        ),
        size: 100,
        cell: info => {
          const quantity = info.getValue();

          if (!quantity)
            return <EmptyValue className="w-100 text-right" fontSize="14" fontWeight="500" />;

          return (
            <div className="d-flex align-items-center justify-content-end gap-1 w-100">
              <Typography fontSize="14" fontWeight="500">
                {quantity}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "data utworzenia",
        size: 130,
        format: "formatRelative",
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.avatar(row => row.createdBy, {
        header: "przez",
        size: 30,
        avatarProps: { size: "sm" },
      }),
      columnHelper.accessor(row => row.manufacturingOrders, {
        id: "manufacturingOrders",
        header: " ",
        size: 930,
        cell: info => {
          const manufacturingOrders = info.getValue();

          if (!Boolean(manufacturingOrders.length)) return null;

          return (
            <div className="d-flex align-items-center gap-3 ml-2">
              <Tag.WithCustomColor
                backgroundColor="success200"
                label="Utworzono zlecenia"
                textColor="success700"
              />
              <div className="d-flex align-items-center gap-2">
                {manufacturingOrders.map(manufacturingOrder => (
                  <Link
                    fontSize="14"
                    fontWeight="500"
                    key={manufacturingOrder.id}
                    to={`/external-manufacturing/orders/list/all?panelId=${manufacturingOrder.id}`}
                  >
                    {manufacturingOrder.signature}
                  </Link>
                ))}
              </div>
            </div>
          );
        },
      }),
    ];
  });
};
