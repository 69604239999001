import { TradingDocument } from "api/trading-documents/models";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../shared/TradingDocumentsLists.module.css";
import { cx } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { columnFactory } from "../shared/columnsFactory/columnFactory";

export const useCorrectionsColumns = () => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    const columns = columnFactory(columnHelper);

    return [
      columns.invoiceConfirmation(),
      columnHelper.accessor(row => row, {
        header: "nabywca",
        size: 200,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.buyerCompanyName ||
                  `${tradingDocument.buyerFirstName} ${tradingDocument.buyerLastName}`}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag label="Nowa" type="outlined" variant="success" />
                </div>
              )}
            </div>
          );
        },
      }),
      columns.signature(140),
      columns.invoiceOrders(),
      columnHelper.text(
        row =>
          Boolean(row.reasons.length) ? (
            row.reasons
              .map(
                reason =>
                  tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action],
              )
              .join(", ")
          ) : (
            <EmptyValue fontSize="12" fontWeight="500" />
          ),
        {
          header: "przyczyna",
          size: 130,
          typographyProps: {
            className: styles.invoiceSpacing,
            fontWeight: "500",
          },
        },
      ),
      columns.countryCode(),
      columnHelper.text(row => row.masterDocument?.signature, {
        header: "faktura",
        size: 110,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columns.invoiceIssueDate({ hasHighlight: true }),
      columns.paymentDeadline(),
      columnHelper.text(row => row.signatureOfAnAuthorizedPerson, {
        header: "podpis",
        size: 75,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columns.notificationStatus(),
      columns.assignedTo(),
      columns.seller(),
    ];
  });
};
