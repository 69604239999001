import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const draftOrdersKeys = {
  items: {
    list: createPaginatedKey("draftOrdersItems"),
    details: (draftOrderId: UUID) => ["draftOrderItem", draftOrderId],
  },
  lineItems: {
    list: createPaginatedKey("draftOrdersLineItems"),
  },
};
