import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ToggleHookState, useSettings, useToggle } from "hooks";
import styles from "pages/routes/shared/pdfPopover/PdfPopover.module.css";
import viewPdfIcon from "assets/images/161.svg";
import { Formik, FormikHelpers } from "formik";
import { cx, queryString } from "utilities";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Assign } from "utility-types";
import { OrderCollectionSource } from "api/wh-entries/enums";
import { routesPreviewFactory } from "api/routes/api";
import { orderGroupsPreviewFactory } from "api/order-groups/api";

interface Props {
  collection: {
    id: number;
    signature: string;
    source: OrderCollectionSource;
  };
  label: string;
  hardProductsCustomWeight: number;
  softProductsCustomWeight: number;
}

export const ViewResourceCmrButton = ({
  collection,
  label,
  hardProductsCustomWeight,
  softProductsCustomWeight,
}: Props) => {
  const modalController = useToggle();
  const { transport } = useSettings();

  if (!transport.useCustomWeightInCmr) {
    return (
      <a
        className={styles.pdf}
        rel="noopener noreferrer"
        href={
          collection.source === OrderCollectionSource.ROUTE
            ? routesPreviewFactory.cmrPDF(collection.id, collection.signature).url
            : orderGroupsPreviewFactory.cmrPDF(collection.id, collection.signature).url
        }
        target="_blank"
      >
        <img alt="" src={viewPdfIcon} />
        <span>{label}</span>
      </a>
    );
  }

  return (
    <div>
      <div className={styles.pdf} onClick={modalController.open}>
        <img alt="" src={viewPdfIcon} />
        <span>{label}</span>
      </div>
      {modalController.isOpen && (
        <ViewCmrModal
          collection={collection}
          modalController={modalController}
          hardProductsCustomWeight={hardProductsCustomWeight}
          softProductsCustomWeight={softProductsCustomWeight}
        />
      )}
    </div>
  );
};

type FormValues = Pick<Props, "hardProductsCustomWeight" | "softProductsCustomWeight">;

const ViewCmrModal = ({
  collection,
  modalController,
  hardProductsCustomWeight,
  softProductsCustomWeight,
}: Assign<
  Omit<Props, "label">,
  {
    modalController: ToggleHookState;
  }
>) => {
  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const search = queryString.stringify({
      ...values,
    });
    window.open(
      collection.source === OrderCollectionSource.ROUTE
        ? routesPreviewFactory.cmrPDF(collection.id, collection.signature, search).url
        : orderGroupsPreviewFactory.cmrPDF(collection.id, collection.signature, search).url,
    );
  };

  const initialValues: FormValues = {
    hardProductsCustomWeight,
    softProductsCustomWeight,
  };

  return (
    <Modal
      close={modalController.close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Waga na dokumencie CMR
        </Typography>
      }
    >
      <div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isValid }) => {
            return (
              <>
                <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center justify-content-center p-3">
                    <div className="d-flex flex-1 align-items-center gap-1">
                      <TextField.Form<FormValues>
                        name="hardProductsCustomWeight"
                        label="Meble twarde"
                        type="number"
                      />
                      <Typography
                        fontSize="12"
                        fontWeight="600"
                        color="neutralBlack64"
                        className="mt-1"
                      >
                        KG
                      </Typography>
                    </div>
                    <div className="d-flex flex-1 align-items-center gap-1">
                      <TextField.Form<FormValues>
                        name="softProductsCustomWeight"
                        label="Meble tapicerowane"
                        type="number"
                      />
                      <Typography
                        fontSize="12"
                        fontWeight="600"
                        color="neutralBlack64"
                        className="mt-1"
                      >
                        KG
                      </Typography>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 p-3">
                    <Button
                      className="text-uppercase"
                      onClick={modalController.close}
                      size="medium"
                      variant="transparent"
                    >
                      Anuluj
                    </Button>
                    <Button
                      className="text-uppercase"
                      size="medium"
                      type="submit"
                      variant="deepPurple"
                    >
                      Podgląd CMR
                    </Button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
