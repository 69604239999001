import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { NormalizedLongHeldPackage } from "../useLongHeldPackagesColumns";
import { CreatePickingForUniquePackages } from "./subcomponents/createPickingForUniquePackages/CreatePickingForUniquePackages";
import { CreateLoadingForUniquePackages } from "./subcomponents/createLoadingForUniquePackages/CreateLoadingForUniquePackages";

interface Props {
  close: () => void;
  packages: NormalizedLongHeldPackage[];
  numberOfSelectedPackages: number;
}

export const LongHeldPackagesTableToolbar = ({
  close,
  numberOfSelectedPackages,
  packages,
}: Props) => {
  return (
    <TableToolbar close={close} numberOfSelectedItems={numberOfSelectedPackages}>
      {/* <SettlePackage packages={packages} />
      <UnsettlePackage packages={packages} />
      <LongHeldPackagesCsvReport packages={packages} /> */}
      <CreatePickingForUniquePackages selectedUniquePackages={packages} />
      <CreateLoadingForUniquePackages selectedUniquePackages={packages} />
    </TableToolbar>
  );
};
