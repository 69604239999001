import { routeKeys } from "api/keys";
import { deleteComment, patchComment, postComment } from "api/routes/calls";
import { Route, routeStatusesToPick } from "api/routes/models";
import carImg from "assets/images/24.svg";
import rampIcon from "assets/images/172g.svg";
import cx from "classnames";
import { Button, CommentForm, CommentFormHelpers, Comments } from "components/common";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { IndexFulfillmentModal } from "components/common/indexFulfillmentModal";
import {
  AsyncInput,
  CommonError,
  DisabledOpacity,
  EditableHoverEffect,
  ErrorMessage,
  Icon,
  MockupLoader,
  RadioLabels,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import {
  DrawerBottom,
  DrawerContent,
  DrawerRight,
  DrawerSection,
  DrawerSections,
} from "components/utils/drawer";
import { TextToClipboard, UrlToClipboard } from "components/utils/toClipboard";
import { UserAvatar } from "components/utils/userAvatar";
import { useQuery, useQueryUtils, useSelector, useToastr, useToggle } from "hooks";
import { useRoutePatchMutation } from "hooks/apiPrimitives";
import { produce } from "immer";
import { PartialOf } from "typeUtilities";
import { arrayToDict, dateFns, dictToList, getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { WarehouseDocumentsSection } from "../../../warehouse/shared/warehouseDocumentsSection/WarehouseDocumentsSection";
import { useRouteHook } from "../hooks";
import { CountersSection } from "./countersSection";
import { DatesSection } from "./datesSection";
import { Header } from "./header/header";
import { PointsSection } from "./pointsSection/PointsSection";
import styles from "./RightPanel.module.css";
import { RampLoadingStatusModal } from "./rampLoadingStatusModal/RampLoadingStatusModal";
import { CreatePickingOrLoadingSource } from "api/orders/models";
import { CreateManufacturingOrderSection } from "./CreateManufacturingOrder";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { DeliveryPointsLinksModal } from "../deliveryPointsLinksModal/DeliveryPointsLinksModal";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { SynchronizeRoute } from "./synchronizeRoute/SynchronizeRoute";
import { ShipmentsSection } from "components/common/shipmentsSection/ShipmentsSection";
import { routeActions } from "api/routes/actions";
import { MdiSync } from "components/miloDesignSystem/atoms/icons/MdiSync";
import { useNavigate } from "hooks/useNavigate";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { PickingLink } from "./loadingAndPickingLinks/PickingLink";
import { LoadingLink } from "./loadingAndPickingLinks/LoadingLink";
import { Switch } from "components/miloDesignSystem/atoms/switch";
import { countryCurrenciesFlags } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { CURRENCY_TYPE } from "../../../../CONSTANTS";
import { useQueryClient } from "react-query";
import { CarrierOrderSection } from "./carrierOrderSection/CarrierOrderSection";
import { RouteCost } from "components/common/routeCost/RouteCost";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: route, isLoading: inProgress, error } = useRouteHook(panelId);
  const patchMutation = useRoutePatchMutation();
  const routeStatusMutation = useRoutePatchMutation();
  const setStatusInExternalServiceMutation = routeActions.useSetStatusInExternalService();
  const toastr = useToastr();
  const driversDict = useSelector(state => arrayToDict(state.partials.drivers));
  const drivers = useSelector(state => state.partials.drivers);
  const isWarehouseInUse = useSelector(store => store.partials.configuration.isWarehouseInUse);
  const indexFulfillmentModal = useToggle();
  const rampLoadingStatusModal = useToggle();
  const deliveryPointsLinksModal = useToggle();
  const cars = useSelector(state => state.partials.cars);
  const ramps = useSelector(state => state.partials.ramps);
  const { handleMutate, handlePaginatedListUpdate } = useQueryUtils();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function mutateRoute(toUpdate: PartialOf<Route>) {
    handleMutate(routeKeys.route(String(route!.id)), toUpdate);
    handlePaginatedListUpdate(routeKeys.routes(), route!.id, toUpdate);
  }

  const carsForSelect = cars.map(car => ({
    id: car.id,
    name: `${car.brand}, ${car.registrationNumber}`,
    filters: {
      brand: car.brand,
      registrationNumber: car.registrationNumber,
    },
  }));

  const currenciesOptions = dictToList(countryCurrenciesFlags).map(({ key, value }) => ({
    icon: value,
    text: key,
    type: MenuItemType.ICON,
    value: key,
  }));

  const rampsForSelect = ramps.map(ramp => ({
    id: ramp.id,
    name: ramp.name,
    filters: {
      code: ramp.code,
      name: ramp.name,
    },
  }));

  async function handleSubmitComment(
    value: string,
    { setError, setFetching, clear }: CommentFormHelpers,
  ) {
    return new Promise<{ success: boolean }>(async resolve => {
      setFetching(true);
      const [comment, error] = await postComment({
        body: value,
        route: Number(panelId),
      });
      if (error) {
        setError(error.body || "Nie udało się zapisać komentarza");
        setFetching(false);
        return resolve({ success: false });
      } else {
        const newComments = [comment!, ...route!.comments];
        mutateRoute({ comments: newComments });

        setFetching(false);
        setError("");
        clear();
        toastr.open({ type: "success", title: `Dodano komentarz`, text: "" });
        return resolve({ success: true });
      }
    });
  }

  async function handleDeleteComment(comment: Route["comments"][number]) {
    const newComments = route!.comments.filter(el => el.id !== comment.id);
    mutateRoute({ comments: newComments });
    const [, error] = await deleteComment(comment.id);
    if (error) {
      const newComments = route!.comments;
      mutateRoute({ comments: newComments });
      toastr.open({
        type: "failure",
        title: "Nie udało się usunąć komentarza",
        text: getAnyErrorKey(error),
      });
    } else {
    }
  }

  async function handleEditComment(
    comment: Route["comments"][number],
    helpers: StatusHandlerHelpers,
  ) {
    helpers.startFetching();
    const [, error] = await patchComment({ id: comment.id, body: comment.body });
    if (error) {
      toastr.open({
        type: "failure",
        title: "Nie udało edytować komentarza",
        text: getAnyErrorKey(error),
      });
    } else {
      const newComments = produce(route!.comments, draft => {
        const commentToUpdate = draft.find(el => el.id === comment.id);
        assertIsDefined(commentToUpdate);
        Object.assign(commentToUpdate, comment);
      });
      mutateRoute({ comments: newComments });
    }
    helpers.stopFetching();
  }

  if (!panelId) {
    return null;
  }

  if (error) {
    return (
      <DrawerRight className={styles.drawer}>
        <CommonError text={getAnyErrorKey(error)} status={error.status} />;
      </DrawerRight>
    );
  }

  if (!route) {
    return (
      <DrawerRight className={styles.drawer}>
        <MockupLoader on={inProgress} type="drawer" />
      </DrawerRight>
    );
  }

  if (
    route.ordersPositions.filter(orderPosition => orderPosition.type === "order").length !==
      route.orders.length &&
    route.settledDetails === null
  )
    return <SynchronizeRoute route={route} />;

  return (
    <DrawerRight key={route.id} className={styles.drawer}>
      <DrawerContent>
        <Header close={close} />
        <DisabledOpacity disabled={inProgress}>
          <DrawerSections>
            <DrawerSection>
              <div className={styles.signature}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <TextToClipboard text={route.signature}>{route.signature}</TextToClipboard>
                    <UrlToClipboard />
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <DesignSystemButton
                      className="mb-1"
                      onClick={deliveryPointsLinksModal.open}
                      size="small"
                      variant="transparent"
                    >
                      <Typography
                        className="cabinFont"
                        color="blue200"
                        fontSize="14"
                        fontWeight="700"
                      >
                        Linki do punktów dostawy
                      </Typography>
                    </DesignSystemButton>
                    {route.carrier && (
                      <div className="d-flex align-items-baseline gap-2 pb-1">
                        <Typography
                          color="neutralBlack48"
                          fontFamily="cabin"
                          fontSize="12"
                          fontWeight="500"
                        >
                          przewoźnik:
                        </Typography>
                        <Typography fontFamily="cabin" fontSize="14" fontWeight="700" noWrap>
                          {route.carrier.name}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <div className="w-100 mr-5">
                  <EditableHoverEffect>
                    <StatusHandler>
                      {helpers => (
                        <AsyncInput
                          look="editable"
                          disabled={helpers.isFetching}
                          onChange={value =>
                            patchMutation.mutate({ id: route.id, toUpdate: { name: value } })
                          }
                          value={route.name}
                          inProgress={helpers.isFetching}
                          error={helpers.errors.message}
                          placeholder="Nazwa trasy"
                        />
                      )}
                    </StatusHandler>
                  </EditableHoverEffect>
                </div>
              </div>
              <div>
                <div>
                  <span className={styles.createdLabel}>utworzono przez</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Avatar className="mr-1 mt-0 mb-0" size="sm" user={route.createdBy} />
                  <strong className={styles.createdBy}>
                    {route.createdBy.firstName} {route.createdBy.lastName}
                  </strong>
                  <span className={styles.createdDate}>
                    {dateFns.format(new Date(route.created), "d MMM yyyy")}
                  </span>
                </div>
              </div>
              {route.status === "SETTLED" && (
                <div className="mb-4">
                  <div>
                    <span className={styles.createdLabel}>rozliczono przez</span>
                  </div>
                  {route.settledDetails && (
                    <div className="d-flex align-items-center">
                      <Avatar className="mr-1 mt-0 mb-0" size="sm" user={route.createdBy} />
                      <strong className={styles.createdBy}>
                        {route.settledDetails.settledBy.firstName}{" "}
                        {route.settledDetails.settledBy.lastName}
                      </strong>
                      <span className={styles.createdDate}>
                        {dateFns.format(new Date(route.settledDetails.created), "d MMM yyyy")}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <DatesSection />
              <div className="d-flex flex-column gap-2 mb-2">
                <PickingLink
                  signature={route.signature}
                  source={CreatePickingOrLoadingSource.ROUTE}
                />
                <LoadingLink
                  signature={route.signature}
                  source={CreatePickingOrLoadingSource.ROUTE}
                />
              </div>
              <div className="my-3">
                <Switch
                  checked={route.isPickupCodeRequired}
                  label="Wymagany kod odbioru"
                  onChange={isPickupCodeRequired => {
                    patchMutation.mutate({
                      id: route.id,
                      toUpdate: {
                        isPickupCodeRequired,
                      },
                    });
                  }}
                />
              </div>
              <div className="mb-3">
                <span className={styles.createdLabel}>status</span>
                <RadioLabels
                  items={routeStatusesToPick}
                  name=""
                  onChange={({ value }) => {
                    if (!value) return;
                    routeStatusMutation.mutate({
                      id: route.id,
                      toUpdate: { status: value as "DEPARTED" | "ROUTE_READY" },
                    });
                  }}
                  value={route.status || "name"}
                  allowUncheck={false}
                  isDisabled={value => {
                    if (value.id === "FINISHED" || value.id === "NEW" || value.id === "SETTLED")
                      return true;
                  }}
                />
              </div>
              <div className="d-flex">
                <StatusHandler>
                  {helpers => (
                    <div>
                      <AdvancedSelect
                        inputPlaceholder="Szukaj..."
                        onChange={value => {
                          const driver = value && drivers.find(driver => driver.id === value.id);
                          patchMutation.mutate({ id: route.id, toUpdate: { driver } });
                        }}
                        selectedItem={route.driver?.id ?? null}
                        enableClear={route.status !== "SETTLED"}
                        itemToDisplaySelected={item => (
                          <div className="d-flex align-items-center">
                            <UserAvatar
                              avatarSrc={route.driver ? driversDict[route.driver.id]?.avatar : ""}
                              className="mr-2"
                            />
                            <span className="text-nowrap text-truncate overflow-hidden">
                              {item ? item.name : "Przypisz"}
                            </span>
                          </div>
                        )}
                        items={drivers.map(driver => ({
                          id: driver.id,
                          name: `${driver.firstName} ${driver.lastName}`,
                          filters: {
                            firstName: driver.firstName,
                            lastName: driver.lastName,
                            phone: driver.phone,
                          },
                        }))}
                        width={210}
                        itemsWidth={300}
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>
                {route.driver && (
                  <div className="ml-5">
                    <small className="text-color-grey">Nr do kierowcy:</small>
                    <div className={styles.address}>
                      <span>{route.driver.phone}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex">
                <StatusHandler>
                  {helpers => (
                    <div>
                      <AdvancedSelect
                        inputPlaceholder="Szukaj..."
                        onChange={value => {
                          const driver = value && drivers.find(el => el.id === value.id);
                          patchMutation.mutate({
                            id: route.id,
                            toUpdate: { secondaryDriver: driver },
                          });
                        }}
                        selectedItem={route.secondaryDriver?.id ?? null}
                        enableClear={route.status !== "SETTLED"}
                        itemToDisplaySelected={item => (
                          <div className="d-flex align-items-center">
                            <UserAvatar
                              avatarSrc={
                                route.secondaryDriver
                                  ? driversDict[route.secondaryDriver.id]?.avatar
                                  : ""
                              }
                              className="mr-2"
                            />
                            <span className="text-nowrap text-truncate overflow-hidden">
                              {item ? item.name : "Przypisz"}
                            </span>
                          </div>
                        )}
                        items={drivers
                          .filter(el => el.id !== route.driver?.id)
                          .map(driver => ({
                            id: driver.id,
                            name: `${driver.firstName} ${driver.lastName}`,
                            filters: {
                              firstName: driver.firstName,
                              lastName: driver.lastName,
                              phone: driver.phone,
                            },
                          }))}
                        width={210}
                        itemsWidth={300}
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>
                {route.secondaryDriver && (
                  <div className="ml-5">
                    <small className="text-color-grey">Nr do kierowcy:</small>
                    <div className={styles.address}>
                      <span>{route.secondaryDriver.phone}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex">
                <StatusHandler>
                  {helpers => (
                    <div>
                      <AdvancedSelect
                        inputPlaceholder="Szukaj..."
                        onChange={value => {
                          if (value) {
                            const car = value && cars.find(car => car.id === value.id);
                            if (!route.driver) {
                              const driver =
                                car && drivers.find(driver => driver.id === car.defaultDriver);
                              patchMutation.mutate(
                                { id: route.id, toUpdate: { car, driver } },
                                {
                                  onSuccess: () =>
                                    queryClient.invalidateQueries(
                                      routeKeys.route(String(route.id)),
                                    ),
                                },
                              );
                              return;
                            }
                            patchMutation.mutate(
                              { id: route.id, toUpdate: { car } },
                              {
                                onSuccess: () =>
                                  queryClient.invalidateQueries(routeKeys.route(String(route.id))),
                              },
                            );
                            return;
                          }
                          patchMutation.mutate(
                            { id: route.id, toUpdate: { car: null } },
                            {
                              onSuccess: () =>
                                queryClient.invalidateQueries(routeKeys.route(String(route.id))),
                            },
                          );
                        }}
                        selectedItem={route.car?.id ?? null}
                        itemToDisplaySelected={item => (
                          <div className="d-flex align-items-center">
                            <Icon img={carImg} type="round" className={cx("mr-2", styles.carImg)} />
                            <span className="text-nowrap text-truncate overflow-hidden">
                              {item ? item.name : "Przypisz"}
                            </span>
                          </div>
                        )}
                        items={carsForSelect}
                        width={346}
                        itemsWidth={350}
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>

                {route.car && (
                  <div className="d-flex align-items-center gap-2 ml-2">
                    <div>
                      <Typography fontSize="10" fontWeight="500" color="neutralBlack48">
                        ładowność:
                      </Typography>
                      <Typography fontSize="12" fontWeight="700">
                        {route.car.cargo} (t)
                      </Typography>
                    </div>
                    <div>
                      <Typography fontSize="10" fontWeight="500" color="neutralBlack48">
                        długość:
                      </Typography>
                      <Typography fontSize="12" fontWeight="700">
                        {route.car.depth} (m)
                      </Typography>
                    </div>
                    <div>
                      <Typography fontSize="10" fontWeight="500" color="neutralBlack48">
                        szerokość:
                      </Typography>
                      <Typography fontSize="12" fontWeight="700">
                        {route.car.width} (m)
                      </Typography>
                    </div>
                    <div>
                      <Typography fontSize="10" fontWeight="500" color="neutralBlack48">
                        wysokość:
                      </Typography>
                      <Typography fontSize="12" fontWeight="700">
                        {route.car.height} (m)
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <StatusHandler>
                  {helpers => (
                    <div className="mr-2">
                      <AdvancedSelect
                        inputPlaceholder="Szukaj rampy..."
                        onChange={value => {
                          const ramp = value && ramps.find(ramp => ramp.id === value.id);
                          patchMutation.mutate({ id: route.id, toUpdate: { ramp } });
                        }}
                        selectedItem={route.ramp?.id ?? null}
                        itemToDisplaySelected={item => (
                          <div className="d-flex align-items-center">
                            <Icon
                              img={rampIcon}
                              type="round"
                              className={cx("mr-2", styles.carImg)}
                            />
                            <span className="text-nowrap text-truncate overflow-hidden">
                              {item ? item.name : "Przypisz"}
                            </span>
                          </div>
                        )}
                        items={rampsForSelect}
                        width={210}
                        itemsWidth={300}
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>
                <Button
                  className="mb-1"
                  disabled={!route.ramp}
                  kind="secondary-grey"
                  onClick={rampLoadingStatusModal.open}
                  size="round-s"
                >
                  ?
                </Button>
              </div>
              <div className="mt-3 d-flex align-items-center justify-content-between">
                <Button kind="secondary-grey" onClick={indexFulfillmentModal.open} size="small">
                  Pokaż zapotrzebowanie
                </Button>
                <DesignSystemButton
                  className="text-uppercase"
                  onClick={() => setStatusInExternalServiceMutation.mutate({ route: route.id })}
                  size="medium"
                  startIcon={MdiSync}
                  variant="gray"
                >
                  Aktualizuj zamówienia z zewnętrznych serwisów
                </DesignSystemButton>
              </div>
              <div className="mt-3">
                <DesignSystemButton
                  className="text-uppercase"
                  onClick={() => navigate(`/manufacturing/routes/list/all?panelId=${route.id}`)}
                  size="medium"
                  startIcon={MdiArrowForward}
                  variant="gray"
                >
                  Przenieś do widoku trasy w produkcji
                </DesignSystemButton>
              </div>
            </DrawerSection>
            <ShipmentsSection
              key={route.id}
              ordersIds={route.orders.map(order => order.id)}
              source="ROUTE"
              sourceId={route.id}
            />
            {route.carrier && (
              <DrawerSection title="Kwota za trasę">
                <RouteCost
                  amountProps={{
                    label: "kwota za trasę",
                    mutationHook: useRoutePatchMutation,
                    size: "small",
                    transformQueryData: customRouteCost => ({
                      id: route.id,
                      toUpdate: {
                        routeCost: {
                          ...route.routeCost,
                          customRouteCost: {
                            ...route.routeCost.customRouteCost,
                            amount: Number(customRouteCost),
                          },
                        },
                        customRouteCost,
                      },
                    }),
                    type: "number",
                    value: route.customRouteCost ?? "",
                  }}
                  currencyProps={{
                    className: styles.currencySelector,
                    items: currenciesOptions,
                    label: "Waluta",
                    mutationHook: useRoutePatchMutation,
                    selected: route.customRouteCostCurrency,
                    transformQueryData: customRouteCostCurrency => ({
                      id: route.id,
                      toUpdate: {
                        customRouteCostCurrency: customRouteCostCurrency as CURRENCY_TYPE,
                      },
                    }),
                  }}
                  routeCost={route.routeCost}
                />
                <CarrierOrderSection route={route} />
              </DrawerSection>
            )}
            <DrawerSection>
              <hr />
              <CountersSection route={route} />
            </DrawerSection>
            <PointsSection />
            <DrawerSection title="Ogólne uwagi do trasy">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    disabled={helpers.isFetching}
                    overwrites={{ input: { className: styles.routeNote } }}
                    onChange={value =>
                      patchMutation.mutate({ id: route.id, toUpdate: { note: value } })
                    }
                    value={route.note}
                    inProgress={helpers.isFetching}
                    error={helpers.errors.message}
                    placeholder="Jakieś ogólne uwagi do trasy"
                  />
                )}
              </StatusHandler>
            </DrawerSection>
            <CreateManufacturingOrderSection
              collection="route"
              orders={route.orders.map(order => order.id)}
            />
            {isWarehouseInUse && (
              <WarehouseDocumentsSection
                warehouseDocument={route.warehouseDocument}
                sourceType="ROUTE"
                updateDocument={document => {
                  mutateRoute({ warehouseDocument: document });
                }}
              />
            )}
            {/* TODO: Waiting for backend */}
            {/* <div className="mt-3">
              <OrderReleaseProgressSection
                collectionId={route.id}
                source={OrderCollectionSource.ROUTE}
              />
            </div> */}
            <Comments
              comments={route.comments}
              onDelete={handleDeleteComment}
              onEdit={handleEditComment}
            />
          </DrawerSections>
        </DisabledOpacity>
      </DrawerContent>

      <DrawerBottom>
        <CommentForm handleSubmit={handleSubmitComment} followers={route.followers} />
      </DrawerBottom>
      {deliveryPointsLinksModal.isOpen && (
        <DeliveryPointsLinksModal close={deliveryPointsLinksModal.close} route={route.id} />
      )}
      {indexFulfillmentModal.isOpen && (
        <IndexFulfillmentModal close={indexFulfillmentModal.close} id={route.id} source="route" />
      )}
      {rampLoadingStatusModal.isOpen && (
        <RampLoadingStatusModal close={rampLoadingStatusModal.close} route={route} />
      )}
    </DrawerRight>
  );
};
