const useSelectItems = (
  items: number[],
  selected: number[],
  setSelected: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  const areAllItemsSelected =
    items.every(itemId => selected.some(selectedId => selectedId === itemId)) &&
    Boolean(selected.length);

  const areSomeItemsSelected = Boolean(
    selected.length && items.some(itemId => selected.some(selectedId => selectedId === itemId)),
  );

  const isItemSelected = (id: number): boolean => selected.some(selectedId => selectedId === id);

  const setAllItems = (): void => {
    if (areAllItemsSelected) return setSelected([]);
    const notSelectedItems = items.filter(
      itemId => !selected.some(selectedId => selectedId === itemId),
    );
    setSelected(prevSelected => [...prevSelected, ...notSelectedItems]);
  };

  const setItem = (id: number): void => {
    if (isItemSelected(id))
      return setSelected(prevSelected => prevSelected.filter(selectedId => selectedId !== id));
    setSelected(prevSelected => [...prevSelected, id]);
  };

  return {
    areAllItemsSelected,
    areSomeItemsSelected,
    isItemSelected,
    setAllItems,
    setItem,
  };
};

export const createDraftOrderUtils = {
  useSelectItems,
};
