import { Package, PackageListItem } from "api/warehouse/models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { packagesKeys } from "./keys";
import { Pagination } from "api/types";
import { PackageAssignmentDTO } from "./models";

const getPackageQuery = (internalId: string): ApiFetcher<Package> => ({
  key: packagesKeys.package.details(internalId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/packages/items-by-internal-id/" + internalId,
    }),
});

const getItems = (search: string = ""): ApiFetcher<Pagination<PackageListItem>> => ({
  key: packagesKeys.package.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/packages/items" + search,
    }),
});

const postAssignment = (data: PackageAssignmentDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/packages/assign",
    data,
  });

const deleteAssignment = (data: PackageAssignmentDTO) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/packages/assign",
    data,
  });

export const packagesApi = {
  deleteAssignment,
  getItems,
  getPackageQuery,
  postAssignment,
};
