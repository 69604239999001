import { DropResult } from "react-beautiful-dnd";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { useQueryClient } from "react-query";
import { ManufacturingGroup, ManufacturingUnit } from "api/manufacturing/units/models";
import { manufacturingItemsConstants } from "constants/manufacturingItemsConstants";
import { manufacturingUnitsKeys } from "api/manufacturing/units/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { getDataFromPaginatedQuery } from "utilities";

const DRAGGABLE_BOARD_ITEMS = manufacturingItemsConstants.DRAGGABLE_BOARD_ITEMS;

export const useColumnDropService = () => {
  const stageId = useStageId();
  const postGroupMutation = manufacturingUnitsActions.usePostGroup();
  const setAsFinishedMutation = manufacturingUnitsActions.useSetAsFinished();
  const getTodoUnit = useGetTodoUnit();
  const getTodoGroup = useGetTodoGroup();

  return (result: DropResult) => {
    if (!result.destination) return;

    const [dragItemType, id] = result.draggableId.split(";") as [
      keyof typeof DRAGGABLE_BOARD_ITEMS,
      string,
    ];

    const dropColumnId = result.destination.droppableId;

    const dropOnInProgresColumn =
      dropColumnId === manufacturingStagesConstants.IN_PROGRESS_DROPPABLE;
    if (dropOnInProgresColumn) {
      return inProgressColumnDropUseCase({ dragItemType, id });
    }

    const dropOnReadyColumn = dropColumnId === manufacturingStagesConstants.READY_DROPPABLE;

    if (dropOnReadyColumn) {
      return readyColumnDropUseCase({ dragItemType, id });
    }
  };

  function inProgressColumnDropUseCase({
    dragItemType,
    id,
  }: {
    dragItemType: keyof typeof DRAGGABLE_BOARD_ITEMS;
    id: string | number;
  }) {
    if (dragItemType === DRAGGABLE_BOARD_ITEMS.TODO_MWU) {
      const unitToAdd = getTodoUnit(String(id));
      postGroupMutation.mutate({
        attributes: unitToAdd.attributeValues,
        manufacturingWorkingUnitIds: [String(id)],
        schema_stage_id: stageId,
      });
    }

    if (dragItemType === DRAGGABLE_BOARD_ITEMS.TODO_GROUP) {
      const groupToAdd = getTodoGroup(id);
      assertIsDefined(groupToAdd);
      const attributes = [
        {
          attribute: {
            id: null,
            name: "",
          },
          category: AttributeCategory.PRODUCT,
          value: {
            id: null,
            name: groupToAdd.modelName,
          },
        },
        ...groupToAdd.attributesValues,
      ];

      postGroupMutation.mutate({
        attributes,
        manufacturingWorkingUnitIds: groupToAdd.elements.map(unit => unit.id),
        schema_stage_id: stageId,
      });
    }
    return;
  }

  function readyColumnDropUseCase({
    dragItemType,
    id,
  }: {
    dragItemType: keyof typeof DRAGGABLE_BOARD_ITEMS;
    id: string | number;
  }) {
    if (dragItemType === DRAGGABLE_BOARD_ITEMS.TODO_MWU) {
      setAsFinishedMutation.mutate({
        manufacturingWorkingUnitsIds: [String(id)],
      });
    }

    if (dragItemType === DRAGGABLE_BOARD_ITEMS.TODO_GROUP) {
      const groupToAdd = getTodoGroup(id);
      assertIsDefined(groupToAdd);
      setAsFinishedMutation.mutate({
        manufacturingWorkingUnitsIds: groupToAdd.elements.map(unit => unit.id),
      });
    }

    if (dragItemType === DRAGGABLE_BOARD_ITEMS.IN_PROGRESS_GROUP) {
      setAsFinishedMutation.mutate({
        manufacturingWorkingUnitGroupsIds: [String(id)],
      });
    }
    if (dragItemType === DRAGGABLE_BOARD_ITEMS.IN_PROGRESS_MWU) {
      setAsFinishedMutation.mutate({
        manufacturingWorkingUnitsIds: [String(id)],
      });
    }
    return;
  }
};

const useGetTodoUnit = () => {
  const queryClient = useQueryClient();
  return (id: ManufacturingUnit["id"]) => {
    const unitsQuery = queryClient.getQueriesData({
      queryKey: manufacturingUnitsKeys.unitItems(),
      active: true,
      predicate: query => query.queryHash.includes("READY"),
    });

    const units = getDataFromPaginatedQuery<ManufacturingUnit[]>(unitsQuery);
    const unitToAdd = units.find(unit => unit.id === id);
    assertIsDefined(unitToAdd);
    return unitToAdd;
  };
};

export const useGetTodoGroups = () => {
  const queryClient = useQueryClient();
  return () => {
    const query = queryClient.getQueriesData({
      queryKey: manufacturingUnitsKeys.manufacturingGroup.list(),
      active: true,
    });
    return getDataFromPaginatedQuery<ManufacturingGroup[]>(query);
  };
};

export const useGetTodoGroup = () => {
  const queryClient = useQueryClient();
  return (id: ManufacturingGroup["id"]) => {
    const query = queryClient.getQueriesData({
      queryKey: manufacturingUnitsKeys.manufacturingGroup.list(),
      active: true,
    });
    const groups = getDataFromPaginatedQuery<ManufacturingGroup[]>(query);
    const groupToAdd = groups.find(group => String(group.id) === id);
    return groupToAdd;
  };
};
