import { useMutation } from "hooks/useMutation";
import { proformasApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { tradingDocumentsKeys } from "../keys";
import { useConfirmModal } from "hooks";

const CREATE_PROFORMA_WITHOUT_AMOUNT_STATUS_CODE = 422;

const useCreateProforma = () => {
  const confirmModal = useConfirmModal();

  const mutation = useMutation(proformasApi.postProformaForOrder, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(tradingDocumentsKeys.lightTradingDocument.list());
      toastr.open({
        type: "success",
        title: "Udało się",
        text: "Utworzono proformę",
      });
    },
    onError: (error, variables) => {
      if (error.response?.status === CREATE_PROFORMA_WITHOUT_AMOUNT_STATUS_CODE) {
        confirmModal.open({
          text: "Czy na pewno chcesz utworzyć proformę z kwotą 0.00?",
          confirmText: "Tak, utwórz",
          callback: () => mutation.mutate({ ...variables, forceCreateZeroAmount: true }),
        });
        return;
      }
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
  return mutation;
};

const useCreateSalesInvoiceFromProforma = () => {
  return useMutation(proformasApi.createSalesInvoiceFromProforma, ({ toastr }) => ({
    onSuccess: () =>
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono fakturę sprzedażową na podstawie proformy",
      }),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

export const proformasActions = {
  useCreateProforma,
  useCreateSalesInvoiceFromProforma,
};
