import { uniquePackagesActions } from "api/unique-packages/actions";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiTrolley } from "components/miloDesignSystem/atoms/icons/MdiTrolley";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { NormalizedLongHeldPackage } from "pages/wms/longHeldPackages/useLongHeldPackagesColumns";

interface Props {
  selectedUniquePackages: NormalizedLongHeldPackage[];
}

export const CreatePickingForUniquePackages = ({ selectedUniquePackages }: Props) => {
  const createPickingMutation = uniquePackagesActions.useBulkCreatePicking();

  return (
    <div>
      <Tooltip title="Utwórz picking">
        <IconButton
          icon={MdiTrolley}
          isLoading={createPickingMutation.isLoading}
          onClick={() =>
            createPickingMutation.mutate({
              uniquePackagesGroupsIds: [
                ...new Set(selectedUniquePackages.map(uniquePackage => uniquePackage.productId)),
              ],
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
