import cx from "classnames";
import { FLAVOR } from "CONSTANTS";
import { useQuery, useSelector } from "hooks";
import { NavLink } from "react-router-dom";
import styles from "../Navbar.module.css";
import { OrdersMenu } from "../ordersMenu";
import { RoutesMenu } from "../routesMenu";

function MainNavigation() {
  const { search } = useQuery({ exclude: ["page"] });
  const isWarehouseInUse = useSelector(state => state.partials.configuration.isWarehouseInUse);
  if (FLAVOR === "b2b") return null;
  if (FLAVOR === "manufacturing") return null;
  if (FLAVOR === "externalManufacturing") return null;

  return (
    <>
      <OrdersMenu search={search} />
      <>
        <div className={styles.navLeftLink}>
          <NavLink
            className="pl-3 pr-3"
            to="/order-groups/list/orders"
            activeClassName={styles.active}
          >
            <strong>Grupy zamówień</strong>
          </NavLink>
        </div>
        <RoutesMenu />
        <div className={styles.navLeftLink}>
          <NavLink exact className="pl-3 pr-3" to="/offers" activeClassName={styles.active}>
            <strong>Oferty</strong>
          </NavLink>
        </div>
        <div className={styles.navLeftLink}>
          <NavLink className="pl-3 pr-3" to="/products" activeClassName={styles.active}>
            <strong>Produkty</strong>
          </NavLink>
        </div>
        <div className={styles.navLeftLink}>
          <NavLink exact className="pl-3 pr-3" to="/customers" activeClassName={styles.active}>
            <strong>Kontrahenci</strong>
          </NavLink>
        </div>
        <div className={styles.navLeftLink}>
          <NavLink exact className="pl-3 pr-3" to="/sales-accounts" activeClassName={styles.active}>
            <strong>Konta sprzedażowe</strong>
          </NavLink>
        </div>
        <div className={styles.navLeftLink}>
          <NavLink exact className="pl-3 pr-3" to="/attributes" activeClassName={styles.active}>
            <strong>Atrybuty</strong>
          </NavLink>
        </div>
        <div className={styles.navLeftLink}>
          <NavLink exact className="pl-3 pr-3" to="/users" activeClassName={styles.active}>
            <strong>Użytkownicy</strong>
          </NavLink>
        </div>

        <div className={styles.navLeftLink}>
          {isWarehouseInUse && (
            <NavLink className="pl-3 pr-3" to="/warehouse" activeClassName={styles.active}>
              <strong>Magazyn</strong>
            </NavLink>
          )}
          <NavLink exact className="pl-3 pr-3" to="/manufacturers" activeClassName={styles.active}>
            <strong>Producenci</strong>
          </NavLink>
        </div>
      </>
    </>
  );
}

const ManufacturingNavigation = () => {
  return (
    <div className={styles.navLeftLink}>
      <NavLink className="pl-3 pr-3" to="/manufacturing/routes" activeClassName={styles.active}>
        <strong>Trasy</strong>
      </NavLink>
      <NavLink
        className="pl-3 pr-3"
        to="/manufacturing/order-groups/list/orders"
        activeClassName={styles.active}
      >
        <strong>Grupy zamówień</strong>
      </NavLink>
    </div>
  );
};

const ExternalManufacturing = () => {
  return null;
};

function B2bNavigation() {
  const hasPermissionsToCreateProducts = useSelector(
    state => state.partials.customer?.hasPermissionsToCreateProducts,
  );

  return (
    <div className={styles.navLeftLink}>
      <NavLink className="pl-3 pr-3" to="/client/orders" activeClassName={styles.active}>
        <strong>Zamówienia</strong>
      </NavLink>
      {hasPermissionsToCreateProducts && (
        <NavLink className="pl-3 pr-3" to="/client/products" activeClassName={styles.active}>
          <strong>Produkty</strong>
        </NavLink>
      )}
      <NavLink
        exact
        className="pl-3 pr-3"
        to="/client/sales-accounts"
        activeClassName={styles.active}
      >
        <strong>Konta sprzedażowe</strong>
      </NavLink>
      <NavLink
        exact
        className="pl-3 pr-3"
        to="/client/awaiting-transports"
        activeClassName={styles.active}
      >
        <strong>Awizacje</strong>
      </NavLink>
      <NavLink
        exact
        className="pl-3 pr-3"
        to="/client/finances/sales"
        activeClassName={styles.active}
      >
        <strong>Faktury sprzedażowe</strong>
      </NavLink>
      <NavLink
        exact
        className="pl-3 pr-3"
        to="/client/finances/advances"
        activeClassName={styles.active}
      >
        <strong>Faktury zaliczkowe</strong>
      </NavLink>
      <NavLink
        exact
        className="pl-3 pr-3"
        to="/client/finances/corrections"
        activeClassName={styles.active}
      >
        <strong>Korekty</strong>
      </NavLink>
    </div>
  );
}

export const FullNavigation = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div className={cx(styles.navLeftBody, "dark-scrollbar", { "d-none": !isOpen })}>
      <div className={styles.contentWrapper}>
        <div className={styles.navLeftLink}>
          <NavLink to="/" className="pl-3 pr-3" activeClassName={styles.active} exact>
            <strong>Pulpit</strong>
          </NavLink>
        </div>
        <MainNavigation />
        {FLAVOR === "b2b" && <B2bNavigation />}
        {FLAVOR === "manufacturing" && <ManufacturingNavigation />}
        {FLAVOR === "externalManufacturing" && <ExternalManufacturing />}
      </div>
    </div>
  );
};
