import {
  ExpectedPaymentForm,
  OrderPositionForCreatingInvoice,
  PreviewTradingDocument,
} from "api/trading-documents/models";
import { FormInput, FormSelect } from "components/utils";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { useSelector } from "hooks";
import styles from "./CreateInvoicesForMultipleOrdersModal.module.css";
import cx from "classnames";
import { getAnyErrorKey, pluralize } from "utilities";
import { Button } from "components/common";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { postTradingDocumentForMultipleOrders } from "api/trading-documents/calls";
import { CustomModal } from "components/utils/customModal";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { RightPanelTableRow } from "components/utils/drawer";
import { currencyConvertSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { CustomerSection } from "./components/CustomerSection";
import { getAllPositions } from "./utils/getAllPositions";
import { getInitialValues } from "./utils/getInitialValues";
import { validationSchema } from "./utils/validationSchema";
import React from "react";
import { Assign } from "utility-types";
import { WholeAmountToPay } from "./components/WholeAmountToPay";
import { AmountForVatRate } from "./components/AmountForVatRate";
import { CurrencyConvertionSection } from "./components/CurrencyConvertionSection";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  close: () => void;
  customerId?: string;
  isOpen: boolean;
  state: { preview: PreviewTradingDocument; customerId?: number } | null;
  onSuccess?: () => void;
}

export interface CreateInvoiceValues {
  orderId: string;
  orderSignature: string;
  positions: Assign<OrderPositionForCreatingInvoice[], { isPositionEdited: boolean }>;
}

export const CreateInvoicesForMultipleOrdersModal = ({
  close,
  customerId,
  isOpen,
  onSuccess,
  state,
}: Props) => {
  const customer = useSelector(store =>
    store.partials.customers.find(customer =>
      customerId !== undefined
        ? customer.id === Number(customerId)
        : state?.customerId !== undefined
        ? customer.id === state.customerId
        : undefined,
    ),
  );

  const sellers = useSelector(store => store.partials.businessEntities);
  const sellersToPick = sellers
    .filter(seller => seller.kind === "INTERNAL")
    .map(seller => {
      return {
        id: seller.id,
        name:
          seller.id === state?.preview.sellerId
            ? `${seller.companyName} (domyślnie)`
            : seller.companyName,
      };
    });

  const createDocumentOptions = useMutationOptions(
    postTradingDocumentForMultipleOrders,
    ({ toastr }) => ({
      onSuccess: () => {
        onSuccess?.();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Utworzono fakturę",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const createDocumentMutation = useMutation(
    createDocumentOptions.mutationFn,
    createDocumentOptions,
  );

  if (!state) return null;

  // all positions from all orders merged into one array
  const allPositions = getAllPositions(state?.preview.orders);

  const handleSubmit = (
    values: {
      seller: string;
      orders: CreateInvoiceValues[];
      customer: string | number;
    },
    actions: FormikHelpers<{
      seller: string;
      orders: CreateInvoiceValues[];
      customer: string | number;
    }>,
  ) => {
    const keysToRemove = [
      "amountSummary",
      "amountSummaryPerVatRate",
      "amountSummaryCurrencyConvert",
      "amountSummaryPerVatRateCurrencyConvert",
    ];
    const previewTradingDocument = {
      ...state.preview,
      orders: values.orders
        .filter(order => {
          const invoicablePosition = order.positions.some(_position => !_position.alreadyInvoiced);
          if (invoicablePosition) return true;
          return false;
        })
        .map(order => ({
          ...order,
          positions: order.positions
            .filter(_position => !_position.alreadyInvoiced)
            .map(_position => {
              // @ts-ignore
              const { isPositionEdited, amountWithTaxAfterDiscount, ...restOfPosition } = _position;
              return {
                ...restOfPosition,
                discountAmount: (_position.discount / 100) * _position.amountWithTax,
              };
            }),
        })),
    };
    keysToRemove.forEach(key => delete (previewTradingDocument as any)[key]);

    const formValues: any = {
      ...previewTradingDocument,
      sellerId: values.seller,
      customerId: values.customer,
    };
    createDocumentMutation.mutate(formValues, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  const initialOrders: CreateInvoiceValues[] = state.preview.orders.map(order => {
    // @ts-ignore
    const initialPositions: Assign<
      OrderPositionForCreatingInvoice[],
      { isPositionEdited: boolean }
    > = order.positions.map(_position => ({
      ..._position,
      isPositionEdited: false,
    }));

    return {
      ...order,
      positions: initialPositions,
    };
  });

  const isEdited = (position: any): React.ReactNode => {
    if (position.isPositionEdited) {
      return <div className={cx(styles.helperText, "text-black-6")}>(edytowano)</div>;
    }
    return null;
  };

  return (
    <CustomModal
      close={close}
      isOpen={isOpen}
      overrides={{
        container: { className: styles.modalContainer },
        title: { className: styles.modalTitle },
      }}
      title="Wystaw fakturę"
    >
      <Formik
        initialValues={getInitialValues(customer, initialOrders, state.preview.sellerId)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => {
          return (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="d-flex gap-4">
                <div>
                  <div className="px-3 py-2">
                    <h4 className={cx(styles.subTitle, "pb-3")}>Sprzedawca</h4>
                    <div className={styles.selectCustomer}>
                      <FormSelect
                        items={sellersToPick}
                        itemToSelection={item => (item ? item.id : null)}
                        label="Wybierz sprzedawcę"
                        name="seller"
                        selectedItem={values.seller}
                        width="full"
                      />
                    </div>
                  </div>
                  <div className="px-3 py-2">
                    <div className="pb-3">
                      <div className={cx(styles.tableHeader, styles.tableHeaderOrder)}>
                        <div>#</div>
                        <div>nazwa towaru</div>
                        <div>zamówienie</div>
                        <div>rabat (%)</div>
                        <div>wartość rabatu</div>
                        <div className="d-flex align-items-center justify-content-end">liczba</div>
                        <div>jednostka</div>
                        <div className="d-flex align-items-center justify-content-end">
                          cena netto
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          cena brutto
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          c. brutto po rabacie
                        </div>
                        <div className="d-flex align-items-center justify-content-end">VAT</div>
                        <div className="d-flex align-items-center justify-content-end">
                          Wartość netto
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          Wartość brutto
                        </div>
                      </div>
                      <FieldArray
                        name="orders"
                        render={({ push }) => (
                          <div className={styles.positionList}>
                            {values.orders.map((order, orderIndex) => (
                              <React.Fragment key={orderIndex}>
                                {order.positions.map((_position, _positionIndex) => (
                                  <div
                                    className={cx(styles.tableRow, styles.tableRowOrder, {
                                      [styles.stripesBgGrey]: _position.alreadyInvoiced,
                                      [styles.inaccessible]: _position.alreadyInvoiced,
                                    })}
                                    key={_positionIndex}
                                  >
                                    <div className="fw-700">{_position.displayPosition}.</div>
                                    <div className="d-flex align-items-center gap-2">
                                      <div className={cx(styles.overflow, "fw-700")}>
                                        {_position.name}
                                      </div>
                                      {isEdited(_position)}
                                    </div>
                                    <div>
                                      {order.orderSignature !== undefined &&
                                      order.orderSignature.length > 0 ? (
                                        order.orderSignature
                                      ) : (
                                        <div className="d-flex align-items-center ml-4">--</div>
                                      )}
                                    </div>
                                    {_position.alreadyInvoiced ? (
                                      <div className="d-flex align-items-center fw-700">
                                        {_position.discount} %
                                      </div>
                                    ) : (
                                      <div className="d-flex align-items-baseline gap-1">
                                        <FormInput
                                          className="mb-0 mt-2"
                                          name={`orders[${orderIndex}].positions[${_positionIndex}].discount`}
                                          onChange={event => {
                                            // mark position as edited
                                            setFieldValue(
                                              `orders[${orderIndex}].positions[${_positionIndex}].isPositionEdited`,
                                              true,
                                            );

                                            // set new amount with tax after discount
                                            setFieldValue(
                                              `orders[${orderIndex}].positions[${_positionIndex}].amountWithTaxAfterDiscount`,
                                              event.target.value.length > 0
                                                ? _position.amountWithTax -
                                                    (Number(event.target.value) / 100) *
                                                      _position.amountWithTax
                                                : undefined,
                                            );
                                          }}
                                          overwrites={{
                                            floatingLabel: { className: "d-none" },
                                            input: {
                                              className: styles.amountWithoutTaxFormWrapperInput,
                                            },
                                            wrapper: {
                                              className: styles.amountWithoutTaxFormWrapper,
                                            },
                                          }}
                                          type="number"
                                        />
                                        %
                                      </div>
                                    )}
                                    <div className="d-flex align-items-center justify-content-end fw-700">
                                      <FinanceAmountFormat
                                        value={(
                                          (_position.discount / 100) *
                                          _position.amountWithTax
                                        ).toFixed(2)}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end fw-700">
                                      {_position.quantity}
                                    </div>
                                    <div className="fw-700">szt.</div>

                                    <div className="d-flex align-items-center justify-content-end">
                                      {_position.amountWithoutTax !== undefined ? (
                                        <FinanceAmountFormat value={_position.amountWithoutTax} />
                                      ) : (
                                        <div className="text-red-4">błąd przelicz.</div>
                                      )}
                                    </div>

                                    {_position.alreadyInvoiced ? (
                                      <div className="d-flex align-items-center justify-content-end">
                                        <FinanceAmountFormat value={_position.amountWithTax} />
                                      </div>
                                    ) : (
                                      <FormInput
                                        className="mb-0 mt-2 ml-2"
                                        name={`orders[${orderIndex}].positions[${_positionIndex}].amountWithTax`}
                                        onChange={e => {
                                          const newAmountWithoutTax =
                                            Number(e.target.value) / (1 + _position.vatRate / 100);

                                          // set new vat tax amount
                                          setFieldValue(
                                            `orders[${orderIndex}].positions[${_positionIndex}].taxAmountBeforeRounding`,
                                            e.target.value.length > 0
                                              ? Number(e.target.value) - newAmountWithoutTax
                                              : undefined,
                                          );

                                          // set new amount without tax
                                          setFieldValue(
                                            `orders[${orderIndex}].positions[${_positionIndex}].amountWithoutTax`,
                                            e.target.value.length > 0
                                              ? newAmountWithoutTax
                                              : undefined,
                                          );

                                          // set new amount with tax after discount
                                          setFieldValue(
                                            `orders[${orderIndex}].positions[${_positionIndex}].amountWithTaxAfterDiscount`,
                                            e.target.value.length > 0
                                              ? Number(e.target.value) -
                                                  (_position.discount / 100) *
                                                    Number(e.target.value)
                                              : undefined,
                                          );

                                          // mark position as edited
                                          setFieldValue(
                                            `orders[${orderIndex}].positions[${_positionIndex}].isPositionEdited`,
                                            true,
                                          );
                                        }}
                                        overwrites={{
                                          floatingLabel: { className: "d-none" },
                                          input: {
                                            className: styles.amountWithoutTaxFormWrapperInput,
                                          },
                                          wrapper: {
                                            className: styles.amountWithoutTaxFormWrapper,
                                          },
                                        }}
                                        type="number"
                                      />
                                    )}

                                    <div className="d-flex align-items-center justify-content-end">
                                      <FinanceAmountFormat
                                        value={_position.amountWithTaxAfterDiscount}
                                      />
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end w-100">
                                      {_position.vatRate}%
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                      {_position.amountWithoutTax !== undefined ? (
                                        <FinanceAmountFormat
                                          value={(
                                            _position.quantity * _position.amountWithoutTax
                                          ).toFixed(2)}
                                        />
                                      ) : (
                                        <div className="text-red-4">błąd przelicz.</div>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                      <FinanceAmountFormat
                                        value={(
                                          _position.quantity * _position.amountWithTax
                                        ).toFixed(2)}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      />
                      <div className="pt-4">
                        <div className={cx(styles.tableHeader, styles.tableHeaderOrderSummary)}>
                          <div />
                          <div className="d-flex align-items-center justify-content-end">
                            kwota do zapłaty
                          </div>
                          <div className="d-flex align-items-center justify-content-end" />
                          <div>VAT</div>
                          <div className="d-flex align-items-center justify-content-end">
                            wartość netto
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            kwota VAT
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            Wartość brutto
                          </div>
                        </div>
                        {state.preview.amountSummaryPerVatRate.map((summary, index) => (
                          <div
                            className={cx(styles.tableRow, styles.tableRowOrderSummary)}
                            key={index}
                          >
                            <div />
                            <div className="d-flex align-items-center justify-content-end">
                              {index === 0 && (
                                <WholeAmountToPay
                                  currency={state.preview.currency}
                                  fieldName="amountWithTax"
                                  orders={values.orders}
                                />
                              )}
                            </div>
                            {index === 0 ? (
                              <div className="d-flex align-items-center justify-content-end text-grey-35">
                                w tym:
                              </div>
                            ) : (
                              <div />
                            )}
                            <div>{summary.vatRate}%</div>
                            <AmountForVatRate
                              fieldName="amountWithoutTax"
                              orders={values.orders}
                              vatRate={summary.vatRate}
                            />
                            <AmountForVatRate
                              fieldName="taxAmountBeforeRounding"
                              orders={values.orders}
                              vatRate={summary.vatRate}
                            />
                            <AmountForVatRate
                              fieldName="amountWithTax"
                              orders={values.orders}
                              vatRate={summary.vatRate}
                            />
                          </div>
                        ))}
                        <div className={cx(styles.tableRow, styles.tableRowOrderSummary)}>
                          <div />
                          <div />
                          <div className="d-flex align-items-center justify-content-end text-grey-35">
                            suma:
                          </div>
                          <div />
                          <div className="d-flex align-items-center justify-content-end" />
                          <div className="d-flex align-items-center justify-content-end">
                            <strong className="body-14-600 fw-800">
                              <WholeAmountToPay
                                currency={state.preview.currency}
                                fieldName="taxAmountBeforeRounding"
                                orders={values.orders}
                              />
                            </strong>
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            <strong className="body-14-600 fw-800">
                              <WholeAmountToPay
                                currency={state.preview.currency}
                                fieldName="amountWithTax"
                                orders={values.orders}
                              />
                            </strong>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="d-flex justify-content-between pt-3">
                        <div className="pt-1">
                          <div>
                            <div className="d-flex align-items-center gap-3 pb-2">
                              <div>
                                <div className="body-12 fw-700 text-black-6">Forma płatności</div>
                                <div>
                                  {state.preview.expectedPaymentForm
                                    ? getExpectedPaymentForm(state.preview.expectedPaymentForm)
                                    : EMPTY_VALUE}
                                </div>
                              </div>
                              <div>
                                <div className="body-12 fw-700 text-black-6">Termin płatności</div>
                                {state.preview.basePaymentDeadlineDelta ? (
                                  <div>
                                    {state.preview.basePaymentDeadlineDelta}{" "}
                                    {pluralize.pl(state.preview.basePaymentDeadlineDelta, {
                                      singular: "dzień",
                                      plural: "dni",
                                      other: "dni",
                                    })}
                                  </div>
                                ) : (
                                  EMPTY_VALUE
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div>
                          <div className="d-flex align-items-center justify-content-end gap-3">
                            <div className="d-flex align-items-center justify-content-end body-14-500">
                              razem do zapłaty:
                            </div>

                            <div className="d-flex align-items-center justify-content-end">
                              <strong className="body-18 fw-800">
                                <WholeAmountToPay
                                  currency={state.preview.currency}
                                  fieldName="amountWithTax"
                                  orders={values.orders}
                                />
                              </strong>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end gap-3">
                            <div className="d-flex align-items-center justify-content-end body-12">
                              pozostaje do zapłaty:
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                              <strong className="body-14 fw-800">
                                <WholeAmountToPay
                                  currency={state.preview.currency}
                                  fieldName="amountWithTax"
                                  orders={values.orders}
                                />
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4">
                      <RightPanelTableRow
                        grid={currencyConvertSectionListConfig.grid}
                        hover={false}
                      >
                        <div>kurs</div>
                        <div>z dnia</div>
                        <div></div>
                        <div>VAT</div>
                        <div className="d-flex align-items-center justify-content-end">
                          wartość netto
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          kwota VAT
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          wartość brutto
                        </div>
                      </RightPanelTableRow>
                      {state.preview.amountSummaryPerVatRateCurrencyConvert.map(
                        (currencyConvert, index) => (
                          <CurrencyConvertionSection
                            currencyConvert={currencyConvert}
                            key={index}
                            orders={values.orders}
                          />
                        ),
                      )}
                      <RightPanelTableRow grid={currencyConvertSectionListConfig.grid}>
                        <div />
                        <div />
                        <div className="d-flex align-items-center justify-content-end text-grey-35">
                          suma:
                        </div>
                        <div />
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          {state.preview.amountSummaryCurrencyConvert.exchangeRate ? (
                            <WholeAmountToPay
                              convertionRate={
                                state.preview.amountSummaryCurrencyConvert.exchangeRate
                              }
                              currency={state.preview.currency}
                              fieldName="amountWithoutTax"
                              orders={values.orders}
                            />
                          ) : (
                            EMPTY_VALUE
                          )}
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          {state.preview.amountSummaryCurrencyConvert.exchangeRate ? (
                            <WholeAmountToPay
                              convertionRate={
                                state.preview.amountSummaryCurrencyConvert.exchangeRate
                              }
                              currency={state.preview.currency}
                              fieldName="taxAmountBeforeRounding"
                              orders={values.orders}
                            />
                          ) : (
                            EMPTY_VALUE
                          )}
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          {state.preview.amountSummaryCurrencyConvert.exchangeRate ? (
                            <WholeAmountToPay
                              convertionRate={
                                state.preview.amountSummaryCurrencyConvert.exchangeRate
                              }
                              currency={state.preview.currency}
                              fieldName="amountWithTax"
                              orders={values.orders}
                            />
                          ) : (
                            EMPTY_VALUE
                          )}
                        </div>
                      </RightPanelTableRow>
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-end gap-3">
                        <div className="d-flex align-items-center justify-content-end body-14-600">
                          razem do zapłaty:
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          {state.preview.amountSummaryCurrencyConvert.exchangeRate ? (
                            <strong className="body-18-800">
                              <div className="d-flex">
                                <WholeAmountToPay
                                  convertionRate={
                                    state.preview.amountSummaryCurrencyConvert.exchangeRate
                                  }
                                  currency={state.preview.currency}
                                  fieldName="amountWithTax"
                                  isMainSummary
                                  orders={values.orders}
                                />
                              </div>
                            </strong>
                          ) : (
                            <strong className="body-18-800">--</strong>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomerSection preview={state.preview} />
              </div>
              <div className="d-flex align-items-center gap-3 p-3 borderTop">
                <Button className="uppercase" kind="create-medium-transparent" onClick={close}>
                  <div className="btnBaseMedium btnBase">Anuluj</div>
                </Button>
                <Button
                  className={cx({
                    [styles.noPositionsToInvoiceBtn]: !allPositions.find(
                      position => position.alreadyInvoiced === false,
                    ),
                  })}
                  disabled={
                    isSubmitting ||
                    !allPositions.find(position => position.alreadyInvoiced === false)
                  }
                  kind="submit-medium"
                  type="submit"
                >
                  <div className="btnBaseMedium btnBase">Wystaw fakturę{isSubmitting && "..."}</div>
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

const getExpectedPaymentForm = (expectedPaymentForm: ExpectedPaymentForm): string => {
  if (!Boolean(expectedPaymentForm)) return EMPTY_VALUE;
  if (expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE")
    return tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm];
  return expectedPaymentForm;
};
