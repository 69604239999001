import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { externalManufacturingOrdersActions } from "api/external-manufacturing/orders/actions";
import { AddUnloadingPreview } from "api/external-manufacturing/orders/models";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import infoSuccessIcon from "assets/images/infoSuccess.svg";
import { useStateModal } from "hooks";
import { EstimatedTimeOfSendingEmail } from "pages/externalManufacturing/shared/components/EstimatedTimeOfSendingEmail";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import styles from "./RightPanel.module.css";
import { cx } from "utilities";
import { Link } from "components/miloDesignSystem/atoms/link";

interface Props {
  order: ExternalManufacturingOrder;
}

interface PostUnloadingResponseModalProps {
  close: () => void;
  state: AddUnloadingPreview;
}

export const GeneralInfoSection = ({ order }: Props) => {
  const postUnloadingMutation = externalManufacturingOrdersActions.usePostUnloading();
  const postUnloadingResponseModal = useStateModal<AddUnloadingPreview>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <InfoLabel title="producent">
          <Typography fontSize="14" fontWeight="700">
            {order.manufacturer.name}
          </Typography>
        </InfoLabel>
        <InfoLabel title="planowana wysyłka">
          <EstimatedTimeOfSendingEmail
            estimatedTimeOfSendingEmail={order.estimatedTimeOfSendingEmail}
            fontSize="16"
            fontWeight="700"
          />
        </InfoLabel>
        <InfoLabel title="rozładunek">
          {Boolean(order.unloadings.length) ? (
            <div className="d-flex align-items-center gap-1">
              <Link
                fontSize="14"
                fontWeight="600"
                to={`/wms/unloadings/list/all?panelId=${order.unloadings[0].id}`}
              >
                {order.unloadings[0].signature}
              </Link>
              {order.unloadings.length > 1 && (
                <Typography color="deepPurple400" fontSize="14" fontWeight="600">
                  +{order.unloadings.length - 1}
                </Typography>
              )}
            </div>
          ) : (
            <Button
              className="text-uppercase"
              size="small"
              variant="gray"
              isLoading={postUnloadingMutation.isLoading}
              startIcon={MdiAdd}
              onClick={() => {
                postUnloadingMutation.mutate(
                  { manufacturingOrder: order.id },
                  {
                    onSuccess: payload => {
                      postUnloadingResponseModal.open(payload);
                    },
                  },
                );
              }}
            >
              Utwórz rozładunek
            </Button>
          )}
        </InfoLabel>
      </RightPanelSection>
      {postUnloadingResponseModal.isOpen && postUnloadingResponseModal.state && (
        <PostUnloadingResponseModal
          close={postUnloadingResponseModal.close}
          state={postUnloadingResponseModal.state}
        />
      )}
    </>
  );
};

const PostUnloadingResponseModal = ({ close, state }: PostUnloadingResponseModalProps) => {
  return (
    <Modal close={close} isOpen removeHeader width={430}>
      <>
        <div className={cx("d-flex justify-content-between p-3", styles.successTitle)}>
          <div className="d-flex align-items-center">
            <div>
              <img alt="sukces" src={infoSuccessIcon} />
            </div>
            <Typography color="neutralWhite100" fontSize="20" fontWeight="700">
              Dodano rozładunek
            </Typography>
          </div>
          <IconButton
            icon={<MdiClose size="16" />}
            onClick={close}
            variant="transparent"
            theme="dark"
          />
        </div>
        <div className="d-flex flex-column p-3 gap-2">
          <div className="d-flex align-items-center gap-2">
            <Typography color="success500" fontSize="16" fontWeight="600">
              Pomyślnie utworzono rozładunek:
            </Typography>
            <Link
              fontSize="16"
              fontWeight="700"
              to={`/wms/unloadings/list/all?panelId=${state.id}`}
            >
              {state.signature}
            </Link>
          </div>
          <div>
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Zamknij
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
