import { ListOrder, Order } from "api/orders/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { orderConstants } from "constants/orders";
import { orderUtils } from "pages/orders/orderUtils";

interface Props {
  order: ListOrder;
}

export const PaymentLabel = ({ order }: Props) => {
  const getOrderItemsAmount = orderUtils.useGetOrderItemsAmount();
  const isOrderValueForPaidOrderValid = orderUtils.isOrderValueForPaidOrderValid({
    itemsValue: getOrderItemsAmount(order?.items || []),
    orderPayment: order.payment,
    deliveryAmount: order.deliveryAmount,
  });

  const fullOrderPrice = orderUtils.getOrderPriceWithDeliveryAndDiscount({
    deliveryAmount: order.deliveryAmount,
    discount: order.payment.discount,
    itemsAmount: getOrderItemsAmount(order?.items || []),
  });

  return (
    <div className="d-flex align-items-center gap-2">
      <PaymentSourceTag order={order} />
      {isOrderValueForPaidOrderValid ? (
        <PaymentStatusTag paymentStatus={order.payment.status} />
      ) : (
        <div className="d-flex align-items-center gap-2">
          <Tag
            label={`Opłacono ${Number(order.payment.paidAmount).toFixed(2)} ${
              order.payment.currency
            }`}
            variant="success"
          />
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="10" fontWeight="400" color="neutralBlack48">
              suma:
            </Typography>
            <Typography fontSize="12" fontWeight="700">
              {fullOrderPrice.toFixed(2)} {order.payment.currency}
            </Typography>
            <Typography fontSize="10" fontWeight="400" color="danger500">
              różnica:
            </Typography>
            <Typography fontSize="12" fontWeight="700" color="danger500">
              {(fullOrderPrice - Number(order.payment.paidAmount)).toFixed(2)}{" "}
              {order.payment.currency}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentSourceTag = ({ order }: Props) => {
  switch (order.payment.source) {
    case "ONLINE":
      return <Tag label="Przelew" variant="deepPurple50" />;
    case "ON_DELIVERY":
      return <Tag label="Pobranie" variant="info" />;
    default:
      const exhaustiveCheck: never = order.payment.source;
      console.error(`Unhandled payment source: ${exhaustiveCheck}`);
      return null;
  }
};

export const PaymentStatusTag = ({
  paymentStatus,
}: {
  paymentStatus: Order["payment"]["status"];
}) => {
  switch (paymentStatus) {
    case "OVERPAID":
      return <Tag label={orderConstants.paymentStatusDict["OVERPAID"]} variant="info" />;
    case "PAID":
      return <Tag label={orderConstants.paymentStatusDict["PAID"]} variant="success" />;
    case "PARTIALLY_PAID":
      return <Tag label={orderConstants.paymentStatusDict["PARTIALLY_PAID"]} variant="warning" />;
    case "UNPAID":
      return <Tag label={orderConstants.paymentStatusDict["UNPAID"]} variant="danger" />;
    default:
      const exhaustiveCheck: never = paymentStatus;
      console.error(`Unhandled payment status: ${exhaustiveCheck}`);
      return <Tag label="Nie rozpoznano statusu płatności" variant="danger" type="outlined" />;
  }
};
