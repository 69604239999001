import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { indexesUtils } from "pages/externalManufacturing/indexes/utils/indexesUtils";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { SelectAllItemsCheckbox } from "../shared/components/SelectAllItemsCheckbox";
import { SelectItemCheckbox } from "../shared/components/SelectItemCheckbox";

export const useIndexesColumns = (
  items: number[],
  selected: number[],
  setSelected: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  return useCreateTableColumns<IndexForExternalManufacturingListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectIndexes",
        header: () => (
          <div className="d-flex align-items-center gap-2">
            <SelectAllItemsCheckbox items={items} selected={selected} setSelected={setSelected} />
          </div>
        ),
        size: 35,
        cell: info => {
          const index = info.getValue();

          return (
            <SelectItemCheckbox
              itemId={index.id}
              items={items}
              selected={selected}
              setSelected={setSelected}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.name, {
        header: "nazwa",
        size: 400,
        cell: info => {
          const name = info.getValue();

          return (
            <div className="d-flex flex-1 align-items-center gap-2 overflow-hidden">
              <MdiChair color="magenta300" size="12" />
              <Typography fontSize="12" fontWeight="600" noWrap>
                {name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.verboseAttributesValues, {
        header: "atrybuty",
        size: 381,
        cell: info => {
          const verboseAttributesValues = info.getValue();

          if (!Boolean(verboseAttributesValues.length)) return <EmptyValue fontWeight="600" />;

          return <Attributes attributes={verboseAttributesValues} />;
        },
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 110,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.warehouseState, {
        id: "state",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan
          </Typography>
        ),
        size: 85,
        cell: info => {
          const warehouseState = info.getValue();

          return (
            <div className="w-100 d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-baseline gap-1">
                <Typography
                  color={
                    indexesUtils.getWarehouseStateLegend({ warehouseState })
                      .textColor as ColorPalette
                  }
                  fontSize="14"
                  fontWeight="700"
                >
                  {warehouseState.state}
                </Typography>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  szt.
                </Typography>
              </div>
              {indexesUtils.getWarehouseStateLegend({ warehouseState }).icon}
            </div>
          );
        },
      }),
    ];
  });
};
