import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OrderLineItemDetails, OrderStatus } from "api/external-manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";

export const useLabelListColumns = (lineItemDetails: OrderLineItemDetails) =>
  useCreateTableColumns<OrderLineItemDetails["labels"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 110,
        }),
        columnHelper.text(row => row.description, {
          header: "opis",
          typographyProps: { fontWeight: "600" },
          size: 227,
        }),
        columnHelper.accessor(row => row.received, {
          header: "status",
          size: 75,
          cell: info => {
            const received = info.getValue();

            if (received) return <Tag label="Odebrano" variant="success" />;

            if (lineItemDetails.orderStatus === OrderStatus.SENT)
              return <Tag label="Zlecono" variant="info" />;

            return <Tag label="Nie zlecono" variant="warning" />;
          },
        }),
        columnHelper.date(row => row.received?.receivedAt ?? null, {
          header: "data przyjęcia",
          size: 80,
          typographyProps: {
            fontSize: "12",
            fontWeight: "400",
            color: "neutralBlack88",
          },
        }),
        columnHelper.accessor(row => row.parameters.weight, {
          header: "waga",
          size: 60,
          cell: info => {
            const weight = info.getValue();
            return (
              <Typography fontSize="10" fontWeight="700">
                {weight} kg
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.parameters, {
          header: "wymiary",
          size: 80,
          cell: info => {
            const parameters = info.getValue();
            return (
              <Typography fontSize="10" fontWeight="700">
                {parameters.length}x{parameters.width}x{parameters.height} cm
              </Typography>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
