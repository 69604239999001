import { DraftDocumentPosition, TradingDocument } from "api/trading-documents/models";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Link } from "components/miloDesignSystem/atoms/link";
import { queryString } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useIndexesManufacturers } from "api/indexes/hooks";

export const useDocumentPositionsColumns = (
  tradingDocument: TradingDocument,
  normalizedRows: DraftDocumentPosition[],
) => {
  const removePositionMutation = tradingDocumentsActions.useRemoveDraftDocumentPosition();
  const sourceIndexes = normalizedRows
    .filter(item => item.sourceIndex)
    .map(item => item.sourceIndex);

  const { data: indexes, isLoading } = useIndexesManufacturers(
    queryString.stringify({
      indexes: sourceIndexes,
    }),
    { enabled: Boolean(sourceIndexes.length) },
  );

  return useCreateTableColumns<DraftDocumentPosition>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa towaru / usługi",
          size: 280,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "600",
          },
          editable: {
            mutationHook: tradingDocumentsActions.useModifyDraftDocumentPosition,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              name: value,
              tradingDocumentId: tradingDocument.id,
            }),
          },
        }),
        columnHelper.accessor(row => row, {
          header: "producent",
          size: 100,
          cell: info => {
            const row = info.getValue();

            if (isLoading) return <Spinner size={20} />;

            const indexData = indexes?.find(index => index.id === row.sourceIndex);
            return (
              <Typography fontSize="14" fontWeight="500" noWrap>
                {indexData?.manufacturer?.name || EMPTY_VALUE}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.order, {
          header: "zamówienie",
          size: 130,
          cell: info => {
            const order = info.getValue();
            if (!order) return <EmptyValue color="neutralBlack88" fontSize="14" fontWeight="400" />;
            return (
              <Link
                fontSize="14"
                fontWeight="500"
                target="_blank"
                to={`/orders/list/active/all?panelId=${order.id}`}
              >
                {order.signature}
              </Link>
            );
          },
        }),
        columnHelper.number(row => row.discount, {
          textAlign: "right",
          header: "rabat [%]",
          size: 65,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
          },
          editable: {
            mutationHook: tradingDocumentsActions.useModifyDraftDocumentPosition,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              discount: Number(value),
              tradingDocumentId: tradingDocument.id,
            }),
          },
        }),
        columnHelper.amount(row => row.discountAmount, {
          header: "wartość rabatu",
          size: 88,
        }),
        columnHelper.number(row => row.quantity, {
          textAlign: "right",
          header: "liczba",
          size: 50,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
          },
          editable: {
            mutationHook: tradingDocumentsActions.useModifyDraftDocumentPosition,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              quantity: Number(value),
              tradingDocumentId: tradingDocument.id,
            }),
          },
        }),
        columnHelper.text(row => (Boolean(row.unit) ? row.unit : EMPTY_VALUE), {
          header: "jedn.",
          size: 40,
        }),
        columnHelper.amount(row => row.amountWithoutTax, {
          header: "cena netto",
          size: 100,
        }),
        columnHelper.amount(row => row.amountWithTax, {
          header: "cena brutto",
          size: 100,
          editable: {
            mutationHook: tradingDocumentsActions.useModifyDraftDocumentPosition,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              amountWithTax: Number(value),
              tradingDocumentId: tradingDocument.id,
            }),
          },
        }),
        columnHelper.number(row => row.vatRate, {
          header: "VAT [%]",
          size: 65,
          editable: {
            mutationHook: tradingDocumentsActions.useModifyDraftDocumentPosition,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              vatRate: Number(value),
              tradingDocumentId: tradingDocument.id,
            }),
          },
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.amount(row => row.amountWithTaxAfterDiscount, {
          header: "cena brutto po rabacie",
          size: 130,
        }),
        columnHelper.amount(row => row.totalAmountWithTaxAfterDiscount, {
          header: "wartość brutto",
          size: 100,
        }),
        columnHelper.accessor(row => row, {
          id: "removePosition",
          header: " ",
          size: 26,
          cell: info => {
            const documentPosition = info.getValue();
            return (
              <IconButton
                icon={MdiClose}
                isLoading={
                  removePositionMutation.isLoading &&
                  removePositionMutation.variables === documentPosition.id
                }
                onClick={() => removePositionMutation.mutate(documentPosition.id)}
                size="small"
                variant="transparent"
              />
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: row => row.displayPosition },
  );
};
