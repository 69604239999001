import { UUID } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import { useLineItemsColumns } from "./useLineItemsColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "../../../DraftOrders.module.css";
import { cx } from "utilities";
import {
  DraftOrderDetails,
  DraftOrderLineItem,
  DraftOrderListItem,
} from "api/external-manufacturing/orders/drafts/models";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";
import { DraftOrderLineItemSourceKind } from "api/external-manufacturing/orders/drafts/enums";

interface Props {
  draftOrder: DraftOrderDetails;
}

interface Filters {
  page: number;
  externalManufacturingOrderDraft: UUID;
}

export interface NormalizedLineItem
  extends Pick<
    DraftOrderLineItem,
    | "code"
    | "createdAt"
    | "currentlyInProduction"
    | "description"
    | "id"
    | "manufacturer"
    | "parent"
    | "quantity"
    | "name"
    | "salesFromLastTwoWeeks"
    | "sourceKind"
    | "warehouseState"
  > {
  createdBy: DraftOrderLineItem["createdBy"] | null;
  externalManufacturingOrderDraft: DraftOrderListItem["id"] | null;
  sourceId: DraftOrderLineItem["sourceId"] | null;
  subRows: NormalizedLineItem[];
}

export const LineItemsSection = ({ draftOrder }: Props) => {
  const { filters, searchParams, setFilter } = useFilters<Filters>({
    page: 1,
    externalManufacturingOrderDraft: draftOrder.id,
  });
  const {
    data: lineItems,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = draftOrdersActions.useLineItems(searchParams);

  const tableProps = useTableFeatureConnector({
    rows: getNormalizedData(lineItems),
  });

  const columns = useLineItemsColumns(filters.page);

  return (
    <RightPanelSection
      padding="px-0 pt-3 pb-2"
      title={
        <Typography className="px-3" color="neutralBlack48" fontSize="14" fontWeight="600">
          Produkty i paczki w zleceniu
        </Typography>
      }
    >
      <Table<NormalizedLineItem>
        expandable
        columns={columns}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        isLoading={isLoading || isPreviousData}
        error={error}
        uiSchema={{ cell: { height: "34" }, header: { backgroundColor: "neutralWhite100" } }}
        {...tableProps}
        overrides={() => {
          return {
            ...tableProps.overrides?.(),
            row: row => {
              return {
                className: cx(
                  {
                    [styles.lineItemSubRow]: Boolean(row.parent),
                    [styles.indexRow]: row.sourceKind === DraftOrderLineItemSourceKind.INDEX,
                  },
                  tableProps.overrides?.().row?.(row).className,
                ),
              };
            },
          };
        }}
      />
    </RightPanelSection>
  );
};

export const getNormalizedData = (lineItems: DraftOrderLineItem[]): NormalizedLineItem[] => {
  if (!lineItems) return [];

  return lineItems.map(lineItem => {
    const subRows: NormalizedLineItem[] = (lineItem.children || []).map(packageRow => {
      return {
        code: packageRow.code,
        createdAt: packageRow.createdAt,
        createdBy: null,
        currentlyInProduction: packageRow.currentlyInProduction,
        description: packageRow.description,
        externalManufacturingOrderDraft: lineItem.externalManufacturingOrderDraft,
        id: packageRow.id,
        manufacturer: packageRow.manufacturer,
        name: packageRow.name,
        parent: packageRow.parent,
        quantity: packageRow.quantity,
        salesFromLastTwoWeeks: packageRow.salesFromLastTwoWeeks,
        sourceId: null,
        sourceKind: packageRow.sourceKind,
        subRows: [],
        warehouseState: packageRow.warehouseState,
      };
    });

    return {
      code: lineItem.code,
      id: lineItem.id,
      createdAt: lineItem.createdAt,
      createdBy: lineItem.createdBy,
      externalManufacturingOrderDraft: lineItem.externalManufacturingOrderDraft,
      subRows: lineItem.sourceKind === DraftOrderLineItemSourceKind.PACKAGE ? [] : subRows,
      manufacturer: lineItem.manufacturer,
      parent: lineItem.parent,
      sourceId: lineItem.sourceId,
      sourceKind: lineItem.sourceKind,
      description: lineItem.description,
      name: lineItem.name,
      quantity: lineItem.quantity,
      currentlyInProduction: lineItem.currentlyInProduction,
      salesFromLastTwoWeeks: lineItem.salesFromLastTwoWeeks,
      warehouseState: lineItem.warehouseState,
    };
  });
};
