import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiStateHigh = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M4 13H8V19H4V13Z" />
    <path d="M10 9H14V19H10V9Z" />
    <path d="M16 5H20V19H16V5Z" />
  </SvgWrapper>
);
