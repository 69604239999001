import { ProductEntity } from "api/products/models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { productSetKeys } from "./keys";

const getCustomerElements = (hackId: string | number): ApiFetcher<ProductEntity> => ({
  key: productSetKeys.customerElements(hackId),
  fetcher: () => {
    if (typeof hackId === "number") {
      return queryFetch<ProductEntity>({
        method: "GET",
        url: `/product-sets/elements-customer/${hackId}`,
      });
    } else {
      // createPrimitiveHook accepts only string | number | null so we need to hack two ids into a string
      const [customer, product] = hackId.split("-");
      return queryFetch<ProductEntity>({
        method: "GET",
        url: `/product-sets/elements-customer/${customer}/${product}`,
      });
    }
  },
});

const getCustomerElementsB2b = (hackId: string | number): ApiFetcher<ProductEntity> => ({
  key: productSetKeys.customerElementsB2b(hackId),
  fetcher: () => {
    if (typeof hackId === "number") {
      return queryFetch<ProductEntity>({
        method: "GET",
        url: `/product-sets/elements-b2b/${hackId}`,
      });
    } else {
      // createPrimitiveHook accepts only string | number | null so we need to hack two ids into a string
      const [customer, product] = hackId.split("-");
      return queryFetch<ProductEntity>({
        method: "GET",
        url: `/product-sets/elements-b2b/${customer}/${product}`,
      });
    }
  },
});
export const productSetApi = {
  getCustomerElements,
  getCustomerElementsB2b,
};
