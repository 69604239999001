import { marketplaceStatusesApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";

const useAttempts = createPaginatedApiQuery(marketplaceStatusesApi.getAttempts);
const useItems = createPaginatedApiQuery(marketplaceStatusesApi.getItems);

const usePostRetry = () =>
  useMutation(
    marketplaceStatusesApi.postRetry,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

const usePostOrdersStatusAsSent = () =>
  useMutation(
    marketplaceStatusesApi.postOrdersStatusAsSent,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

export const marketplaceStatusesActions = {
  useAttempts,
  useItems,
  usePostOrdersStatusAsSent,
  usePostRetry,
};
