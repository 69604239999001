import { filterFactory } from "components/utils/withFilters";
import { priorityDict } from "pages/externalManufacturing/shared/components/PriorityLabel";
import { countryCodes } from "CONSTANTS";
import { dateFns, dateUtils } from "utilities";
import { addWeeks, subWeeks } from "date-fns";

export const orderLineItemsFiltersList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    label: "producent",
    name: "manufacturerId",
    searchBy: "manufacturers",
  },
  {
    type: FilterType.DateRangeWithOptions,
    label: "data odbioru",
    name: ["minPickedUpAt", "maxPickedUpAt"],
    options: [
      {
        label: "DZISIAJ",
        value: [dateUtils.formatDateToIso(new Date()), dateUtils.formatDateToIso(new Date())],
      },
      {
        label: "JUTRO",
        value: [
          dateUtils.formatDateToIso(dateFns.addDays(new Date(), 1)),
          dateUtils.formatDateToIso(dateFns.addDays(new Date(), 1)),
        ],
      },
      {
        label: "W CIĄGU 3 DNI",
        value: [
          dateUtils.formatDateToIso(new Date()),
          dateUtils.formatDateToIso(dateFns.addDays(new Date(), 3)),
        ],
      },
      {
        label: "W CIĄGU 5 DNI",
        value: [
          dateUtils.formatDateToIso(new Date()),
          dateUtils.formatDateToIso(dateFns.addDays(new Date(), 5)),
        ],
      },
    ],
  },
  {
    type: FilterType.Select,
    label: "Odebrane przez sprzedawcę",
    name: "pickedUpBySeller",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
  {
    type: FilterType.Select,
    label: "sortuj wg",
    name: "ordering",
    options: [{ label: "Najnowsze zlecenia", value: "-createdAt" }],
  },
  {
    type: FilterType.DateRangeWithOptions,
    label: "data zlecenia",
    name: ["minOrderedAt", "maxOrderedAt"],
    options: [
      {
        label: "POPRZEDNI TYDZIEŃ",
        value: [
          dateUtils.formatDateToIso(dateFns.startOfWeek(subWeeks(new Date(), 1))),
          dateUtils.formatDateToIso(dateFns.endOfWeek(subWeeks(new Date(), 1))),
        ],
      },
      {
        label: "TEN TYDZIEŃ",
        value: [
          dateUtils.formatDateToIso(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 })),
          dateUtils.formatDateToIso(dateFns.endOfWeek(new Date(), { weekStartsOn: 1 })),
        ],
      },
      {
        label: "NASTĘPNY TYDZIEŃ",
        value: [
          dateUtils.formatDateToIso(dateFns.startOfWeek(addWeeks(new Date(), 1))),
          dateUtils.formatDateToIso(dateFns.endOfWeek(addWeeks(new Date(), 1))),
        ],
      },
    ],
  },
  {
    type: FilterType.Select,
    label: "państwo",
    name: "countryCode",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
  {
    type: FilterType.Select,
    label: "priorytet",
    name: "priority",
    options: Object.entries(priorityDict)
      .map(([key, value]) => ({
        label: value.label,
        value: key,
      }))
      .reverse(),
  },
  {
    type: FilterType.Select,
    label: "potwierdzono",
    name: "hasConfirmedDeliveryDate",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
]);
