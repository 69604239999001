import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { AwaitingTransportGroupListItem, GroupStatus } from "api/wms/awaiting-transports/models";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { wmsAwaitingTransportsApi } from "api/wms/awaiting-transports/api";
import { queryString } from "utilities";

export const useGroupColumns = (orders: AwaitingTransportGroupListItem[]) => {
  return useCreateTableColumns<AwaitingTransportGroupListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "zamówienie",
          size: 120,
          typographyProps: { fontSize: "12", fontWeight: "600" },
        }),
        columnHelper.accessor(row => row.status, {
          header: "skan",
          size: 80,
          cell: info => {
            const status = info.getValue();
            return getGroupStatus(status);
          },
        }),
        columnHelper.text(row => row.sourceExternalId, {
          header: "nr kontrahenta",
          size: 100,
        }),
        columnHelper.accessor(row => row, {
          header: "paczki",
          size: 60,
          cell: info => {
            const loadedPackages = info.getValue().loadedPackages;
            const numberOfPackages = info.getValue().numberOfPackages;

            return (
              <div className="d-flex align-items-center">
                <Typography
                  color={loadedPackages ? "success500" : "neutralBlack100"}
                  fontSize="14"
                  fontWeight="700"
                >
                  {loadedPackages}
                </Typography>
                <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                  /{numberOfPackages}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.address, {
          header: "kraj wysyłki",
          size: 70,
          cell: info => {
            const address = info.getValue();
            if (!address || !address.countryCode)
              return <EmptyValue fontSize="14" fontWeight="700" />;
            return <CountryFlag countryCode={address.countryCode} />;
          },
        }),
        columnHelper.accessor(row => row.id, {
          header: "",
          id: "labels",
          size: 56,
          cell: info => {
            const id = info.getValue();
            return (
              <FileDownloadHandler
                factoryFn={() =>
                  wmsAwaitingTransportsApi.awaitingTransportFileFactory.packagesLabels(
                    queryString.stringify({ packageGroupId: id }),
                  )
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <Tooltip title="Pobierz etykiety">
                    <IconButton
                      icon={MdiQrCode}
                      isLoading={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        download();
                      }}
                      variant="transparent"
                    />
                  </Tooltip>
                )}
              </FileDownloadHandler>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: row => row.position },
  );
};

export const getGroupStatus = (status: AwaitingTransportGroupListItem["status"]) => {
  switch (status) {
    case GroupStatus.FINISHED: {
      return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
    }
    case GroupStatus.NOT_STARTED: {
      return null;
    }
    case GroupStatus.IN_PROGRESS: {
      return <Tag label="w trakcie" variant="warning" />;
    }
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled group status: ${exhaustiveCheck}`);
      return null;
    }
  }
};
