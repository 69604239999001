import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { LineItemsSection } from "./components/lineItemsSection/LineItemsSection";
import { draftOrdersActions } from "api/external-manufacturing/orders/drafts/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: draftOrder, error, isLoading } = draftOrdersActions.useItem(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!draftOrder) return null;

  return (
    <DrawerRightPanel key={draftOrder.id}>
      <PanelHeader draftOrder={draftOrder} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <GeneralInfoSection draftOrder={draftOrder} />
          <LineItemsSection draftOrder={draftOrder} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
