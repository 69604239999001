import { uniquePackagesActions } from "api/unique-packages/actions";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiTruckS } from "components/miloDesignSystem/atoms/icons/MdiTruckS";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { NormalizedLongHeldPackage } from "pages/wms/longHeldPackages/useLongHeldPackagesColumns";

interface Props {
  selectedUniquePackages: NormalizedLongHeldPackage[];
}

export const CreateLoadingForUniquePackages = ({ selectedUniquePackages }: Props) => {
  const createLoadingMutation = uniquePackagesActions.useBulkCreateLoading();

  return (
    <div>
      <Tooltip title="Utwórz załadunek">
        <IconButton
          icon={MdiTruckS}
          isLoading={createLoadingMutation.isLoading}
          onClick={() =>
            createLoadingMutation.mutate({
              uniquePackagesGroupsIds: [
                ...new Set(selectedUniquePackages.map(uniquePackage => uniquePackage.productId)),
              ],
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
