import { countryCodes } from "CONSTANTS";
import { getRoute, getRoutesSimplifiedItems } from "api/routes/calls";
import { filterFactory } from "components/utils/withFilters";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    kind: "label",
    label: "status",
    name: "status",
    options: [
      { label: "nierozpoczęty", value: "NOT_STARTED" },
      { label: "w trakcie", value: "IN_PROGRESS" },
      { label: "zakończony", value: "FINISHED" },
    ],
  },
  {
    type: FilterType.Select,
    label: "Czy zlecenie wymaga poprawy?",
    name: "isDeclined",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "Status gotowości zlecenia do rozpoczęcia",
    name: "stageStatus",
    options: [
      { label: "gotowe", value: "READY" },
      { label: "niegotowe", value: "NOT_STARTED" },
    ],
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "priorytet",
    multiple: true,
    name: "priorities",
    options: [
      { label: "krytyczny", value: "A" },
      { label: "pilny", value: "B" },
      { label: "zwykły", value: "C" },
    ],
  },
  {
    type: FilterType.Select,
    label: "kraj",
    name: "countryCodes",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "rodzaj produkcji",
    name: "implementedBy",
    options: [
      { label: "na miejscu", value: "IN_HOUSE" },
      { label: "u kontrahenta", value: "CONTRACTOR" },
    ],
  },
  {
    type: FilterType.Search,
    label: "kontrahenci",
    name: "manufacturers",
    multiple: true,
    searchBy: "manufacturers",
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "materiał",
    multiple: true,
    name: "materialOrderStatus",
    options: [
      { label: "niezamówiony", value: "NOT_ORDERED" },
      { label: "odebrany", value: "RECEIVED" },
      { label: "zamówiony", value: "ORDERED" },
    ],
  },
  {
    type: FilterType.AsyncSearch,
    fetchListFrom: getRoutesSimplifiedItems,
    fetchDetailsFrom: getRoute,
    label: "Numer trasy",
    name: "routes",
    value: "id",
    display: "signature",
    placeholder: "Szukaj trasy",
  },
  {
    type: FilterType.Select,
    label: "Czy są przypisane do trasy?",
    name: "hasRoute",
    options: [
      { label: "Przypisane do trasy", value: "true" },
      { label: "Nieprzypisane do trasy", value: "false" },
    ],
  },
]);
