import { SoldIndex } from "api/trading-documents/overview/models";
import { ISODate } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes/Attributes";
import { useQuery } from "hooks";
import { dateFns, dateUtils } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Assign } from "utility-types";

export const useSoldIndexesColumns = () => {
  const { query } = useQuery();

  return useCreateTableColumns<Assign<SoldIndex, { id: string }>>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.productName, {
        header: "nazwa produktu",
        size: 200,
      }),
      columnHelper.accessor(row => row.attributes, {
        header: "atrybuty",
        size: 350,
        cell: info => {
          const attributes = info.getValue();
          if (!Boolean(attributes.length)) return <EmptyValue />;
          return <Attributes attributes={attributes} />;
        },
      }),
      columnHelper.text(row => row.indexExternalId, {
        header: "numer zewnętrzny",
        size: 130,
      }),
      columnHelper.text(row => row.indexInternalId, {
        header: "numer wewnętrzny",
        size: 130,
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 100,
      }),
      columnHelper.text(row => row.salesAccountName, {
        header: "konto sprzedażowe",
        size: 120,
      }),
      columnHelper.accessor(row => row, {
        header: "data sprzedaży",
        size: 100,
        cell: info => {
          const soldDate = info.getValue().soldDate;
          const year = info.getValue().item_Order_Created_Year;
          if (!soldDate) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="600">
                {query["time_grouping_variable"] === "DAY"
                  ? dateUtils.formatDateToDisplayOnlyDate(soldDate as ISODate)
                  : query["time_grouping_variable"] === "WEEK"
                  ? `Tydzień ${parseInt(soldDate as string)},`
                  : `${dateFns.format(
                      new Date(2000, parseInt(soldDate as string) - 1, 1),
                      "LLLL",
                    )}`}
              </Typography>
              {query["time_grouping_variable"] !== "DAY" && (
                <Typography fontSize="12" fontWeight="600">
                  {year}
                </Typography>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor(row => row.numberOfSold, {
        id: "numberOfSold",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            sprzedanych sztuk
          </Typography>
        ),
        size: 120,
        cell: info => {
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue()}
            </Typography>
          );
        },
      }),
    ];
  });
};
