import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ManufacturingOrder, ManufacturingOrderStatus } from "api/manufacturing/models";
import { dateFns, dateUtils } from "utilities";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingActions } from "api/manufacturing/actions";
import { EmailStatus } from "api/messages/models";
import { Button } from "components/miloDesignSystem/atoms/button";

export const useManufacturerOrderColumns = () => {
  const postEmailMutation = manufacturingActions.usePostManufacturingEmails();

  return useCreateTableColumns<ManufacturingOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 130,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => dateFns.formatRelative(new Date(row.createdAt)), {
        header: "utworzono",
        size: 110,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.materialsOrdersItems.length, {
        header: "l. pozycji",
        size: 70,
        textAlign: "right",
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "progres realizacji",
        size: 150,
        cell: info => {
          const row = info.getValue();
          const receivedOrders = row.materialsOrdersItems.filter(
            order => order.status === ManufacturingOrderStatus.RECEIVED,
          ).length;
          const progress = row.materialsOrdersItems.length
            ? (receivedOrders * 100) / row.materialsOrdersItems.length
            : 0;

          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <ProgressBar progress={progress} />
              <Typography fontSize="12" fontWeight="700">
                {progress.toFixed()}%
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status zlecenia",
        size: 130,
        cell: info => {
          const row = info.getValue();
          if (row.isCancelled) {
            return <Tag label="Anulowano" variant="warning" type="outlined" />;
          }
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[row.status]} />;
        },
      }),
      columnHelper.text(row => row.manufacturer.name, {
        header: "dostawca",
        size: 200,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.date(row => row.scheduledEmailSendAt, {
        header: "planowana wysyłka",
        size: 120,
        format: "formatRelative",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status email",
        size: 170,
        cell: info => {
          const order = info.getValue();
          if (!order.emailStatus)
            return <Tag label="nie wysłano" type="outlined" variant="danger" />;

          return (
            <div className="d-flex align-items-center gap-1">
              <Tag {...manufacturingConstants.emailStatusDict[order.emailStatus.status]} />
              {order.emailStatus.status === EmailStatus.DECLINED && (
                <Button
                  className="text-uppercase"
                  isLoading={postEmailMutation.isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    postEmailMutation.mutate({
                      orders: [order.id],
                    });
                  }}
                  size="small"
                  variant="gray"
                >
                  Wyślij teraz
                </Button>
              )}
            </div>
          );
        },
      }),
      columnHelper.date(row => row.orderedAt, {
        header: "zlecono dn.",
        size: 84,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.date(row => row.deliveryDeadlineAt, {
        header: "termin",
        size: 84,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.expectedDeliveryAt, {
        header: "przewidywany termin dostawy",
        size: 180,
        cell: info => {
          const expectedDeliveryAt = info.getValue();
          if (!expectedDeliveryAt) return <EmptyValue fontSize="14" fontWeight="600" />;

          const daysLeft = dateFns.differenceInDays(new Date(expectedDeliveryAt), new Date());

          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <Tag
                label={dateUtils.formatDateToDisplayOnlyDate(expectedDeliveryAt)}
                variant={daysLeft <= 1 ? "danger" : "quaternary"}
                type={daysLeft <= 1 ? "filled" : "outlined"}
              />
              {daysLeft >= 0 ? (
                <Typography
                  fontSize="12"
                  fontWeight="700"
                  color={daysLeft <= 1 ? "danger500" : "neutralBlack100"}
                >
                  pozostało {daysLeft} dni
                </Typography>
              ) : (
                <EmptyValue fontSize="12" fontWeight="700" />
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.deliveries, {
        header: "odbiorcy",
        size: 150,
        cell: info => {
          const deliveries = info.getValue();
          if (!Boolean(deliveries.length)) return <EmptyValue fontSize="14" fontWeight="400" />;
          return (
            <div className="d-flex align-items-center gap-2 cut-text">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="400" noWrap>
                {deliveries[0].name}
              </Typography>
              {deliveries.length > 1 && (
                <Typography color="deepPurple400" fontSize="14" fontWeight="600">
                  +{deliveries.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.deliveries, {
        header: "adresy dostaw",
        size: 200,
        cell: info => {
          const deliveries = info.getValue();
          if (!Boolean(deliveries.length)) return <EmptyValue fontSize="14" fontWeight="400" />;
          return (
            <div className="d-flex align-items-center gap-2 cut-text">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="400" noWrap>
                {deliveries[0].street}
                {deliveries[0].street && ","} {deliveries[0].postCode} {deliveries[0].city}
              </Typography>
              {deliveries.length > 1 && (
                <Typography color="deepPurple400" fontSize="14" fontWeight="600">
                  +{deliveries.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.date(row => row.receivedAt, {
        header: "odebrano",
        size: 100,
        format: "formatRelative",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "zlecił/-a",
        size: 55,
        cell: info => {
          const createdBy = info.getValue();
          return (
            <div className="d-flex w-100 justify-content-center">
              <Avatar size="sm" user={createdBy} />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const order = info.getValue();
          return (
            <FileDownloadHandler
              factoryFn={() =>
                manufacturingFileFactory.manufacturingMaterialOrders(order.id, order.signature)
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz etykietę">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
          );
        },
      }),
    ];
  });
};
