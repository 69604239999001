import { ApiFetcher } from "hooks/createApiQuery";
import { routesKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import {
  ManufacturingRoute,
  ManufacturingRouteListItem,
  Route,
  RouteListItem,
  RoutePostDeliveryInfo,
  RouteSynchronize,
} from "./models";
import { Pagination } from "api/types";
import { createPreviewFactory } from "utilities/createPreviewMiddleware";
import { formatSignatureForPreview } from "utilities";

const getRouteSynchronizeDetails = (id: number): ApiFetcher<RouteSynchronize[]> => ({
  key: routesKeys.routeSynchronize(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/synchronize/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

const postRouteSynchronize = (id: number) =>
  queryFetch<void>({
    method: "POST",
    url: "/routes/synchronize/" + id,
  });

const getRoutesForInvoicing = (search: string = ""): ApiFetcher<Pagination<any>> => ({
  key: routesKeys.routesForInvoicing(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/for-invoicing" + search,
    }),
});

const postStatusInExternalService = (data: { route: number }) =>
  queryFetch<void>({
    method: "POST",
    url: "/routes/set-status-in-external-service",
    data,
  });

const getManufacturingRoutes = (
  search: string,
): ApiFetcher<Pagination<ManufacturingRouteListItem>> => ({
  key: routesKeys.manufacturingRoutes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/routes/manufacturing${search}`,
    }),
});

const getManufacturingRoute = (id: number): ApiFetcher<ManufacturingRoute> => ({
  key: routesKeys.manufacturingRoute(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/manufacturing/" + id,
    }),
});

const getManufacturingRoutePositions = (
  id: number,
): ApiFetcher<{
  next: number | null;
  previous: number | null;
}> => ({
  key: routesKeys.manufacturingRoutePositions(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/routes/manufacturing/get-route-neighbor-ids?route_id=${id}`,
    }),
});

const getRoutePostDeliveryInfo = (id: number): ApiFetcher<RoutePostDeliveryInfo> => ({
  key: routesKeys.routePostDeliveryInfo(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/post-delivery-info/" + id,
    }),
});

const getRoutePostDeliveryInfos = (
  search: string,
): ApiFetcher<Pagination<RoutePostDeliveryInfo>> => ({
  key: routesKeys.routePostDeliveryInfos(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/post-delivery-info" + search,
    }),
});

export const routesPreviewFactory = createPreviewFactory({
  tradingDocumentPDF: (routeId: Route["id"], routeSignature: Route["signature"]) => ({
    url: `/routes/trading-document-pdf/${routeId}/${routeSignature}`,
  }),
  routePDF: (routeUuid: Route["uuidId"], routeSignature: Route["signature"], search: string) => ({
    url: `/routes/route-pdf/${routeUuid}/${routeSignature}?${search}`,
  }),
  receiptPDF: (route: Route, language: string) => ({
    url: `/routes/receipt-pdf/${route.id}/${language}/${formatSignatureForPreview(
      route.signature,
    )}`,
  }),
  productionPDF: (routeId: Route["id"], routeSignature: Route["signature"]) => ({
    url: `/routes/production-pdf/${routeId}/${routeSignature}`,
  }),
  groupedProtocolPDF: (route: Route) => ({
    url: `/routes/grouped-protocols-pdf/${route.id}/${formatSignatureForPreview(route.signature)}`,
  }),
  driverPDF: (route: Route, userId: number) => ({
    url: `/routes/driver-pdf/${route.id}/${formatSignatureForPreview(
      route.signature,
    )}?user=${userId}`,
  }),
  cmrPDF: (routeId: Route["id"], signature: Route["signature"], search?: string) => ({
    url: search
      ? `/routes/cmr-pdf/${routeId}/${formatSignatureForPreview(signature)}${search}`
      : `/routes/cmr-pdf/${routeId}/${formatSignatureForPreview(signature)}`,
  }),
  cmrPerOrderPDF: (routeId: Route["id"], search: string = "") => ({
    url: `/routes/cmr-pdf-per-order/${routeId}` + search,
  }),
  warehousePackagesLabels: (route: Route) => ({
    url: `/routes/warehouse-packages/${route.id}`,
  }),
  productsWithPackagesAndWeightPDF: (route: Route) => ({
    url: `/orders/products-with-packages-and-weight-pdf?route=${route.id}`,
  }),
  packingSlip: (route: Route) => ({
    url: `/utils/packing-slip/route/${route.id}`,
  }),
  packingSlipGroupedByOrders: (route: Route) => ({
    url: `/utils/packing-slip-grouped-by-orders/route/${route.id}`,
  }),
  routesProductionPDF: (routesIds: string) => ({
    url: `/utils/collection-production-pdf/routes-production-pdf?routes=${encodeURIComponent(
      routesIds,
    )}`,
  }),
  routeInvoicesPreview: (route: Route, userId: number) => ({
    url: `/routes/${route.id}/preview-invoices?user=${userId}`,
  }),
  ordersWithWeightPDF: (route: Route) => ({
    url: `/orders/orders-with-weight-pdf?route=${route.id}`,
  }),
  ordersLabels: (route: Route | RouteListItem) => ({
    url: `/utils/labels/route/${route.id}/${formatSignatureForPreview(route.signature)}`,
  }),
});

export const routeApi = {
  getRouteSynchronizeDetails,
  postRouteSynchronize,
  postStatusInExternalService,
  getManufacturingRoutes,
  getManufacturingRoutePositions,
  getManufacturingRoute,
  getRoutesForInvoicing,
  getRoutePostDeliveryInfo,
  getRoutePostDeliveryInfos,
};
