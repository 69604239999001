import { useRef, useState } from "react";

function initializeGeocoder(ref: React.MutableRefObject<any>) {
  if (ref.current) return ref.current;
  // @ts-ignore
  return new google.maps.Geocoder();
}
export function useGeocoder() {
  const geocoder = useRef(null);
  geocoder.current = initializeGeocoder(geocoder);
  return geocoder.current as any;
}

export function useGeocodeService() {
  const geocoder = useGeocoder();
  const [isLoading, setIsLoading] = useState(false);
  const geocode: (
    request: google.maps.GeocoderRequest,
    callback?:
      | ((a: google.maps.GeocoderResult[] | null, b: google.maps.GeocoderStatus) => void)
      | null,
  ) => Promise<google.maps.GeocoderResponse> = (request, callback) => {
    return geocoder.geocode(request, callback);
  };

  const handleGeocode = async (geocoderRequest: google.maps.GeocoderRequest) => {
    setIsLoading(true);
    const payload = await geocode(geocoderRequest);
    if (!payload?.results[0]) {
      setIsLoading(false);
      throw new Error("Nie możemy zlokalizować punktu");
    }
    const result = payload?.results[0];

    const location = result.geometry.location;
    const point = { lat: location.lat(), lng: location.lng() };
    const countryCode = (result.address_components || [])
      .filter(addressComponent => addressComponent.types.includes("country"))
      .map(addressComponent => addressComponent.short_name)[0];

    const [street, city, country] = result.formatted_address.split(",");

    setIsLoading(false);
    if (!countryCode || !point) {
      throw new Error("Nie możemy zlokalizować punktu");
    }

    return {
      point,
      countryCode,
      street,
      city,
      country,
    };
  };

  return { handleGeocode, isLoading };
}
